import React, { useMemo } from "react";
import { createCommunicatorHook } from "../../Helpers/Communicator";
import {
  InterventionPlaceTypes,
  InterventionPdfButton
} from "../../Components/Interventions/Interventions";
import { InterventionFormButton } from "../Form/FormButtons";
import { FormattedMessage } from "react-intl";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import { originTypes } from "../../Helpers/MiscHelper";
import { LabelTitle } from "../../Helpers/GridHelper";
import { InterventionsClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { spaceInterventionsSchema } from "../../config/schema";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import classnames from "classnames";
import {
  getColumnDropdownTarget,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignJustify,
  faCalendarAlt,
  faUserClock,
  faTruckClock,
  faPauseCircle,
  faClock
} from "@fortawesome/pro-light-svg-icons";
import {
  createDepartmentFilter,
  createFollowingFilter,
  createIndirectClientFilter,
  createIndirectContractFilter,
  createIndirectDealFilter,
  createIndirectProjectFilter,
  createInterventionTypeFilter,
  createNotFollowingFilter,
  createOrganizationFilter,
  createOriginFilter,
  createReadFilter,
  createTeamFilter,
  createUnreadFilter,
  createUserFilter
} from "../../Helpers/FilterHelper";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { InterventionTargetDropdown } from "../AdvancedMultiInputs";

export const getTotalInterventionDuration = (
  beginDate,
  endDate,
  TotalPauses
) => {
  let milsecs = new Date(endDate).getTime() - new Date(beginDate).getTime();

  if (TotalPauses) milsecs = milsecs - TotalPauses;

  return milsecs;
};

export const INTERVENTION_CREATED = "INTERVENTION_CREATED";

export const INTERVENTION_UPDATED = "INTERVENTION_CREATED";

export const useInterventionCommunicator = createCommunicatorHook();

export const defaultInterventionForm = () => {
  const EndDate = new Date();

  const BeginDate = new Date(EndDate);
  BeginDate.setHours(9);
  BeginDate.setMinutes(0);
  BeginDate.setSeconds(0);
  BeginDate.setMilliseconds(0);

  return {
    InterventionTarget: null,
    EstimatedCost: 3600000, //1 hora
    TotalPauses: 0,
    Place: "",
    BeginDate,
    EndDate: EndDate,
    Type: null,
    PlaceType: InterventionPlaceTypes.remote,
    Description: "",
    InternalDescription: "",
    Files: null
  };
};

export const convertInterventionEntityToForm = (entity, props) => {
  if (!entity) {
    if (props.taskOrigin) {
      const { BeginDate, EndDate, EstimatedCost } = props.taskOrigin;

      return {
        InterventionTarget:
          getColumnDropdownTarget(props) ||
          getInterventionTarget(props.taskOrigin),
        BeginDate,
        EndDate,
        EstimatedCost
      };
    }
    return;
  }

  const { Call, Project, Task, Client, Ticket, Team, ...entityRest } = entity;

  return {
    ...entityRest,
    InterventionTarget:
      getColumnDropdownTarget(props) || getInterventionTarget(entity)
  };
};

export const validateInterventionFormNoMinDescript = (
  {
    InterventionTarget,
    EstimatedCost,
    BeginDate,
    EndDate,
    Description,
    InterventionType,
    InefficientDuration,
    TimeDetail,
    PlaceType,
    DeslocationTime,
    InternalDescription,
    TimeDetailDescription
    // EndDate,
    // Contract,
    // Place
  },
  addError
) => {
  if (PlaceType === InterventionPlaceTypes.onSite && DeslocationTime === 0)
    addError("DeslocationTime");
  if (!InterventionTarget) addError("InterventionTarget");
  if (typeof InefficientDuration === "number" && InefficientDuration > 0) {
    if (!TimeDetail) addError("TimeDetail");
    if (!TimeDetailDescription) addError("TimeDetailDescription");
  }
  // if (!EstimatedCost) addError("EstimatedCost");
  // if (!Place) addError("Place");
  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
  if (!InterventionType) addError("InterventionType");
  if (!Description) addError("Description");
  if (Description && Description?.length > 65535)
    addError("Description", <FormattedMessage id="DESCRIPTION_TOO_BIG" />);
  if (InternalDescription && InternalDescription?.length > 65535)
    addError(
      "InternalDescription",
      <FormattedMessage id="INTERNAL_DESCRIPTION_TOO_BIG" />
    );

  // if (!Contract) addError("Contract");
};

export const validateInterventionFormNoMinDescriptNoTravel = (
  {
    InterventionTarget,
    EstimatedCost,
    BeginDate,
    EndDate,
    Description,
    InterventionType,
    InefficientDuration,
    TimeDetail,
    PlaceType,
    DeslocationTime,
    TimeDetailDescription,
    InternalDescription
    // EndDate,
    // Contract,
    // Place
  },
  addError
) => {
  if (!InterventionTarget) addError("InterventionTarget");
  if (typeof InefficientDuration === "number" && InefficientDuration > 0) {
    if (!TimeDetail) addError("TimeDetail");
    if (!TimeDetailDescription) addError("TimeDetailDescription");
  }
  // if (!EstimatedCost) addError("EstimatedCost");
  // if (!Place) addError("Place");
  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );
  if (!InterventionType) addError("InterventionType");
  if (!Description) addError("Description");

  // if (!Contract) addError("Contract");
};

export const validateInterventionForm = (
  {
    InterventionTarget,
    EstimatedCost,
    BeginDate,
    EndDate,
    Description,
    InterventionType,
    InefficientDuration,
    TimeDetail,
    TimeDetailDescription
    // EndDate,
    // Contract,
    // Place
  },
  addError
) => {
  var parser = new DOMParser();
  var dom = parser.parseFromString(
    "<!doctype html><body>" + Description,
    "text/html"
  );
  var ResolvedDescription = dom.body.textContent;

  if (!InterventionTarget) addError("InterventionTarget");
  // if (!EstimatedCost) addError("EstimatedCost");
  // if (!Place) addError("Place");
  if (!BeginDate) addError("BeginDate");
  if (InefficientDuration > 0) {
    if (!TimeDetail) addError("TimeDetail");
    if (!TimeDetailDescription) addError("TimeDetailDescription");
  }
  if (!EndDate) addError("EndDate");
  if (!Description) addError("Description");
  if (ResolvedDescription.trim().length < 30) addError("Description");
  if (!InterventionType) addError("InterventionType");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );

  // if (!Contract) addError("Contract");
};

export const InterventionEntityButtons = ({ entity, skeleton }) => {
  return (
    <React.Fragment>
      <InterventionFormButton
        className="mr-2"
        id={entity.Id}
        canEdit={entity.CanEdit}
        skeleton={skeleton}
      />
      <InterventionFormButton
        className="mr-2"
        skeleton={skeleton}
        alt
        id={entity.Id}
        duplicate
      />
      <InterventionPdfButton id={entity.Id} skeleton={skeleton} />
    </React.Fragment>
  );
};

export const InterventionEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  children,
  detailsView
}) => {
  return (
    <>
      {!sidebar && !detailsView && (
        <InterventionFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && (
        <InterventionFormButton globalEntityView id={entity.Id} />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <InterventionFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <InterventionFormButton id={entity.Id} duplicate />
      <InterventionPdfButton LineButton id={entity.Id} />
      <InterventionsClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceInterventionsSchema} />
      <DeleteButtonContextMenu entity={entity} />
      {children}
    </>
  );
};

export const InterventionEntityActionsButton = ({ minimal, ...props }) => {
  return (
    <EntityPopup minimal={minimal}>
      <InterventionEntityActionsChildren minimal={minimal} {...props} />
    </EntityPopup>
  );
};

export const InterventionEntityGridButtons = ({
  entity,
  minimal,
  hideBreadCrumbs
}) => {
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        <InnerColorFilterBars />
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className={classnames("mr-2 fs-14 d-inline-flex text-primary", {
              "ml-1": conds?.length > 0
            })}
            originType={originTypes.timecharge}
            minimal
            id={entity.Id}
          />
        )}
        <InterventionFormButton
          minimal={minimal}
          className={classnames("mr-2 FormButtonClassID", {
            "ml-1": conds?.length > 0 && hideBreadCrumbs
          })}
          id={entity.Id}
          canEdit={entity.CanEdit}
        />
        <InterventionEntityActionsButton entity={entity} minimal={minimal} />
      </div>
    </TriggeredColoredFilterProvider>
  );
};

export const useInterventionFilters = (userId) => {
  return useMemo(() => {
    return [
      {
        propField: "NoTagDescription",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "REPORT_CLIENT",
        field: "TimeCharge/NoTagDescription",
        type: "string"
      },
      createUserFilter({
        propField: "Account",
        titleId: "CREATED_BY",
        field: "Account/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />{" "}
              <ConnectedAccountProfile maxWidth account={value} />
            </div>
          );
        }
      }),
      createIndirectClientFilter({
        field: "DirectIndirectClient/Id"
      }),
      createIndirectContractFilter({
        field: "DirectIndirectContract/Id"
      }),
      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "END_DATE",
        field: "TimeCharge/EndDateNoHours",
        type: "dateTime"
      },
      {
        propField: "CreationDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "CREATION_DATE",
        field: "TimeCharge/CreationDate",
        type: "dateTime"
      },
      {
        icon: <FontAwesomeIcon icon={faPauseCircle} />,
        titleId: "TOTAL_PAUSES",
        field: "TimeCharge/PauseTime",
        type: "number"
      },
      {
        icon: <FontAwesomeIcon icon={faTruckClock} />,
        titleId: "TRAVEL_TIME",
        field: "TimeCharge/DeslocationTime",
        type: "number"
      },
      {
        icon: <FontAwesomeIcon icon={faClock} />,
        titleId: "INEFFICIENT_TIME",
        field: "TimeCharge/InefficientDuration",
        type: "number"
      },
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "TimeCharge/BeginDateNoHours",
        type: "dateTime"
      },
      createDepartmentFilter(),
      createOrganizationFilter({ field: "Company/Id" }),
      createTeamFilter(),

      createIndirectDealFilter({
        field: "DirectIndirectDeal/Id"
      }),
      createReadFilter({
        baseField: "TimeCharge"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faUserClock} />,
        titleId: "TIME_NR",
        field: "TimeCharge/Id",
        type: "string"
      },
      createUnreadFilter({
        baseField: "TimeCharge"
      }),
      createFollowingFilter({
        baseField: "TimeCharge"
      }),
      createNotFollowingFilter({
        baseField: "TimeCharge"
      }),
      createOriginFilter({
        baseFieldName: "TimeCharge",
        dropdownType: InterventionTargetDropdown,
        allTasks: 0
      }),
      createIndirectProjectFilter({
        field: "DirectIndirectProject/Id"
      }),
      createInterventionTypeFilter({
        propField: "InterventionType",
        icon: <FontAwesomeIcon icon={faUserClock} />,
        field: "Timecharge/EntityTypeId",
        All: true
      })
    ];
  }, []);
};
