import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ConnectedBreadcrumbs } from "../../../Components/Breadcrumbs/Breadcrumbs";
import DockTyper from "../../../Components/DocTyper/DocTyper";
import FileUploader from "../../../Components/FileUploader/FileUploader";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import { originTypes } from "../../../Helpers/MiscHelper";
import {
  ClientDropdown,
  ContactDropdown,
  EmailSignaturesTypesDropdown,
  OrganizationDropdown,
  UserDropdown,
  ConversationTargetDropdown
} from "../../AdvancedMultiInputs";
import { ReceptionEmailAssociator } from "../ReceptionEmails/ReceptionEmailAssociations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

const ConvBreadcrumbs = ({ taskId, dealId, ticketId, className }) => {
  const target = useMemo(() => {
    let type;

    if (taskId) type = originTypes.task;
    else if (ticketId) type = originTypes.ticket;
    else if (dealId) type = originTypes.deals;
    else return null;

    return {
      type,
      data: dealId || taskId || ticketId
    };
  }, [dealId, taskId, ticketId]);

  if (!target) return null;

  return <ConnectedBreadcrumbs value={target} className={className} />;
};

const EmailFormContent = ({
  formState,
  organizations,
  pipelines,
  Automation,
  disableManual,
  id,
  canEdit,
  disableAttachments,
  disableConversations
}) => {
  const { form, handleChange } = formState;
  const { Subject } = form;

  const changes = (e) => {
    const { name, value } = e.target;
    handleChange({
      [name]: value
    });
  };

  // const [enableManualTo, setEnableManualTo] = useState(false);
  // const [enableManualCC, setEnableManualCC] = useState(false);
  // const [enableManualBCC, setEnableManualBCC] = useState(false);

  const handleTicketTargetChange = (e) => {
    handleChange({
      ConversationTarget: e.target.value
      // Contact: null
    });
  };

  const {
    Tickets,
    Deals,
    ConversationTarget,
    Tasks,
    enableManualTo,
    enableManualCC,
    enableManualBCC,
    ticketId: fTicketId,
    dealId: fDealId,
    taskId: fTaskId
  } = form;

  return (
    <>
      {Automation && (
        <FormInput
          textId="CREATED_BY"
          name="Creator"
          inputType={UserDropdown}
          className="mb-3"
        />
      )}

      <FormInput
        name="Company"
        text={<FormattedMessage id={"COMPANY"} />}
        inputType={OrganizationDropdown}
        // onChange={handleCompanyChange}
        className="mb-3"
      />

      {!Automation && (
        <ConvBreadcrumbs
          className="mb-3"
          taskId={fTaskId}
          ticketId={fTicketId}
          dealId={fDealId}
        />
      )}

      <FormInput
        className="mb-3"
        name="Client"
        text={<FormattedMessage id={"ACCOUNT"} />}
        inputType={ClientDropdown}
      />

      {Automation && (
        <FormInput
          name="ConversationTarget"
          textId="ASSOCIATE_TO"
          inputType={ConversationTargetDropdown}
          allClients={false}
          value={ConversationTarget}
          onChange={handleTicketTargetChange}
          className="mb-3"
        />
      )}

      {!Automation && !fTicketId && !fDealId && !fTaskId && (
        <ReceptionEmailAssociator
          Tasks={Tasks}
          Tickets={Tickets}
          Deals={Deals}
          CanEdit={canEdit}
          onChange={handleChange}
        />
      )}

      {!enableManualTo && (
        <FormInput
          className={(!Automation || disableManual) && "mb-3"}
          name="To"
          text={<FormattedMessage id={"EMAIL_TO"} />}
          inputType={ContactDropdown}
          multiple
          hasEmail
        />
      )}
      {enableManualTo && (
        <FormInput
          className={!Automation && "mb-3"}
          name="ToManual"
          text={<FormattedMessage id={"EMAIL_TO"} />}
          title="Write the emails, if more than one, separate them using a comma ','"
          // inputType={ContactDropdown}
          // multiple
          // hasEmail
        />
      )}
      {!disableManual && Automation && !enableManualTo && (
        <div
          onClick={() => {
            handleChange({
              enableManualTo: true
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="MANUAL" />
        </div>
      )}
      {!disableManual && Automation && enableManualTo && (
        <div
          onClick={() => {
            handleChange({
              enableManualTo: false
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="BACK" />
        </div>
      )}

      {!enableManualCC && (
        <FormInput
          className={(!Automation || disableManual) && "mb-3"}
          name="Cc"
          text={"Cc"}
          inputType={ContactDropdown}
          multiple
          hasEmail
        />
      )}
      {enableManualCC && (
        <FormInput
          className={!Automation && "mb-3"}
          name="CcManual"
          text={"Cc"}
          title="Write the emails, if more than one, separate them using a comma ','"
          // inputType={ContactDropdown}
          // multiple
          // hasEmail
        />
      )}
      {!disableManual && Automation && !enableManualCC && (
        <div
          onClick={() => {
            handleChange({
              enableManualCC: true
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="MANUAL" />
        </div>
      )}
      {!disableManual && Automation && enableManualCC && (
        <div
          onClick={() => {
            handleChange({
              enableManualCC: false
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="BACK" />
        </div>
      )}

      {!enableManualBCC && (
        <FormInput
          className={(!Automation || disableManual) && "mb-3"}
          name="Bcc"
          text={"Bcc"}
          inputType={ContactDropdown}
          multiple
          hasEmail
        />
      )}
      {enableManualBCC && (
        <FormInput
          className={!Automation && !disableManual && "mb-3"}
          name="BccManual"
          text={"Bcc"}
          title="Write the emails, if more than one, separate them using a comma ','"
          // inputType={ContactDropdown}
          // multiple
          // hasEmail
        />
      )}
      {!disableManual && Automation && !enableManualBCC && (
        <div
          onClick={() => {
            handleChange({
              enableManualBCC: true
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="MANUAL" />
        </div>
      )}
      {!disableManual && Automation && enableManualBCC && (
        <div
          onClick={() => {
            handleChange({
              enableManualBCC: false
            });
          }}
          className="cursor-pointer fs-12 text-primary mb-3"
        >
          <FormattedMessage id="BACK" />
        </div>
      )}

      <FormInput
        className="mb-3"
        name="Subject"
        onChange={changes}
        value={Subject}
        text={<FormattedMessage id={"SUBJECT"} />}
      />

      <FormInput
        className="mb-3"
        name="Text"
        isHtmlInput
        extraMenuItemHtmlEditorButton
        inputType={DockTyper}
        text={<FormattedMessage id={"MESSAGE"} />}
      />

      <FormInput
        className="mb-3"
        name="Signature"
        inputType={EmailSignaturesTypesDropdown}
        type={1}
        text={<FormattedMessage id={"SIGNATURE"} />}
      />

      {!disableConversations && (
        <FormInput
          name="Followers"
          textId="FOLLOWERS"
          inputType={UserDropdown}
          multiple
          // items={possibleFollowers}
          className="mb-3"
        />
      )}

      {!disableAttachments && (
        <FormInput
          text={<FormattedMessage id={"ATTACHMENTS"} />}
          name="Files"
          inputType={FileUploader}
          enableSwapper
          className="mb-3 form-Anexos-floater"
        />
      )}
    </>
  );
};

export default EmailFormContent;
