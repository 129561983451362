import React, { useContext, useState } from "react";

import { useSpacePost, usePost, getEntity } from "../../Helpers/IOClient";
// import { usePost } from "../../Helpers/HooksHelper";
import { useSpace } from "../../Contexts/SpaceContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import Button from "../Button/Button";
import moment from "moment";
import classes from "./SendNotification.module.css";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import { EntityPopupContext } from "../EntityPopup/EntityPopupHelper";
import { FormattedMessage, useIntl } from "react-intl";
import { useTicket, useContact } from "../../Hooks/EntityHooks";

let lastDate = {};

export const SendNotificationButton = ({
  Schema,
  Id,
  alt,
  toggler,
  textId
}) => {
  const onSuccess = useContext(EntityPopupContext);
  if (alt) {
    return (
      <>
        <Button
          className="ssi-button ssi-button-grid rounded mr-2 sm"
          vType="primary-ghost"
          onClick={() => {
            toggler();
            onSuccess();
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </Button>
      </>
    );
  } else
    return (
      <>
        <Button
          className="ssi-button ssi-control ssi-button-link text-left"
          vType="link"
          onClick={() => {
            toggler();
            onSuccess();
          }}
        >
          <div className="d-flex align-items-center">
            <div className="mr-1 ar-form-button-link-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>

            <span>
              <FormattedMessage id={textId || "SEND_NOTIFICATION"} />
            </span>
          </div>
        </Button>
      </>
    );
};

export const SendPostNotificationButton = ({ Schema, Id, alt }) => {
  const space = useSpace();
  const [loadingButton, setLoadingButton] = useState(false);
  const onSuccess = useContext(EntityPopupContext);

  //   const { data, error, loading, refetch } = useQuery(
  //     `${Schema.getEndpoint(space.Id)}/${Id}/Notification`,
  //     null,
  //     {
  //       autoFetch: false
  //     }
  //   );
  const createToast = useToast();
  const intl = useIntl();
  const [post] = usePost(
    `${Schema.getEndpoint(space.Id)}/EmailClientReport`,
    null,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.FormattedMessage({ id: "NOTIFICATION_SUCCESS" })
        });
        onSuccess();
        setLoadingButton(false);
      },
      onError: ({ error }) => {
        handleError(createToast, error);
        setLoadingButton(false);
      }
    }
  );

  const click = () => {
    if (lastDate[Id] && moment().diff(moment(lastDate[Id]), "seconds") < 60)
      return;
    lastDate[Id] = new Date();
    setLoadingButton(true);
    post({
      Data: Array.isArray(Id) ? Id : [Id]
    });
  };

  if (alt) {
    return (
      <Button
        className="ssi-button ssi-button-grid rounded mr-2 sm"
        vType="primary-ghost"
        disabled={
          (lastDate[Id] &&
            moment().diff(moment(lastDate[Id]), "seconds") < 60) ||
          loadingButton
        }
        onClick={click}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </Button>
    );
  } else
    return (
      <Button
        className="ssi-button ssi-control ssi-button-link text-left"
        vType="link"
        disabled={
          (lastDate[Id] &&
            moment().diff(moment(lastDate[Id]), "seconds") < 60) ||
          loadingButton
        }
        onClick={click}
      >
        <div className="d-flex align-items-center">
          <div className="mr-1 ar-form-button-link-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>

          <span>
            <FormattedMessage id="SEND_NOTIFICATION" />
          </span>
        </div>
      </Button>
    );
};
