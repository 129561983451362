import React, { useMemo, useContext } from "react";
import { AccountSpace } from "../../Interfaces/EntityInterfaces";
import {
  moduleTypes,
  isAdmin,
  modulePermissions,
  hasModulePermission,
  isDeveloper,
  hasInterventionReportAccess,
  hasProjectsReportAccess,
  hasTicketsReportAccess,
  hasTasksReportAccess,
  hasDealsReportAccess,
  hasContractsReportAccess,
  hasReportsClassificationAccess,
  isOwner,
  isSupport,
  hasModuleAccess,
  canUserReadModule
} from "../../Helpers/ModulesHelper";
import {
  useCurrentAccountSpace,
  useCurrentAccount
} from "../../Contexts/UserContext";
import {
  spaceClientSchema,
  spaceContactSchema,
  spaceDealsSchema,
  spaceContractSchema,
  spaceCallsSchema,
  spaceProjectsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceInterventionsSchema,
  spaceSubscriptionSchema,
  spaceImportsSchema,
  spaceSocialPostsSchema,
  spaceEmailSignatureSchema,
  spaceAutomationSchema,
  spaceSchema,
  spaceDocumentsSchema,
  spaceDocTemplatesSchema
} from "../../config/schema";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faSuitcase,
  faUserTie,
  faMessagesDollar,
  faFileContract,
  faFileInvoice,
  faPhone,
  faSquareParking,
  faTicketSimple,
  faListCheck,
  faUserClock,
  faArrowAltUp,
  faFileChartLine,
  faFileSpreadsheet,
  faMemoCircleCheck,
  faFileChartPie,
  faShareAlt,
  faAnalytics,
  faCalendarExclamation,
  faCodeMerge,
  faFileImport,
  faSignature,
  faUserChart,
  faFileLines,
  faTableLayout
} from "@fortawesome/pro-solid-svg-icons";
// import SidebarTestView from "../../Components/Sidebar/SidebarTestView";

import Deals from "../Deals/Deals";
import Clients from "../Clients/Clients";
import Contacts from "../Contacts/Contacts";
import Contracts from "../Contracts/Contracts";
import Subscriptions from "../Subscriptions/Subscriptions";
import Classification from "../Classification/Classification";
import Billing from "../Billing/BillingOverview/BillingOverviewComp";
import Calls from "../Calls/Calls";
import Projects from "../Projects/Projects";
import Tickets from "../Tickets/Tickets";
import Tasks from "../Tasks/Tasks";
import Interventions from "../Interventions/Interventions";
import { FormattedMessage } from "react-intl";
import SpaceList from "../Spaces/SpaceList";
import FirstWelcome from "../FirstWelcome/FirstWelcome";
import ReportingOverviewComp from "../Reports/ReportingOverviewComp";
import SubscriptionCanceledView, {
  SubscriptionTrialEndView
} from "../SubscriptionCanceledErrorView/SubscriptionCanceledView";
import htmlEditorNewRouteTestPage from "../../Components/HTMLInput/htmlEditorNewRouteTestPage";
import Accounts from "../Accounts/Accounts";
import CalendarTestView from "../../Components/Calendar/CalendarTestView";
import { Redirect } from "react-router";
import { useSpace } from "../../Contexts/SpaceContext";
import Automation from "../Automation/Automation";
import { faStackpath } from "@fortawesome/free-brands-svg-icons";
import { PermissionModuleTypes } from "../Settings/Users/Permissions/PermissionsHelper";
import Exports from "../Settings/Exports/Exports";
import { getEntity } from "../../Helpers/IOClient";
import { useLocation } from "react-router-dom";

const Priorities = React.lazy(
  () => import(/* webpackChunkName: "priorities" */ "../Priorities/Priorities")
);

const ReportsProjects = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reportProjects" */ "../Reports/ReportsProjects"
    )
);

const ReportsContracts = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reportContracts" */ "../Reports/ReportsContracts"
    )
);

const TimeReports = React.lazy(
  () => import(/* webpackChunkName: "reportTimes" */ "../Reports/TimesReport")
);

const NewTimeReports = React.lazy(
  () =>
    import(/* webpackChunkName: "reportTimes" */ "../Reports/NewTimesReport")
);

const TravelDurationGrid = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TravelReports" */ "../Reports/TravelReports/TravelReports"
    )
);

const ReportsDeals = React.lazy(
  () => import(/* webpackChunkName: "reportDeals" */ "../Reports/ReportsDeals")
);

const ReportsTasks = React.lazy(
  () => import(/* webpackChunkName: "reportTasks" */ "../Reports/ReportsTasks")
);

const ReportsTickets = React.lazy(
  () =>
    import(/* webpackChunkName: "reportTickets" */ "../Reports/ReportsTickets")
);

const Account = React.lazy(
  () => import(/* webpackChunkName: "account" */ "../Accounts/Account")
);

const Imports = React.lazy(
  () => import(/* webpackChunkName: "imports" */ "../Settings/Imports/Imports")
);

const Dashboard = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ "../Dashboard/Dashboard")
);

// const Reports = React.lazy(() =>
//   import(/* webpackChunkName: "reports" */ "../Reports/Reports")
// );
const ClassificationReport = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reportsClassification" */ "../Reports/ClassificationReport"
    )
);

const ProductivityReport = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reports" */ "../Reports/Productivity/ProductivityReport"
    )
);
const PersonalProductivityReport = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reportsPersonalProduc" */ "../Reports/Productivity/PersonalProductivityReport"
    )
);

const Settings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "settings" */ "../../Containers/Settings/Settings"
    )
);

const SocialPosts = React.lazy(
  () =>
    import(
      /* webpackChunkName: "socialPosts" */ "../../Containers/SocialPosts/SocialPosts"
    )
);

const Logs = React.lazy(
  () => import(/* webpackChunkName: "logs" */ "../Logs/Logs")
);

const Documents = React.lazy(
  () => import(/* webpackChunkName: "documents" */ "../Documents/Documents")
);

const Templates = React.lazy(
  () =>
    import(/* webpackChunkName: "doctemplates" */ "../Documents/DocTemplates")
);

const CacheControl = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CacheControl" */ "../CacheControl/CacheControl"
    )
);
const QueueControl = React.lazy(
  () =>
    import(/* webpackChunkName: "QueueControl" */ "../QueueViews/QueueOverview")
);

const UserControl = React.lazy(
  () =>
    import(/* webpackChunkName: "UserControl" */ "../UserControl/UserControl")
);

const MaintenanceControl = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MaintenanceControl" */ "../Maintenance/MaintenanceControl"
    )
);

const BillingPlanControl = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BillingPlanControl" */ "../BillingPlans/BillingPlan"
    )
);

const WorkspaceSubscriptionControl = React.lazy(
  () =>
    import(
      /* webpackChunkName: "WorkspaceSubscriptionControl" */ "../WorkspaceSubscription/WorkspaceSubscription"
    )
);

// const PaymentPlanControl = React.lazy(() =>
//   import(/* webpackChunkName: "PaymentPlanControl" */ "../Payments/Payments")
// );

const CuponListControl = React.lazy(
  () =>
    import(/* webpackChunkName: "CuponListControl" */ "../CuponList/CuponList")
);

const ReceptionControl = React.lazy(
  () =>
    import(/*webpackChunkName: "ReceptionControl" */ "../Reception/Reception")
);

// const PaymentUpgradePlanControl = React.lazy(() =>
//   import(
//     /* webpackChunkName: "PaymentUpgradePlanControl" */ "../Payments/PaymentsUpgrades"
//   )
// );

const EmailSignatureControl = React.lazy(
  () =>
    import(
      /*webpackChunkName: "EmailSignatureControl" */ "../EmailSignature/EmailSignature"
    )
);

export interface IMainRoute {
  moduleType?: moduleTypes;
  readModuleType?: moduleTypes;
  hasAccess?: (accountSpace: AccountSpace) => boolean;
  DeveloperOnly?: boolean;
  SupportOnly?: boolean;
  RequiresPayment?: boolean;
  OwnerOnly?: boolean;
  path: string;
  text: any;
  Component: any;
  exact?: true;
  schema?: any;
  icon?: IconDefinition;
  lsKey?: string;
  unreadEntityName?: string;
}

export const dashboardRoute: IMainRoute = {
  hasAccess: () => true,
  path: "",
  exact: true,
  text: "Dashboard",
  lsKey: "dashboard",
  Component: () => {
    const space = useSpace();
    return <Redirect to={`/s/${space.Id}/dashboard`} />;
  },
  icon: faUserChart
};

export const reportDashboardRoute: IMainRoute = {
  hasAccess: () => true,
  exact: true,
  path: "dashboard",
  text: "Dashboard",
  lsKey: "dashboard",
  Component: Dashboard,
  icon: faUserChart
};

const testSettings = (accountSpace: AccountSpace) => {
  if (isAdmin(accountSpace)) return true;

  const { Modules } = accountSpace;

  let result = false;

  const filteredSetingsList = PermissionModuleTypes.filter(
    (e) => Boolean(e.isService) === false && Boolean(e.isHeader) === false
  );

  for (const t of filteredSetingsList) {
    result =
      typeof t.type === "string"
        ? hasModulePermission(Modules[t.type], modulePermissions.Access)
        : false;
    if (result) return result;
  }
  return result;

  // return hasModulePermission(Modules.configurations, modulePermissions.Access);

  // return (
  //   hasModulePermission(Modules.organizations, modulePermissions.Access) ||
  //   hasModulePermission(Modules.departments, modulePermissions.Access) ||
  //   hasModulePermission(Modules.users, modulePermissions.Access) ||
  //   hasModulePermission(Modules.teams, modulePermissions.Access)
  // );
};

export const settingsRoute: IMainRoute = {
  hasAccess: (accountSpace) => testSettings(accountSpace),
  path: "settings",
  text: <FormattedMessage id={"SETTINGS"} />,
  Component: Settings
};
export const GlobalExportsRoute: IMainRoute = {
  hasAccess: (accountSpace) =>
    isAdmin(accountSpace) ||
    hasModuleAccess(accountSpace.Modules[moduleTypes.settingsSpace]),
  path: "spaceExports",
  text: <FormattedMessage id={"EXPORTS"} />,
  Component: Exports
};

export const clientRoute: IMainRoute = {
  moduleType: moduleTypes.clients,
  path: "customers",
  text: <FormattedMessage id={"ACCOUNTS"} />,
  Component: Clients,
  schema: spaceClientSchema,
  icon: faSuitcase,
  unreadEntityName: "Clients"
};

export const ImportsRoute: IMainRoute = {
  moduleType: moduleTypes.imports,
  path: "imports",
  text: <FormattedMessage id={"IMPORTS"} />,
  Component: Imports,
  schema: spaceImportsSchema,
  icon: faFileImport
};

export const contactRoute: IMainRoute = {
  moduleType: moduleTypes.contacts,
  path: "contacts",
  text: <FormattedMessage id={"CONTACTS"} />,
  Component: Contacts,
  schema: spaceContactSchema,
  icon: faUserTie
};

export const dealRoute: IMainRoute = {
  moduleType: moduleTypes.deals,
  path: "deals",
  text: <FormattedMessage id={"DEALS"} />,
  Component: Deals,
  schema: spaceDealsSchema,
  icon: faMessagesDollar,
  unreadEntityName: "Deals"
};

export const contractRoute: IMainRoute = {
  moduleType: moduleTypes.contracts,
  path: "contracts",
  text: <FormattedMessage id={"CONTRACTS"} />,
  Component: Contracts,
  schema: spaceContractSchema,
  icon: faFileContract,
  unreadEntityName: "Contracts"
};

export const subscriptionRoute: IMainRoute = {
  moduleType: moduleTypes.subscriptions,
  path: "subscriptions",
  text: <FormattedMessage id={"SUBSCRIPTIONS"} />,
  Component: Subscriptions,
  schema: spaceSubscriptionSchema,
  icon: faCalendarExclamation,
  unreadEntityName: "Subscriptions"
};

export const socialPostsRoute: IMainRoute = {
  moduleType: moduleTypes.socialPost,
  path: "social",
  text: <FormattedMessage id={"PUBLICATIONS"} />,
  Component: SocialPosts,
  schema: spaceSocialPostsSchema,
  icon: faShareAlt
};

export const classificationRoute: IMainRoute = {
  moduleType: moduleTypes.classification,
  readModuleType: moduleTypes.interventions,
  hasAccess: (accountSpace) =>
    isAdmin(accountSpace) ||
    hasModuleAccess(accountSpace.Modules[moduleTypes.classification]),
  path: "classification",
  text: <FormattedMessage id={"CLASSIFICATION"} />,
  Component: Classification,
  icon: faMemoCircleCheck
};

export const billingRoute: IMainRoute = {
  moduleType: moduleTypes.billing,
  hasAccess: (accountSpace) =>
    isAdmin(accountSpace) ||
    hasModuleAccess(accountSpace.Modules[moduleTypes.billing]),
  path: "billing",
  text: <FormattedMessage id={"BILLING"} />,
  Component: Billing,
  icon: faFileInvoice
};

export const callRoute: IMainRoute = {
  moduleType: moduleTypes.calls,
  path: "calls",
  text: <FormattedMessage id={"CALLS"} />,
  Component: Calls,
  schema: spaceCallsSchema,
  icon: faPhone,
  unreadEntityName: "Calls"
};

export const projectRoute: IMainRoute = {
  moduleType: moduleTypes.projects,
  path: "projects",
  text: <FormattedMessage id={"PROJECTS"} />,
  Component: Projects,
  schema: spaceProjectsSchema,
  icon: faSquareParking,
  unreadEntityName: "Projects"
};

export const ticketRoute: IMainRoute = {
  moduleType: moduleTypes.tickets,
  path: "tickets",
  text: <FormattedMessage id={"CASES"} />,
  Component: Tickets,
  schema: spaceTicketsSchema,
  icon: faTicketSimple,
  unreadEntityName: "Tickets"
};

export const taskRoute: IMainRoute = {
  moduleType: moduleTypes.tasks,
  path: "tasks",
  text: <FormattedMessage id={"TASKS"} />,
  Component: Tasks,
  schema: spaceTasksSchema,
  icon: faListCheck,
  unreadEntityName: "Tasks"
};

export const interventionRoute: IMainRoute = {
  moduleType: moduleTypes.interventions,
  path: "interventions",
  text: <FormattedMessage id={"TIME"} />,
  Component: Interventions,
  schema: spaceInterventionsSchema,
  icon: faUserClock,
  unreadEntityName: "TimeCharges"
};

export const emailSignatureRoute: IMainRoute = {
  moduleType: moduleTypes.emailSignatures,
  path: "signatures",
  text: <FormattedMessage id={"EMAIL_SIGNATURE"} />,
  Component: EmailSignatureControl,
  schema: spaceEmailSignatureSchema,
  icon: faSignature
};

export const SubscriptionCanceledRoute: IMainRoute = {
  // moduleType: moduleTypes.subscriptionCanceled,
  path: "SubscriptionCanceled",
  hasAccess: () => {
    return true;
  },
  text: <FormattedMessage id={"CANCELED_SUBSCRIPTION"} />,
  Component: SubscriptionCanceledView
};

export const SubscriptionTrialEndRoute: IMainRoute = {
  hasAccess: () => {
    return true;
  },
  path: "SubscriptionTrialEnd",
  text: <FormattedMessage id={"TRIAL_ENDED"} />,
  Component: SubscriptionTrialEndView
};

export const priorityRoute: IMainRoute = {
  path: "priorities",
  hasAccess: (accountSpace) => {
    if (accountSpace.Space < 2 && isAdmin(accountSpace)) {
      return true;
    } else return false;
  },
  text: <FormattedMessage id={"PRIORITY"} />,
  Component: Priorities,
  icon: faArrowAltUp
};

export const documentsRoute: IMainRoute = {
  path: "documents",
  moduleType: moduleTypes.documents,
  schema: spaceDocumentsSchema,
  // hasAccess: (accountSpace) => {
  //   if (isAdmin(accountSpace)) return true;
  //   else return false;
  // },
  // DeveloperOnly: true,
  text: <FormattedMessage id={"DOCUMENTS"} />,
  Component: Documents,
  icon: faFileLines
};
export const templateRoute: IMainRoute = {
  path: "templates",
  moduleType: moduleTypes.docTemplates,
  schema: spaceDocTemplatesSchema,
  // hasAccess: (accountSpace) => {
  //   if (isAdmin(accountSpace)) return true;
  //   else return false;
  // },
  // DeveloperOnly: true,
  text: <FormattedMessage id={"TEMPLATES"} />,
  Component: Templates,
  icon: faTableLayout
};

export const reportsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasInterventionReportAccess(accountSpace),
  path: "reports",
  text: <FormattedMessage id={"TIME"} />,
  Component: TimeReports,
  icon: faFileSpreadsheet
};
export const newReportsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasInterventionReportAccess(accountSpace),
  path: "newReports",
  text: <FormattedMessage id={"TIME"} />,
  Component: NewTimeReports,
  icon: faFileSpreadsheet
};

export const TravelDurationGridRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasReportsClassificationAccess(accountSpace),
  path: "reportsTravel",
  text: <FormattedMessage id={"TRAVEL"} />,
  Component: TravelDurationGrid,
  icon: faUserClock
};

export const reportsProjectsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasProjectsReportAccess(accountSpace),
  path: "reportsProjects",
  text: <FormattedMessage id={"PROJECTS"} />,
  Component: ReportsProjects,
  icon: faFileInvoice
};

export const reportsTicketsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasTicketsReportAccess(accountSpace),
  path: "reportsTickets",
  text: <FormattedMessage id={"CASES"} />,
  Component: ReportsTickets,
  icon: faFileChartLine
};

export const reportsTasksRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasTasksReportAccess(accountSpace),
  path: "reportsTasks",
  text: <FormattedMessage id={"TASKS"} />,
  Component: ReportsTasks,
  icon: faMemoCircleCheck
};

export const reportsDealsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasDealsReportAccess(accountSpace),
  path: "reportsDeals",
  text: <FormattedMessage id={"DEALS"} />,
  Component: ReportsDeals,
  icon: faFileChartPie
};

export const reportContractsRoute: IMainRoute = {
  hasAccess: (accountSpace) => hasContractsReportAccess(accountSpace),
  path: "reportsContracts",
  text: <FormattedMessage id={"CONTRACTS"} />,
  Component: ReportsContracts,
  icon: faFileContract
};

export const automationRoute: IMainRoute = {
  moduleType: moduleTypes.automation,
  hasAccess: (accountSpace) => {
    const { Space } = accountSpace;
    const resolvedSpace = getEntity(spaceSchema, Space);
    if (resolvedSpace.TrialEnd) return false;
    return isAdmin(accountSpace);
  },
  schema: spaceAutomationSchema,
  path: "automation",
  text: <FormattedMessage id={"AUTOMATION"} />,
  Component: Automation,
  icon: faCodeMerge
};

export const reportsClassificationRoute: IMainRoute = {
  hasAccess: (accountSpace) => {
    if (hasReportsClassificationAccess(accountSpace)) {
      return true;
    } else return false;
  },
  path: "reportsClassification",
  text: <FormattedMessage id={"CLASSIFICATION"} />,
  Component: ClassificationReport,
  icon: faFileSpreadsheet
};
export const reportsProductivityRoute: IMainRoute = {
  hasAccess: (accountSpace) => {
    if (hasReportsClassificationAccess(accountSpace)) {
      return true;
    } else return false;
  },
  path: "reportsProductivity",
  text: <FormattedMessage id={"PRODUCTIVITY"} />,
  Component: ProductivityReport,
  icon: faFileSpreadsheet
};

export const reportsPersonalProductivityRoute: IMainRoute = {
  path: "reportsPersonalProductivity",
  hasAccess: () => true,
  text: <FormattedMessage id={"PRODUCTIVITY"} />,
  Component: PersonalProductivityReport,
  icon: faFileSpreadsheet
};

export const logsRoute: IMainRoute = {
  DeveloperOnly: true,
  // OwnerOnly: true,
  path: "logs",
  text: "Logs",
  Component: Logs,
  icon: faFileChartLine
};

export const spaceListRoute: IMainRoute = {
  DeveloperOnly: true,
  SupportOnly: true,
  path: "spaceList",
  text: "SpaceList",
  Component: SpaceList,
  icon: faFileChartLine
};

export const cacheControlRoute: IMainRoute = {
  DeveloperOnly: true,
  path: "cache",
  text: "Cache",
  Component: CacheControl,
  icon: faFileChartLine
};
export const queuesControlRoute: IMainRoute = {
  DeveloperOnly: true,
  path: "queues",
  text: "Queues",
  Component: QueueControl,
  icon: faStackpath
};

export const calendarTestViewRoute: IMainRoute = {
  hasAccess: (accountSpace) => true,
  path: "calendar",
  text: "calendar",
  Component: CalendarTestView,
  icon: faFileChartLine
};

export const ReceptionControlRoute: IMainRoute = {
  path: "inbox",
  // moduleType: moduleTypes.emailConversations,
  text: <FormattedMessage id={"RECEPTION"} />,
  Component: ReceptionControl,
  hasAccess: () => true
  // moduleType: moduleTypes.emailConversations,
  // hasAccess: (accountSpace) => {
  //   if (
  //     hasModulePermission(
  //       accountSpace.Modules.emailConversations,
  //       modulePermissions.DepartementReadOnly
  //     ) ||
  //     hasModulePermission(
  //       accountSpace.Modules.emailConversations,
  //       modulePermissions.OrganizationReadOnly
  //     ) ||
  //     hasModulePermission(
  //       accountSpace.Modules.emailConversations,
  //       modulePermissions.PrivateReadOnly
  //     ) ||
  //     hasModulePermission(
  //       accountSpace.Modules.emailConversations,
  //       modulePermissions.ReadAll
  //     ) ||
  //     hasModulePermission(
  //       accountSpace.Modules.emailConversations,
  //       modulePermissions.TeamReadOnly
  //     )
  //   ) {
  //     return true;
  //   } else return false;
  // },
};

export const userControlRoute: IMainRoute = {
  DeveloperOnly: true,
  path: "usersControl",
  text: <FormattedMessage id={"USERS"} />,
  Component: UserControl
};

export const workspaceSubscriptionRoute: IMainRoute = {
  OwnerOnly: true,
  path: "workspace",
  text: <FormattedMessage id="WORKSPACE_SUBSCRIPTION" />,
  Component: WorkspaceSubscriptionControl
};

export const maintenanceControlRoute: IMainRoute = {
  DeveloperOnly: true,
  path: "maintenance",
  text: <FormattedMessage id={"MAINTENENCE"} />,
  Component: MaintenanceControl
};

export const accountRoute: IMainRoute = {
  hasAccess: (accountSpace) => true,
  path: "account",
  text: "Conta",
  Component: Account
};

export const welcomeRoute: IMainRoute = {
  hasAccess: () => true,
  exact: true,
  path: "welcome",
  text: "welcome",
  lsKey: "welcome",
  Component: FirstWelcome
};

export const PlansRoute: IMainRoute = {
  OwnerOnly: true,
  // exact: true,
  path: "plans",
  text: "plans",
  Component: BillingPlanControl
};

// export const PaymentRoute: IMainRoute = {
//   OwnerOnly: true,
//   exact: true,
//   path: "plans/payment",
//   text: "plans/payment",
//   Component: PaymentPlanControl,
// };

export const CuponListRoute: IMainRoute = {
  DeveloperOnly: true,
  exact: true,
  path: "cupons",
  text: "cupons",
  Component: CuponListControl
};

// export const PaymentUpgradesRoute: IMainRoute = {
//   OwnerOnly: true,
//   exact: true,
//   path: "plans/upgrade",
//   text: "plans/upgrade",
//   Component: PaymentUpgradePlanControl,
// };

export const newHtmlEditorTestPageRoute: IMainRoute = {
  DeveloperOnly: true,
  exact: true,
  path: "newhtmleditorpage",
  text: "newhtmleditorpage",
  Component: htmlEditorNewRouteTestPage
};

export const AccountListRoute: IMainRoute = {
  hasAccess: () => true,
  path: "accounts",
  text: "accounts",
  Component: Accounts
};

export const reportingOverview: IMainRoute = {
  icon: faAnalytics,
  hasAccess: () => true,
  path: "reporting",
  Component: ReportingOverviewComp,
  text: <FormattedMessage id={"REPORTING"} />
};

// export const sidebarTestViewRoute: IMainRoute = {
//   hasAccess: accountSpace => true,
//   path: "sidebar",
//   text: "Sidebar",
//   Component: SidebarTestView
// };

export const contactsGroup = [[clientRoute, contactRoute]];

export const commercialGroup = [
  [dealRoute, contractRoute, subscriptionRoute],
  [classificationRoute, billingRoute]
];

export const commercialLitGroup = [[dealRoute, contractRoute]];

export const servicesGroup = [
  [callRoute, projectRoute, ticketRoute, taskRoute, interventionRoute],
  [automationRoute]
  // [automationRoute]
];

export const documentsGroup = [[documentsRoute, templateRoute]];

export const servicesLitGroup = [
  [callRoute, projectRoute, ticketRoute, taskRoute, interventionRoute]
];

export const reportsGroup = [
  [
    reportsRoute,
    reportsTicketsRoute,
    reportsTasksRoute,
    reportsDealsRoute,
    reportContractsRoute,
    reportsProjectsRoute,
    reportsClassificationRoute,
    reportsProductivityRoute,
    reportsPersonalProductivityRoute,
    TravelDurationGridRoute
  ]
];

export const MarketingGroup = [[socialPostsRoute, emailSignatureRoute]];

export const ReportsGroupMaster = [[reportDashboardRoute, reportingOverview]];

export const reportsLitGroup = [
  [
    reportsRoute,
    reportsTicketsRoute,
    reportsTasksRoute,
    reportsDealsRoute,
    reportContractsRoute,
    reportsProjectsRoute
  ]
];

export const spaceCanceledRoutes = [
  PlansRoute,
  workspaceSubscriptionRoute,
  // PaymentRoute,
  accountRoute,
  maintenanceControlRoute,
  spaceListRoute,
  cacheControlRoute,
  queuesControlRoute,
  CuponListRoute,
  SubscriptionCanceledRoute,
  SubscriptionTrialEndRoute,
  userControlRoute
  // PaymentUpgradesRoute,
];

export const availableRoutes = [
  documentsRoute,
  TravelDurationGridRoute,
  reportingOverview,
  welcomeRoute,
  dashboardRoute,
  clientRoute,
  reportDashboardRoute,
  socialPostsRoute,
  contactRoute,
  dealRoute,
  ImportsRoute,
  contractRoute,
  classificationRoute,
  billingRoute,
  callRoute,
  projectRoute,
  subscriptionRoute,
  ticketRoute,
  taskRoute,
  interventionRoute,
  emailSignatureRoute,
  settingsRoute,
  reportsRoute,
  newReportsRoute,
  accountRoute,
  logsRoute,
  spaceListRoute,
  cacheControlRoute,
  queuesControlRoute,
  userControlRoute,
  maintenanceControlRoute,
  reportsTicketsRoute,
  reportsTasksRoute,
  reportsDealsRoute,
  reportsClassificationRoute,
  reportsProductivityRoute,
  reportsPersonalProductivityRoute,
  reportContractsRoute,
  reportsProjectsRoute,
  PlansRoute,
  workspaceSubscriptionRoute,
  // PaymentRoute,
  // PaymentUpgradesRoute,
  CuponListRoute,
  ReceptionControlRoute,
  SubscriptionCanceledRoute,
  SubscriptionTrialEndRoute,
  AccountListRoute,

  calendarTestViewRoute,
  GlobalExportsRoute,
  // sidebarTestViewRoute
  //   reportsClassificationRoute
  automationRoute,
  // documentsRoute,
  templateRoute
];
export const tempAvailableRoutes = [
  reportingOverview,
  welcomeRoute,
  dashboardRoute,
  reportDashboardRoute,
  clientRoute,
  socialPostsRoute,
  contactRoute,
  dealRoute,
  ImportsRoute,
  contractRoute,
  classificationRoute,
  billingRoute,
  callRoute,
  projectRoute,
  subscriptionRoute,
  ticketRoute,
  taskRoute,
  interventionRoute,
  // emailSignatureRoute,
  settingsRoute,
  reportsRoute,
  accountRoute,
  logsRoute,
  spaceListRoute,
  cacheControlRoute,
  userControlRoute,
  maintenanceControlRoute,
  reportsTicketsRoute,
  reportsTasksRoute,
  reportsDealsRoute,
  reportsClassificationRoute,
  reportContractsRoute,
  reportsProjectsRoute,
  PlansRoute,
  workspaceSubscriptionRoute,
  // PaymentRoute,
  // PaymentUpgradesRoute,
  CuponListRoute,
  SubscriptionCanceledRoute,
  SubscriptionTrialEndRoute,
  AccountListRoute
  // sidebarTestViewRoute
  //   reportsClassificationRoute
];

export interface RouteAccessDict {
  [path: string]: boolean;
}

const SpaceAccessContext = React.createContext<RouteAccessDict>({});

export const useSpaceRoutesAccess = () => useContext(SpaceAccessContext);

export const canAccessRouteFromDict = (
  dict: RouteAccessDict,
  route: IMainRoute
) => {
  return dict[route.path];
};

export const SpaceAccessProvider = ({ children }: { children: any }) => {
  const accountSpace = useCurrentAccountSpace() as AccountSpace;
  const account = useCurrentAccount();
  const { Modules } = accountSpace;

  const isCurrentUserAdmin = isAdmin(accountSpace);
  const dev = isDeveloper(account);
  const space = useSpace();
  const support = isSupport(account);
  const owner = isOwner(accountSpace);
  const accessDict = useMemo(() => {
    const dict: RouteAccessDict = {};

    for (const route of availableRoutes) {
      const {
        path,
        hasAccess,
        OwnerOnly,
        DeveloperOnly,
        SupportOnly,
        moduleType,
        RequiresPayment,
        readModuleType
      } = route;

      let hasAccessToRoute;
      if (OwnerOnly) {
        hasAccessToRoute = owner;
      } else if (SupportOnly) {
        hasAccessToRoute = support || dev;
      } else if (DeveloperOnly) {
        hasAccessToRoute = dev;
      } else if (RequiresPayment) {
        if (!space.TrialEnd) {
          hasAccessToRoute = canUserReadModule(
            accountSpace,
            moduleTypes.automation
          );
        } else hasAccessToRoute = false;
      } else {
        if (isCurrentUserAdmin) {
          hasAccessToRoute = true;
        } else if (moduleType) {
          const routeModule = Modules[moduleType];
          const readModule = Modules[readModuleType || moduleType];
          hasAccessToRoute =
            hasModulePermission(routeModule, modulePermissions.Access) &&
            (hasModulePermission(readModule, modulePermissions.ReadAll) ||
              hasModulePermission(
                readModule,
                modulePermissions.PrivateReadOnly
              ) ||
              hasModulePermission(readModule, modulePermissions.TeamReadOnly) ||
              hasModulePermission(
                readModule,
                modulePermissions.DepartementReadOnly
              ) ||
              hasModulePermission(
                readModule,
                modulePermissions.OrganizationReadOnly
              ));
        } else if (hasAccess) {
          hasAccessToRoute = hasAccess(accountSpace);
        } else {
          hasAccessToRoute = false;
        }
      }

      dict[path] = hasAccessToRoute;
    }
    return dict;
  }, [
    owner,
    support,
    dev,
    space.TrialEnd,
    accountSpace,
    isCurrentUserAdmin,
    Modules
  ]);

  return (
    <SpaceAccessContext.Provider value={accessDict}>
      {children}
    </SpaceAccessContext.Provider>
  );
};

const SpaceMainViewContext = React.createContext("");

const getMainViewFromUrl = (pathName: string) => pathName.split("/")[3];

export const useSpaceMainView = () => useContext(SpaceMainViewContext);

export const SpaceMainUrlProvider = ({ children }: { children: any }) => {
  const location = useLocation();

  const view = useMemo(() => {
    return getMainViewFromUrl(location.pathname);
  }, [location.pathname]);

  return (
    <SpaceMainViewContext.Provider value={view}>
      {children}
    </SpaceMainViewContext.Provider>
  );
};
