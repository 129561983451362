import React, { useContext, useMemo, useRef } from "react";
import {
  useServerAwareColumns,
  useServerAwareFilterColumns
} from "../../../CGrid/ServerAwareContexts";
import { FilterConditionsEnum } from "../AdvancedFilterConditions";
import { FilterOperatorsEnum } from "../AdvancedFilterOperators";
import classes from "./AdvancedFilterColors.module.css";
import classnames from "classnames";
import { useServerAwareSelectedFilterColumns } from "../../../CGrid/ServerAwareColorFilters";
import { useHoverEvents } from "../../../../Containers/Origin/Origin";
import Popup from "../../../Popup/Popup";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

// export const exampleCondition: any = {
//   Condition: FilterConditionsEnum.And,
//   Groups: [],
//   Operator: FilterOperatorsEnum.Eq,
//   Parameter: "Id",
//   Value: 16680,
//   Color: "#000",
// };

export const exampleCondition: any = {
  Color: "#000",
  Groups: [
    {
      Condition: FilterConditionsEnum.Or,
      Groups: [],
      Operator: FilterOperatorsEnum.Eq,
      Parameter: "Id",
      Value: 16744
    },
    {
      Condition: FilterConditionsEnum.Or,
      Groups: [],
      Operator: FilterOperatorsEnum.Eq,
      Parameter: "Account",
      Value: "dd79a58d-3692-46ed-b983-4d9ff9eeedfd"
    }
  ]
};

export const exampleCondition2: any = {
  Color: "red",
  Groups: [
    {
      Condition: FilterConditionsEnum.Or,
      Groups: [],
      Operator: FilterOperatorsEnum.Eq,
      Parameter: "Id",
      Value: 16744
    }
  ]
};

const testStringCondition = (
  currentValue: string,
  targetValue: string,
  operator: FilterOperatorsEnum
) => {
  switch (operator) {
    case FilterOperatorsEnum.Eq:
      return currentValue === targetValue;

    case FilterOperatorsEnum.Ne:
      return currentValue !== targetValue;

    case FilterOperatorsEnum.StartsWith:
      return currentValue ? currentValue.startsWith(targetValue) : false;

    case FilterOperatorsEnum.EndsWith:
      return currentValue ? currentValue.endsWith(targetValue) : false;

    case FilterOperatorsEnum.Contains:
      return currentValue ? currentValue.includes(targetValue) : false;

    case FilterOperatorsEnum.DoesNotContain:
      return currentValue ? !currentValue.includes(targetValue) : false;

    default:
      return false;
  }
};

const testNumberCondition = (
  currentValue: number,
  targetValue: number,
  operator: FilterOperatorsEnum
) => {
  switch (operator) {
    case FilterOperatorsEnum.Eq:
      return currentValue === targetValue;

    case FilterOperatorsEnum.Ne:
      return currentValue !== targetValue;

    case FilterOperatorsEnum.LessThan:
      return currentValue < targetValue;

    case FilterOperatorsEnum.LessThanOrEqual:
      return currentValue <= targetValue;

    case FilterOperatorsEnum.GreaterThan:
      return currentValue > targetValue;

    case FilterOperatorsEnum.GreaterThanOrEqual:
      return currentValue >= targetValue;

    case FilterOperatorsEnum.StartsWith:
      return currentValue
        ? String(currentValue).startsWith(String(targetValue))
        : false;

    case FilterOperatorsEnum.EndsWith:
      return currentValue
        ? String(currentValue).endsWith(String(targetValue))
        : false;

    case FilterOperatorsEnum.Contains:
      return currentValue
        ? String(currentValue).includes(String(targetValue))
        : false;

    case FilterOperatorsEnum.DoesNotContain:
      return currentValue
        ? !String(currentValue).includes(String(targetValue))
        : false;

    default:
      return false;
  }
};

const getResolvedDate = (d: any) => {
  const resolvedDate = new Date(d);
  resolvedDate.setMilliseconds(0);
  resolvedDate.setSeconds(0);
  resolvedDate.setMinutes(0);
  resolvedDate.setHours(0);
  return resolvedDate;
};

const testDateCondition = (
  currentValue: number,
  targetValue: number,
  operator: FilterOperatorsEnum
) => {
  const resolvedCurrentValue = getResolvedDate(currentValue);
  const currentTime = resolvedCurrentValue.getTime();
  const resolvedTargetValue = getResolvedDate(targetValue);
  const targetTime = resolvedTargetValue.getTime();
  let begin = dayjs();
  let parsedBegin, parsedEnd;
  switch (operator) {
    case FilterOperatorsEnum.Eq:
      return currentTime === targetTime;

    case FilterOperatorsEnum.Ne:
      return currentTime !== targetTime;

    case FilterOperatorsEnum.LessThan:
      return currentTime < targetTime;

    case FilterOperatorsEnum.GreaterThanOrEqual:
      return currentTime >= targetTime;

    case FilterOperatorsEnum.LessThanOrEqual:
      return currentTime <= targetTime;

    case FilterOperatorsEnum.GreaterThan:
      return currentTime > targetTime;

    case FilterOperatorsEnum.Next:
      parsedBegin = begin.format("YYYY-MM-DD");
      parsedEnd = begin.add(targetValue, "day").format("YYYY-MM-DD");

      return dayjs(resolvedCurrentValue).isBetween(
        parsedBegin,
        parsedEnd,
        "date",
        "[)"
      );

    case FilterOperatorsEnum.Last:
      parsedBegin = begin.format("YYYY-MM-DD");
      parsedEnd = begin.subtract(targetValue, "day").format("YYYY-MM-DD");

      return dayjs(resolvedCurrentValue).isBetween(
        parsedBegin,
        parsedEnd,
        "date",
        "[)"
      );

    case FilterOperatorsEnum.NotNext:
      parsedBegin = begin.format("YYYY-MM-DD");
      parsedEnd = begin.add(targetValue, "day").format("YYYY-MM-DD");

      return !dayjs(resolvedCurrentValue).isBetween(
        parsedBegin,
        parsedEnd,
        "date",
        "[)"
      );

    case FilterOperatorsEnum.NotLast:
      parsedBegin = begin.format("YYYY-MM-DD");
      parsedEnd = begin.subtract(targetValue, "day").format("YYYY-MM-DD");

      return !dayjs(resolvedCurrentValue).isBetween(
        parsedBegin,
        parsedEnd,
        "date",
        "[)"
      );

    default:
      return false;
  }
};

const getCurrentValue = (row: any, filter: any) => {
  const { propField, propFields, getPropValue } = filter;

  let val = row[propField];

  if (propFields) {
    if (!val) val = row;
    for (const f of propFields) {
      if (val[f] !== undefined)
        return getPropValue ? getPropValue(val[f]) : val[f];
    }
    return undefined;
  }

  return getPropValue ? getPropValue(val) : val;
};

export const testCondition = (row: any, conditionObj: any, filter: any) => {
  const { Operator, Value: targetValue } = conditionObj;

  const {
    type,
    getResolvedValue
    // getPropValue
  } = filter;

  let currentValue = getCurrentValue(row, filter);

  const resolvedTargetValue = getResolvedValue
    ? getResolvedValue(targetValue)
    : targetValue;

  if (!type) {
    switch (Operator) {
      case FilterOperatorsEnum.Eq:
        return `${currentValue}` === `${resolvedTargetValue}`;

      case FilterOperatorsEnum.Ne:
        return `${currentValue}` !== `${resolvedTargetValue}`;

      default:
        return false;
    }
  }

  switch (type) {
    case "string":
      return testStringCondition(
        String(currentValue).toLowerCase(),
        String(resolvedTargetValue).toLowerCase(),
        Operator
      );
    case "number":
      return testNumberCondition(
        Number(currentValue),
        Number(resolvedTargetValue),
        Operator
      );
    case "date":
    case "dateTime":
      return testDateCondition(currentValue, resolvedTargetValue, Operator);

    default:
      return false;
  }
};

const testNestedConditions = (
  rowValue: any,
  conditionObj: any,
  filterDict: any
) => {
  const { Groups, Parameter } = conditionObj;

  if (!Groups || Groups.length === 0) {
    const filter = filterDict[Parameter];
    if (!filter) return false;
    return testCondition(rowValue, conditionObj, filter);
  }

  const currentLevelCondition = Groups[0].Condition;

  if (currentLevelCondition === FilterConditionsEnum.And) {
    for (const cond of Groups) {
      const condChecks = testNestedConditions(rowValue, cond, filterDict);
      if (!condChecks) return false;
    }
    return true;
  } else {
    for (const cond of Groups) {
      const condChecks = testNestedConditions(rowValue, cond, filterDict);
      if (condChecks) return true;
    }
    return false;
  }
};

const FilterColumnsColorDictContext = React.createContext<any>(undefined);

export const useFilterColumnsColorDict = (v: any) => {
  const columns = useServerAwareColumns();

  return useMemo(() => {
    const dict = {} as any;
    for (const col of columns) {
      const { propField } = col;

      if (!propField) continue;

      dict[propField] = col;
    }

    return dict;
  }, [columns]);
};

export const FilterColumnColorProvider = ({ children }: any) => {
  const columns = useServerAwareFilterColumns();
  const dict = useMemo(() => {
    const dict = {} as any;
    if (!columns) return dict;
    for (const col of columns) {
      const { propField, propFields, field } = col;

      if (!propField && !propFields && !field) continue;

      dict[field] = col;
    }

    return dict;
  }, [columns]);

  return (
    <FilterColumnsColorDictContext.Provider value={dict}>
      {children}
    </FilterColumnsColorDictContext.Provider>
  );
};

export const useTriggeredColorFilters = (val: any) => {
  const colorFilterColumnsDict = useContext(FilterColumnsColorDictContext);
  const selectedCond = useServerAwareSelectedFilterColumns();
  return useMemo(() => {
    if (!colorFilterColumnsDict || !selectedCond) return null;

    const resolvedConditions = [];

    for (const condObj of selectedCond) {
      if (testNestedConditions(val, condObj, colorFilterColumnsDict))
        resolvedConditions.push(condObj);
    }
    return resolvedConditions;
  }, [colorFilterColumnsDict, selectedCond, val]);
};

const InnerClorFilterBars = ({ conds, kanban, timeline }: any) => {
  const {
    isOpen,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = useHoverEvents();

  const anchorRef = useRef() as any;

  return (
    <>
      <div
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className={classnames(classes.container, {
          [classes.kanban]: kanban,
          [classes.timeline]: timeline
        })}
      >
        <div
          className={classnames(classes.innerContainer, "d-flex flex-column")}
        >
          {conds.map((cond: any, i: number) => {
            const { Color, Id } = cond;

            return (
              <div
                key={Id}
                className="flex-1"
                style={{ background: Color }}
              ></div>
            );
          })}
        </div>
      </div>
      <Popup
        placement="right-center"
        anchorEl={anchorRef.current}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
        className="mx-2"
        isOpen={isOpen}
      >
        <div
          style={{ maxWidth: 260 }}
          className="d-flex flex-column bg-white px-2 py-1 fs-14 rounded text-black overflow-hidden"
        >
          {conds.map((cond: any) => {
            const { Color, Name, Id } = cond;

            return (
              <div key={Id} className="my-1 d-flex align-items-center">
                <div
                  className="mr-2 rounded-50"
                  style={{ background: Color, width: 16, height: 16 }}
                ></div>
                <span className="text-truncate flex-1">{Name}</span>
              </div>
            );
          })}
        </div>
      </Popup>
    </>
  );
};

export const TriggeredColoredFilterContext =
  React.createContext<any>(undefined);

export const TriggeredColoredFilterProvider = ({ children, conds }: any) => {
  return (
    <TriggeredColoredFilterContext.Provider value={conds}>
      {children}
    </TriggeredColoredFilterContext.Provider>
  );
};

export const InnerColorFilterBars = ({ kanban, timeline }: any) => {
  const conds = useContext(TriggeredColoredFilterContext) as any;

  if (!conds || conds.length === 0) return null;

  return (
    <InnerClorFilterBars kanban={kanban} timeline={timeline} conds={conds} />
  );
};

export const ColorFilterBars = ({ entity, kanban, timeline }: any) => {
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterContext.Provider value={conds}>
      <InnerColorFilterBars kanban={kanban} timeline={timeline} />
    </TriggeredColoredFilterContext.Provider>
  );
};
