import React, { useContext, useMemo, useState, useCallback } from "react";
import {
  buildGridTotalsContext,
  GridColloredEndDateComponent
} from "../../../Helpers/GridHelper";
import { useSpace } from "../../../Contexts/SpaceContext";
import { useCurrency, useEmailReception } from "../../../Hooks/EntityHooks";
import SpentHoursPreview from "../../../Components/SpentHoursPreview/SpentHoursPreview";
import {
  MilSecToHour,
  HourToMilSec,
  MilSecToFormatedHourMinute,
  MilSecToMinute
} from "../../../Helpers/MiscHelper";
import {
  ServerAwareProvider,
  ServerAwareQueryInput,
  GridRequestProvider,
  useServerAwareState,
  CardRequestProvider
} from "../../../Components/CGrid/ServerGrid";
import {
  ContractEntityGridButtons,
  OutsideContractHours,
  HourControll,
  ContractEntityActionsChildren
} from "../ContractsHelper";
import EntityFollowIcon from "../../../Components/EntityFollow/EntityFollowIcon";
import {
  spaceContractSchema,
  spaceContractTagsSchema,
  spaceEmailReceptionSchema
} from "../../../config/schema";
import EntityIconOverview from "../../../Components/EntityIconOverview/EntityIconOverview";
import {
  ConnectedPipelineListItem,
  StatusPipelineGridDropdown,
  StatusContractPipelineGridprofile,
  StatusCompContext
} from "../../AdvancedMultiInputs/PipelineDropdowns";
import EntityStatusUpdater from "../../../Components/EntityStatusUpdater/EntityStatusUpdater";
import {
  ContractStatusProfile,
  ContractStatusTypes
} from "../../../Components/Contract/ContractProfile";
import { PipelineTypeEnum } from "../../Settings/Pipelines/NewPipelineHelper";
import { ContractsLink } from "../../../Helpers/HooksHelper";
import { ConnectedOrganizationProfile } from "../../../Components/Organizations/OrganizationProfile";
import { ConnectedContractTypeProfile } from "../../../Components/ContractType/ContractTypeProfile";
import AccountsInRoomStack from "../../../Components/AccountsInRoom/AccountsInRoomStack";
import OriginOverview from "../../Origin/OriginOverview";
import {
  EntityGridChildrenProgress,
  EntityGridChildrenProgressProvider
} from "../../EntityChildrenProgress/EntityChildrenProgress";
import TextEllipsis from "../../../Components/TextEllipsis/TextEllipsis";
import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import { toCurrencyFromDecimal } from "../../../Helpers/CurrencyHelper";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import { ConnectedContactPopup } from "../../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import { useAirdeskTitle } from "../../../Hooks/MiscHooks";
import { useCurrentAccount } from "../../../Contexts/UserContext";
import {
  createIndirectClientFilter,
  createTeamFilter,
  createContractStatusFilter,
  createPipelineStatusFilter,
  createDepartmentFilter,
  createOrganizationFilter,
  createReadFilter,
  createUnreadFilter,
  createFollowingFilter,
  createNotFollowingFilter,
  createOriginFilter,
  createManagerFilter,
  createContractTypeFilter,
  createUserFilter,
  createUpgradedPipelineStatusFilter,
  createContractTagFilter,
  createSupportEmailFilter,
  createContractSignedFilter
} from "../../../Helpers/FilterHelper";
import { ContractTargetDropdown } from "../../AdvancedMultiInputs";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import Modal from "../../../Components/Modal/Modal";
import { ColumnChooser } from "../../../Components/ColumnChooser/ColumnChooser";
import PipelineProvider from "../../../Components/Pipelines/PipelineProvider";
import PageHeader from "../../../Components/Headers/PageHeader";
import { ContractFormButton } from "../../Form/FormButtons";
import {
  ActionButtonOptions,
  ActionButtonBoardOptions,
  ActionButtonCardsOptions
} from "../../../Components/ExtraActionsButton/ExtraActionButton";
import { moduleTypes } from "../../../Helpers/ModulesHelper";
import ServerAwareFilterList from "../../../Components/FilterList/ServerAwareFilterList";
import { FilterViewTypeEnum } from "../../../Components/FilterList/FilterList";
import ContractGrid from "./ContractGrid";
import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFileContract,
  faPercentage,
  faMoneyBill,
  faList,
  faColumns,
  faClock,
  faUser,
  faUserPlus,
  faStream,
  faCalendar,
  faBuilding,
  faDrawCircle,
  faBusinessTime,
  faHandshake,
  faSuitcase,
  faAnalytics,
  faUserTie,
  faAlignJustify,
  faCarBuilding,
  faThLarge,
  faSignature,
  faCity,
  faRoad,
  faTicketSimple,
  faTag
} from "@fortawesome/pro-light-svg-icons";
import {
  faBell as faBellSolid,
  faUserPlus as faUserPlusSolid,
  faSitemap as faSitemapSolid,
  faUser as faUserSolid,
  faCalendarAlt as faCalendarAltSolid,
  faSuitcase as faSuitcaseSolid,
  faFileContract as faFileContractSolid,
  faUserTie as faUserTieSolid,
  faMoneyBill as faMoneyBillSolid,
  faClock as faClockSolid,
  faTag as faTagSolid,
  faInfoSquare,
  faEnvelope as faEnvelopeSolid,
  faBuilding as faBuildingSolid,
  faCarBuilding as faCarBuildingSolid,
  faFolderTree,
  faHandshake as faHandshakeSolid,
  faTicketSimple as faTicketSimpleSolid,
  faSignature as faSignatureSolid,
  faPollPeople,
  faBusinessTime as faBusinessTimeSolid,
  faWatchCalculator,
  faRetweet,
  faAnalytics as faAnalyticsSolid,
  faDrawCircle as faDrawCircleSolid,
  faBullseyePointer,
  faAlignJustify as faAlignJustifySolid,
  faEnvelope
} from "@fortawesome/pro-solid-svg-icons";
import {
  ServerAwareView,
  ServerAwareButtons,
  ServerAwareButton
} from "../../../Components/GroupButton/GroupButton";
import ContractBoard, {
  useContractPipelinesStatusItems,
  useContractItemBoardComponents
} from "./ContractBoard/ContractBoard";
import { PipelineStatusChooser } from "../../../Components/PipelineStatusBoardChooser/PipelineStatusBoardChooser";
import { KanbanChooser } from "../../../Components/KanbanChooser/KanbanChooser";
import ContractTimeline from "./ContractssTimeline/ContractsTimeline";
import { RealTimeRoomTypes } from "../../RealTime/RealTimeHelper";
import { InboxTypeEnum } from "../../Reception/ReceptionHelpers";
import { SelectedArrMsgContext } from "../../../Components/UnOrReadOptions/UnreadOptions";
import { GridActionContext } from "../../../Components/ContextMenu/ContextMenu";
import ContractCalendar from "./ContractCalendar/ContractCalendar";
import DeleteMultipleModalHandler from "../../../Components/CGrid/DeleteMultipleModal";
import { ContractSidebarLink } from "../../../Components/EntitySidebarLinks/EntitySidebarLinks";
import { DefaultColumnsWidth } from "../../../Components/CGrid/GridDefinitions";
import { useEntitySummarySidebar } from "../../../Components/EntitySummaries/EntitySummariesHelper";
import { KanbanCreateNewContext } from "../../../Components/EntityBoards/EntityBoardsHelper";
import ContractCards from "./ContractCards/ContractCards";
import { CardGridChooser } from "../../../Components/KanbanChooser/CardGridChooser";
import { ConnectedAccountProfile } from "../../../Components/Accounts/AccountProfile";
import { useCurrentUserEditContractPermission } from "../../../Helpers/ContactPermissionsHelper";
import { ActionButtonItem } from "../../../Components/ActionsButton/ActionsButton";
import { ContractManagerMultipleButton } from "../ContractMultiActionsForm";
import SaveFavoriteButton from "../../AppBar/SaveFavoriteButton";
import { GridDateComponent } from "../../../Components/CGrid/CGrid";
import {
  RealTimePageEnum,
  RealTimePageProvider
} from "../../RealTime/RealTimePages";
import { useUrlQueryString } from "../../../Helpers/QueryHelper";
import { getEntity } from "../../../Helpers/IOClient";
import MultiMarkerChanger from "../../../Components/MultiMarkerChanger/MultiMarkerChanger";
import { CheckboxPreview } from "../../../Components/Checkbox/Checkbox";

const [TotalsContext, TotalsProvider] = buildGridTotalsContext(
  (query) =>
    `contracts/totalSum${query}&SumParams=Contract.EstimatedCost,Contract.SpentHours,Currency.EstimatedCost`
);

const TotalComponent = (props) => {
  const {
    // loading: loadingSum,
    data: sumDict
    // error: sumError
    // refetch: refetchSum
  } = useContext(TotalsContext);

  const sumValue = sumDict ? sumDict["Currency.EstimatedCost"] : 0;
  const { Currency: CurrencyId } = useSpace();
  const Currency = useCurrency(CurrencyId);
  if (sumValue)
    return (
      <div className="w-100 d-flex justify-content-end">
        <span>
          {Currency && Currency.Symbol} {sumValue}
        </span>
      </div>
    );
  else
    return (
      <div className="text-truncate w-100 d-flex justify-content-end">
        {"―"}
      </div>
    );
};

const TotalSpentComponent = (props) => {
  const {
    loading: loadingSum,
    data: sumDict,
    error: sumError
    // refetch: refetchSum
  } = useContext(TotalsContext);

  if (loadingSum || sumError) return null;

  if (sumDict)
    return (
      <div className="w-100 d-flex justify-content-end">
        {MilSecToFormatedHourMinute(sumDict["Contract.SpentHours"] || 0)}
        {/* <SpentHoursPreview
          onlyHours
          SpentHours={MilSecToHour(sumDict["Contract.SpentHours"])}
          EstimatedHours={MilSecToHour(sumDict["Contract.EstimatedCost"])}
        /> */}
      </div>
    );
  else return <div className="w-100 d-flex justify-content-end">―</div>;
};

const TotalPerformanceComponent = (props) => {
  const {
    loading: loadingSum,
    data: sumDict,
    error: sumError
    // refetch: refetchSum
  } = useContext(TotalsContext);

  if (loadingSum || sumError) return null;

  if (sumDict)
    return (
      <div className="w-100 d-flex justify-content-end">
        <SpentHoursPreview
          onlyPercentage
          SpentHours={sumDict["Contract.SpentHours"]}
          EstimatedHours={sumDict["Contract.EstimatedCost"]}
        />
      </div>
    );
  else return <div className="w-100 d-flex justify-content-end">―</div>;
};

const TotalEstimatedComponent = (props) => {
  const {
    loading: loadingSum,
    data: sumDict,
    error: sumError
    // refetch: refetchSum
  } = useContext(TotalsContext);

  if (loadingSum || sumError) return null;

  const sumValue = sumDict ? sumDict["Contract.EstimatedCost"] : 0;

  if (sumValue)
    return (
      <div className="w-100 d-flex justify-content-end">
        {MilSecToFormatedHourMinute(sumValue)}
      </div>
    );
  else return <div className="w-100 d-flex justify-content-end">―</div>;
};

export const contractsColumns = [
  {
    columnChooserIcon: faBullseyePointer,
    sticky: true,
    required: true,
    sortable: false,
    orderBy: false,
    noTitle: true,
    title: "Actions",
    minimumWidth: 100,
    minSize: DefaultColumnsWidth.buttons,
    field: "Buttons",
    width: DefaultColumnsWidth.buttons,
    className: "p-2",
    Cell: ({ Data: value }) => {
      //   console.log(value.CanEdit);
      return <ContractEntityGridButtons entity={value} />;
    }
  },
  {
    sticky: true,
    headerIcon: faAlignJustifySolid,
    filterIcon: faAlignJustify,
    width: DefaultColumnsWidth.Description,
    field: "Contract/NoTagDescription",
    title: <FormattedMessage id={"DESCRIPTION"} />,
    Cell: ({ Data }) => (
      <div className="d-flex align-items-center">
        {Data.AccountsInRoomView && Data.AccountsInRoomView.length > 0 && (
          <span className="mr-2">
            <AccountsInRoomStack
              dontShowLine
              accountsInRoom={Data.AccountsInRoomView}
            />
          </span>
        )}
        <ContractSidebarLink className="fs-12" id={Data.Id}>
          <TextEllipsis length={85} truncate text={Data.NoTagDescription} />
        </ContractSidebarLink>
      </div>
    )
  },
  {
    headerIcon: faBellSolid,
    width: DefaultColumnsWidth.followIcon,
    title: <FormattedMessage id={"FOLLOW_UP"} />,
    field: "IsFollowing",
    sortable: false,
    orderBy: false,
    noTitle: true,
    Cell: ({ Data: value }) => (
      <EntityFollowIcon
        className="mx-auto"
        size="lg"
        canEdit={value.CanEdit}
        schema={spaceContractSchema}
        isFollowing={value.IsFollowing}
        id={value.Id}
      />
    )
  },
  {
    headerIcon: faInfoSquare,
    width: DefaultColumnsWidth.icons,
    field: "Attributes",
    title: <FormattedMessage id={"ATTRIBUTES"} />,
    sortable: false,
    orderBy: false,
    // noTitle: true,
    Cell: ({ Data: value }) => <EntityIconOverview value={value} />
  },
  {
    headerIcon: faTagSolid,
    filterIcon: faTag,
    orderBy: false,
    sortable: false,
    width: DefaultColumnsWidth.Company,
    field: "Contract/Tags",
    title: <FormattedMessage id={"TAGS"} />,
    Cell: ({ Data }) => {
      if (!Data.Tags || Data.Tags?.length === 0) return <div>―</div>;

      const resolvedTags = [];

      for (const e of Data.Tags) {
        const tag = getEntity(spaceContractTagsSchema, e);
        resolvedTags.push(tag.Name);
      }

      const tagsLine = resolvedTags.join(", ");
      return (
        <div className="text-truncate">
          <TextEllipsis
            truncate
            Component={<div className="text-truncate">{tagsLine}</div>}
            text={tagsLine}
          />
        </div>
      );
    }
  },
  {
    headerIcon: faDrawCircleSolid,
    filterIcon: faDrawCircle,
    width: DefaultColumnsWidth.pipeline,
    field: "Contract/PipelineId",
    title: <FormattedMessage id={"PIPELINE"} />,
    type: "string",
    Cell: ({ Data }) => (
      <div className="fs-12 text-truncate">
        <ConnectedPipelineListItem grid pipeline={Data.Pipeline} />
      </div>
    )
  },
  {
    headerIcon: faDrawCircleSolid,
    filterIcon: faDrawCircle,
    width: DefaultColumnsWidth.status,
    field: "Contract/PipelineStatusId",
    minSize: DefaultColumnsWidth.minStatusSize,
    title: <FormattedMessage id={"STATUS"} />,
    // minSize: 150,
    Cell: ({ Data }) => {
      //   console.log(Data.CanEdit);
      return (
        <div className="w-100">
          <StatusCompContext.Provider value={ContractStatusProfile}>
            <EntityStatusUpdater
              hideName={true}
              canEdit={Data.CanEdit}
              status={Data.PipelineStatus}
              Company={Data.Company}
              filterBlock={true}
              entityStatusTypes={ContractStatusTypes}
              StatusComponent={StatusContractPipelineGridprofile}
              Component={StatusPipelineGridDropdown}
              schema={spaceContractSchema}
              pipelineId={Data.Pipeline}
              pipelineType={PipelineTypeEnum.Contract}
              type="CONTRACT_NR"
              id={Data.Id}
              hideCancellation={true}
            />
          </StatusCompContext.Provider>
        </div>
      );
    }
  },
  {
    headerIcon: faFileContractSolid,
    filterIcon: faFileContract,
    width: DefaultColumnsWidth.id,
    field: "Contract/Id",
    title: "Nº",
    Cell: ({ Data: value }) => (
      <ContractsLink id={value.Id}>{value.Id}</ContractsLink>
    )
  },
  {
    headerIcon: faCarBuildingSolid,
    filterIcon: faCarBuilding,
    width: DefaultColumnsWidth.travels,
    field: "Deslocations",
    title: <FormattedMessage id={"TRAVELS"} />,
    Cell: ({ Data: value }) => <span>{value.Deslocations}</span>
  },
  {
    headerIcon: faBuildingSolid,
    filterIcon: faBuilding,
    width: DefaultColumnsWidth.Company,
    field: "Company/Name",
    title: <FormattedMessage id={"COMPANY"} />,
    Cell: ({ Data }) => {
      return <ConnectedOrganizationProfile organization={Data.Company} />;
    }
  },
  {
    headerIcon: faFileContractSolid,
    filterIcon: faFileContract,
    required: true,
    width: 180,
    field: "ContractType/Name",
    title: <FormattedMessage id={"TYPE_CONTRACT"} />,
    Cell: ({ Data }) => (
      <ConnectedContractTypeProfile contracttype={Data.ContractType} />
    )
  },
  // {
  //   headerIcon: faPollPeople,
  //   width: DefaultColumnsWidth.AssignedGroup,
  //   orderBy: false,
  //   sortable: false,
  //   minSize: 145,
  //   field: "AccountsInRoom",
  //   title: <FormattedMessage id={"WORKING_IN"} />,
  //   Cell: ({ Data }) => {
  //     return <AccountsInRoomStack accountsInRoom={Data.AccountsInRoom} />;
  //   }
  // },
  {
    headerIcon: faSitemapSolid,
    width: DefaultColumnsWidth.Origem,
    orderBy: false,
    title: <FormattedMessage id={"ORIGIN"} />,
    field: "origin",
    sortable: false,
    Cell: ({ Data }) => {
      return (
        <div className="text-truncate">
          <OriginOverview entity={Data} />
        </div>
      );
    }
  },
  {
    headerIcon: faFolderTree,
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id={"ORIGINATED"} />,
    sortable: false,
    orderBy: false,
    field: "Contract/originated",
    Cell: ({ Data }) => {
      return <EntityGridChildrenProgress id={Data.Id} />;
    }
  },
  {
    headerIcon: faSuitcaseSolid,
    filterIcon: faSuitcase,
    width: DefaultColumnsWidth.ClientName,
    field: "Client-IndirectClient/Name",
    title: <FormattedMessage id={"ACCOUNT"} />,
    Cell: ({ Data }) => (
      <ConnectedClientOrigin client={Data.Client || Data.IndirectClient} />
    )
  },
  {
    headerIcon: faEnvelope,
    width: DefaultColumnsWidth.CreatorName,
    title: <FormattedMessage id={"SUPPORT_EMAIL"} />,
    sortable: false,
    orderBy: false,
    field: "Contract/SupportEmail",
    Cell: ({ Data }) => {
      if (Array.isArray(Data?.InboundEmails) && Data.InboundEmails.length > 0) {
        const resolvedEmailList = Data.InboundEmails.map((e) => {
          const resolEmailObject = getEntity(spaceEmailReceptionSchema, e);
          return resolEmailObject.Email;
        });
        const tagsLine = resolvedEmailList.join(", ");
        return (
          <TextEllipsis
            truncate
            // Component={<div className="text-truncate w-100">{tagsLine}</div>}
            text={tagsLine}
          />
        );
      }

      return <div className="w-100">―</div>;
    }
  },
  {
    headerIcon: faTicketSimpleSolid,
    filterIcon: faTicketSimple,
    width: DefaultColumnsWidth.status,
    field: "Contract/TicketLimit",
    title: <FormattedMessage id={"TICKET_LIMIT"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate w-100 d-flex justify-content-end">
        {value.TicketLimit || "―"}
      </div>
    )
  },
  {
    headerIcon: faFolderTree,
    width: DefaultColumnsWidth.Origem,
    title: <FormattedMessage id={"TICKETS_ASSIGNED"} />,
    sortable: false,
    orderBy: false,
    field: "Contract/TicketAssigned",
    Cell: ({ Data }) => {
      return <EntityGridChildrenProgress id={Data.Id} forcedType={2} />;
    }
  },
  {
    headerIcon: faMoneyBillSolid,
    filterIcon: faMoneyBill,
    sortable: false,
    orderBy: false,
    width: DefaultColumnsWidth.EstimatedCost,
    field: "CurrencyHour/EstimatedCost",
    // title: "Valor",
    title: <FormattedMessage id={"VALUE_HOUR"} />,
    Cell: ({ Data }) => {
      const { Currency: CurrencyId } = useSpace();
      const Currency = useCurrency(CurrencyId);
      const unresolvedValue = Data.EstimatedCostsHour.find(
        (e) => e.CurrencyId === CurrencyId
      );

      const resolvedValue = unresolvedValue?.Cost || 0;
      const value = toCurrencyFromDecimal(resolvedValue);

      return (
        <div className="text-truncate w-100 d-flex justify-content-end">
          <span>
            {resolvedValue === 0
              ? "―"
              : Currency && Currency.Symbol + " " + value}
          </span>
        </div>
      );
    }
  },
  {
    headerIcon: faMoneyBillSolid,
    filterIcon: faMoneyBill,
    sortable: false,
    orderBy: false,
    width: DefaultColumnsWidth.EstimatedCost,
    field: "Currency/EstimatedCost",
    // title: "Valor",
    title: <FormattedMessage id={"VALUE"} />,
    Cell: ({ Data }) => {
      const { Currency: CurrencyId } = useSpace();
      const Currency = useCurrency(CurrencyId);
      const unresolvedValue = Data.DefaultEstimatedCost
        ? Data.DefaultEstimatedCost.Cost
        : 0;
      const value = toCurrencyFromDecimal(unresolvedValue);

      return (
        <div className="text-truncate w-100 d-flex justify-content-end">
          <span>
            {unresolvedValue === 0
              ? "―"
              : Currency && Currency.Symbol + " " + value}
          </span>
        </div>
      );
    },
    TotalCell: TotalComponent
  },
  {
    headerIcon: faClockSolid,
    filterIcon: faClock,
    width: DefaultColumnsWidth.SpentTime,
    // field: "SpentHours",
    title: <FormattedMessage id={"SPENT_HOURS"} />,
    field: "Contract/SpentHours",
    Cell: ({ Data }) => {
      const { SpentHours } = Data;
      return (
        <div className="w-100 d-flex justify-content-end">
          {MilSecToFormatedHourMinute(SpentHours || 0)}
        </div>
      );
    },
    TotalCell: TotalSpentComponent
  },
  {
    headerIcon: faAnalyticsSolid,
    filterIcon: faAnalytics,
    width: DefaultColumnsWidth.performance,
    // field: "SpentHours",
    title: <FormattedMessage id={"PERFORMANCE"} />,
    field: "Contract/Timeleft",
    Cell: ({ Data }) => {
      const { Performance } = Data;
      return (
        <div className="w-100 d-flex justify-content-end">
          <SpentHoursPreview
            onlyPercentage
            SpentHours={Performance?.SpentHours}
            EstimatedHours={Performance?.EstimatedCost}
          />
        </div>
      );
    },
    TotalCell: TotalPerformanceComponent
  },
  {
    headerIcon: faBusinessTimeSolid,
    filterIcon: faBusinessTime,
    width: DefaultColumnsWidth.estimatedTime,
    field: "Contract/EstimatedCost",
    title: <FormattedMessage id={"ESTIMATED_TIME"} />,
    Cell: ({ Data }) => (
      <div className="w-100 d-flex justify-content-end">
        {MilSecToFormatedHourMinute(Data.EstimatedCost, true)}
      </div>
    ),
    TotalCell: TotalEstimatedComponent
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.relativeTime,
    field: "Contract/BeginDate",
    title: <FormattedMessage id={"START_DATE"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate">
        <RelativeTime date={value.BeginDate} />
      </div>
    )
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.relativeTime,
    field: "Contract/EndDate",
    title: <FormattedMessage id={"END_DATE"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate">
        <RelativeTime date={value.EndDate} />
      </div>
    )
  },
  {
    headerIcon: faHandshakeSolid,
    filterIcon: faHandshake,
    width: DefaultColumnsWidth.opv,
    field: "Contract/SalesOpportunity",
    title: "OPV",
    Cell: ({ Data: value }) => (
      <div className="text-truncate w-100 d-flex justify-content-end">
        {value.SalesOpportunity || "―"}
      </div>
    )
  },
  {
    headerIcon: faEnvelopeSolid,
    filterIcon: faEnvelope,
    width: DefaultColumnsWidth.Email,
    field: "Contract/ReportEmail",
    title: <FormattedMessage id={"REPORT_EMAIL"} />,
    Cell: ({ Data: value }) => (
      <div className="text-truncate w-100 d-flex">
        {value.ReportEmail || "―"}
      </div>
    )
  },
  {
    headerIcon: faUserTieSolid,
    filterIcon: faUserTie,
    width: DefaultColumnsWidth.Company,
    field: "Contact/Name",
    title: <FormattedMessage id={"CONTACT"} />,
    Cell: ({ Data }) => <ConnectedContactPopup contact={Data.Contact} />
  },
  {
    headerIcon: faClockSolid,
    filterIcon: faClock,
    width: DefaultColumnsWidth.relativeTime,
    sortable: false,
    orderBy: false,
    field: "Contact/OutsideContractHours",
    title: <FormattedMessage id={"OUTSIDE_CONTRACT_HOURS"} />,
    Cell: ({ Data }) => (
      <OutsideContractHours
        LeftDurationNotInRange={Data.LeftDurationNotInRange}
        RightDurationNotInRange={Data.RightDurationNotInRange}
        Entity={Data}
      />
    )
  },
  {
    headerIcon: faCalendarAltSolid,
    width: DefaultColumnsWidth.hourControl,
    sortable: false,
    orderBy: false,
    field: "Contract/HourControl",
    title: <FormattedMessage id={"CONTROL_HOURS"} />,
    Cell: ({ Data }) => (
      <HourControll
        Entity={Data}
        TimeChargesDurationByRenovation={Data.TimeChargesDurationByRenovation}
        PeriodTime={Data.PeriodTime}
      />
    )
  },
  {
    headerIcon: faWatchCalculator,
    width: DefaultColumnsWidth.periodomaxhours,
    sortable: false,
    orderBy: false,
    field: "Contract/RoundingMargin",
    title: <FormattedMessage id={"ROUNDING_MARGIN_ABV"} />,
    Cell: ({ Data }) => (
      <div className="">
        {Data.RoundingMargin ? `${MilSecToMinute(Data.RoundingMargin)}` : "―"}
      </div>
    )
  },
  {
    headerIcon: faWatchCalculator,
    width: DefaultColumnsWidth.periodomaxhours,
    sortable: false,
    orderBy: false,
    field: "Contract/PeriodHours",
    title: <FormattedMessage id={"MAXIMUM_PERIOD_HOURS"} />,
    Cell: ({ Data }) => (
      <div className="">
        {Data.PeriodTime ? MilSecToHour(Data.PeriodTime) : "―"}
      </div>
    )
  },
  {
    headerIcon: faRetweet,
    width: DefaultColumnsWidth.nextPeriod,
    sortable: false,
    orderBy: false,
    field: "Contract/NextRenovation",
    title: <FormattedMessage id={"NEXT_PERIOD"} />,
    Cell: ({ Data }) => {
      const Obj = {
        BeginDate: new Date(),
        EndDate: Data.NextRenovation
      };
      if (!Data.NextRenovation) return <div>―</div>;
      return <GridColloredEndDateComponent Data={Obj} />;
    }
  },
  {
    headerIcon: faUserSolid,
    filterIcon: faUser,
    width: DefaultColumnsWidth.AssignedGroup,
    field: "Assigned/Name",
    title: <FormattedMessage id={"MANAGER"} />,
    Cell: ({ Data }) => {
      return <ConnectedAccountPopup account={Data.Manager} />;
    }
  },

  {
    headerIcon: faUserPlusSolid,
    filterIcon: faUserPlus,
    width: DefaultColumnsWidth.Company,
    field: "Creator/Name",
    title: <FormattedMessage id={"CREATED_BY"} />,
    Cell: ({ Data }) => <ConnectedAccountPopup account={Data.Creator} />
  },
  {
    headerIcon: faCalendarAltSolid,
    orderBy: false,
    sortable: false,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.ReceivedDate,
    field: "Contract/CreationDate",
    title: <FormattedMessage id={"CREATION_DATE"} />,
    Cell: ({ Data }) => <GridDateComponent date={new Date(Data.CreationDate)} />
  },
  {
    filterIcon: faUserPlus,
    orderBy: false,
    sortable: false,
    headerIcon: faUserPlusSolid,
    width: DefaultColumnsWidth.Company,
    field: "Contract/PipelineAccount",
    title: <FormattedMessage id={"LAST_PIPELINE_STATUS_ACCOUNT"} />,
    Cell: ({ Data }) => {
      if (!Data || !Data.PipelineAccount) return "―";

      return <ConnectedAccountPopup account={Data.PipelineAccount} />;
    }
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.ReceivedDate,
    orderBy: false,
    sortable: true,
    field: "Contract/PipelineDate",
    title: <FormattedMessage id={"LAST_PIPELINE_STATUS_DATE"} />,
    Cell: ({ Data }) =>
      Data.PipelineDate ? (
        <GridDateComponent date={new Date(Data.PipelineDate)} />
      ) : (
        <div>―</div>
      )
  },
  {
    filterIcon: faUserPlus,
    headerIcon: faUserPlusSolid,
    orderBy: false,
    sortable: false,
    width: DefaultColumnsWidth.Company,
    field: "Contract/StatusAccount",
    title: <FormattedMessage id={"LAST_STATUS_ACCOUNT"} />,
    Cell: ({ Data }) => {
      if (!Data || !Data.StatusAccount) return "―";

      return <ConnectedAccountPopup account={Data.StatusAccount} />;
    }
  },
  {
    headerIcon: faCalendarAltSolid,
    filterIcon: faCalendarAlt,
    width: DefaultColumnsWidth.ReceivedDate,
    orderBy: false,
    sortable: true,
    field: "Contract/StatusDate",
    title: <FormattedMessage id={"LAST_STATUS_DATE"} />,
    Cell: ({ Data }) =>
      Data.StatusDate ? (
        <GridDateComponent date={new Date(Data.StatusDate)} />
      ) : (
        <div>―</div>
      )
  },
  {
    headerIcon: faSignatureSolid,
    filterIcon: faSignature,
    width: DefaultColumnsWidth.smallButton,
    orderBy: false,
    sortable: true,
    field: "Contract/IsSigned",
    title: <FormattedMessage id={"SIGNED"} />,
    Cell: ({ Data }) => {
      if (Data.IsSigned === -1) return "―";
      else if (Data.IsSigned === 0)
        return <CheckboxPreview defaultChecked={false} />;
      else return <CheckboxPreview defaultChecked={true} />;
    }
  }
];

const Modals = ({
  modalIsOpen,
  closeModal,
  gridColumnLayout,
  setModalIsOpen,
  stateModalIsOpen,
  stateCloseModal,
  setStateModalIsOpen
}) => {
  const { params } = useServerAwareState();

  const { view } = params;
  const pipelineId = params.pipeline;
  const PipelineStatus = useContractPipelinesStatusItems();
  const BoardComponents = useContractItemBoardComponents({});
  if ("board" === view)
    return (
      <>
        <Modal
          // containerClassName=""
          isOpen={modalIsOpen}
          onClose={closeModal}
        >
          <KanbanChooser
            schemaView={spaceContractSchema}
            grid={"contracts"}
            Name="contracts"
            Columns={BoardComponents}
            onClose={closeModal}
            setIsOpen={setModalIsOpen}
            isOpen={modalIsOpen}
          />
        </Modal>
        <Modal
          // containerClassName=""
          isOpen={stateModalIsOpen}
          onClose={stateCloseModal}
        >
          <PipelineStatusChooser
            grid={"contracts"}
            schemaView={spaceContractSchema}
            Name="contracts"
            pipelineId={pipelineId}
            Columns={PipelineStatus}
            onClose={stateCloseModal}
            setIsOpen={setStateModalIsOpen}
            isOpen={stateModalIsOpen}
          />
        </Modal>
      </>
    );
  else if ("cards" === view)
    return (
      <Modal
        // containerClassName=""
        isOpen={modalIsOpen}
        onClose={closeModal}
      >
        <CardGridChooser
          schemaView={spaceContractSchema}
          grid={"contracts"}
          Name="contracts"
          Columns={BoardComponents}
          onClose={closeModal}
          setIsOpen={setModalIsOpen}
          isOpen={modalIsOpen}
        />
      </Modal>
    );
  else
    return (
      <Modal
        // containerClassName=""
        isOpen={modalIsOpen}
        onClose={closeModal}
      >
        <ColumnChooser
          grid={"contracts"}
          schemaView={spaceContractSchema}
          Name="contracts"
          Columns={contractsColumns}
          onClose={closeModal}
          setIsOpen={setModalIsOpen}
          isOpen={modalIsOpen}
        />
      </Modal>
    );
};

const ActionButtons = ({ setModalIsOpen, columnResize, enableStatusEdit }) => {
  const { params } = useServerAwareState();
  const pipelineId = params.pipeline;
  const { view } = params;

  if ("board" === view)
    return (
      <ActionButtonBoardOptions
        moduleType={moduleTypes.contracts}
        enableColumnEdit={() => {
          setModalIsOpen(true);
        }}
        pipelineFilter={contractPipelineSettings.field}
        columnResize={columnResize}
        spaceType={spaceContractSchema.name}
        type="contracts"
        pipelineId={pipelineId}
        enableStatusEdit={() => {
          enableStatusEdit(true);
        }}
      />
    );
  else if ("cards" === view) {
    return (
      <ActionButtonCardsOptions
        moduleType={moduleTypes.contracts}
        enableColumnEdit={() => {
          setModalIsOpen(true);
        }}
        columnResize={columnResize}
        pipelineFilter={contractPipelineSettings.field}
        spaceType={spaceContractSchema.name}
        pipelineId={pipelineId}
        type="contracts"
        enableStatusEdit={() => {
          enableStatusEdit(true);
        }}
      />
    );
  } else
    return (
      <ActionButtonOptions
        moduleType={moduleTypes.contracts}
        enableColumnEdit={() => {
          setModalIsOpen(true);
        }}
        pipelineFilter={contractPipelineSettings.field}
        columnResize={columnResize}
        spaceType={spaceContractSchema.name}
        type="contracts"
      />
    );
};

const contractPipelineSettings = {
  field: "Contract/PipelineId",
  pipelineStatusField: "Contract/PipelineStatusId",
  type: PipelineTypeEnum.Contract,
  StatusProfileComponent: ContractStatusProfile
};

export const useContractFilterColumns = (userId) => {
  return useMemo(() => {
    return [
      {
        propField: "NoTagDescription",
        icon: <FontAwesomeIcon icon={faAlignJustify} />,
        titleId: "DESCRIPTION",
        field: "Contract/NoTagDescription",
        type: "string"
      },
      {
        propField: "Locality",
        icon: <FontAwesomeIcon icon={faCity} />,
        titleId: "CITY",
        field: "Location/Locality",
        type: "string"
      },
      {
        propField: "PostalCode",
        icon: <FontAwesomeIcon icon={faRoad} />,
        titleId: "POSTAL_CODE",
        field: "Location/PostalCode",
        type: "string"
      },
      {
        propField: "TicketLimit",
        icon: <FontAwesomeIcon icon={faTicketSimple} />,
        titleId: "TICKET_LIMIT",
        field: "Contract/TicketLimit",
        type: "number"
      },
      createIndirectClientFilter({ field: "DirectIndirectClient/Id" }),

      createTeamFilter(),
      createContractStatusFilter({}),
      // createPipelineStatusFilter({
      //   pipelineSettings: contractPipelineSettings
      // }),
      createUpgradedPipelineStatusFilter({
        pipelineSettings: contractPipelineSettings
      }),
      {
        propField: "EndDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "END_DATE",
        field: "Contract/EndDate",
        type: "dateTime"
      },
      {
        propField: "BeginDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "START_DATE",
        field: "Contract/BeginDate",
        type: "dateTime"
      },
      createDepartmentFilter(),
      createContractTagFilter({ requiresParams: true }),
      createOrganizationFilter({ field: "Company/Id" }),
      createReadFilter({
        baseField: "Contract"
      }),
      {
        propField: "Id",
        icon: <FontAwesomeIcon icon={faFileContract} />,
        titleId: "CONTRACT_NR",
        field: "Contract/Id",
        type: "number"
      },
      {
        icon: <FontAwesomeIcon icon={faClock} />,
        titleId: "SPENT_HOURS",
        field: "Contract/SpentHours",
        type: "number",
        // DescriptionComponent: ({ item, allData, value }) => {
        //   return (
        //     <div>
        //       <FormattedMessage id={"SPENT_HOURS"} />{" "}
        //       <FormattedMessage
        //         id={messages.filters.default[allData.operator]}
        //       />
        //       {` ${MilSecToHour(value)}`}
        //       {/* <ConnectedOrganizationProfile noManager organization={value} /> */}
        //     </div>
        //   );
        // },
        // resolveURLOnly: true,
        getResolvedAPIValue: (value) => HourToMilSec(value)
      },
      {
        propField: "StatusDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_STATUS_DATE",
        field: "Contract/StatusDate",
        type: "dateTime"
      },

      {
        propField: "PipelineDate",
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        titleId: "LAST_PIPELINE_STATUS_DATE",
        field: "Contract/PipelineDate",
        type: "dateTime"
      },
      createUnreadFilter({
        baseField: "Contract"
      }),
      createUserFilter({
        propField: "Creator",
        titleId: "CREATED_BY",
        field: "Creator/Id",
        DescriptionComponent: ({ item, value }) => {
          return (
            <div>
              <FormattedMessage id={"CREATED_BY"} />{" "}
              <ConnectedAccountProfile account={value} />
            </div>
          );
        }
      }),
      createFollowingFilter({
        baseField: "Contract"
      }),
      createSupportEmailFilter({
        baseField: "Contract",
        value: 0
      }),
      createContractSignedFilter({
        baseField: "Contract"
      }),
      createSupportEmailFilter({
        baseField: "Contract",
        value: 1
      }),
      createNotFollowingFilter({
        baseField: "Contract"
      }),
      // { title: "Client", field: "Client/Name", type: "string" },
      createOriginFilter({
        baseFieldName: "Contract",
        dropdownType: ContractTargetDropdown
      }),
      {
        icon: <FontAwesomeIcon icon={faPercentage} />,
        titleId: "PERCENTAGE_PERFORMANCE",
        field: "Contract/Timeleft",
        type: "number"
      },
      createManagerFilter({
        propField: "Manager"
      }),
      createContractTypeFilter({
        propField: "ContractType",
        icon: <FontAwesomeIcon icon={faFileContract} />
      }),
      {
        icon: <FontAwesomeIcon icon={faMoneyBill} />,
        titleId: "VALUE",
        field: "Currency/EstimatedCost",
        type: "number"
      },
      {
        icon: <FontAwesomeIcon icon={faMoneyBill} />,
        titleId: "VALUE_HOUR",
        field: "CurrencyHour/EstimatedCost",
        type: "number"
      }
    ];
  }, []);
};

// const defaultOrderBy = { column: "Contract/Id", type: "desc" };

const ContractOverviewComp = () => {
  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "CONTRACTS" }));
  const CurrentAccount = useCurrentAccount();
  const filterColumns = useContractFilterColumns(CurrentAccount.Id);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelection = useCallback((fn) => {
    setSelectedItems((t) => {
      const resolvedNewItems = fn(t);
      return resolvedNewItems;
    });
  }, []);

  const SelectedResolvedArr = useMemo(() => {
    return selectedItems.map((t) => {
      return { type: InboxTypeEnum.Contract, id: t };
    });
  }, [selectedItems]);

  const columnResize = true;
  let gridColumnLayout = JSON.parse(
    window.localStorage.getItem("ar-grid-column-layout")
  );

  const localStorageColumns = JSON.parse(
    window.localStorage.getItem("ar-grid-column-layout")
  )
    ? window.localStorage.getItem("ar-grid-column-layout")["contracts"]
    : undefined;

  const groupableColumns = ["Assigned/Name", "Client-IndirectClient/Name"];
  const ordenableColums = [];
  if (localStorageColumns) {
    contractsColumns.forEach((e) => {
      if (
        localStorageColumns.findIndex(e.field) !== -1 &&
        e.sortable !== false
      ) {
        ordenableColums.push(e.field);
      }
    });
  } else {
    contractsColumns.forEach((e) => {
      if (e.sortable !== false) {
        ordenableColums.push(e.field);
      }
    });
  }

  const openSidebar = useEntitySummarySidebar();

  const openFormFromKanban = useCallback(
    (pipeline, pipelineStatus) => {
      openSidebar({
        type: moduleTypes.contracts,
        props: {
          form: {
            Pipeline: pipeline,
            PipelineStatus: pipelineStatus
          }
        }
      });
    },
    [openSidebar]
  );

  const KanbanCreateNewValue = useMemo(() => {
    return {
      handleNewItemFromKanban: openFormFromKanban,
      TranslationTag: "ADD_CONTRACT",
      icon: faFileContract
    };
  }, [openFormFromKanban]);

  const [stateModalIsOpen, setStateModalIsOpen] = useState(false);

  const stateCloseModal = useCallback(() => {
    setStateModalIsOpen(false);
  }, []);

  const ClearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);
  const hasContractEditPermission = useCurrentUserEditContractPermission();

  let query = useUrlQueryString();
  const canShowMoreOptions = useMemo(() => {
    const view = query.get("view");
    if (view === "calendar" || view === "board" || view === "timeline") {
      return false;
    } else return true;
  }, [query]);

  return (
    <PageContainer>
      <GridActionContext.Provider value={ContractEntityActionsChildren}>
        <SelectedArrMsgContext.Provider value={SelectedResolvedArr}>
          <PipelineProvider value={contractPipelineSettings}>
            <ServerAwareProvider
              columns={contractsColumns}
              filterColumns={filterColumns}
              moduleType={moduleTypes.contracts}
              schema={spaceContractSchema}
            >
              <EntityGridChildrenProgressProvider
                roomType={RealTimeRoomTypes.Contract}
                schema={spaceContractSchema}
              >
                <Modals
                  setModalIsOpen={setModalIsOpen}
                  closeModal={closeModal}
                  gridColumnLayout={gridColumnLayout}
                  modalIsOpen={modalIsOpen}
                  stateCloseModal={stateCloseModal}
                  setStateModalIsOpen={setStateModalIsOpen}
                  stateModalIsOpen={stateModalIsOpen}
                />
                {/* <Modal
              // containerClassName=""
              isOpen={modalIsOpen}
              onClose={closeModal}
			  >
              <ColumnChooser
			  grid={"contracts"}
			  Name="contracts"
			  Columns={contractsColumns(gridColumnLayout)}
			  onClose={closeModal}
			  setIsOpen={setModalIsOpen}
			  isOpen={modalIsOpen}
              />
            </Modal> */}
                <DeleteMultipleModalHandler
                  handleItemSelection={setSelectedItems}
                >
                  <GridRequestProvider toggleColumnChooser={setModalIsOpen}>
                    <PageHeader
                      schema={spaceContractSchema}
                      toggleFilterList
                      title={intl.formatMessage({ id: "CONTRACTS" })}
                      unreadOptions={
                        canShowMoreOptions && {
                          Text: true,
                          IsService: true,
                          EnableDeleteAll: true,
                          Children: hasContractEditPermission && (
                            <>
                              <ActionButtonItem
                                component={
                                  <ContractManagerMultipleButton
                                    selectedItems={{
                                      selectedItems,
                                      ClearSelection
                                    }}
                                  />
                                }
                              ></ActionButtonItem>
                              <ActionButtonItem
                                component={
                                  <MultiMarkerChanger
                                    selectedItems={{
                                      selectedItems,
                                      ClearSelection
                                    }}
                                  />
                                }
                              ></ActionButtonItem>
                            </>
                          )
                        }
                      }
                    >
                      <div className="d-flex ml-2 justify-content-start flex-1 align-items-center">
                        <ContractFormButton />
                        <ServerAwareQueryInput
                          placeholder={intl.formatMessage({ id: "SEARCH" })}
                          className="mx-2"
                        />
                      </div>
                      <div className="d-flex">
                        <SaveFavoriteButton />
                        <ServerAwareButtons className="ml-2">
                          <ServerAwareButton icon={faList} />
                          <ServerAwareButton
                            icon={faCalendar}
                            type="calendar"
                          />
                          <ServerAwareButton icon={faStream} type="timeline" />
                          <ServerAwareButton icon={faColumns} type="board" />
                          <ServerAwareButton icon={faThLarge} type="cards" />
                        </ServerAwareButtons>

                        <ActionButtons
                          setModalIsOpen={setModalIsOpen}
                          columnResize={columnResize}
                          enableStatusEdit={setStateModalIsOpen}
                        />
                      </div>
                    </PageHeader>
                    <PageContent>
                      <ServerAwareFilterList
                        groupableColumns={groupableColumns}
                        filterType={FilterViewTypeEnum.Contract}
                      />
                      <ServerAwareView>
                        <TotalsProvider>
                          <ContractGrid
                            columnResize={columnResize}
                            className="flex-1"
                            onItemSelect={handleItemSelection}
                            selectedItems={selectedItems}
                          />
                        </TotalsProvider>
                      </ServerAwareView>
                      <ServerAwareView type="cards">
                        {/* <CardRequestProvider > */}
                        <ContractCards onItemSelect={handleItemSelection} />
                        {/* </CardRequestProvider> */}
                      </ServerAwareView>
                      <ServerAwareView type="board">
                        <KanbanCreateNewContext.Provider
                          value={KanbanCreateNewValue}
                        >
                          <RealTimePageProvider
                            pageType={RealTimePageEnum.Contract}
                          >
                            <ContractBoard />
                          </RealTimePageProvider>
                        </KanbanCreateNewContext.Provider>
                      </ServerAwareView>
                      <ServerAwareView type="calendar">
                        <ContractCalendar />
                      </ServerAwareView>
                      <ServerAwareView type="timeline">
                        <ContractTimeline />
                      </ServerAwareView>
                    </PageContent>
                  </GridRequestProvider>
                </DeleteMultipleModalHandler>
              </EntityGridChildrenProgressProvider>
            </ServerAwareProvider>
          </PipelineProvider>
        </SelectedArrMsgContext.Provider>
      </GridActionContext.Provider>
    </PageContainer>
  );
};

export default ContractOverviewComp;
