export const en = {
  "14_DAYS_TRIAL": " free trial days",
  "180DAYS": "180 days",
  "24H": "24H",
  ACCEPT_INVITATION: "The invitation has already been accepted.",
  ACCEPT_TERMS_AND_CONDITIONS: "I accept the <a>terms and conditions</a>",
  ACCESS: "Access",
  ACCESSES: "Accesses",
  ACCOUNT: "Account",
  ACCOUNT_ASSIGNED: "assigned you the account",
  ACCOUNT_DELETED: "deleted the client",
  ACCOUNT_IS: "Account is",
  ACCOUNT_NR: "Account nº",
  ACCOUNT_PROFILE: "Account and profile",
  ACCOUNT_STATUS: "Account status",
  ACCOUNT_TYPE: "Account type",
  ACCOUNT_UPDATE: "updated the account",
  ACCOUNT_UPDATED: "updated the account",
  ACCOUNTING: "Accounting",
  ACCOUNTS: "Accounts",
  ACTIONS: "Actions",
  ACTIVATE_TRIGGER_ON_CREATION_OR_EDITION: "Activate trigger on",
  ACTIVE: "Active",
  ACTIVE_NOTIFICATIONS: "Active notifications",
  ACTIVE_NOW: "Active now",
  ACTIVE_WORKSPACE: "Workspace",
  ACTIVITY: "Activity",
  ADD: "Add",
  ADD_ACCOUNT: "Add account",
  ADD_ADDRESS: "Add address",
  ADD_ANOTHER_PUBLICATION: "Add another publication",
  ADD_API_KEY: "Add API key",
  ADD_BLOCK: "Add new block",
  ADD_BOOLEAN: "Add true or false",
  ADD_CALL: "Add call",
  ADD_CANCELATION_REASON: "Add cancellation reason",
  ADD_CASE: "Add case",
  ADD_CHECKLIST_TEMPLATE: "Add checklist template",
  ADD_CLIENT_TYPE: "Add account type",
  ADD_CLIENTS_WORKSPACE: "Add clients to your workspace",
  ADD_COMPANY: "Add company",
  ADD_CONDITION: "Add condition",
  ADD_CONTACT: "Add contact",
  ADD_CONTACTS: "Add contacts",
  ADD_CONTACTS_WORKSPACE: "Associate your customers' contacts",
  ADD_CONTRACT: "Add contract",
  ADD_CONTRACTS: "Add contracts",
  ADD_CONTRACTS_WORKSPACE: "Insert and update your contracts",
  ADD_COUPON: "Add coupon",
  ADD_CRON_TIME: "Add cron",
  ADD_DATE: "Add date",
  ADD_DEAL: "Add deal",
  ADD_DEALS_WORKSPACE: "Creating a deal helps you make the best decisions",
  ADD_DEPARTMENT: "Add department",
  ADD_EMAIL_DOMAIN: "Add email domain",
  ADD_EMAIL_SIGNATURE: "Add email signature",
  ADD_FAVORITE: "Add favorite",
  ADD_FILTER: "Add filter",
  ADD_FOLDER: "Add folder",
  ADD_INEFICIENT_TYPE: "Add non-attributable time reason",
  ADD_ITEM: "Add item",
  ADD_LOSS_REASON: "Add loss reason",
  ADD_LOST_REASON: "Add loss reason",
  ADD_NEW_ACCOUNT: "Add new account",
  ADD_NEW_BILLING_CONTACT: "Update billing contact(s)",
  ADD_NEW_BLOCK: "Add new block",
  ADD_NEW_CONTACT: "Add new contact",
  ADD_NEW_COUPON: "Add new coupon",
  ADD_NEW_DETAIL_VIEW_APP_BAR: "Add 360 view",
  ADD_NEW_DETAILS_APP_BAR_DESC: "Select the element to add to favorites",
  ADD_NEW_DETAILS_APP_BAR_TITLE: "Add 360 view to favorites",
  ADD_NEW_EMAIL_INPUT: "Add another invitation",
  ADD_NEW_PERIOD: "Add new period",
  ADD_NEW_VIEW_APP_BAR: "Add new view",
  ADD_NEW_VIEW_APP_BAR_DESC: "Select the type of view to add to favorites",
  ADD_NEW_VIEW_APP_BAR_TITLE: "Add view to favorites",
  ADD_NODES: "Add nodes",
  ADD_NOTE: "Add note",
  ADD_NUMBER: "Add number",
  ADD_PIPELINE: "Add pipeline",
  ADD_PROJECT: "Add project",
  ADD_PUBLICATION: "Add publication",
  ADD_RECEPTION: "Add inbox",
  ADD_SERVICES_WORKSPACE:
    "A whole range of features at the service of your company",
  ADD_SOCIAL_POST: "Add post",
  ADD_SOCIAL_SCHEDULE: "Add publish schedule",
  ADD_SPACE: "Add space",
  ADD_STATUS: "Add status",
  ADD_STRING: "Add text",
  ADD_SUBJECT: "Add subject",
  ADD_SUBSCRIPTION: "Add subscription",
  ADD_TASK: "Add task",
  ADD_TEAM: "Add team",
  ADD_TIME: "Add time",
  ADD_TIME_TYPE: "Add time type",
  ADD_TIMER: "Add timer",
  ADD_TIMERS: "Add timers",
  ADD_TYPE_OF_CONTRACT: "Add contract type",
  ADD_TYPE_OF_PROJECTS: "Add project type",
  ADD_TYPE_OF_SUBSCRIPTION: "Add subscription type",
  ADD_USER: "Add user",
  ADD_VIEW: "Add view",
  ADD_WIDGET: "Add widget",
  ADD_WORKFLOW: "Add workflow",
  ADDRESS: "Address",
  ADDRESS_1: "Address 1",
  ADDRESS_2: "Address 2",
  ADITION: "Adition",
  ADMIN: "Admin",
  ADMIN_ACCESS: "Admin access",
  ADMINISTRATION_DEPARTMENT: "Administration",
  ADVANCE_TO_AIRDESK: "Ignore and go to Airdesk",
  ADVANCED_FILTER: "Advanced filter",
  ADVANCED_OPTIONS: "Advanced options",
  ADVANCED_SETTINGS: "Advanced settings",
  AGROUP: "Group",
  AIRDESK_UPDATE_DESCRIPTION: "Please refresh the page.",
  AIRDESK_UPDATE_TITLE: "There's a new update available!",
  AIRLINES_OR_AVIATION: "Airlines/Aviation",
  ALIGN_CENTER: "Align center",
  ALIGN_JUSTIFY: "Justify",
  ALIGN_LEFT: "Align left",
  ALIGN_RIGHT: "Align right",
  ALL: "All",
  ALL_CONVERSATIONS: "All messages",
  ALLOWED_DOMAINS: "Allowed domains",
  ALMOST_EXPIRED_CONTRACTS: "Almost expired contracts",
  ALMOST_EXPIRED_PROJECTS: "Almost expired projects",
  ALTERNATIVE_DISPUTE_RESOLUTION: "Alternative dispute resolution",
  ALTERNATIVE_MEDICINE: "Alternative medicine",
  AMOUNT: "Amount",
  ANALYSIS: "Analysis",
  ANALYSIS_DEALS: "Analysis of deals",
  AND: "and",
  ANIMATION: "Animation",
  ANNUALY: "Annualy",
  ANSWER_SIGNATURE: "Reply",
  API_ALREADY_FOLLOWING: "You are already following this item.",
  API_ALREADY_NOT_FOLLOWING: "You are not following this item.",
  API_CANNOT_CLOSE_DEPENDENCIES_OPEN:
    "The item cannot be closed / canceled because it has dependencies still in the open state.",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS:
    "The item cannot be deleted because it has emails associated with it.",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS_MULTIPLE:
    "{Ids} items cannot be deleted because they have emails associated with them.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES:
    "The item cannot be deleted because it has dependencies associated.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_DATA:
    "The item cannot be deleted because it still has these dependencies associated: {Depends}.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_MULTIPLE:
    "Items {Ids} cannot be deleted because they have dependencies associated.",
  API_CANNOT_REMOVE_HAS_TIMECHARGES:
    "The item cannot be deleted because it has associated times.",
  API_CANNOT_REMOVE_HAS_TIMECHARGES_MULTIPLE:
    "Items {Ids} cannot be deleted because they have associated times.",
  API_CANNOT_REMOVE_IS_CLASSIFIED:
    "The item cannot be deleted because it is already in a classification.",
  API_CANNOT_REMOVE_IS_CLASSIFIED_MULTIPLE:
    "{Ids} items cannot be deleted because they are already classified.",
  API_DATA_IS_EQUAL: "The data is the same.",
  API_EXPORT_COMPLETED_SUCCESSFULLY:
    "The export was successfully completed. You can now see your file in the personal export view.",
  API_EXPORT_STARTED: "The export started successfully.",
  API_FILTER_ALREADY_EXISTS: "Filter name already exists.",
  API_IMPORT_CLIENT_END: "The account import job has ended.",
  API_IMPORT_CONTACT_END: "The contacts import job has ended.",
  API_KEY: "API Key",
  API_KEY_ID: "API Key Id",
  API_KEYS: "API",
  API_MARKED_AS_READ: "It is already marked as read.",
  API_MARKED_AS_UNREAD: "It is already marked as unread.",
  API_NO_SOCIAL_COMPANY_PAGE_ASSOCIATED:
    "There are no business accounts associated with your account.",
  API_NOT_FOUND: "Item not found.",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EDIT:
    "Item not found or does not have enough permissions to edit it.",
  API_NOT_FOUND_OR_NO_PERMISSIONS_REMOVE:
    "Item not found or you do not have sufficient permissions to remove it.",
  API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW:
    "Item not found or you do not have enough permissions to view it.",
  API_REFERENCE: "API reference",
  APPAREL_OR_FASHION: "Apparel/Fashion",
  APPLY: "Apply",
  APPLY_ALL: "Apply all",
  APPLY_TO: "Apply to",
  APRIL: "April",
  APROVED: "Aproved",
  APROVING: "Aproving",
  ARCHITECTURE_OR_PLANNING: "Architecture/Planning",
  ARTS_OR_CRAFTS: "Arts/Crafts",
  ASSIGN_TO: "Assign to",
  ASSIGNED_THE_DEAL: "assigned the deal",
  ASSIGNED_TO: "Assigned to",
  ASSOCIATE_CASE: "Associate case to",
  ASSOCIATE_CONTRACT_TO: "Associate contract to",
  ASSOCIATE_DEAL_TO: "Associate deal to",
  ASSOCIATE_PROJECT_TO: "Associate project to",
  ASSOCIATE_SUBSCRIPTION: "Associate subscription to",
  ASSOCIATE_TASK_TO: "Associate task to",
  ASSOCIATE_TIME: "Associate time registration to",
  ASSOCIATE_TIME_RECORD: "Associate time record to",
  ASSOCIATE_TO: "Associate to",
  ASSOCIATED: "Associated",
  ASSOCIATED_ACCOUNTS: "Associated accounts",
  ASSOCIATED_CONTRACTS: "Associated contracts",
  ASSOCIATED_PROJECTS: "Associated projects",
  ASSOCIATED_TO: "Associated to",
  ASSOCIATIONS: "Associations",
  ASSOCIATIONS_CONTACT_DATA: "Association of contact data",
  ATTACHMENTS: "Attachments",
  ATTRIBUTED_TO: "Attributed to",
  ATTRIBUTES: "Attributes",
  AUGUST: "August",
  AUTO_REFRESH: "Auto-update",
  AUTOMATION: "Automation",
  AUTOMOTIVE: "Automotive",
  AVERAGE: "Neutral",
  AVERAGE_CLOSE_CASE: "Average time to close case",
  AVERAGE_CLOSE_TASKS: "Average time to close tasks",
  AVERAGE_CLOSE_TIME: "Average time to close",
  AVERAGE_STATUS: "Average time per status",
  AVIATION_OR_AEROSPACE: "Aviation/Aerospace",
  BACK: "Back",
  BACKGROUND: "Background",
  BACKGROUND_JOB_CANCELED_TITLE: "Process has been canceled",
  BAD: "Bad",
  BANKING_OR_MORTGAGE: "Banking/Mortgage",
  BANNER: "Banner",
  BANNER_LINK: "Banner link",
  BASIC: "Basic",
  BASIS_CUSTOMER: "Legitimate interest - existing customer",
  BASIS_OTHER: "Legitimate interest - other",
  BASIS_POSSIBLE: "Legitimate interest - possible / potential",
  BEGINS_WITH: "Begins with",
  BILLED: "Billed",
  BILLED_NR: "Billing Nº",
  BILLING: "Billing",
  BILLING_CONTACTS_ADD_SUCCESS: "Billing contacts added successfully",
  BILLING_CONTACTS_UPDATED_SUCCESS: "Billing contacts updated successfully",
  BILLING_DETAILS: "Billing details",
  BILLING_EMAIL: "Billing email contact",
  BILLING_EMAILS: "Billing contact email",
  BILLING_HISTORY: "Billing history",
  BILLING_INFORMATION_OPTIONAL: "Billing information",
  BILLING_NR: "Billing nº",
  BILLING_STATUS: "Billing status",
  BILLING_STATUS_CUSTOM: "Company",
  BILLINGS_DELETE_DELAY_DESCRIPTION: "Preparing to delete billings",
  BILLINGS_DELETE_DELAY_TITLE: "Elimination of changes",
  BILLINGS_DELETE_DESCRIPTION: "Deleting billings",
  BILLINGS_DELETE_TITLE: "Elimination of changes",
  BIOTECHNOLOGY_OR_GREENTECH: "Biotechnology/Greentech",
  BIRTH_DATE: "Birthday date",
  BIRTHDAY: "Birthday",
  BIRTHDAY_TODAY: "Find out who's birthday is this week.",
  BLOCK: "Block",
  BLOCK_USER: "Block user",
  BLOCKED: "Blocked",
  BLOCKS: "Blocks",
  BODY: "Body",
  BOLD: "Bold",
  BOTH: "Both",
  BRANDING: "Branding",
  BROADCAST_MEDIA: "Broadcast media",
  BROKEN_SOCIAL_CONNECTION: "Broken link, please reconnect the account.",
  BUDGET_RISK: "Budget risk",
  BUILDING_MATERIALS: "Building materials",
  BULLET_LIST: "Bullet list",
  BUSINESS_SUPPLIES_OR_EQUIPMENT: "Business supplies/equipment",
  BUTTONS: "Actions",
  CALENDAR_GENERAL_ERROR: "We had an error loading the calendar.",
  CALENDAR_MORE_ITEMS: "{num} more",
  CALL: "Call",
  CALL_ASSIGNED: "assigned you the call",
  CALL_CREATION: "Call creation",
  CALL_DELETED: "deleted the call",
  CALL_IS: "Call is",
  CALL_NR: "Call Nº",
  CALL_UPDATE: "updated the call",
  CALLS: "Calls",
  CALLS_CHANGED: "changed calls",
  CALLS_DELETE_DELAY_DESCRIPTION: "Preparing to delete calls",
  CALLS_DELETE_DELAY_TITLE: "Deleting calls",
  CALLS_DELETE_DESCRIPTION: "Deleting calls",
  CALLS_DELETE_TITLE: "Call elimination",
  CALLS_DESCRIPTION: "Track your company's call count, response time and more.",
  CALLS_FAILED_CHANGED: "failed when changing",
  CALLS_MULTIPLE: "Calls(s)",
  CALLS_NOT_CHANGED: "unchanged calls",
  CANCEL: "Cancel",
  CANCEL_BILLING_ANYTIME: "Cancel anytime",
  CANCEL_PLAN_MODAL_DESCRIPTION:
    "Before you leave, contact us and let us know why you are leaving. We could help you before you go. Contact us via",
  CANCEL_PLAN_MODAL_DESCRIPTION_WARNING:
    "You will lose access to workspace information, be sure to export before canceling.",
  CANCEL_PLAN_MODAL_TITLE: "Cancel subscription",
  CANCEL_PLAN_SUBSCRIPTION: "Cancel subscription",
  CANCEL_SUBSCRIPTION: "Cancel Subscription",
  CANCEL_SUBSCRIPTION_AIRDESK: "Cancel your Airdesk subscription",
  CANCEL_SUBSCRIPTION_AIRDESK_UNDER:
    "Canceling your subscription suspends the service and the data is deleted in 15 days.",
  CANCELATION_DESCRIPTION: "Cancellation description",
  CANCELATION_REASON: "Cancellation reason",
  CANCELATION_REASONS: "Cancellations reasons",
  CANCELATION_REASONS_SUCCESS: "Cancellation reason saved successfully",
  CANCELED_SUBSCRIPTION: "Canceled subscription",
  CANCELLED: "Cancelled",
  CANCELLED_PROJECTS: "Cancelled projects",
  CANT_REPLY_CLOSED_CONVERSATION: "Only open conversations can be answered.",
  CAPITAL_MARKETS_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Capital markets/hedge fund/private equity",
  CARD_CANCELED_SUCCESS: "Card removed successfully",
  CARD_NAME: "Name on card",
  CARD_NUMBER: "Card number",
  CARD_SECURITY_CODE: "Security code",
  CASE: "Case",
  CASE_ASSIGNED: "assigned you the case",
  CASE_ASSIGNED_TO: "assigned the case",
  CASE_DELETED: "deleted the case",
  CASE_FORM: "Case form",
  CASE_IS: "Case is",
  CASE_NR: "Case Nº",
  CASE_UPDATE: "updated the case",
  CASE_WAS_CLOSED: "the case <b>{caseName}</b> was closed",
  CASES: "Cases",
  CASES_STATUS: "Case status",
  CATEGORY: "Category",
  CATEGORY_IS: "Category is",
  CELLPHONE_CODES: "Change cellphone codes",
  CHANGE_ACTIVE_PLAN: "Change plan",
  CHANGE_ASSIGNED: "Change assignment",
  CHANGE_BILLING_ANYTIME: "Change plans whenever you want",
  CHANGE_GDPR: "Change GDPR",
  CHANGE_MANAGER: "Change manager",
  CHANGE_NOTE: "Change note",
  CHANGE_PASSWORD: "Change",
  CHANGE_PASSWORD_BLOCKED_DESC:
    "The current password has failed more than 3 times please try again after",
  CHANGE_PASSWORD_BLOCKED_TITLE: "Password change blocked",
  CHANGE_PIPELINE: "Change pipeline",
  CHANGE_STATUS: "Change status",
  CHANGE_WORKSPACE: "Change workspace",
  CHANGED_BY: "Changed by",
  CHANGES: "Changes",
  CHANGES_SAVED_WITH_SUCCESS: "Changes saved successfully",
  CHAT_CONNECTION_ERROR_DESCRIPTION:
    "There was a problem while trying to connect you with the chat",
  CHAT_NO_MESSAGES: "There are no messages",
  CHAT_NO_NOTES: "There are no notes",
  CHAT_ONLINE: "Online chat",
  CHAT_RECONNECTING: "Reconnecting...",
  CHECK_LIST: "Check list",
  CHECKLIST: "Checklist",
  CHECKLIST_TEMPLATE: "Checklist templates",
  CHEMICALS: "Chemicals",
  CHOOSE_A_SPACE_NAME: "What's the name of your company/group?",
  CHOOSE_A_SPACE_NAME_EXPLANATION:
    "We will use this to identify your Airdesk workspace, which you can always change later.",
  CHOOSE_A_TEMPLATE_TO_USE: "Choose a starting template",
  CHOOSE_BILLING_PLAN: "Choose the plan that best suits your company",
  CHOOSE_COLUMNS: "Choose the columns to display",
  CHOOSE_DEPARTMENT: "Choose the department",
  CHOOSE_MODULES: "Choose the modules to display",
  CHOOSE_PAGE: "Pick a page",
  CHOOSE_PIPELINE_STATUS: "Choose the pipeline status to display",
  CHOOSE_STATUS: "Choose the status to display",
  CHOOSE_THE_DROPDOWN_ORDER: "Choose the order for the dropdown",
  CHOSEN_PLAN: "Plan chosen:",
  CITY: "City",
  CIVIC_OR_SOCIAL_ORGANIZATION: "Civic/Social organization",
  CIVIL_ENGINEERING: "Civil engineering",
  CLASS_NR: "Class Nº",
  CLASSIFICATION: "Classification",
  CLASSIFICATION_CONFIRMATION_TEXT: "Do you want to perform this action?",
  CLASSIFICATION_DESCRIPTION:
    "See the rankings of times posted by your collaborators.",
  CLASSIFICATION_ERROR:
    "There was an error processing your request. If the error persists, contact the administration",
  CLASSIFICATION_IDENTIFIER_ERROR:
    "Identifier already exists. Please select another",
  CLASSIFICATION_INTEGRITY_AFFECTED:
    "The system detected that changing the origin will affect",
  CLASSIFICATION_LIST_EMPTY: "Classification list empty.",
  CLASSIFICATION_NR: "Classification Nº",
  CLASSIFICATION_ORIGIN: "Classification origin",
  CLASSIFICATION_ORIGIN_ERROR: "Origin is required",
  CLASSIFICATION_ORIGIN_RECOMMENDATION:
    "Depending on the time records below, the system recommends the following source:",
  CLASSIFICATION_REPORT: "Classification report",
  CLASSIFICATION_SELECT_ORIGIN: "Please select an origin",
  CLASSIFICATION_SELECT_ORIGIN_QUESTION: "Want to select?",
  CLASSIFICATION_STATUS_ERROR: "Status must be classified",
  CLASSIFICATION_TIMECHARGE_ADDITION_SUCCESS: "Time charges successfully added",
  CLASSIFICATION_TIMECHARGE_ERROR:
    "The system detected that there is one or more time charge records that would be affected, already classified and with another source. To proceed with this classification, update the following:",
  CLASSIFICATION_TO_NEW_ORIGIN: "To new origin:",
  CLASSIFICATION_TOTAL_OF: "getting a total of",
  CLASSIFICATION_UPDATE_SUCCESS: "Classification updated successfully",
  CLASSIFICATION_WILL_BE_ADDED_TO: "will be added to",
  CLASSIFICATION_WILL_CHANGE: "Will change:",
  CLASSIFICATIONS: "Classifications",
  CLASSIFIED: "Classified",
  CLASSIFIED_BUT_OTHER_ENTITY: "Associated but classified with another entity",
  CLASSIFIED_BY: "Classified by",
  CLASSIFY: "Classify",
  CLEAN: "Clean",
  CLEAN_ALL: "Clear all",
  CLEAN_BOARD: "Clean board",
  CLIENT_CREATION: "Account creation",
  CLIENT_DATA_IMPORT_AIRDESK:
    "Importing customer data to associate with Airdesk",
  CLIENT_SIGNATURE: "Client signature",
  CLIENT_STATUS: "Account status",
  CLIENT_STATUS_IS: "Account status is",
  CLIENT_TYPE_EQUALS: "Client type is equal to",
  CLIENT_TYPE_LIST_EMPTY: "List of account types empty.",
  CLIENT_TYPE_LIST_NO: "There are no associated account type records.",
  CLIENTS_CHANGED: "changed accounts",
  CLIENTS_DELETE_DELAY_DESCRIPTION: "Preparing to delete accounts",
  CLIENTS_DELETE_DELAY_TITLE: "Account deletion",
  CLIENTS_DELETE_DESCRIPTION: "Deleting accounts",
  CLIENTS_DELETE_TITLE: "Account deletion",
  CLIENTS_FAILED_CHANGED: "failed when changing",
  CLIENTS_NOT_CHANGED: "unchanged accounts",
  CLOSE_ACCOUNTS: "Closed accounts",
  CLOSE_DATE: "Close date",
  CLOSED: "Closed",
  CLOSED_CASES: "Closed cases",
  CLOSED_CONTRACTS: "Closed contracts",
  CLOSED_PROJECTS: "Closed projects",
  CLOSED_RECEPTION: "Closed",
  CLOSED_TASKS: "Closed tasks",
  CODE: "Code",
  CODE_CONTAINER: "Code box",
  CODE_VIEW: "Code Style",
  COLABORATORS: "Collaborators",
  COLLABORATOR: "Collaborator",
  COLLABORATOR_NR: "Collaborator nº",
  COLLABORATORS: "Collaborators",
  COLOR: "Color",
  COLOR_FILTER: "Color",
  COLUMN: "Column",
  COLUMNS: "Columns",
  COMBINE: "Combine",
  COMERCIAL: "Sales",
  COMMENT: "Comment",
  COMMERCIAL: "Commercial",
  COMMERCIAL_DEPARTMENT: "Commercial",
  COMMERCIAL_REAL_ESTATE: "Commercial real estate",
  COMPANIES: "Companies",
  COMPANIES_SUBSIDIARIES: "Companies/Subsidiaries",
  COMPANY: "Company",
  COMPANY_ADDRESS1: "Company address 1",
  COMPANY_ADDRESS2: "Company address 2",
  COMPANY_AVATAR: "Company avatar",
  COMPANY_DOMAIN: "Company domain",
  COMPANY_EMAIL: "Company email",
  COMPANY_FACEBOOK_URL: "Company facebook",
  COMPANY_GROUP_NAME: "Company/Group name",
  COMPANY_INDUSTRY: "Company industry",
  COMPANY_INFORMATION: "Company information",
  COMPANY_INSTAGRAM_URL: "Company instagram",
  COMPANY_IS: "Company is",
  COMPANY_LINKEDIN_URL: "Company Linkedin",
  COMPANY_LOGO: "Company logo",
  COMPANY_NAME: "Name of the company or group of companies",
  COMPANY_PHONE: "Company phone number",
  COMPANY_SIGNATURE_NAME: "Company name",
  COMPANY_TWITTER_URL: "Company Twitter",
  COMPANY_VAT: "Company VAT",
  COMPANY_YOUTUBE_URL: "Company Youtube",
  COMPARE_OUR_PLANS: "Compare our offers",
  COMPARE_THE_PRICE_OF_PLANS: "Compare the prices of our plans",
  COMPLETE: "Complete",
  COMPLETED: "Completed",
  COMPRESS_ALL: "Minimize everything",
  COMPUTER_GAMES: "Computer games",
  COMPUTER_HARDWARE: "Computer hardware",
  COMPUTER_NETWORKING: "Computer networking",
  COMPUTER_OR_NETWORK_SECURITY: "Computer/network security",
  COMPUTER_SOFTWARE_OR_ENGINEERING: "Computer software/engineering",
  CONCLUDE: "Yes",
  CONCLUDE_CALL: "Change status of call",
  CONCLUDE_CALL_ASK: "Do you want to change the status of this call?",
  CONCLUDE_ENTITY: "Change status of {entityText}",
  CONCLUDE_TASK: "Change status of task",
  CONCLUDE_TASK_ASK: "Do you want to change the status of this task?",
  CONCLUDE_TICKET: "Change status of ticket",
  CONCLUDE_TICKET_ASK: "Do you want to change the status of this ticket?",
  CONCLUDED: "Concluded",
  CONCLUSION: "Conclusion",
  CONDITION: "Condition",
  CONFIG_TITLE: "Settings - Airdesk",
  CONFIGURE_BUTTON: "Configure button",
  CONFIGURE_SUBJECTS: "Configure subjects - attribution of cases",
  CONFIGURE_WORK_START: "Start by setting up your workspace",
  CONFIRM: "Confirm",
  CONFIRM_CHANGES: "Do you want to save the changes made to the notifications?",
  CONFIRM_CONTACT_INFORMATION: "Data verification",
  CONFIRM_CONTACT_INFORMATION_DESCRIPTION:
    "The aggregated data for the contacts that were merged will be associated with this contact.",
  CONFIRM_PASSWORD: "Confirm the password",
  CONNECT_WORKFLOW: "Connect workflow",
  CONNECTED_BY: "Conected by",
  CONSENT_CONTACT: "Consent given by contact",
  CONSTRUCTION: "Construction",
  CONSUMER_ELECTRONICS: "Consumer electronics",
  CONSUMER_GOODS: "Consumer goods",
  CONSUMER_SERVICES: "Consumer Services",
  CONTACT: "Contact",
  CONTACT_ACCESS: "Contact acesss",
  CONTACT_CREATED: "created the contact ",
  CONTACT_CREATION: "Creation of contacts",
  CONTACT_DATA_IMPORT_AIRDESK:
    "Importing contact data to associate with Airdesk",
  CONTACT_IS: "Contact is",
  CONTACT_NOTIFICATIONS: "Contact notifications",
  CONTACT_NR: "Contact nº",
  CONTACT_UPDATED: "updated the contact",
  CONTACT_US: "Contact us",
  CONTACTED_DELETED: "deleted the contact",
  CONTACTS: "Contacts",
  CONTACTS_CHANGED: "changed contacts",
  CONTACTS_DEFAULT_DESCRIPTION_UNDER_TABLES:
    "All billing-related emails will be sent to this email.",
  CONTACTS_DELETE_DELAY_DESCRIPTION: "Preparing to delete contacts",
  CONTACTS_DELETE_DELAY_TITLE: "Deleting contacts",
  CONTACTS_DELETE_DESCRIPTION: "Deleting contacts",
  CONTACTS_DELETE_TITLE: "Deleting contacts",
  CONTACTS_DUPLICATE_DESCRIPTION:
    "The system detected {count} contact(s) with the inserted data above, we recommend that you use an existing contact to prevent duplicates.",
  CONTACTS_FAILED_CHANGED: "failed when changing",
  CONTACTS_MERGE: "Merge contacts",
  CONTACTS_MERGED_FAILURE:
    "There was an error while merging the contacts. Please try again.",
  CONTACTS_MERGED_SUCCESS: "Contacts were merged successfully.",
  CONTACTS_NOT_CHANGED: "unchanged contacts",
  CONTACTS_NR: "Contacts nº",
  CONTAINS: "Contains",
  CONTATCS: "Contacts",
  CONTINUE: "Continue",
  CONTINUE_ON_FAIL: "Continue on fail",
  CONTRACT: "Contract",
  CONTRACT_ASSIGNED: "assigned you the contract",
  CONTRACT_CREATION: "Creation of contracts",
  CONTRACT_DELETED: "deleted the contract",
  CONTRACT_END: "End of contract",
  CONTRACT_IS: "Contract is",
  CONTRACT_NR: "Contract Nº",
  CONTRACT_STATUS: "Contract status",
  CONTRACT_TYPE: "Contract type",
  CONTRACT_TYPES: "Contract types",
  CONTRACT_TYPES_EMPTY_LIST: "Contract type list empty.",
  CONTRACT_TYPES_EMPTY_LIST_DESCRIPTION:
    "There are no records of contract types.",
  CONTRACT_UPDATE: "updated the contract",
  CONTRACTS: "Contracts",
  CONTRACTS_ACTIVITY: "Contracts - activity",
  CONTRACTS_ALMOST_EXCEEDED: "Contracts - time almost exceeded",
  CONTRACTS_BUDGET_RISK: "Contracts - budget risk",
  CONTRACTS_CHANGED: "changed contracts",
  CONTRACTS_DEADLINE_RISK: "Contracts - deadline risk",
  CONTRACTS_DELETE_DELAY_DESCRIPTION: "Preparing to delete contracts",
  CONTRACTS_DELETE_DELAY_TITLE: "Elimination of contracts",
  CONTRACTS_DELETE_DESCRIPTION: "Deleting contracts",
  CONTRACTS_DELETE_TITLE: "Elimination of contracts",
  CONTRACTS_DESCRIPTION:
    "Have a global view of the development of your company's contracts.",
  CONTRACTS_EXCEEDED_HOURS: "Contracts - exceeded time",
  CONTRACTS_FAILED_CHANGED: "failed when changing",
  CONTRACTS_NOT_CHANGED: "unchanged contracts",
  CONTRACTS_PROGRESS: "Contracts progress",
  CONTRACTS_REPORT: "Contracts report",
  CONTROL_HOURS: "Time control",
  CONVERSATION: "Conversation",
  COPIED: "Copied",
  COPY: "Copy",
  COPY_HYPERLINK: "Copy link",
  COPYRIGHT: "All rights reserved.",
  COPYRIGHT_FOOTER:
    "© {date} Airdesk. All rights reserved. <a>Privacy Policy</a>",
  CORRESPOND_CONTACT_INFORMATION: "Match the information",
  CORRESPOND_CONTACT_INFORMATION_DESCRIPTION:
    "Before continuing with this action, confirm the contacts you want to merge. This action cannot be undone and all records will remain associated with the final contact.",
  CORRESPONDENCE: "Match",
  COSMETICS: "Cosmetics",
  COUNTRY: "Country",
  COUNTRY_TAX: "VAT",
  COUPON_ALREDY_EXISTS: "A coupon with the same name already exists",
  CREATE: "Create",
  CREATE_ACCOUNT: "Create account",
  CREATE_ADVANCED_FILTER: "Create advanced filter",
  CREATE_CALL: "Create call",
  CREATE_CASE: "Create case",
  CREATE_COLOR_FILTER: "Create color",
  CREATE_CONTACT: "Create contact",
  CREATE_CONTRACT: "Create contract",
  CREATE_DEAL: "Create deal",
  CREATE_DEPARTMENTS: "Create departments",
  CREATE_DEPARTMENTS_DESC: "Add your departments to your workspace",
  CREATE_DEPARTMENTS_DESC_INFO_DESC:
    "Add your company's departments to Airdesk (e.g. Sales department, Finance department, Design department",
  CREATE_EMAIL: "Create email",
  CREATE_INTERNAL_NOTE: "Create internal notes",
  CREATE_NEW_WIDGET: "Create new widget",
  CREATE_NEW_WORKSPACE: "Create new workspace",
  CREATE_PROJECT: "Create project",
  CREATE_RECURRENCE: "Create recurrence",
  CREATE_SUBSCRIPTION: "Create subscription",
  CREATE_TASK: "Create task",
  CREATE_TEAMS: "Create teams",
  CREATE_TEAMS_DESC:
    "Add your teams to your workspace and associate them with a department",
  CREATE_TIME_RECORD: "Create time record",
  CREATE_TIME_REPORT: "Send times report",
  CREATE_USER: "Create user",
  CREATE_WORKFLOW: "Create workflow",
  CREATE_WORKSPACE: "Create workspace",
  CREATED_ACCOUNT: "Created the account",
  CREATED_BY: "Created by",
  CREATED_CALL: "Created the call",
  CREATED_CASE: "Created the case",
  CREATED_CLASSIFICATION: "Created the classification",
  CREATED_CONTACT: "Created the contact",
  CREATED_CONTRACT: "Created the contract",
  CREATED_CONVERSATION: "Created the conversation",
  CREATED_DEAL: "Created the deal",
  CREATED_NOTE: "Created the note",
  CREATED_PROJECT: "Created the project",
  CREATED_SUBSCRIPTION: "Created the subscription",
  CREATED_TASK: "Created the task",
  CREATED_THE_NOTE: "created the note",
  CREATED_TIME: "Created the time record",
  CREATED_VIEWS: "Created views",
  CREATION: "Creation",
  CREATION_DATE: "Creation date",
  CREDIT_CARD: "Credit card",
  CRON: "Cron",
  CUPON: "Coupon",
  CURRENCY: "Currencies",
  CURRENT_BILLING_PLAN: "Your current plan is",
  CURRENT_CHOSEN_PLAN: "O seu plano atual é",
  CURRENT_ITEM_WAS_DELETED: "The item you were looking at has been deleted.",
  CURRENT_NEW_PASSWORD_SAME:
    "The new password is the same as the current password",
  CURRENT_PASSWORD_INVALID: "Incorrect current password",
  CURRENT_PLAN: "Current plan",
  CUSTOM: "Customized",
  CUSTOM_DASHBOARDS: "Custom dashboards",
  CUSTOM_DOMAIN: "Custom domain",
  CUSTOM_NODE_COLOR: "Custom color",
  CYCLE: "Cycle",
  DAILY: "Daily",
  DAIRY: "Dairy",
  DANGER_ZONE: "Danger zone",
  DASHBOARD: "Dashboard",
  DASHBOARDS: "Standard dashboards",
  DATA_TO_EXPORT: "Data to export",
  DATE: "Date",
  DATE_CREATION: "Date of creation",
  DATE_LAST_CHANGE: "Date last change",
  DAY: "Day",
  DAY_AGO: "Day ago",
  DAY_OF_MONTH: "Day of the month",
  DAYS: "Days",
  DAYS_AGO: "Days ago",
  DEADLINE: "Deadline",
  DEAL: "Deal",
  DEAL_ASSIGNED: "assigned you the deal",
  DEAL_ASSIGNED_TO: "Deal assigned to",
  DEAL_CREATION: "Deal creation",
  DEAL_DELETED: "deleted the deal",
  DEAL_IS: "Deal is",
  DEAL_NR: "Deal nº",
  DEAL_OVERIMPOSED_FOUND: "There is an overlap of deals.",
  DEAL_PROGRESS: "Deals progress",
  DEAL_STATUS: "Deal status",
  DEAL_UPDATE: "updated the deal",
  DEAL_UPDATED: "updated the deal",
  DEALS: "Deals",
  DEALS_ANALYSIS: "Deals analysis",
  DEALS_CHANGED: "changed deals",
  DEALS_DELETE_DELAY_DESCRIPTION: "Preparing to delete business",
  DEALS_DELETE_DELAY_TITLE: "Elimination of business",
  DEALS_DELETE_DESCRIPTION: "Deleting business",
  DEALS_DELETE_TITLE: "Elimination of business",
  DEALS_DESCRIPTION:
    "Track the opportunities created by your sales team, close deals and more.",
  DEALS_DEVELOPMENT: "Deals development",
  DEALS_FAILED_CHANGED: "failed when changing",
  DEALS_HISTORY: "Deals history",
  DEALS_NOT_CHANGED: "unchanged deals",
  DEALS_PERFORMANCE: "Deals performance",
  DEALS_REPORT: "Deals report",
  DEALS_SALES_AMOUNTS: "Deals sales amounts",
  DEALS_STATUS: "Deals status",
  DECEMBER: "December",
  DEFAULT_LANGUAGE: "Default language",
  DEFAULT_VIEW: "General view",
  DEFENSE_OR_SPACE: "Defense/Space",
  DEFINE_ADMIN_ACCESS: "Define admin access",
  DEFINE_CONTACT_ACCESS:
    "All users have basic access. The permissions below apply to sensitive data in some fields of the details.",
  DEFINE_LEGAL_BASIS: "Define legal basis for processing a contact's data",
  DEFINE_MODULES_ACCESS: "Define modules access",
  DEFINE_SETTINGS_ACCESS: "Define settings access.",
  DEFINITIONS: "Settings",
  DELAY: "Delay",
  DELETE: "Delete",
  DELETE_ALL: "Delete all",
  DELETE_ALL_MODAL_CONFIRMATION:
    "Enter the word “DELETE” to confirm the action.",
  DELETE_ALL_MODAL_CONFIRMATION_BUTTON: "Yes, delete",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_ALL_VALID:
    "{total} billings have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_SOME_VALID:
    "{total} invoices have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_ALL_VALID:
    "{total} calls have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_SOME_VALID:
    "{total} calls have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_ALL_VALID:
    "{total} accounts have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_SOME_VALID:
    "{total} accounts have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_ALL_VALID:
    "{total} contacts have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_SOME_VALID:
    "{total} contacts have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_ALL_VALID:
    "{total} contracts were selected.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_SOME_VALID:
    "{total} contracts have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_ALL_VALID: "{total} deals were selected.",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_SOME_VALID:
    "{total} deals have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_ALL_VALID:
    "{total} email signatures have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_SOME_VALID:
    "{total} email signatures have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_ALL_VALID:
    "{total} times were selected.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_SOME_VALID:
    "{total} times have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_NONE_VALID:
    "You do not have permissions to delete any of the selected items",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_ALL_VALID:
    "{total} projects have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_SOME_VALID:
    "{total} projects have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_ALL_VALID:
    "{total} subscriptions have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_SOME_VALID:
    "{total} subscriptions have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_ALL_VALID:
    "{total} tasks have been selected.",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_SOME_VALID:
    "{total} tasks have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_ALL_VALID: "{total} cases were selected.",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_SOME_VALID:
    "{total} cases have been selected, only {valid} can be deleted due to permissions.",
  DELETE_ALL_MODAL_TITLE_BILLINGS:
    "Are you sure you want to delete the invoices below?",
  DELETE_ALL_MODAL_TITLE_CALLS:
    "Are you sure you want to delete the calls below?",
  DELETE_ALL_MODAL_TITLE_CLIENTS:
    "Are you sure you want to delete the accounts below?",
  DELETE_ALL_MODAL_TITLE_CONTACTS:
    "Are you sure you want to delete the contacts below?",
  DELETE_ALL_MODAL_TITLE_CONTRACTS:
    "Are you sure you want to delete the contracts below?",
  DELETE_ALL_MODAL_TITLE_DEALS:
    "Are you sure you want to delete the business below?",
  DELETE_ALL_MODAL_TITLE_EMAIL_SIGNATURES:
    "Are you sure you want to delete the email signatures below?",
  DELETE_ALL_MODAL_TITLE_INTERVENTIONS:
    "Are you sure you want to eliminate the times below?",
  DELETE_ALL_MODAL_TITLE_PROJECTS:
    "Are you sure you want to delete the projects below?",
  DELETE_ALL_MODAL_TITLE_SUBSCRIPTIONS:
    "Are you sure you want to delete subscriptions below?",
  DELETE_ALL_MODAL_TITLE_TASKS:
    "Are you sure you want to delete the tasks below?",
  DELETE_ALL_MODAL_TITLE_TICKETS:
    "Are you sure you want to eliminate the cases below?",
  DELETE_API_KEY_QUESTION: "Do you want to delete this key?",
  DELETE_COLUMN: "Delete column",
  DELETE_COMPANY_MSG: "Do you wish to delete this company?",
  DELETE_COUPON: "Delete coupon",
  DELETE_DOMAIN_QUESTION: "Do you want to delete this domain?",
  DELETE_FILTER: "Delete filter",
  DELETE_FILTER_WARNING: "Do you want to delete this filter?",
  DELETE_INVITE_CONFIRMATION: "Do you wish delete this invitation?",
  DELETE_MESSAGE: "Are you sure you want to delete this message?",
  DELETE_ROW: "Delete row",
  DELETE_SELECTED: "Delete selected",
  DELETE_SOCIAL_ACCOUNT: "Delete connection",
  DELETE_SOCIAL_ACCOUNT_QUESTION: "Do you want to delete this connection?",
  DELETE_SOCIAL_ACCOUNT_WARNING:
    "When you delete the connection, all data will be permanently deleted",
  DELETE_STOPWATCH_CONFIRMATION: "Do you want to delete this timer?",
  DELETE_SUBSCRIPTION_TYPE: "Do you want to delete this subscription type?",
  DELETE_TEAM_QUESTION: "Do you want to delete this team?",
  DELETE_USER: "Delete user",
  DELETE_USER_CONFIRMATION: "Do you wish delete this collaborator?",
  DELETE_VIEW: "Delete view",
  DELETE_VIEW_CONFIRMATION: "Do you want to delete this view?",
  DELETE_WIDGET_CONFIRMATION: "Do you want to delete this widget?",
  DELETE_WORKSPACE: "Delete workspace",
  DELETE_WORKSPACE_DESC:
    "After deleting this workspace you cannot go back, be sure of this action.",
  DELETE_WORKSPACE_TITLE: "Delete workspace",
  DELETED: "Deleted",
  DELETED_ITEM: "Item deleted",
  DELIVERY_TIME: "Delivery time",
  DEPARTMENT: "Department",
  DEPARTMENT_ACTIVITY: "Department activity",
  DEPARTMENT_CASE_AVERAGE: "Department - average case closure time",
  DEPARTMENT_EMAIL: "Department email",
  DEPARTMENT_IS: "Department is",
  DEPARTMENT_LOAD: "Departament - workload",
  DEPARTMENT_NAME: "Department name",
  DEPARTMENT_OR_TEAM: "Department or team",
  DEPARTMENT_PHONE: "Department phone number",
  DEPARTMENT_SAVE_SUCCESS: "Department saved successfully",
  DEPARTMENT_TASKS_AVERAGE: "Department - average task closure time",
  DEPARTMENT_TEAM: "Department / Team",
  DEPARTMENT_TIME_RECORDS: "Time records of department",
  DEPARTMENTS: "Departments",
  DESCRIPTION: "Description",
  DESCRIPTION_LENGTH:
    "The description must have at least 30 characters (only {charLength} entered)",
  DESCRIPTION_PERSON_RESPONSIBLE: "Internal description",
  DESCRIPTION_PERSON_RESPONSIBLE_ABRV: "Internal description",
  DESCRIPTIVE_DEALS: "Descriptive deals performance",
  DESIGN: "Design",
  DESIGNATION: "Designation",
  DESKTOP: "Desktop",
  DETAILED_KNOWLEDGE_OF_EACH_PLAN:
    "Know in detail all the features of each plan",
  DETAILS: "Details",
  DIFFERENT_FROM: "Is different from",
  DIRECT: "Direct message",
  DIRECTION_DEPARTMENT: "Direction",
  DISCOUNT: "Discount",
  DISCOUNT_CODE_VALID_FOREVER: "Promotional code valid forever",
  DISCOUNT_CODE_VALID_ONLY_ONCE: "Promotional code valid for one use only",
  DISCOUNT_CODE_VALID_UNTIL: "Promotional code valid until",
  DISCOUNT_OFF: "Discount of",
  DISPLAY_COLUMNS: "Choose the columns to display",
  DISTRICT: "District",
  DKIM_RECORD: "DKIM record",
  DO_NOT_IMPORT: "Don't import",
  DO_NOTHING: "Do nothing",
  DOC_TEMPLATES: "Templates",
  DOCUMENTATION: "Documentation",
  DOCUMENTS: "Documents",
  DOES_NOT_CONTAIN: "Does not contain",
  DOMAIN: "Domain",
  DOMAIN_ERROR_FORMAT: "The domain you entered does not have a valid format.",
  DOMAIN_KEY_DKIM_DESCRIPTION:
    "You need to add a new <b>TXT</b> record with the name <clip>{Key}</clip> with the following content.",
  DOMAIN_KEY_SPF_DESCRIPTION:
    "You need to add a <b>TXT</b> record at the apex/root of your domain <b>({subDomain})</b> with the following content. If you already send mail from another service, you may just need to add <clip>{Key}</clip> to your existing record.",
  DOMAIN_MX_DESCRIPTION:
    "To receive incoming e-mail for this domain, you need to add the following <b>MX</b> records at the apex/root of your domain <clip>{subDomain}</clip>. You don't have to do this and we'll only tell you if they're set up or not. Both records should be priority <b>10.</b>",
  DOMAIN_NAME: "Domain name",
  DOMAIN_NAME_ERROR_FORMAT:
    "The short name can only contain letters, numbers and hyphen (-)",
  DOMAIN_NAME_ERROR_LENGTH:
    "The short name must be a maximum of 30 characters.",
  DOMAIN_RETURN_PATH_DESCRIPTION:
    "You should add a <b>CNAME</b> record at <clip>{Key}</clip> to point to the hostname below.",
  DOMAINS: "Domains and URL's",
  DOMAINS_URL: "Domains",
  DOWNGRADE_PLAN: "Downgrade",
  DOWNLOAD: "Download",
  DRAFT: "Draft",
  DRAG_OR_CLICK_FILE: "Drag the file or click to search",
  DROP_THE_FILE: "Drop the file to start the upload",
  DUPLICATE: "Duplicate",
  DUPLICATE_ALL: "Duplicate all",
  DUPLICATE_MESSAGE_FOR_CLIENTS:
    "There are already {count} customers with this {type}. <a>Click here to view</a>",
  DUPLICATES: "Duplicates",
  DURATION: "Duration",
  E_LEARNING: "E-learning",
  EDIT: "Edit",
  EDIT_ACCOUNT: "Edit account",
  EDIT_API_KEY: "Edit API key {id}",
  EDIT_CALLS: "Edit calls",
  EDIT_CANCELATION_REASON: "Edit cancellation reason",
  EDIT_CARDS: "Edit cards",
  EDIT_CHECKLIST_TEMPLATE: "Edit checklist template",
  EDIT_CLIENTS: "Edit accounts",
  EDIT_COLOR_FILTER: "Edit color",
  EDIT_COLUMNS: "Edit columns",
  EDIT_COMPANY: "Edit company",
  EDIT_CONTACTS: "Edit contacts",
  EDIT_CONTRACTS: "Edit contracts",
  EDIT_DEAL: "Edited deal",
  EDIT_DEALS: "Edit accounts",
  EDIT_EMAIL_DOMAIN: "Edit email domain",
  EDIT_EMAIL_SIGNATURE: "Edit email signature",
  EDIT_FILTER: "Edit filter",
  EDIT_KANBAN: "Edit kanban",
  EDIT_LOSS_REASON: "Edit loss reason",
  EDIT_MODULES: "Edit modules",
  EDIT_ORDER: "Edit order",
  EDIT_PROJECTS: "Edit projects",
  EDIT_RECEPTION: "Edit reception",
  EDIT_STATUS: "Edit status",
  EDIT_SUBSCRIPTIONS: "Edit subscriptions",
  EDIT_TASKS: "Edit tasks",
  EDIT_TICKETS: "Edit tickets",
  EDIT_WIDGET: "Edit widget",
  EDIT_WORKFLOW: "Edit workflow",
  EDITED: "Edited",
  EDITED_ACCOUNT: "Edited the account",
  EDITED_CALL: "Edited the call",
  EDITED_CASE: "Edited case",
  EDITED_CLASSIFICATION: "Edited the classification",
  EDITED_CONTACT: "Edited the contact",
  EDITED_CONTRACT: "Edited the contract",
  EDITED_CONVERSATION: "Edited the conversation",
  EDITED_DEAL: "Edited the deal",
  EDITED_PROJECT: "Edited project",
  EDITED_SUBSCRIPTION: "Edited the subscription",
  EDITED_TASK: "Edited the task",
  EDITED_TIME: "Edited the time record",
  EDITION: "Edition",
  EDITOR: "Editor",
  EDUCATION_MANAGEMENT: "Education management",
  ELECTRICAL_OR_ELECTRONIC_MANUFACTURING: "Electrical/Electronic manufacturing",
  ELIMINATE_CONTRACT_TYPE: "Do you want to delete this contract type?",
  ELIMINATE_DEPARTMENT_CONFIRMATION: "Do you want to delete this department?",
  ELIMINATE_INEFICIENT_TYPE:
    "Do you want to delete this non-attributable time reason?",
  ELIMINATE_INTERVENTION_TYPE: "Do you want to delete this type of time?",
  ELIMINATE_PROJECT_CATEGORY: "Do you want to delete this project category?",
  EMAIL: "Email address",
  EMAIL_ATTACHMENTS: "Email attachments",
  EMAIL_CORRECT: "Please insert a valid email",
  EMAIL_CREATION: "Email creation",
  EMAIL_DOMAIN: "Email domain",
  EMAIL_DOMAIN_ADD_TO_DNS:
    "Add the records below to your DNS for <b>{Domain}</b>",
  EMAIL_DOMAIN_VERIFY: "Validate records",
  EMAIL_DOMAIN_WILL_SEND_DESCRIPTION:
    "Emails will be sent from: <b>{Domain}</b>",
  EMAIL_FEEDBACK_CONTENT:
    "We invite you to share your feedback regarding your request (<b>{caseId} : {caseName}</b>)",
  EMAIL_FEEDBACK_START: "Dear",
  EMAIL_FROM: "From",
  EMAIL_INFO_DESC: "Your business email",
  EMAIL_PASSWORD_INSTRUCTIONS:
    "We will send instructions by email to reset your password.",
  EMAIL_REPORT: "Email report",
  EMAIL_SIGNATURE: "Email signature",
  EMAIL_SIGNATURE_DELETE_TITLE: "Deleting email signature",
  EMAIL_SIGNATURES: "Email signatures",
  EMAIL_SIGNATURES_DELETE_DELAY_DESCRIPTION:
    "Preparing to delete email signatures",
  EMAIL_SIGNATURES_DELETE_DELAY_TITLE: "Deleting email signature",
  EMAIL_SIGNATURES_DELETE_DESCRIPTION: "Deleting email signature",
  EMAIL_SIGNATURES_DELETE_TITLE: "Email signature elimination",
  EMAIL_TO: "To",
  EMAILS: "Emails",
  EMPLOYE_NO_FILTER:
    "It is not possible to use this module with the selected filter",
  EMPLOYEE: "Employee is not responsible for a department",
  EMPLOYEE_NO_DEPARTMNET: "Collaborator is not responsible for a department",
  EMPLOYEES: "Employees",
  EMPTY_ACCOUNTS: "There are no records of active accounts.",
  EMPTY_ACTIVITY_LIST: "There are no associated activity records.",
  EMPTY_API_KEYS: "Empty API key list",
  EMPTY_AUTOMATION_LIST: "There are no associated automations.",
  EMPTY_BILLING_LIST: "Empty billing listing.",
  EMPTY_CALL_LIST: "There are no associated call records.",
  EMPTY_CALLS: "No calls",
  EMPTY_CASE: "Empty case list.",
  EMPTY_CASE_TECORDS: "There are no associated case records.",
  EMPTY_CASES: "No cases",
  EMPTY_COMPANY: "Empty company list.",
  EMPTY_CONTACTS: "There are no associated contact records.",
  EMPTY_CONTRACTS: "No contracts",
  EMPTY_CONTRACTS_LIST: "Contract listing empty.",
  EMPTY_DEALS: "There are no associated deal records.",
  EMPTY_DEALS_LIST: "Empty deals list",
  EMPTY_DEPARTMENT: "Empty department list.",
  EMPTY_DOMAINS: "Email domains list empty.",
  EMPTY_EMAIL_RECEPTION: "Email reception list empty.",
  EMPTY_EMAIL_SIGNATURES: "Email subscription list empty",
  EMPTY_ENTRY_RECEPTION: "Select an item to read",
  EMPTY_ENTRY_RECEPTION_SUBTEXT: "Nothing is selected",
  EMPTY_EXPORT: "Export list is empty.",
  EMPTY_FAVORITE_LIST: "Favorites list is currently empty",
  EMPTY_FILTERS: "No filters were found.",
  EMPTY_HISTORY: "There are no records.",
  EMPTY_IMPORTS: "Empty imports listing.",
  EMPTY_INBOX_RECEPTION: "There are no conversations.",
  EMPTY_INBOX_RECEPTION_QUERY: "We didn't find any results.",
  EMPTY_INBOX_RECEPTION_QUERY_SUBTEXT: "Try using a different keyword.",
  EMPTY_INEFICIENT_TYPES:
    "There are no records of types of non-attributable time.",
  EMPTY_INTERVENTIONS_LIST: "Time list empty.",
  EMPTY_LOGS: "Empty log list",
  EMPTY_LOST_DEALS: "No lost deals",
  EMPTY_LOST_REASON_LIST: "List of reasons for loss empty.",
  EMPTY_NOTIFICATIONS: "You have no notifications",
  EMPTY_PIPELINES_LIST: "Pipelines listing empty.",
  EMPTY_PROJECT_LIST: "Project list empty.",
  EMPTY_PROJECT_TYPES: "List of project types empty.",
  EMPTY_PROJECTS: "There are no projects",
  EMPTY_RECORDS: "There are no history records.",
  EMPTY_SALES: "No sales",
  EMPTY_SOCIAL_ACCOUNT: "List of social media accounts empty.",
  EMPTY_SOCIAL_POST_LIST: "Publication list empty.",
  EMPTY_SUBSCRIPTIONS: "No associated subscriptions.",
  EMPTY_SUBSCRIPTIONS_RECORDS: "There are no associated subscription records.",
  EMPTY_SUBSCRIPTIONS_TYPE_RECORDS:
    "There are no associated subscription type records.",
  EMPTY_TASK_LIST: "Task list empty.",
  EMPTY_TASKS: "There are no associated tasks records.",
  EMPTY_TEAMS: "Teams list empty",
  EMPTY_TICKETS_LIST: "Case listing empty.",
  EMPTY_TIME: "You have no time records",
  EMPTY_TIMER: "You don't have any timers",
  EMPTY_USERS: "Users list empty",
  EMPTY_WIDGETS: "Widget listing empty.",
  ENABLE: "Enable",
  ENABLE_CUSTOM_NODE_COLOR: "Enable custom color",
  ENABLE_MULTIPLE: "Enable multiple selection",
  ENABLE_RAW_BODY: "Enable raw body",
  ENABLE_RAW_HEADERS: "Enable raw headers",
  ENABLE_RAW_QUERY_STRINGS: "Enable raw query strings",
  ENABLE_SCHEDULE: "Enable schedule",
  END: "End",
  END_ALL_SESSIONS: "End all sessions",
  END_DATE: "End date",
  END_SESSION: "End session",
  ENDS_WITH: "Ends with",
  ENROLL_CONFIRMATION: "Are you sure you want the make this action?",
  ENROLL_DESCRIPTION_CALL:
    "A total of {count} calls will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_CLIENT:
    "A total of {count} clients will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_CONTACT:
    "A total of {count} contacts will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_CONTRACT:
    "A total of {count} contracts will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_DEAL:
    "A total of {count} deals will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_PROJECT:
    "A total of {count} projects will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_SUBSCRIPTION:
    "A total of {count} subscriptions will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_TASK:
    "A total of {count} tasks will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_TICKET:
    "A total of {count} tickets will be enrolled in this workflow.",
  ENROLL_DESCRIPTION_TIME:
    "A total of {count} times will be enrolled in this workflow.",
  ENTER: "Enter",
  ENTER_DEPARTMENT_EMAIL_PLACEHOLDER: "Enter the department email (optional)",
  ENTER_VALID_DISCOUNT_CODE: "Enter a valid discount code",
  ENTERTAINMENT_OR_MOVIE_PRODUCTION: "Entertainment/Movie production",
  ENTITY_ICONS: "Icons",
  ENTRY: "Entry",
  ENVIRONMENTAL_SERVICES: "Environmental services",
  EQUAL: "Equal",
  EQUALS: "Equals",
  ERROR: "Error",
  ERROR_FIELDS: "Error - there are mandatory fields to fill in",
  ERROR_HAPPEND: "An error occurred",
  ERROR_LOADING_EMAIL_PREVIEW:
    "It is not possible to preview attachments in this email. Please download the email to view the attachments",
  ERROR_OCCURRED: "An error occurred",
  ERROR_OCCURRED_CONTACT_ADMIN:
    "If the error persists, contact the administration.",
  ERROR_OCCURRED_SORRY:
    "We are sorry, but an error has occurred. Please try again later.",
  ERROR_OCCURRED_TRY_ADVANCED: "Also check advanced filters.",
  ERROR_OCURRED: "An error has occurred",
  ERRORS_FOUND: "Erros found",
  ESTIMATED: "Estimated",
  ESTIMATED_HOUR: "Estimated time",
  ESTIMATED_HOURS: "Estimated time",
  ESTIMATED_HOURS_ABRV: "Estimated time",
  ESTIMATED_HOURS_TASKS: "Estimated tasks time",
  ESTIMATED_TIME: "Estimated time",
  EVALUATION: "Evaluation",
  EVENTS_SERVICES: "Events services",
  EVERY_DAY: "Every day",
  EVERY_HOUR: "Every hour",
  EVERY_MINUTE: "Every minute",
  EVERY_MONTH: "Every month",
  EVERY_WEEK: "Every week",
  EVERY_X: "Every x",
  EVIDENCE: "Evidence",
  EVOLUTION_ACCOUNTS: "Evolution of cases",
  EVOLUTION_AVERAGE: "Evolution of the close time average of cases",
  EVOLUTION_DEALS: "Evolution of deals",
  EVOLUTION_DEALS_GLOBAL: "Global evolution of deals",
  EXAMPLES: "Examples",
  EXCEEDED_HOURS: "Exceeded time",
  EXCLUDED_USER: "Exclude user(s)",
  EXCLUSIVITY: "Exclusivity",
  EXECUTION_CONTRACT: "Execution of a contract",
  EXECUTIVE_OFFICE: "Executive office",
  EXISTING_EMAIL_CONVERSATIONS:
    "There are open email conversations associated with this item.{br} Click <a>here</a> to view",
  EXIT: "Exit",
  EXIT_ALL_SESSIONS_DESC: "Where are you signed in.",
  EXIT_ALL_SESSIONS_TITLE: "Login",
  EXPAND_ALL: "Expand all",
  EXPIRATION_DATE: "Expiration date",
  EXPIRE_DATE: "Expiration",
  EXPIRED: "Expired",
  EXPIRED_CONTRACTS: "Expired contracts",
  EXPORT: "Export",
  EXPORT_DATE: "Export date",
  EXPORT_EXCEL: "Export all to excel",
  EXPORT_FAILLED: "Failed",
  EXPORT_NOT_CLASSIFIED: "Not classified",
  EXPORT_PAGE: "Export page to excel",
  EXPORT_PDF: "Export PDF",
  EXPORT_QUANTITY_TYPE: "Export",
  EXPORT_QUANTITY_TYPE_COMPLETE: "Complete",
  EXPORT_QUANTITY_TYPE_PARCIAL: "Partial",
  EXPORT_SUCCESS: "Success",
  EXPORT_TO_EXCEL: "Export to excel",
  EXPORT_TYPE: "Export type",
  EXPORT_WORKFLOW: "Export workflow",
  EXPORTATIONS: "Exports",
  EXPORTS: "Exports",
  EXPORTS_EXCEL: "Export excel",
  EXTENSION: "Phone extension",
  EXTRA_GIGA_COST: "(Price per extra GB (s) € {amount}/month/GB)",
  EXTRA_USER_COST: "(Extra user € {amount}/month)",
  FACEBOOK_URL: "Facebook url",
  FACILITIES_SERVICES: "Facilities services",
  FARMING: "Farming",
  FAVORITES: "Favorites",
  FEBRUARY: "February",
  FEEDBACK: "Feedback",
  FEEDBACK_ALREDY_SUBMITED: "This feedback has already been submitted.",
  FEEDBACK_FORM: "Feedback form",
  FEEDBACK_SUBMITED_SUCCESS: "Feedback submitted successfully",
  FEEDBACK_TITLE: "Feedback title",
  FEMALE: "Female",
  FIELD: "Field",
  FIELD_2: "Field",
  FIELD_IS_REQUIRED: "This field is required",
  FIELD_IS_REQUIRED_DESCRIPTION: "Please fill in the details",
  FILE: "File",
  FILE_UPLOAD: "File upload",
  FILES: "Files",
  FILES_ONLY_TYPE: "It is only possible to send files of type",
  FILES_TOO_BIG:
    "It is only possible to send files with a maximum size of 40Mb!",
  FILTER: "Filter",
  FILTER_ADDFILTER: "Add filter",
  FILTER_AND: "and",
  FILTER_BACK: "Back",
  FILTER_BY: "Filter by:",
  FILTER_CONTAINS: "contains",
  FILTER_DATE_GT: "is after",
  FILTER_DATE_LAST: "last x days",
  FILTER_DATE_LT: "is before",
  FILTER_DATE_NEXT: "next x days",
  FILTER_DOESNOTCONTAIN: "does not contain",
  FILTER_ENDSWITH: "ends with",
  FILTER_EQ: "is equal to",
  FILTER_ERROR_OCCURRED_SORRY:
    "We are sorry, but there are one or more filters with an invalid format. Please remove the filters and try again.",
  FILTER_FILTER: "Filter",
  FILTER_GROUP: "Group",
  FILTER_GROUPBY: "Group by:",
  FILTER_GT: "is greater than",
  FILTER_GTE: "is greater than or equal to",
  FILTER_ISEMPTY: "is empty",
  FILTER_ISNOEMPTY: "is not empty",
  FILTER_LAST_X_DAYS: "last {days} days",
  FILTER_LAST_X_DAYS_PREVIEW: "Last {days} days of {field}",
  FILTER_LT: "is less than",
  FILTER_LTE: "is less than or equal to",
  FILTER_NAME: "Filter name",
  FILTER_NAME_NOT_VALID: "Name is not valid / already exists",
  FILTER_NEQ: "is not equal to",
  FILTER_NEXT_X_DAYS: "next {days} days",
  FILTER_NEXT_X_DAYS_PREVIEW: "Next {days} days of {field}",
  FILTER_STARTSWITH: "starts with",
  FILTERS: "Filters",
  FINAL_DATA: "Final data",
  FINANCIAL_DEPARTMENT: "Financial",
  FINANCIAL_SERVICES: "Financial services",
  FINE_ART: "Fine art",
  FIRST: "First",
  FISCAL_COUNTRY: "Billing country",
  FISHERY: "Fishery",
  FOLLOW: "Follow",
  FOLLOW_UP: "Follow-up",
  FOLLOWERS: "Followers",
  FONT_FAMILY: "Font family",
  FONT_SIZE: "Font size",
  FOOD_OR_BEVERAGES: "Food/Beverages",
  FOOD_PRODUCTION: "Food production",
  FOOTER: "Footer",
  FOR_AN_EXTRA_USER_PLAN:
    "For a plan with more than 100 users please get in touch",
  FOR_AN_EXTRA_USER_PLAN_DESCRIPTION:
    "Do not hesitate to ask, we are entirely at your disposal",
  FOR_HIMSELF: "to himself",
  FOR_USER: "to the user",
  FOREVER: "No limit",
  FORGET_PASSWORD: "I forgot my password",
  FORM: "Form",
  FORM_END_DATA_BIGGER_THAN_START_DATA:
    "Final date cannot be smaller than start date",
  FORM_OR_BLOCK: "It is necessary to have at least one block or Form",
  FORM_TITLE: "Form title",
  FREE_TRIAL: "Free trial",
  FREE_TRIAL_DAYS_LEFT: "days remaining of the free trial",
  FREE_TRIAL_ENDED: "Your free trial has ended.",
  FREEZED_COLUMNS: "Freezed columns",
  FRIDAY: "Friday",
  FROM: "From",
  FROM_PLAN: "From plan",
  FULL_ACCESS: "Full access",
  FULL_NAME: "Full name",
  FULLSCREEN: "Fullscreen",
  FUNCTIONALITIES: "Functionalities",
  FUNDRAISING: "Fundraising",
  FURNITURE: "Furniture",
  GAMBLING_OR_CASINOS: "Gambling/Casinos",
  GDPR: "Change GDPR",
  GDPR_LEGALBASIS: "Legal basis for processing a contact's data",
  GDPR_OPTION1: "Legitimate interest - prospect/lead",
  GDPR_OPTION2: "Legitimate interest - existing customer",
  GDPR_OPTION3: "Legitimate interest - other",
  GDPR_OPTION4: "Performance of a contract",
  GDPR_OPTION5: "Freely given conset from contact",
  GDPR_OPTION6: "Not applicable",
  GENDER: "Gender",
  GENERAL_INFORMATION: "General information",
  GENERATE_CODE: "Generate snippet",
  GENERATED_API_KEY: "Generated API key",
  GEOLOCATION_BROWSER_ERROR: "Geolocation is not available in this browser",
  GEOLOCATION_REFUSED: "User declined request for geolocation",
  GEOLOCATION_SUCCESS: "Geolocation request made successfully",
  GEOLOCATION_TIMEOUT:
    "The request to obtain the user's location has timed out",
  GEOLOCATION_UNAVAILABLE: "Location Service is unavailable",
  GERAL: "General",
  GET_CALL: "Get call",
  GET_CLIENT: "Get account",
  GET_CONTACT: "Get contact",
  GET_CONTRACT: "Get contract",
  GET_DEAL: "Get deal",
  GET_ITEMS: "Get items",
  GET_PROJECT: "Get project",
  GET_SUBSCRIPTION: "Get subscription",
  GET_TASK: "Get task",
  GET_TICKET: "Get case",
  GLASS_OR_CERAMICS_OR_CONCRETE: "Glass/Ceramics/Concrete",
  GLOBAL_SEARCH_DEF_TO_CLOSE: "to close",
  GLOBAL_SEARCH_DEF_TO_NAVIGATE: "to browse",
  GLOBAL_SEARCH_DEF_TO_SEARCH: "to select",
  GLOBE_PICTURE_TOOLTIP: "Website",
  GOOD: "Good",
  GOVERNMENT_ADMINISTRATION: "Government administration",
  GOVERNMENT_RELATIONS: "Government relations",
  GRAPHIC_DESIGN_OR_WEB_DESIGN: "Graphic design/Web design",
  GREATER: "Greater",
  GREATER_EQUAL: "Greater or equal",
  GROUP: "Group",
  GROUP_BY: "Group by",
  HEADER: "Header",
  HEADER_WELCOME_BAR_IGNORE: "Ignore forever",
  HEADER_WELCOME_BAR_TEXT: "Learn more about getting started with",
  HEADERS: "Headers",
  HEADING_1: "H1",
  HEADING_2: "H2",
  HEALTH_OR_FITNESS: "Health/Fitness",
  HELLO: "Hello",
  HELP: "Help",
  HELP_MESSAGES: "Help tooltips",
  HIDE_NOTES: "Hide notes",
  HIGHER_EDUCATION_OR_ACADAMIA: "Higher education/Acadamia",
  HIGHLIGHT: "Highlight",
  HISTORY: "Activity",
  HISTORY_DEALS: "History of deals",
  HISTORY_ERROR: "There was an error loading history",
  HISTORY_PIPELINE_STAGES: "Pipeline stages history",
  HISTORY_STARTS: "Your history starts here",
  HOLIDAY: "Holiday",
  HORIZONTAL: "Horizontal",
  HOSPITAL_OR_HEALTH_CARE: "Hospital/Health care",
  HOSPITALITY: "Hospitality",
  HOUR: "Hour",
  HOURS_MULTIPLE: "Hour(s)",
  HOVER_ME_TO_SHOW: "Hover here to show ",
  HOW_TO_RECEIVE_NOTIFICATIONS: "How to receive notifications",
  HOW_WAS_THE_EXPERIENCE: "How would you rate your experience?",
  HTTP_REQUEST: "Http request",
  HUMAN_RESOURCES_DEPARTMENT: "Human resources",
  HUMAN_RESOURCES_OR_HR: "Human resources/HR",
  ICON: "Icon",
  ID: "ID",
  IGNORE_DEPARTMENTS_DESC:
    "Skip step (you can later create and add departments)",
  IGNORE_ORGANIZATION_DESC:
    "Skip step (you can create and add companies later)",
  IGNORE_TEAMS_DESC: "Skip step (you can create and add teams later)",
  IMAGE: "Image",
  IMPORT: "Import",
  IMPORT_ASSOCIATION_DESCRIPTION:
    "If you want to associate Contacts with Clients, it is necessary to identify the columns that connect them.",
  IMPORT_ASSOCIATION_TITLE:
    "Association between {importType1} and {importType2}",
  IMPORT_CHECKBOX_PROPERTIES: "Columns without properties will not be imported",
  IMPORT_CHOOSE_IMPORT:
    "To proceed, you need to select what you want to import.",
  IMPORT_DO_NOT_OVERWRITE: "Do not overwrite existing data",
  IMPORT_DUPLICATES_DESCRIPTION:
    "When selected, Airdesk will allow multiple records with the same value to exist, otherwise the line will not be imported. This option will have no effect on data overlap.",
  IMPORT_EXAMPLES_DESCRIPTION: "Examples of detected values ​​from your file.",
  IMPORT_FILE_WARNING:
    "Before uploading the files, check that it is ready to be imported. Records may be replaced or duplicated depending on your choice.",
  IMPORT_FILES: "Import files",
  IMPORT_FILES_PAGE_REQUIRED:
    "To proceed, it is necessary to upload the file and configure the sheets.",
  IMPORT_FILES_REQUIRED: "To proceed, it is necessary to upload the files",
  IMPORT_INCOMPLETE: "Import incomplete",
  IMPORT_MAP_COLUMNS: "Map columns in your file to conform to the properties",
  IMPORT_MATCHES: "Matches",
  IMPORT_MATCHES_DESCRIPTION:
    "Validates that the column is already well mapped. Airdesk will automatically try to map the properties according to the name of the columns in your file.",
  IMPORT_NAME: "Import name",
  IMPORT_NAME_DESCRIPTION:
    "Column name of your file. If your column is not showing, there may be some inconsistency in the file or no value for it is filled.",
  IMPORT_NAME_PLACEHOLDER: "Enter a name for the import",
  IMPORT_NR: "Import Nº",
  IMPORT_OF: "Import of",
  IMPORT_ONE_FILE: "Import from one file",
  IMPORT_OR_EXPORT: "Import/Export",
  IMPORT_OVERWRITE_EMPTY_VALUE: "Overwrite if value is empty",
  IMPORT_OVERWRITE_SELECTED_DATA: "Overwrite existing data",
  IMPORT_PROCESS_MESSAGE:
    "The import is being processed, you will be notified when it is finished.",
  IMPORT_PROPERTY_DESCRIPTION:
    "These are the properties of Airdesk, you should map your column in the file with the fields you want to save the data. Some fields are required to import new records.",
  IMPORT_REFERENCE_DESCRIPTION:
    "The reference is necessary if you want to update data from existing records. When selected, Airdesk will use this field as a comparison value to overlap the rest of the mapped data (depending on the overlapping rules). If no record is found with the value, a new record will be created.",
  IMPORT_TWO_FILES: "Import from two files",
  IMPORT_TYPE: "Import type",
  IMPORT_WARNING_ALL_PROPERTIES:
    "To proceed, it is necessary that all properties are configured.",
  IMPORT_WARNING_CLIENTS_CONTACTS:
    "To proceed, it is necessary to complete or clear the relationship between customers and contacts.",
  IMPORT_WARNING_REQUIRED_PROPERTIES:
    "To proceed, it is necessary to map the properties that are mandatory.",
  IMPORT_WORKFLOW: "Import workflow",
  IMPORT_WORKFLOW_DESCRIPTION:
    "Please paste the export text into the text area. If the import text is in an invalid format, it might break the workflow board.",
  IMPORTED_RECORDS: "Imported records",
  IMPORTS: "Imports",
  IMPUTED_HOURS: "Imputed time",
  IN: "in",
  IN_APPROVAL: "In approval",
  INACTIVE: "Inactive",
  INACTIVE_CONTRACTS: "Inactive contracts",
  INACTIVE_NOTIFICATIONS: "Inactive notifications",
  INACTIVE_PROJECTS: "Inactive projects",
  INBOX: "Inbox",
  INBOX_EMAIL: "Inbox email",
  INDIVIDUAL_OR_FAMILY_SERVICES: "Individual/Family services",
  INDUSTRIAL_AUTOMATION: "Industrial automation",
  INDUSTRY: "Industry",
  INEFFICIENT: "Non-attributable",
  INEFFICIENT_HOURS: "Non-attributable time",
  INEFFICIENT_HOURS_ABV: "Non-attributable time",
  INEFFICIENT_TIME: "Non-attributable time (minutes)",
  INEFFICIENT_TIME_REASONS: "Non-attributable time reasons",
  INEFFICIENT_TIME_TYPE: "Types of non-attributable time",
  INFORMATION_SERVICES: "Information services",
  INFORMATION_TECHNOLOGY_OR_IT: "Information technology/IT",
  INPUTED_HOURS: "Imputed time",
  INPUTS: "No. of inputs",
  INSERT_COLUMN_AFTER: "Insert column after",
  INSERT_COLUMN_BEFORE: "Insert column before",
  INSERT_ONLY_VALID_SOCIAL_MEDIA_LINK: "Insert a valid social media link",
  INSERT_ROW_AFTER: "Insert row after",
  INSERT_ROW_BEFORE: "Insert row before",
  INSERT_TABLE: "Insert table",
  INSTAGRAM_URL: "Instagram url",
  INSURANCE: "Insurance",
  INTEGRATIONS: "Integrations",
  INTERNAL_NOTE: "Internal note",
  INTERNATIONAL_AFFAIRS: "International affairs",
  INTERNATIONAL_TRADE_OR_DEVELOPMENT: "International trade/development",
  INTERNET: "Internet",
  INTERNET_FAIL_MESSAGE:
    "Trying to re-establish the connection. Please check the internet connection.",
  INTERVAL: "Date range",
  INTERVAL_BEGIN_DATE: "Inverval begin date",
  INTERVAL_CUSTOM: "Custom range",
  INTERVAL_EVEN_OLDER: "Even older",
  INTERVAL_LAST_FIFTEEN_DAYS: "Last 15 days",
  INTERVAL_LAST_MONTH: "Last month",
  INTERVAL_LAST_SEMESTER: "Last semester",
  INTERVAL_LAST_TRIMESTER: "Last quarter",
  INTERVAL_LAST_WEEK: "Last week",
  INTERVAL_THIS_MONTH: "This month",
  INTERVAL_THIS_SEMESTER: "This semester",
  INTERVAL_THIS_TRIMESTER: "This quarter",
  INTERVAL_THIS_WEEK: "This week",
  INTERVAL_TODAY: "Today",
  INTERVAL_YESTERDAY: "Yesterday",
  INTERVENTION_TYPE: "Type of intervention",
  INTERVENTION_TYPES: "Types of intervention",
  INTERVENTIONS: "Times",
  INTERVENTIONS_DELETE_DELAY_DESCRIPTION: "Preparing to delete times",
  INTERVENTIONS_DELETE_DELAY_TITLE: "Elimination of times",
  INTERVENTIONS_DELETE_DESCRIPTION: "Deleting times",
  INTERVENTIONS_DELETE_TITLE: "Elimination of times",
  INTERVENTIONS_TYPES_LIST_EMPTY: "List of time types empty.",
  INTERVENTIONS_TYPES_LIST_EMPTY_DESCRIPTION:
    "There are no associated time records.",
  INVALID_COMPANY_WARNING: "At least one company is required.",
  INVALID_EMAIL: "Please insert a valid email",
  INVALID_FIELD: "Invalid field",
  INVALID_FIELD_DESCRIPTION: "Please enter the correct data",
  INVALID_INVITATION: "Invalid invitation",
  INVALID_NIF: "This fiscal number is not valid.",
  INVALID_NUMBER_WARNING: "Please enter a valid number",
  INVALID_PASSWORD: "Please insert a valid password",
  INVALID_SCHEDULE_DAYS: "It is necessary that all times have the days set.",
  INVESTMENT_BANKING_OR_VENTURE: "Investment banking/Venture",
  INVESTMENT_MANAGEMENT_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Investment management/Hedge fund/Private equity",
  INVITE: "Invite",
  INVITE_ALREADY_ACCEPTED: "This invite has already been accepted.",
  INVITE_EXPIRED: "Invite expired.",
  INVITE_EXPIRED_DESCRIPTION:
    "The link to this invite is no longer available. Contact the person who invited you to request a new one.",
  INVITE_INVALID: "Invalid invite.",
  INVITE_INVALID_ACCOUNT:
    "This invite does not belong to the account that is logged in. Please login with your account.",
  INVITE_LOGIN_REQUIRED: "Login required.",
  INVITE_LOGIN_REQUIRED_DESCRIPTION:
    "You must be signed in to accept this invite.",
  INVITE_NOT_FOUND_DESCRIPTION:
    "We apologize. This invite was not found. Make sure that the invite link you received is correct.",
  INVITE_UNKNOWN_DESCRIPTION:
    "There was an error loading your invite. We apologize for the inconvenience.",
  INVITES: "Invites",
  INVOICE: "Invoice",
  INVOICES: "Invoices",
  IS_EMPTY: "Is empty",
  IS_SUSPENDED: "is canceled.",
  ISNT_EMPTY: "Is not empty",
  IT_DEPARTMENT: "IT",
  ITALIC: "Italic",
  JANUARY: "January",
  JUDICIARY: "Judiciary",
  JULY: "July",
  JUNE: "June",
  KEYBOARD_TO_NAVIGATE: "Use your keyboard to navigate",
  LANGUAGES: "Languages",
  LAST: "Last",
  LAST_ACTIVITY: "Last activity",
  LAST_CASES: "Last cases",
  LAST_CHANGE: "Last change",
  LAST_MONTH: "Last month",
  LAST_PASSWORD_CHANGE: "Last password change was in",
  LAST_RECORDS: "Latest records",
  LAST_TASKS: "Last tasks",
  LAST_THREE_NUMBERS_OF_CARD: "Last three numbers on the back of the card",
  LAST_TIME_REGISTRATION: "Last time registrations",
  LAST_UPDATE: "Last update",
  LATEST_REGISTRATIONS: "Latest registrations",
  LATITUDE: "Latitude",
  LAW_ENFORCEMENT: "Law enforcement",
  LAW_PRACTICE_OR_LAW_FIRMS: "Law practice/Law firms",
  LEGA_BASIS: "Legal basis for processing a contact's data",
  LEGAL: "Legal",
  LEGAL_SERVICES: "Legal services",
  LEGISLATIVE_OFFICE: "Legislative office",
  LEISURE_OR_TRAVEL: "Leisure/Travel",
  LESSER: "Lesser",
  LESSER_EQUAL: "Lesser or equal",
  LIBRARY: "Library",
  LINE_BREAK: "Line break",
  LINK: "Link",
  LINKEDIN_URL: "Linkedin url",
  LOAD_MORE: "Load more",
  LOCAL: "Location",
  LOCATION: "Location",
  LOCATION_ADDRESS: "Location and address",
  LOG: "Log",
  LOG_OUT: "Log out",
  LOGIN_BACK: "Back to login",
  LOGIN_INVALID_CREDENTIALS: "Either your email or password are wrong",
  LOGIN_NO_ACCOUNT: "Don't have an account?",
  LOGIN_REGISTER: "Sign up",
  LOGIN_SET_VALID_EMAIL: "Please set a valid email",
  LOGIN_SET_VALID_PASSWORD: "Please set a valid password",
  LOGIN_UNKNOWN_ERROR:
    "We had an error logging you in. Please try again in some time.",
  LOGISTICS: "Logistics",
  LOGISTICS_DEPARTMENT: "Logistics",
  LOGISTICS_OR_PROCUREMENT: "Logistics/Procurement",
  LOGO: "Logo",
  LOGO_FOR_REPORTS: "Logo for reports",
  LOGO_LINK: "Logo link",
  LOGO_UPLOAD: "Upload logo",
  LOGOTYPE_DESCRIPTION_INPUT: "Drag the file or click to browse",
  LOGS: "Logs",
  LONGITUDE: "Longitude",
  LOSES: "Lost",
  LOSS_DESCRIPTION: "Loss description",
  LOST: "Lost",
  LOST_DEALS: "Lost deals",
  LOST_DEALS_AMOUNTS: "Lost deals amounts",
  LOST_REASON: "Lost reason",
  LOST_REASONS: "Lost reasons",
  LOST_REASONS_SUCCESS: "Loss reason saved successfully",
  LUXURY_GOODS_OR_JEWELRY: "Luxury goods/Jewelry",
  MACHINERY: "Machinery",
  MAIN_CONTACTS: "Main contacts",
  MAINTENANCE: "Maintenance",
  MAINTENANCE_ONGOING_DESC:
    "The system is currently undergoing maintenance, so errors may occur during the process.",
  MAINTENANCE_WARNING_DESC:
    "A system maintenance is planned in {fromNow} ({date}), lasting {minutes} minutes. During the update, the system will be unavailable.",
  MALE: "Male",
  MANAGEMENT_CONSULTING: "Management consulting",
  MANAGER: "Manager",
  MANAGER_IS: "Manager is",
  MANAGERS: "Managers",
  MANUAL: "Manual",
  MAPPING: "Mapping",
  MARCH: "March",
  MARITIME: "Maritime",
  MARK_ALL_AS_READ: "Mark everything as read",
  MARK_AS_READ: "Mark selected as read",
  MARK_AS_UNREAD: "Mark selected as unread",
  MARK_CONVERSARION_AS_CLOSED: "Close conversations",
  MARK_CONVERSARION_AS_OPEN: "Open conversations",
  MARK_SINGLE_AS_READ: "Mark as read",
  MARK_SINGLE_AS_UNREAD: "Mark as unread",
  MARKET_RESEARCH: "Market research",
  MARKETING: "Marketing",
  MARKETING_OR_ADVERTISING_OR_SALES: "Marketing/Advertising/Sales",
  MAX_BODY_LENGTH: "Max body length",
  MAX_CONTENT_LENGTH: "Max content length",
  MAX_NUMBER_OF_REDEEMS: "Number of times the coupon can be redeemed",
  MAX_REDIRECTS: "Max redirects",
  MAXIMIZE: "Maximize",
  MAXIMIZE_DESCRIPTION: "Maximize description",
  MAXIMUM_PERIOD_HOURS: "Maximum hours per period",
  MAXIMUM_PERIOD_HOURS_ABRV: "Max. h/period",
  MAY: "May",
  ME: "Me",
  MECHANICAL_OR_INDUSTRIAL_ENGINEERING: "Mechanical or industrial engineering",
  MEDIA_PRODUCTION: "Media production",
  MEDICAL_EQUIPMENT: "Medical equipment",
  MEDICAL_PRACTICE: "Medical practice",
  MENTAL_HEALTH_CARE: "Mental health care",
  MENU: "Menu",
  MENU_LAYOUT: "Menu layout",
  MERGE_CELLS: "Merge cells",
  MERGE_CONTACTS: "Merge contacts",
  MERGE_CONTACTS_ALTERED_CALLS_DESCRIPTION:
    "calls associated and moved to contact.",
  MERGE_CONTACTS_ALTERED_CASES_DESCRIPTION:
    "tickets associated and moved to contact.",
  MERGE_CONTACTS_ALTERED_CONTRACTS_DESCRIPTION:
    "contracts associated and moved to contact.",
  MERGE_CONTACTS_ALTERED_DEALS_DESCRIPTION:
    "business associated and moved to contact.",
  MERGE_CONTACTS_ALTERED_PROJECTS_DESCRIPTION:
    "projects linked and moved to contact.",
  MERGE_CONTACTS_ALTERED_SUBSCRIPTIONS_DESCRIPTION:
    "subscriptions associated and moved to contact.",
  MERGE_CONTACTS_ALTERED_TIMES_DESCRIPTION:
    "associated and moved times for contact.",
  MESSAGE: "Message",
  MESSAGE_HELP_MESSAGE: "Message tooltip",
  MESSAGES: "Messages",
  METHOD: "Method",
  MILITARY_INDUSTRY: "Military industry",
  MIN_AGO: "min's ago",
  MINIATURES: "Miniatures",
  MINIMIZE: "Minimize",
  MINIMIZE_DESCRIPTION: "Minimize description",
  MINIMUM_BILLING_INTERVAL: "Minimum billing interval (min)",
  MINIMUM_BILLING_INTERVAL_ABV: "Min bil. interval (min)",
  MINIMUM_INTERVAL: "Minimum invoicing interval (min)",
  MINING_OR_METALS: "Mining/Metals",
  MINUTE: "Minute",
  MOBILE_NUMBER: "Mobile number",
  MOBILE_NUMBER_PREFIX: "Cellphone extension",
  MODAL: "Modal",
  MODE: "Mode",
  MODEL: "Model",
  MODEL_BLANK: "Blank model",
  MODIFICATION: "Modification",
  MODULE_ERROR: "There was an error loading the module",
  MODULES: "Modules",
  MODULES_ACCESS: "Modules access",
  MODULES_NAME: "Modules name",
  MONDAY: "Monday",
  MONTH: "Month",
  MONTH_AGO: "A month ago",
  MONTHLY: "Monthly",
  MONTHLY_PAYMENT: "Monthly payment",
  MONTHS: "Months",
  MONTHS_AGO: "Months ago",
  MORE: "More",
  MORE_OPTIONS: "More options",
  MOTION_PICTURES_OR_FILM: "Motion pictures/film",
  MOVE: "Move",
  MOVE_CLASSIFICATION: "Move classification",
  MULTIPLE_TIMERS: "Multiple timers",
  MUSEUMS_OR_INSTITUTIONS: "Museums/Institutions",
  MUSIC: "Music",
  MX_RECORD: "MX record",
  MY_DEPARTMENTS: "My departments",
  N_INPUTS: "Inputs Nº",
  NAME: "Name",
  NAME_FILTER: "Name of filter",
  NAME_MODULES: "Name of the modules",
  NAME_OF_PIPELINE_STATUS: "Pipeline states",
  NANOTECHNOLOGY: "Nanotechnology",
  NEW_CONVERSATION: "New conversation",
  NEW_EMAIL_CONVERSATION: "New email conversation",
  NEW_FOLDER: "New folder",
  NEW_FOLDER_TEXT: "Determine the color, icon and name of the new folder",
  NEW_PASSWORD: "New password",
  NEWSPAPERS_OR_JOURNALISM: "Newspapers/Journalism",
  NEXT: "Next",
  NEXT_PERIOD: "Next period",
  NEXT_RENEWAL: "Next renewal",
  NIF: "VAT",
  NIF_VALIDATION_ERROR: "Failed to find data for this fiscal number",
  NIF_VALIDATION_NOT_FOUND: "Couldn't find data for this fiscal number",
  NIF_VALIDATION_NOT_VALID: "The data for this fiscal number is not valid",
  NO: "No",
  NO_ACCOUNT: "There are no account records",
  NO_ACCOUNTS: "There are no associated accounts",
  NO_ACTIVITY_LIST: "Empty activity listing.",
  NO_API_KEYS: "There are no associated personal API records",
  NO_ATTACHMENTS: "There are no attachments",
  NO_AUTOMATION_LIST: "Automation list empty.",
  NO_BIILING_LIST: "There are no associated billing records.",
  NO_BIRTHDAY_TODAY: "Nobody celebrates their birthday this week.",
  NO_CALLS_LIST: "Call list empty.",
  NO_CLASSIFICATION_LIST: "There are no records of associated classifications.",
  NO_COMPANY: "There are no records of associated companies.",
  NO_CONTACTS: "You have no associated contacts.",
  NO_CONTRACTS: "There are no records of associated contracts.",
  NO_CONTRACTS_LIST: "There are no associated contracts records.",
  NO_DEPARTMENT: "There are no records of associated departments.",
  NO_DOMAINS: "There are no associated email domain records.",
  NO_EMAIL_RECEPTION: "There are no associated email reception.",
  NO_EMAIL_SIGNATURE: "There are no associated email signature records",
  NO_FILES: "There are no files",
  NO_FILTERED_FILTERS: "Your search returned no filters",
  NO_IMPORTS: "There are no associated import records.",
  NO_INTERVENTION_LIST: "There are no associated time records.",
  NO_LOGS: "There are no associated log records",
  NO_LOST_REASON_LIST: "There are no records of associated loss reasons.",
  NO_MATCHES_FOUND: "No matches found.",
  NO_MODULES:
    "It looks like you don't have any modules configured at the moment.",
  NO_MODULES_EXPANDED: "Please contact the administration",
  NO_PERMISSION: "You currently do not have permissions to access this view.",
  NO_PERMISSION_DISCLAIMER_DESCRIPTION:
    "Please contact an administrator for access",
  NO_PERMISSION_DISCLAIMER_TITLE:
    "You don't have permission to access this content",
  NO_PERMISSION_EXPANDED: "If this error persists, contact the administration.",
  NO_PIPELINES_LIST: "There are no records of associated pipelines.",
  NO_PROJECT_LIST: "There are no records of associated projects.",
  NO_PROJECT_TYPES: "There are no records of associated project types.",
  NO_QUEUE_LIST: "No queue records found",
  NO_RECORDS: "No records",
  NO_SEARCH_RESULTS: "There is no result",
  NO_SOCIAL_ACCOUNT:
    "There are no records of associated social media accounts.",
  NO_SOCIAL_POST_LIST: "There are no records of associated publications.",
  NO_SUBSCRIPTIONS: "You have no associated subscriptions.",
  NO_SUBSCRIPTIONS_TYPE_RECORDS: "Empty subscription type listing.",
  NO_TASK_LIST: "There are no associated task records.",
  NO_TASKS: "No tasks",
  NO_TEAMS: "There are no teams",
  NO_TICKETS_LIST: "There are no associated case records.",
  NO_USERS: "There are no users",
  NO_WIDGETS: "There are no records of associated widgets.",
  NON_PROFIT_OR_VOLUNTEERING: "Non-Profit/Volunteering",
  NONE: "None",
  NOT_APPLICABLE: "Not applicable",
  NOT_CLASSIFIED: "not classified",
  NOT_CONTAINS: "Does not contain",
  NOT_EQUAL: "Not equal",
  NOT_READ: "Unread",
  NOT_VALIDATED: "Not validated",
  NOTE: "Note",
  NOTE_ATTACHMENTS: "Note attachments",
  NOTES: "Notes",
  NOTIFICATION_APEARANCE_DESCRIPTON:
    "Show count and the orange circle when you have unread notifications in Airdesk bell.",
  NOTIFICATION_APEARANCE_TITLE: "Appearance",
  NOTIFICATION_BELL_DESCRIPTON:
    "Notifications appear in a orange circle on the bell icon in the navigation bar. You can click on the bell at any time to see your most recent notifications.",
  NOTIFICATION_BELL_TITLE: "Bell",
  NOTIFICATION_BROWSER_DESCRIPTON:
    "Browser notifications appear on your monitor when you are not active in Airdesk, but the site is open in your browser tab or window.",
  NOTIFICATION_BROWSER_TITLE: "Browser",
  NOTIFICATION_EMAIL_DESCRIPTON:
    "Email notifications will be sent to your inbox.",
  NOTIFICATION_EMAIL_TITLE: "Email",
  NOTIFICATION_MOBILE_DESCRIPTON:
    "Push and in-app notifications will be sent to your mobile device (iOS or Android).",
  NOTIFICATION_MOBILE_TITLE: "Mobile",
  NOTIFICATION_POPUP_DESCRIPTON:
    "Pop-up notifications appear on your screen when you are active on Airdesk.",
  NOTIFICATION_POPUP_TITLE: "Pop-up",
  NOTIFICATION_SOUND_DESCRIPTON:
    "It plays sound when you receive new pop-pup notifications.",
  NOTIFICATION_SOUND_TITLE: "Sound",
  NOTIFICATION_TOPIC: "Notifications topic",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_DESCRIPTION:
    "Receive a notification each time an account is deleted",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_TITLE: "When an account is deleted",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_DESCRIPTION:
    "Receive a notification each time the account manager changes",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_TITLE:
    "When the account manager changes",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_DESCRIPTION:
    "Receive a notification each time an account is modified",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_TITLE: "When an account is modified",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_DESCRIPTION:
    "Receive a notification each time a new contact is created",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_TITLE:
    "When a new contact is created",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_DESCRIPTION:
    "Receive a notification each time a new account is created",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a new follower is added to the account",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_TITLE:
    "When a new follower is added to the account",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification every time there are new messages",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_TITLE: "When there are new messages",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_TITLE: "When a new account is created",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time an account is originated",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_TITLE:
    "Receive a notification each time an account is originated",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_DESCRIPTION:
    "Receive a notification each time a contact is removed",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_TITLE: "When a contact is removed",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed from the account",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_TITLE:
    "Receive a notification each time a follower is removed from the account",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the account status changes",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_TITLE:
    "When the account status is changed",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time the person to whom the call is assigned changes",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_TITLE:
    "When the person being assigned to the call changes",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time a user is assigned a change",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_TITLE:
    "When a change is assigned to a user",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_DESCRIPTION:
    "Receive a notification each time there are only three days left until the end date of the call",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_TITLE:
    "When there are only three days left until the end date of the call",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_DESCRIPTION:
    "Receive a notification each time the contact to whom the call is assigned is changed",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_TITLE:
    "When the contact assigned to the call changes",
  NOTIFICATION_TOPIC_CALLS_DELETED_DESCRIPTION:
    "Receive a notification each time a call is removed",
  NOTIFICATION_TOPIC_CALLS_DELETED_TITLE: "When a call is removed",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a new message is received",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_TITLE: "When a call is modified",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is altered",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_TITLE: "When a department is changed",
  NOTIFICATION_TOPIC_CALLS_NEW_DESCRIPTION:
    "Receive a notification each time a call is created",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification each time you receive a new message",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_TITLE: "When a new message is received",
  NOTIFICATION_TOPIC_CALLS_NEW_TITLE: "When a call is created",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a call is originated",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_TITLE: "When a call is originated",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a team is altered",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_TITLE: "When a team is changed",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_TITLE: "When a follower is removed",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the status of a call is changed",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_TITLE:
    "When the state of a call is changed",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time a user is assigned a change",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_TITLE:
    "When a user is assigned a change",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_DESCRIPTION:
    "Receive a notification each time a change is assigned to a user",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_TITLE:
    "When the change is assigned to a user",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_DESCRIPTION:
    "Receive a notification each time a department/team is changed",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_TITLE:
    "When a department/team is changed",
  NOTIFICATION_TOPIC_CASES_MODIFIED_DESCRIPTION:
    "Receive a notification each time a case is modified",
  NOTIFICATION_TOPIC_CASES_MODIFIED_TITLE: "When a case is changed",
  NOTIFICATION_TOPIC_CASES_NEW_DESCRIPTION:
    "Receive a notification each time a case is created",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification each time you receive a new message",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_TITLE: "When you receive a new message",
  NOTIFICATION_TOPIC_CASES_NEW_TITLE: "When a case is created",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a case is originated",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_TITLE: "When a case is originated",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive notification each time a follower is removed",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_TITLE: "When a follower is removed",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time a case status is changed",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_TITLE:
    "When a state of a case is changed",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_DESCRIPTION:
    "Receive a notification each time a classification is removed",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_TITLE:
    "When a classification is removed",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a classification is modified",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_TITLE:
    "When a classification is changed",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_DESCRIPTION:
    "Receive a notification each time a new classification is created",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_TITLE:
    "When a new classification is created",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification every time the status of a classification is changed",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_TITLE:
    "When the state of a classification is changed",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_DESCRIPTION:
    "Receive a notification each time the contact is added to an account",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_TITLE:
    "When the contact is added to an account",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_DESCRIPTION:
    "Receive a notification each time a contact is deleted",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_TITLE: "When a contact is deleted",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_DESCRIPTION:
    "Receive a notification every time a contact is modified",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_TITLE: "When a contact is changed",
  NOTIFICATION_TOPIC_CONTACTS_NEW_DESCRIPTION:
    "Receive a notification each time a new contact is created",
  NOTIFICATION_TOPIC_CONTACTS_NEW_TITLE: "When a new contact is created",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_DESCRIPTION:
    "Receive a notification each time the contact is removed from an account",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_TITLE:
    "When the contact is removed from an account",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the status of a contact changes",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_TITLE:
    "When the status of a contact changes",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time a change is assigned to a user",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_TITLE:
    "When the change is assigned to a user",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_DESCRIPTION:
    "Receive a notification each time there are only three days left until the end date of a contract",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_TITLE:
    "When only three days are left before the end date of a contract",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_DESCRIPTION:
    "Receive a notification every time the company is altered",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_TITLE:
    "When the company is altered",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_DESCRIPTION:
    "Receive a notification each time a contract is changed",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_TITLE:
    "When the contract is changed",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_DESCRIPTION:
    "Receive a notification each time a contract is deleted",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_TITLE: "When a contract is deleted",
  NOTIFICATION_TOPIC_CONTRACTS_END_DESCRIPTION:
    "Receive a notification each time a contract is terminated",
  NOTIFICATION_TOPIC_CONTRACTS_END_TITLE: "When a contract is terminated",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_DESCRIPTION:
    "Receive a notification each time the contract exceed the time limit",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_TITLE:
    "When a contract's exceed the time limit",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receive a notification each time x amount of time is left until the end of the contract",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_TITLE:
    "When x amount of time is left until the end of the contract",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_DESCRIPTION:
    "Receive a notification each time the responsible person is changed",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_TITLE:
    "When the responsible person is changed",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_DESCRIPTION:
    "Receive a notification each time a contract exceeds the time limit",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_TITLE:
    "When a contract exceeds the time limit",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a contract is modified",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_TITLE: "When a contract is modified",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_TITLE:
    "When a department is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DESCRIPTION:
    "Receive a notification each time a new contract is created",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_DESCRIPTION:
    "Receive a notification each time a new team is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_TITLE: "When a new team is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TITLE: "When a new contract is created",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_DESCRIPTION:
    "Receive a notification each time a new user is added",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_TITLE: "When a new user is added",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a contract is originated",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_TITLE:
    "When a contract is originated",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_TITLE:
    "When a follower is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_DESCRIPTION:
    "Receive a notification each time a new team is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_TITLE: "When a new team is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_DESCRIPTION:
    "Receive a notification each time a new user is removed",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_TITLE: "When a new user is removed",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the status of a contract changes",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_TITLE:
    "When the state of a contract is changed",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_DESCRIPTION:
    "Receive a notification every time you receive a new message",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_TITLE: "When you receive a new message",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_DESCRIPTION:
    "Receive a notification every time a contact is changed",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_TITLE: "When the contact is changed",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_DESCRIPTION:
    "Receive a notification each time the responsible person is changed",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_TITLE:
    "When the responsible person is changed",
  NOTIFICATION_TOPIC_DEALS_DELETED_DESCRIPTION:
    "Receive a notification each time a deal is deleted",
  NOTIFICATION_TOPIC_DEALS_DELETED_TITLE: "When a deal is deleted",
  NOTIFICATION_TOPIC_DEALS_END_DESCRIPTION:
    "Receive a notification each time a deal ends",
  NOTIFICATION_TOPIC_DEALS_END_TITLE: "When a deal ends",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_DESCRIPTION:
    "Receive a notification every time a deal is modified",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_TITLE: "When a business is changed",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_DESCRIPTION:
    "Receive a notification each time a change is assigned to a business",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_TITLE:
    "When a change is assigned to the business",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is added",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_TITLE: "When a department is added",
  NOTIFICATION_TOPIC_DEALS_NEW_DESCRIPTION:
    "Receive a notification each time a new deal is created",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification every time there are new messages",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_TITLE: "When there are new messages",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_DESCRIPTION:
    "Receive a notification each time a team is added",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_TITLE: "When a team is added",
  NOTIFICATION_TOPIC_DEALS_NEW_TITLE: "When a new business is created",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_DESCRIPTION:
    "Receive a notification each time a user is added",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_TITLE: "When a user is added",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a deal is originated",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_TITLE: "When a deal is originated",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_TITLE: "When a follower is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_DESCRIPTION:
    "Receive a notification each time a team is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_TITLE: "When a team is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_DESCRIPTION:
    "Receive a notification each time a user is removed",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_TITLE: "When a user is removed",
  NOTIFICATION_TOPIC_DEALS_START_DESCRIPTION:
    "Receive a notification each time a deal starts",
  NOTIFICATION_TOPIC_DEALS_START_TITLE: "When a business starts",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the state of a deal changes",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_TITLE:
    "When the state of a business is changed",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_DESCRIPTION:
    "Receive a notification each time an email is closed",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_TITLE: "When an email is closed",
  NOTIFICATION_TOPIC_INBOX_DELETED_DESCRIPTION:
    "Receive a notification each time an email is removed",
  NOTIFICATION_TOPIC_INBOX_DELETED_TITLE: "When an email is removed",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_DESCRIPTION:
    "Receive an email notification each time an email is deleted",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_TITLE: "When an email is deleted",
  NOTIFICATION_TOPIC_INBOX_NEW_DESCRIPTION:
    "Receive a notification each time a new email is received",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification each time an email associated with an email conversation is sent.",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_TITLE:
    "When an email is sent from Airdesk associated with an email conversation.",
  NOTIFICATION_TOPIC_INBOX_NEW_TITLE:
    "When a new email conversation is created",
  NOTIFICATION_TOPIC_INBOX_OPEN_DESCRIPTION:
    "Receive an email notification each time an email conversation is created",
  NOTIFICATION_TOPIC_INBOX_OPEN_TITLE: "When an email is opened",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the email conversation status is changed",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_TITLE:
    "When the email conversation state is changed",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time a change is assigned to a user",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_TITLE:
    "When the change is assigned to a user",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_DESCRIPTION:
    "Receive a notification each time a contact is updated",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_TITLE:
    "When a contact is changed",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_DESCRIPTION:
    "Receive a notification when a time is removed",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_TITLE: "When a time is removed",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_DESCRIPTION:
    "Receive a notification when a time is modified",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_TITLE: "When a time is modified",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_DESCRIPTION:
    "Receive a notification when a time is created",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_TITLE:
    "When a follower is added",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification whenever a new message is received",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_TITLE:
    "When a new message is received",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_TITLE: "When a time is created",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive notification each time a follower is removed",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_TITLE:
    "When a follower is removed",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_DESCRIPTION:
    "Receive a notification each time a company is added",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_TITLE: "When a company is added",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is added",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_TITLE: "When a department is added",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_DESCRIPTION:
    "Receive a notification each time a social media account is added",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_TITLE:
    "When a social media account is added",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_DESCRIPTION:
    "Receive a notification each time a company is added",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_TITLE: "When a team is added",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_DESCRIPTION:
    "Receive a notification each time a user cancels a case",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_TITLE: "When a user cancels a case",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_DESCRIPTION:
    "Receive a notification each time a user cancels a contract",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_TITLE:
    "When a user cancels a contract",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_DESCRIPTION:
    "Receive a notification each time a user cancels a deal",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_TITLE: "When a user cancels a deal",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_DESCRIPTION:
    "Receive a notification each time a user cancels a task",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_TITLE: "When a user cancels a task",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_DESCRIPTION:
    "Receive a notification each time there are only three days left for a payment",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_TITLE:
    "When there are only three days left for a payment",
  NOTIFICATION_TOPIC_OWNER_DELETED_DESCRIPTION:
    "Receive a notification each time an account is deleted",
  NOTIFICATION_TOPIC_OWNER_DELETED_TITLE: "When an account is deleted",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_DESCRIPTION:
    "Receive a notification each time an account is deactivated",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_TITLE:
    "When an account is deactivated",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_DESCRIPTION:
    "Receive a notification each time an invitation expires",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_TITLE:
    "When an invitation expires",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_DESCRIPTION:
    "Receive a notification each time an invitation is accepted",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_TITLE:
    "When an invitation is accepted",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_DESCRIPTION:
    "Receive a notification each time an invitation is sent",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_TITLE: "When an invitation is sent",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_DESCRIPTION:
    "Receive a notification each time a user has low business profits",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_TITLE:
    "When a user has low business profits",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_DESCRIPTION:
    "Receive a notification each time an export is made by the user",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_TITLE:
    "When an export is made by the user",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_DESCRIPTION:
    "Receive a notification each time a user is missing 8h a day",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_TITLE:
    "When a user is missing 8h a day",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_DESCRIPTION:
    "Receive a notification each time a user has cases open for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_TITLE:
    "When a user has open cases for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_DESCRIPTION:
    "Receive a notification each time a user has a deal open for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_TITLE:
    "When a user has had a deal open for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_DESCRIPTION:
    "Receive a notification each time a user has a task open for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_TITLE:
    "When a user has an open task for more than 1 month",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_DESCRIPTION:
    "Receive a notification each time a subscription payment is successful",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_TITLE:
    "When a subscription payment is successful",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_DESCRIPTION:
    "Receive a notification each time a plan is changed",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_TITLE: "When a plan is changed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_DESCRIPTION:
    "Receive a notification each time a company is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_TITLE: "When a company is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_DESCRIPTION:
    "Receive a notification each time a social media account is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_TITLE:
    "When a social media account is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_DESCRIPTION:
    "Receive a notification each time a company is removed",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_TITLE: "When a team is removed",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_DESCRIPTION:
    "Receive a notification each time a user loses several trades",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_TITLE:
    "When a user has lost several trades",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_DESCRIPTION:
    "Receive a notification each time responsibility is transferred",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_TITLE: "When responsibility is transferred",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_DESCRIPTION:
    "Receive a notification each time a user is overloaded with tasks and cases",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_TITLE:
    "When a user is overloaded with tasks and cases",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_DESCRIPTION:
    "Receive a notification each time there are only three days left until the project's end date",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_TITLE:
    "When there are only three days left until the project's end date",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_DESCRIPTION:
    "Receive a notification each time a project is removed",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_TITLE: "When a project is removed",
  NOTIFICATION_TOPIC_PROJECTS_END_DESCRIPTION:
    "Receive a notification each time a project ends",
  NOTIFICATION_TOPIC_PROJECTS_END_TITLE: "When a project ends",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_DESCRIPTION:
    "Receive a notification each time a project ends",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_TITLE: "When a project is finished",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_DESCRIPTION:
    "Receive a notification each time the project exceeds the time limit",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_TITLE:
    "When the project exceeds the time limit",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receive a notification each time x amount of time is left until the end of the contract",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_TITLE:
    "When x amount of time is left until the end of the contract",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_DESCRIPTION:
    "Receive a notification each time the responsible person is changed",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_TITLE:
    "When the responsible person is changed",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a project is modified",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_TITLE: "When a project is changed",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_TITLE:
    "When a department is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DESCRIPTION:
    "Receive a notification each time a project is created",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_DESCRIPTION:
    "Get a notification each time a new message is received",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_TITLE:
    "When a new message is received",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_DESCRIPTION:
    "Receive notification each time a team is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_TITLE: "When a team is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TITLE: "When a project is created",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_DESCRIPTION:
    "Receive notification each time a user is added",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_TITLE: "When a user is added",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a project is originated",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_TITLE: "When a project is originated",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_TITLE:
    "When a follower is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_DESCRIPTION:
    "Receive notification each time a team is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_TITLE: "When a team is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_DESCRIPTION:
    "Receive notification each time a user is removed",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_TITLE: "When a user is removed",
  NOTIFICATION_TOPIC_PROJECTS_START_DESCRIPTION:
    "Receive a notification each time a project starts",
  NOTIFICATION_TOPIC_PROJECTS_START_TITLE: "When a project starts",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_DESCRIPTION:
    "Receive a notification each time a project starts",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_TITLE: "When a project starts",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_DESCRIPTION:
    "When a project's status is changed",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_TITLE:
    "When the state of a project is changed",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_DESCRIPTION:
    "Receive a notification each time a publication is removed",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_TITLE: "When a publication is removed",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_DESCRIPTION:
    "Receive a notification each time a publication is modified",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_TITLE:
    "When a publication is modified",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_DESCRIPTION:
    "Receive a notification each time a new publication is scheduled",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_TITLE:
    "When a new publication is scheduled",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_DESCRIPTION:
    "Receive a notification each time a publication is published",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_TITLE:
    "When a publication is published",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the status of a publication is modified",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_TITLE:
    "When the state of a publication is changed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_DESCRIPTION:
    "Receive a notification each time there are only three days left until the subscription end date",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_TITLE:
    "When there are only three days left before the subscription's end date",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_DESCRIPTION:
    "Receive a notification each time a subscription is deleted",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_TITLE:
    "When a subscription is deleted",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_DESCRIPTION:
    "Receive a notification each time a subscription is terminated",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_TITLE:
    "When a subscription is terminated",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_DESCRIPTION:
    "Receive a notification each time the responsible person is changed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_TITLE:
    "When the responsible person is changed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a subscription is modified",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_TITLE:
    "When a subscription is changed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_TITLE:
    "When you add a department",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DESCRIPTION:
    "Receive a notification each time a new subscription is created",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_TITLE:
    "When a follower is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification every time a new message is recieved",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_TITLE:
    "When a new message is received",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_DESCRIPTION:
    "Receive a notification each time a team is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_TITLE: "When a team is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TITLE:
    "When a new subscription is created",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_DESCRIPTION:
    "Receive a notification each time a new user is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_TITLE: "When a new user is added",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive a notification each time a department is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_TITLE:
    "When a follower is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_DESCRIPTION:
    "Receive a notification each time a team is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_TITLE: "When a team is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_DESCRIPTION:
    "Receive a notification each time a user is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_TITLE: "When a user is removed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_DESCRIPTION:
    "Receive a notification each time a subscription is renewed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_TITLE:
    "When a subscription is renewed",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time the status of a subscription changes",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_TITLE:
    "When the state of a subscription is changed",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time the change is assigned to a user",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_TITLE:
    "When the change is assigned to a user",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Receive a notification each time a change is associated with a user",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_TITLE:
    "When the change is associated with a user",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_DESCRIPTION:
    "Receive a notification each time there are only three days left until the task's end date",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_TITLE:
    "When there are only three days left until the task's end date",
  NOTIFICATION_TOPIC_TASKS_DELETED_DESCRIPTION:
    "Receive a notification each time a task is removed",
  NOTIFICATION_TOPIC_TASKS_DELETED_TITLE: "When a task is removed",
  NOTIFICATION_TOPIC_TASKS_ENDED_DESCRIPTION:
    "Receive a notification each time a task finishes",
  NOTIFICATION_TOPIC_TASKS_ENDED_TITLE: "When a task finishes",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_DESCRIPTION:
    "Receive a notification each time the task exceeds the time limit",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_TITLE:
    "When the task exceeds the time limit",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receive a notification each time x amount of time is left until the end of the task",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_TITLE:
    "When x amount of time is left until the end of the task",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_DESCRIPTION:
    "Receive a notification each time a task is modified",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_TITLE: "When a task is modified",
  NOTIFICATION_TOPIC_TASKS_NEW_DESCRIPTION:
    "Receive a notification each time a task is created",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is added",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_TITLE: "When a follower is added",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_DESCRIPTION:
    "Receive a notification each time you receive a new message",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_TITLE: "When a new message is received",
  NOTIFICATION_TOPIC_TASKS_NEW_TITLE: "When a task is created",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_DESCRIPTION:
    "Receive a notification each time a task is originated",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_TITLE: "When a task is originated",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receive a notification each time a follower is removed",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_TITLE: "When a follower is removed",
  NOTIFICATION_TOPIC_TASKS_STARTED_DESCRIPTION:
    "Receive a notification each time a task starts",
  NOTIFICATION_TOPIC_TASKS_STARTED_TITLE: "When a task starts",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_DESCRIPTION:
    "Receive a notification each time a status of a task is changed",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_TITLE:
    "When a task's state is changed",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_DESCRIPTION:
    "Receive a notification each time a user is added as assignee",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_TITLE:
    "When a user is added as assignee",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_DESCRIPTION:
    "Receive a notification each time a user is added to the company",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_TITLE:
    "When a user is added to the company",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_DESCRIPTION:
    "Receive notification each time a department is added",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_TITLE: "When a department is added",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_DESCRIPTION:
    "Receive notification each time a team is added",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_TITLE: "When a team is added",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_DESCRIPTION:
    "Receive a notification each time a user celebrates a birthday",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_TITLE:
    "When a user celebrates his/her birthday",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_DESCRIPTION:
    "Receive a notification each time a password is changed",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_TITLE: "When password is changed",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_DESCRIPTION:
    "Receive a notification each time a user is removed as assignee",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_TITLE:
    "When a user is removed as a manager",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_DESCRIPTION:
    "Receive a notification each time a user is removed from the company",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_TITLE:
    "When a user is removed from the company",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receive notification each time a department is removed",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_TITLE:
    "When a department is removed",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_DESCRIPTION:
    "Receive a notification each time a team is removed",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_TITLE: "When a team is removed",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_DESCRIPTION:
    "Receive a notification each time a user's role is changed",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_TITLE: "When a user's role is changed",
  NOTIFICATIONS: "Notifications",
  NOTIFICATIONS_CONF: "Do you want to enable notifications on this device?",
  NOTIFICATIONS_READ: "Read",
  NOTIFICATIONS_UNREAD: "Unread",
  NOVEMBER: "November",
  NR_BILLING: "No. billing",
  NR_ITEMS: "Number of items",
  NR_SALE_OPPORTUNITY: "Nº sale opportunity",
  NUMBERED_LIST: "Numbered list",
  OCTOBER: "October",
  OF: "of",
  OIL_OR_ENERGY_OR_SOLAR_OR_GREENTECH: "Oil/Energy/Solar/Greentech",
  OLD_PASSWORD: "Current password",
  ON_SITE: "On site",
  ONCE: "Once",
  ONGOING_PROJECTS: "Ongoing projects",
  ONLINE_PUBLISHING: "Online publishing",
  ONLY_1_LINK_PER_SOCIAL_NETWORK: "Only 1 link per social media",
  ONLY_MAX_FIVE_EMAILS:
    "Maximum of 5 guests at this stage of registration, if you want to add more users see:",
  ONLY_MAX_FIVE_EMAILS_PART_TWO: "settings -> users -> invitations",
  ONLY_ME: "Me",
  OPEN: "Open",
  OPEN_ACCOUNTS: "Opened accounts",
  OPEN_CASES: "Open cases",
  OPEN_CONTRACTS: "Open contracts",
  OPEN_CONTRACTS_PROGRESS: "Open contracts progress",
  OPEN_DEAL: "Open deals",
  OPEN_DEALS: "Open deals",
  OPEN_DEALS_AMOUNTS: "Open deals amounts",
  OPEN_IN_APP: "Open in app",
  OPEN_PROJECT_ATRIBUTED: "Team - open projects assigned",
  OPEN_PROJECTS: "Open projects",
  OPEN_TASKS: "Open tasks",
  OPEN_TASKS_PROGRESS: "Time open tasks in progress",
  OPENIN_SCHEDULE: "Opening schedule",
  OPENING_HOURS: "Opening hours",
  OPERATION: "Operator",
  OPERATIONS_DEPARTMENT: "Operations",
  OPERATOR: "Operator",
  OPTIONAL: "Optional",
  OPTIONAL_INBOX_EMAIL: "Custom email (optional)",
  OR: "or",
  ORDER: "Order",
  ORDER_BY: "Order by",
  ORDERING: "Ordering",
  ORGANIZATION_NAME: "Company name",
  ORGANIZATION_NAME_INFO_DESC: "Your company's name",
  ORGANIZATION_REQUEST_GENERAL_ERROR:
    "There was an error processing your request, please try again later.",
  ORGANIZATION_REQUEST_NO_DATA:
    "To proceed, it's necessary the adition of a company.",
  ORGANIZATION_REQUEST_NO_DATA_NO_PERMISSION:
    "You must be in a company to proceed. Please contact the administrator.",
  ORGANIZATION_REQUEST_NO_PERMISSION:
    "Permissions are required to perform this request",
  ORGANIZATION_SAVE_SUCCESS: "Organization saved successfully",
  ORGANIZATIONS_SHORT_NAME: "Company short name",
  ORIGIN: "Origin",
  ORIGIN_AND_STATUS: "Origin and status",
  ORIGIN_IS: "Origin is",
  ORIGINATED: "Originated",
  OTHER_INDUSTRY: "Other industry",
  OUT_OF_CONTRACT_HOURS_ABRV: "T. out of contract",
  OUTSIDE_CONTRACT_HOURS: "Time outside contract",
  OUTSOURCING_OR_OFFSHORING: "Outsourcing/Offshoring",
  OVER: "Up to",
  OVERALL_TASK_PERFORMANCE: "Overall task performance",
  OVERALL_TICKET_PERFORMANCE: "Overall case performance",
  OVERLAP_RULE: "Overlap rule",
  OWNER: "Owner",
  PACKAGE_OR_FREIGHT_DELIVERY: "Package/Freight delivery",
  PACKAGING_OR_CONTAINERS: "Packaging/Containers",
  PAGE: "Page",
  PAPER_OR_FOREST_PRODUCTS: "Paper/Forest products",
  PARAMETERS: "Parameters",
  PASSWORD: "Password",
  PASSWORD_CHANGE: "Password change",
  PASSWORD_CHANGED_SUCCESS: "Password changed successfully",
  PASSWORD_STRENGH_MODERATE: "Password is moderated",
  PASSWORD_STRENGH_STRONG: "Password is strong",
  PASSWORD_STRENGH_VERY_STRONG: "Password is very strong",
  PASSWORD_STRENGH_WEAK:
    "Your password is not strong enough. Your password must be at least 8 characters, uppercase and lowercase.",
  PASSWORDS_DONT_MATCH: "Passwords are not the same",
  PAUSES: "Pauses",
  PAY_AND_UPGRADE: "Pay and upgrade",
  PAY_BY_CREDIT_CARD: "Pay by credit card",
  PAYMENT_DETAILS: "Payment details",
  PAYMENT_METHOD: "Payment method",
  PAYMENT_METHODS: "Payment methods",
  PAYMENT_PENDING_DESCRIPTION:
    "Please finalize your payment or cancel your subscription",
  PAYMENT_PENDING_TITLE: "Your payment is pending",
  PAYMENT_SUMARY: "Order summary",
  PAYMENT_TOTAL: "Total",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_FIRST_HALF:
    "The amount shown is a simulation with the current data for your space and will only be charged starting from",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_SECOND_HALF:
    "* The value may be different if the number of users and / or storage is changed.",
  PENDING: "Pending",
  PER_PAGE: "Per page",
  PERCENTAGE: "Percentage",
  PERCENTAGE_HOURS: "Percentage of time consumed ",
  PERCENTAGE_PERFORMANCE: "Performance percentage",
  PERFORMANCE: "Performance",
  PERFORMANCE_ACCOUNT: "Performance of accounts",
  PERFORMING_ARTS: "Performing arts",
  PERIOD_TOTAL: "Period total",
  PERSONAL: "Personal",
  PERSONAL_DATA: "Personal data",
  PERSONALIZE_BLOCKS: "Personalize blocks",
  PERSONALIZED: "Personalized",
  PHARMACEUTICALS: "Pharmaceuticals",
  PHILANTHROPY: "Philanthropy",
  PHONE_CODES: "Change phone codes",
  PHONE_NUMBER: "Phone number",
  PHONE_NUMBER_PREFIX: "Phone extension",
  PHOTOGRAPHY: "Photography",
  PICK_THE_MOST_IMPORTANT_TOPICS:
    "Choose the most important topics and how to receive notifications.",
  PIN: "Pin",
  PINNED: "Pinned",
  PIPE_STATUS: "Pipe. status",
  PIPELINE: "Pipeline",
  PIPELINE_DELETE_MSG: "Do you want to delete this pipeline?",
  PIPELINE_ERROR: "Pipeline error",
  PIPELINE_ERROR_DESC:
    "The selected pipeline may not exist or be poorly configured",
  PIPELINE_STATUS: "Pipeline status",
  PLAN: "Plan",
  PLAN_ADMINISTRATION: "Administration",
  PLAN_ADVANCED: "Advanced filters",
  PLAN_ADVANCED_PERMISSION: "Advanced permissions",
  PLAN_ANNUALLY: "Annually",
  PLAN_BASIC: "Pro",
  PLAN_BULK: "Bulk export of PDF’s",
  PLAN_BUSINESS: "Companies",
  PLAN_BUSINESS_CONTRACTS_SUBSCRIPTIONS: "Deals, contracts and subscriptions",
  PLAN_CALLS: "Calls and cases",
  PLAN_COMMENTS: "Comments",
  PLAN_COMPANY: "Enterprise",
  PLAN_CONTACTS: "Contacts and customers",
  PLAN_DASHBOARDS: "Dashboards",
  PLAN_DEDICATED: "Dedicated manager",
  PLAN_DEPARTMENTS: "Departments and teams",
  PLAN_EARLY: "Early access to new features",
  PLAN_EXPORT: "Export",
  PLAN_EXPORT_CONTROL: "Export control",
  PLAN_EXTRA_USER: "Extra user",
  PLAN_FORMATION: "Training (hours)",
  PLAN_FUNCTIONALITIES: "Features",
  PLAN_GEOLOCATION: "Geo-location and geo-referencing",
  PLAN_GEOLOCATION_SM: "Geo-location",
  PLAN_MANAGEMENT: "Management panel",
  PLAN_MANDATORY: "Required",
  PLAN_MOST_POPULAR: "Most popular",
  PLAN_NOTIFICATIONS: "Email notifications",
  PLAN_OF_SPACE: "from space",
  PLAN_ONBOARDING: "Onboarding / Installation price",
  PLAN_PRICE: "Price",
  PLAN_PRICE_GIGA: "Price per extra GB (s)",
  PLAN_PRICING: "Price and customized billing",
  PLAN_PRIORITY: "Priority support",
  PLAN_PRO: "Business",
  PLAN_PROJECTS: "Projects and tasks",
  PLAN_REGISTRATION: "Time record",
  PLAN_RENOVATION_DATE: "Subscription renewal date",
  PLAN_RENOVATION_PAYMENT_AMOUNT: "How much will you be charged",
  PLAN_REPORTING: "Reporting",
  PLAN_RULE_APPLICATION: "Application rule (plans)",
  PLAN_SPACE: "Space limit (GB)",
  PLAN_STARTER: "Start",
  PLAN_SUPPORT: "Support",
  PLAN_TERM: "Term",
  PLAN_UPLOAD: "File upload",
  PLAN_USE: "Use",
  PLAN_USERS_LIMIT: "User limit",
  PLAN_VERSION: "Version history",
  PLAN_WEB: "Web and mobile apps",
  PLANS: "Plans",
  PLASTICS: "Plastics",
  PLEASE_CONTACT_YOUR_ADMIN: "Please contact the administrator",
  PLEASE_RECONECT: "Please reconect",
  POLITICAL_ORGANIZATION: "Political organization",
  POST_PAYMENT: "Post payment",
  POSTAL_CODE: "Postal code",
  POTENTIALLY_DANGEROUS: "Potentially dangerous",
  PREVIEW: "Preview",
  PREVIEW_TEST_RESULTS: "Test results",
  PREVIOUS: "Previous",
  PREVIOUS_BILLING_PLAN: "Your last plan was",
  PRIMARY_DOMAIN: "Primary domain",
  PRIMARY_OR_SECONDARY_EDUCATION: "Primary/Secondary education",
  PRINTING: "Printing",
  PRIORITY: "Priority",
  PROCESSING: "In process",
  PRODUCT: "Product",
  PROFESSIONAL_TRAINING: "Professional training",
  PROFILE: "Profile",
  PROGRAM_DEVELOPMENT: "Program development",
  PROGRAMMING: "Programming",
  PROGRESS_CLOSED_TASKS: "Average progress of closed tasks",
  PROGRESS_OPEN_PROJECTS: "Open projects in progress",
  PROJECT: "Project",
  PROJECT_ASSIGNED: "assigned you the project",
  PROJECT_CREATION: "Project creation",
  PROJECT_DELETED: "deleted the project",
  PROJECT_DESCRIPTION:
    "Have a global view of the development of your company's projects.",
  PROJECT_IS: "Project is",
  PROJECT_NR: "Project Nº",
  PROJECT_OVERIMPOSED_FOUND: "There is an overlap of projects.",
  PROJECT_TYPE: "Project type",
  PROJECT_TYPE_EQUALS: "Project type is equal to",
  PROJECT_TYPES: "Project types",
  PROJECT_UPDATE: "updated the project",
  PROJECTS: "Projects",
  PROJECTS_ACTIVITY: "Projects - activity",
  PROJECTS_ALMOST_EXCEEDED: "Projects - time almost exceeded",
  PROJECTS_BUDGET_RISK: "Projects - budget risk",
  PROJECTS_CHANGED: "changed projects",
  PROJECTS_CLOSED: "Projects - closed",
  PROJECTS_DEADLINE_RISK: "Projects - deadline risk",
  PROJECTS_DELETE_DELAY_DESCRIPTION: "Preparing to delete projects",
  PROJECTS_DELETE_DELAY_TITLE: "Project elimination",
  PROJECTS_DELETE_DESCRIPTION: "Deleting projects",
  PROJECTS_DELETE_TITLE: "Project elimination",
  PROJECTS_EXCEEDED_HOURS: "Projects - exceeded time",
  PROJECTS_FAILED_CHANGED: "failed when changing",
  PROJECTS_INACTIVE: "Projects - inactive",
  PROJECTS_NEAR_DEADLINE: "Projects - near the deadline",
  PROJECTS_NOT_CHANGED: "unchanged projects",
  PROJECTS_OPEN_EVOLUTION: "Evolution of projects opening",
  PROJECTS_REPORT: "Projects report",
  PROJECTS_STATUS: "Projects status",
  PROJECTS_WORKLOAD: "Projects - workload",
  PROMOTIONAL_CODE: "Promotional code",
  PROPERTY: "Property",
  PROPERTY_SETTER: "Property setter",
  PROTOCOL: "Protocol",
  PUBLIC: "Public",
  PUBLIC_RELATIONS_OR_PR: "Public relations/PR",
  PUBLIC_SAFETY: "Public safety",
  PUBLICATION_DATE: "Publication date",
  PUBLICATION_NR: "Publication Nº",
  PUBLICATION_REMOVE_MSG: "Do you want to remove this publication?",
  PUBLICATIONS: "Publications",
  PUBLICATIONS_SCHEDULE: "Publications schedule",
  PUBLISH_FROM: "Publish from",
  PUBLISH_NOW: "Publish now",
  PUBLISHED: "Published",
  PUBLISHING: "Publishing",
  PUBLISHING_DATE: "Publishing date",
  PUBLISHING_FAIL: "Publishing fail",
  PUBLISHING_INDUSTRY: "Publishing industry",
  PUBLISHING_PERMISSION: "Publish permission",
  PURCHASES_DEPARTMENT: "Purchases",
  QUALITY: "Quality",
  QUANTITY: "Quantity",
  QUARTER: "Quarter",
  QUERY_PARAMETERS: "Query parameters",
  QUEUE_LIST_EMPTY: "Empty queue list",
  QUICK_ACTIONS: "Quick actions",
  QUOTE_BLOCK: "Quote block",
  RAILROAD_MANUFACTURE: "Railroad manufacture",
  RANCHING: "Ranching",
  RATING: "Rating",
  READ: "Read",
  REAL_ESTATE_OR_MORTGAGE: "Real estate/Mortgage",
  REALTIME_DISCONNECTED:
    "You were disconnected from real time functionalities.",
  REASON_INEFFICIENCY: "Reason for non-attributable time",
  REASON_TEXT: "Reason: {reason}",
  REASONS_LOSS: "Reasons for loss",
  RECEIVE_CALLS: "Received calls",
  RECEPTION: "Inbox",
  RECEPTION_ASSIGNED_TO: "Assigned to",
  RECEPTION_SIGNATURE: "First sent",
  RECONNECT: "Reconnect",
  RECONNECTING: "Reconnecting...",
  RECORD: "Record",
  RECREATIONAL_FACILITIES_OR_SERVICES: "Recreational facilities/Services",
  RECURRENCE_CONFIGURATION: "Recurrence configuration",
  RECURRENCY_DAYS: "day(s)",
  RECURRENCY_EVERY: "Every:",
  RECURRENCY_LIMIT_EXCEEDED:
    "Maximum number of tasks per recurrence exceeded, the creation will be adjusted for the first 100 tasks in the desired date ranges.",
  RECURRENCY_MONTH_WARNING:
    "There are months that have less than {day} days, in which case the task will be created on the last day of the month.",
  RECURRENCY_MONTHS: "month(s)",
  RECURRENCY_ON: "On:",
  RECURRENCY_ORIGIN_DATE_WARNING:
    "Confirm the dates of the tasks that will be created.",
  RECURRENCY_WEEKS: "week(s)",
  RECURRENCY_WILL_CREATE: "<b>{count}</b> recurring tasks will be created:",
  RECURRENCY_YEARS: "year(s)",
  RECURRENT_TASKS_CREATION: "Creating recurrent tasks",
  RECURRENT_TASKS_CREATION_DELAY_DESCRIPTION:
    "Preparing to create recurrent tasks",
  RECURRENT_TASKS_CREATION_DELAY_TITLE: "Recurrent tasks",
  RECURRENT_TASKS_CREATION_DESCRIPTION: "Creating recurrent tasks",
  RECURRENT_TASKS_CREATION_SUCCESS: "Recurrent tasks created successfully",
  RECURRENT_TASKS_CREATION_TITLE: "Recurrent tasks",
  REDEEM_RULES: "Redeem rules",
  REDEEMS: "Redeems",
  REDO: "Redo",
  REFERENCE: "Reference",
  REFRESH: "Refresh",
  REGION_STATE_DISCTRICT: "Region / State / District",
  REGISTER_DESCRIPTION: "To create a new account fill in the required data",
  REGISTER_EMAIL_EXISTS: "This email is already in use",
  REGISTER_ERROR_ALREADY_ACCEPTED: "Invite was already accepted",
  REGISTER_ERROR_EMAIL_EXISTS: "This email already exists",
  REGISTER_ERROR_EMAIL_MISMATCH: "Email does not match the invitation email",
  REGISTER_ERROR_INVITE_NOT_FOUND: "Invite was was not found",
  REGISTER_ERROR_MEMBERSHIP_ERROR: "Membership error",
  REGISTER_ERROR_NOT_VALID:
    "Provided data is not valid. Please make sure to provide a valid name, password and email",
  REGISTER_ERROR_RESERVED_NAME: "Name is reserved",
  REGISTER_INVALID_EMAIL: "Please set a valid email",
  REGISTER_INVALID_EMAIL_OR_DUPLICATED:
    "Please set a valid and not already existing email",
  REGISTER_INVALID_NAME: "Please set a valid name",
  REGISTER_INVALID_PASSWORD: "Please set a valid password",
  REGISTER_UNKNOWN_ERROR: "We couldn't register your account.",
  REGISTER_WELCOME: "Welcome, create a new account",
  REGISTRATION: "Registration",
  REJECTED: "Rejected",
  RELATED_ATTACHMENTS: "Related attachments ",
  RELIGIOUS_INSTITUTIONS: "Religious institutions",
  REMOTE: "Remote",
  REMOVAL: "Removal",
  REMOVE: "Remove",
  REMOVE_ALL: "Remove all",
  REMOVE_ASSOCIATED_CARD_DESCRIPTION:
    "When you remove the associated card, your subscription will not automatically renew and will be canceled after three days after the next billing period.",
  REMOVE_ASSOCIATED_CARD_TITLE: "Remove associated card",
  REMOVE_FORMAT: "Remove format",
  REMOVE_LINK: "Remove link",
  REMOVE_TABLE: "Delete table",
  RENAME: "Rename view",
  RENEWABLES_OR_ENVIRONMENT: "Renewables/Environment",
  RENEWAL: "Renewal",
  REORDER_VIEWS: "Sort views",
  REPEAT_ON_FAIL: "Reapeat on fail",
  REPEAT_ON_FAIL_COUNT: "Max repeat attempts",
  REPEATING: "Multiple times",
  REPEATS: "Repeats",
  REPLACE_CARD: "Replace Card",
  REPLY: "Reply",
  REPORT: "Report",
  REPORT_ACCOUNT: "Report to account",
  REPORT_CASES: "Report cases",
  REPORT_CLIENT: "Report to client",
  REPORTING: "Reports",
  REPORTS: "Reports",
  REPORTS_TIMES: "Times report",
  REPUBLISH: "Republish",
  REQUEST_BODY: "Request body",
  REQUEST_HELP: "Request support",
  REQUEST_TYPE: "Request type",
  RESEARCH_INDUSTRY: "Research industry",
  RESET_EMAIL_ERROR: "We had an error while trying to reset your password.",
  RESET_EMAIL_ERROR_TRY_AGAIN: "Please try again in a moment.",
  RESET_EMAIL_INSERT_NEW_PASSWORD: "Please set your new password.",
  RESET_EMAIL_INVALID_URL: "This url is no longer valid.",
  RESET_EMAIL_SENT_DESCRIPTION:
    "Follow the instructions in the email to complete the password reset",
  RESET_EMAIL_SENT_TITLE: "We sent an email to <b>{email}</b>",
  RESET_PASSWORD: "Reset your password",
  RESOLVED: "Resolved",
  RESOURCE: "Resource",
  RESPONSABILITY: "Sales manager",
  RESPONSE_BODY: "Response body",
  RESPONSE_CODE: "Response code",
  RESPONSE_FORMAT: "Response format",
  RESPONSIBLE_DEAL: "Responsible for deal",
  RESPONSIBLE_DEAL_2: "Responsible deal",
  RESTAURANTS: "Restaurants",
  RESTORE_ALL: "Restore all",
  RESTORE_CARDS: "Restore cards",
  RESTORE_COLUMNS: "Restore columns",
  RESTORE_KANBAN: "Restore kanban",
  RESTORE_MODULES: "Restore modules",
  RESTORE_PIPELINE_STATUS: "Restore pipeline states",
  RESTORE_STATES: "Restore states",
  RETAIL_INDUSTRY: "Retail Industry",
  RETRY_PAYMENT: "Retry payment",
  RETURN: "Return",
  RETURN_PATH_RECORD: "Return path",
  ROADMAP: "Roadmap",
  ROLE: "Role",
  ROUNDING_MARGIN: "Rounding margin (min)",
  ROUNDING_MARGIN_ABV: "R. margin (min)",
  SALES: "Sales",
  SALES_DEALS: "Sales deals",
  SALESPERSON: "Salesperson",
  SATURDAY: "Saturday",
  SAVE: "Save",
  SAVE_SUCCESS: "Successfully saved",
  SAVE_VIEW: "Save view",
  SCHEDULE: "Schedule",
  SCHEDULE_TASKS: "Scheduled tasks",
  SCHEDULED: "Scheduled",
  SCOPE: "Filter",
  SCORE: "Score",
  SCREEN_RECORD_TOOLTIP: "Screen recorder",
  SCRIPT: "Scripts",
  SEARCH: "Search",
  SEARCH_ASSOCIATION: "Search for association",
  SEARCH_CASES_CALLS: "Search cases, calls, other...",
  SEARCH_CLIENT_NAME: "Search client name, client number, etc...",
  SEARCH_FUNCTIONALITIES: "Search functionalities",
  SEARCH_GIFS: "Search for GIFs",
  SEARCH_INBOX_PLACEHOLDER: "Search in inbox",
  SEARCH_MODULES: "Search modules",
  SEARCH_PIPELINE_STATUS: "Search for pipeline states",
  SEARCHING: "Searching ...",
  SECOND: "Second",
  SECURITY: "Security",
  SECURITY_OR_INVESTIGATIONS: "Security/Investigations",
  SEE_AS: "See as",
  SEE_CHANGES: "See changes",
  SEE_ORIGINAL: "See original",
  SELECT_A_PROPERTY: "Choose a property",
  SELECT_ALL: "Select all",
  SELECT_LANGUAGE: "Select a language",
  SELECT_LEGAL_BASIS: "Select legal basis",
  SELECT_NEW_PLAN_AFTER_TRIAL: "Select a plan for your new subscription",
  SELECT_ONE_PIPELINE: "Please select one pipeline",
  SELECT_PAYMENT_METHOD: "Select your payment method",
  SELECT_PRIMARY: "Select primary",
  SELECT_PRIMARY_CONTACT: "Select the primary contact",
  SELECT_PRIMARY_CONTACT_DESCRIPTION:
    "Please define the primary contact to be used. This action is irreversible, all records will be associated to the primary contact and the others will be permanently erased.",
  SELECT_PROPERTY: "Select property",
  SELECT_SCHEDULE: "Select a schedule",
  SELECT_SOCIAL_NETWORK: "Select a social network to connect",
  SELECT_STATUS: "Select status",
  SELECT_TEST_TARGET: "Perform a test",
  SELECT_WHAT_IMPORT: "Select what to import",
  SELECTED_COLUMNS: "Selected columns",
  SELECTED_LANGUAGE: "Selected language",
  SELECTED_MODULES: "Selected modules",
  SELECTED_ORIGIN: "Selected origin:",
  SEMICONDUCTORS: "Semiconductors",
  SEND: "Send",
  SEND_EMAIL: "Send email",
  SEND_INVITES: "Send invitations",
  SEND_INVITES_DESC:
    "Your Airdesk workspace is ready to be used. Do you know any friends or co-workers who would like to explore Airdesk with you?",
  SEND_INVITES_FORM_LABEL: "Email address",
  SEND_MAIL: "Send email",
  SEND_NOTIFICATION: "Notify contact",
  SENDING_DATE: "Date to send",
  SENT_BY: "Sent by",
  SENT_DATE: "Sent date",
  SENT_EMAIL: "Sent the email",
  SENT_STATUS: "Message status",
  SEPERATOR: "Separator",
  SEPTEMBER: "September",
  SERVICE: "Service",
  SERVICES: "Services",
  SESSION_ENDED: "Session ended",
  SESSIONS_CLOSED_SUCCESS: "Sessions ended successfully",
  SET_NEW_PASSWORD: "Set new password",
  SETTINGS: "Settings",
  SETTINGS_ACCESS: "Settings access",
  SETUP: "Setup",
  SHARE: "Share",
  SHARE_BUTTON_ALT: "Share",
  SHARE_ERROR_DESCRIPTION: "We had an error while sharing",
  SHARE_ERROR_TITLE: "Sharing error",
  SHARE_SUCCESS_DESCRIPTION: "The action has been performed successfully",
  SHARE_SUCCESS_DESCRIPTION_ALT:
    "Action taken has been completed. {total} completed, {errors} failed.",
  SHARING_API_KEY: "API Key {id} is being shared",
  SHARING_API_KEY_SUCCESS_DESCRIPTION:
    "Action taken has been completed. {total} completed, {errors} failed.",
  SHARING_API_KEY_SUCCESS_TITLE: "API Key {id} was shared",
  SHARING_AUTOMATION_DESCRIPTION: "Workflow  {id} is being shared",
  SHARING_AUTOMATION_SUCCESS_TITLE: "Workflow {id} was shared",
  SHARING_AUTOMATION_TITLE: "Workflow  {id} is being shared",
  SHARING_BILLING_SUCCESS_TITLE: "Classification {id} was shared",
  SHARING_BILLING_TITLE: "Classification {id} is being shared",
  SHARING_CALL_SUCCESS_TITLE: "Call {id} was shared",
  SHARING_CALL_TITLE: "Call {id} is being shared",
  SHARING_CLIENT_SUCCESS_TITLE: "Client {id} was shared",
  SHARING_CLIENT_TITLE: "Client {id} is being shared",
  SHARING_COMPANY_SUCCESS_TITLE: "Company {id} was shared",
  SHARING_COMPANY_TITLE: "Company {id} is being shared",
  SHARING_CONTACT_SUCCESS_TITLE: "Contact {id} was shared",
  SHARING_CONTACT_TITLE: "Contact {id} is being shared",
  SHARING_CONTRACT_SUCCESS_TITLE: "Contract {id} was shared",
  SHARING_CONTRACT_TITLE: "Contract {id} is being shared",
  SHARING_CONVERSATION: "The conversation is being shared",
  SHARING_CONVERSATION_SUCCESS_TITLE: "The conversation has been shared",
  SHARING_DEAL_SUCCESS_TITLE: "Deal {id} was shared",
  SHARING_DEAL_TITLE: "Deal {id} is being shared",
  SHARING_DEPARTMENT_SUCCESS_TITLE: "Department {id} was shared",
  SHARING_DEPARTMENT_TITLE: "Department {id} is being shared",
  SHARING_DESCRIPTION: "Your action is being processed",
  SHARING_EMAIL_SIGNATURE_SUCCESS_TITLE: "Email signature {id} was shared",
  SHARING_EMAIL_SIGNATURE_TITLE: "Email signature {id} is being shared",
  SHARING_PROJECT_SUCCESS_TITLE: "Project {id} was shared",
  SHARING_PROJECT_TITLE: "Project {id} is being shared",
  SHARING_SOCIAL_POST_SUCCESS_TITLE: "Publications {id} was shared",
  SHARING_SOCIAL_POST_TITLE: "Publication {id} is being shared",
  SHARING_SUBSCRIPTION_SUCCESS_TITLE: "Subscription {id} was shared",
  SHARING_SUBSCRIPTION_TITLE: "Subscription {id} is being shared",
  SHARING_TASK_SUCCESS_TITLE: "Task {id} was shared",
  SHARING_TASK_TITLE: "Task {id} is being shared",
  SHARING_TEAM_SUCCESS_TITLE: "Team {id} was shared",
  SHARING_TEAM_TITLE: "Team {id} is being shared",
  SHARING_TICKET_SUCCESS_TITLE: "Ticket {id} was shared",
  SHARING_TICKET_TITLE: "Ticket {id} is being shared",
  SHARING_TIMECHARGE_SUCCESS_TITLE: "Time record {id} was shared",
  SHARING_TIMECHARGE_TITLE: "Time record {id} is being shared",
  SHIPBUILDING: "Shipbuilding",
  SHORT_NAME: "Short name",
  SHORT_NAME_DES: "shortname",
  SHORT_NAME_INFORMATION:
    "The short name is required for creating inbox emails. Example:",
  SHOW_ALL: "Show all",
  SHOW_LESS: "See less",
  SHOW_MORE: "See more",
  SHOW_NOTES: "View notes",
  SIDEBAR: "Sidebar",
  SIGN_IN: "Sign in",
  SIGNATURE: "Signature",
  SIGNATURE_BANNER: "Banner",
  SIGNATURE_LOGO: "Logo",
  SIMULATION: "Simulation",
  SMALL_SCREEN_SUBTITLE:
    "Please enlarge Browser window or for mobile devices download/open the application for android.",
  SMALL_SCREEN_TITLE: "Your screen is too small to work with Airdesk",
  SNIPPET: "Snippet",
  SNIPPET_DESCRIPTION_AFTER: "homepage tag for compliance and collect details.",
  SNIPPET_DESCRIPTION_BEFORE: "Copy and paste the snippet before",
  SNIPPET_TITLE: "Snippet code",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA:
    "To proceed you need to add a social network account.",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA_NO_PERMISSION:
    "A social network account is required to proceed. Please contact the administrator.",
  SOCIAL_AUTHENTICATION: "Social - authentication",
  SOCIAL_MEDIA: "Social media",
  SOCIAL_MEDIA_ACCOUNTS: "Social media accounts",
  SOCIALMEDIA: "Social media",
  SORT_YOUR_VIEWS: "Sort your created views",
  SOUND_AND_APEARANCE: "Sound and appearance",
  SPACE: "Space",
  SPACE_ENTERED: "entered the space",
  SPACE_OPEN: "Open",
  SPACE_SETUP_DATA: "Workspace data",
  SPACE_SETUP_DESCRIPTION:
    "To be able to personalize your workspace, we will need some information first. You can change it at any time in the future.",
  SPACE_SETUP_NAME_FIELD: "Workspace name",
  SPACE_SETUP_NAME_FIELD_INFO_DESC:
    "Name of your workspace in Airdesk (ex: company name / company group)",
  SPACE_SETUP_TITLE: "Set up your new workspace",
  SPACE_SUBSCRIPTION_WARNING:
    "If you continue the current subscription will be canceled.",
  SPACE_UPDATE: "Space updated",
  SPACE_UPDATE_DOMAIN_CONFLICT: "The selected short name already exists",
  SPACE_UPDATE_MSG: "Space configuration was changed successfully",
  SPEECH_TO_TEXT: "Speech to text",
  SPEECH_TO_TEXT_LANGUAGE: "Speech language",
  SPENT_HOURS: "Time consumed ",
  SPENT_HOURS_ABRV: "T. consumed",
  SPF_RECORD: "SPF record",
  SPLIT: "Split",
  SPLIT_CELLS: "Split cells",
  SPLIT_IN_BATCHES: "Batch split",
  SPORTING_GOODS: "Sporting goods",
  SPORTS: "Sports",
  STAFFING_OR_RECRUITING: "Staffing/Recruiting",
  START: "Start",
  START_CONVERSATION: "Send email",
  START_DATE: "Start date",
  START_DATE_NO_BIGGER_THEN_END: "Start date cannot be greater than end date",
  STARTS_WITH: "Starts with",
  STATUS: "Status",
  STATUS_CHANGED_SUCCESS: "Status successfuly changed.",
  STATUS_CONTRACTS: "Status contracts",
  STATUS_EQUALS: "Status equals to",
  STATUS_PROJECTS: "Status projects",
  STEP: "Step",
  STICKY: "Sticky",
  STORAGE: "Storage",
  STRIKETHROUGH: "Strikethrough",
  SUBJECT: "Subject",
  SUBJECT_HELP_MESSAGE: "Subject tooltip",
  SUBMIT: "Submit",
  SUBMIT_AND_SEND: "Send invite",
  SUBMIT_FEEDBACK: "Submit feedback ",
  SUBMIT_NOT_SEND: "Save",
  SUBSCRIBE: "Subscribe",
  SUBSCRIPTION: "Subscription",
  SUBSCRIPTION_CANCELED: "Subscription canceled",
  SUBSCRIPTION_CREATION: "Subscription creation",
  SUBSCRIPTION_DELETED: "deleted the subscription",
  SUBSCRIPTION_EXPIRED: "Trial expired",
  SUBSCRIPTION_NOT_RENOVATED: "Subscription w/ payment",
  SUBSCRIPTION_NR: "Nº subscrição",
  SUBSCRIPTION_OF_WORKSPACE: "The subscription of the workspace",
  SUBSCRIPTION_OF_WORKSPACE_TRIAL_ENDED: "Your free trial is over.",
  SUBSCRIPTION_TYPE: "Subscription type",
  SUBSCRIPTION_TYPES: "Subscription types",
  SUBSCRIPTION_WAS_CANCELED_DESCRIPTION:
    "Select a plan for your new subscription",
  SUBSCRIPTION_WAS_CANCELED_TITLE: "Your subscription has been canceled",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION:
    "Due to some payment failures, your subscription has been canceled. Please select a plan for your new subscription.",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_ONE:
    "Please check if you have enough balance or update your payment method, in",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_TWO:
    "days your subscription will be canceled.",
  SUBSCRIPTION_WAS_NOT_RENOVATED_TITLE:
    "Your subscription has not been renewed",
  SUBSCRIPTIONS: "Subscriptions",
  SUBSCRIPTIONS_CHANGED: "changed subscriptions",
  SUBSCRIPTIONS_DELETE_DELAY_DESCRIPTION: "Preparing to delete subscriptions",
  SUBSCRIPTIONS_DELETE_DELAY_TITLE: "Delete subscriptions",
  SUBSCRIPTIONS_DELETE_DESCRIPTION: "Deleting subscriptions",
  SUBSCRIPTIONS_DELETE_TITLE: "Delete subscriptions",
  SUBSCRIPTIONS_FAILED_CHANGED: "failed when changing",
  SUBSCRIPTIONS_NOT_CHANGED: "unchanged subscriptions",
  SUBSCRITION_CANCELED_SUCCESS: "Subscription canceled successfully",
  SUBSCRITION_CANCELED_SUCCESS_DESCRIPT:
    "The subscription won't be renovated at the end of the billed period",
  SUBTOTAL: "Subtotal",
  SUCCESS: "Success",
  SUCCESS_DELETED: "Successfuly deleted",
  SUCCESS_MESSAGES: "Success message",
  SUCCESS_PAYMENT: "Payment was successful",
  SUCCESS_PLAN_CHANGE: "Successful plan change",
  SUCCESS_SAVED: "Saved successfully",
  SUCCESS_SUBMIT_MESSAGE: "Success confirmation message",
  SUCCESSFULLY_CONCLUDED: "Changed successfully",
  SUCCESSFULLY_RECORDED: "Successfully recorded",
  SUMMARY: "Summary",
  SUNDAY: "Sunday",
  SUPERMARKETS: "Supermarkets",
  SUPPORT: "Support",
  SUPPORT_REQUEST: "Support request",
  SYSTEM: "System",
  SYTEM_AIRDESK_EMAIL: "Airdesk alias email",
  TABLE_CALLS: "Calls chart",
  TABLE_CONTRACTS: "Contracts chart",
  TABLE_DEALS: "Deals chart",
  TABLE_PROJECTS: "Projects chart",
  TABLE_SUBSCRIPTIONS: "Subscriptions chart",
  TABLE_TASKS: "Tasks chart",
  TABLE_TICKETS: "Cases chart",
  TABLE_TIMES: "Times chart",
  TASK: "Task",
  TASK_ASSIGNED: "assigned you the task",
  TASK_ASSIGNED_TO: "assigned the task",
  TASK_CLOSING_TIME: "Team - average task closing time",
  TASK_CREATION: "Task creation",
  TASK_DELETED: "deleted the task",
  TASK_HISTORY: "Task history",
  TASK_IS: "Task is",
  TASK_LIST: "Check list",
  TASK_NR: "Task Nº",
  TASK_OVERIMPOSED_FOUND: "There is an overlap of tasks/times.",
  TASK_REMOVED: "removed you from the task",
  TASK_UPDATE: "updated the task",
  TASKS: "Tasks",
  TASKS_CHANGED: "changed tasks",
  TASKS_DELETE_DELAY_DESCRIPTION: "Preparing to delete tasks",
  TASKS_DELETE_DELAY_TITLE: "Deleting tasks",
  TASKS_DELETE_DESCRIPTION: "Deleting tasks",
  TASKS_DELETE_TITLE: "Deleting tasks",
  TASKS_DESCRIPTION:
    "Track your company's task count, response time, and more.",
  TASKS_FAILED_CHANGED: "failed when changing",
  TASKS_MULTIPLE: "Tasks(s)",
  TASKS_NOT_CHANGED: "unchanged tasks",
  TASKS_PERFORMANCE: "Tasks performance",
  TASKS_PROGRESS: "Tasks progress",
  TASKS_REPORT: "Tasks report",
  TASKS_STATUS: "Tasks status",
  TAX_INCLUDED: "(includes taxes)",
  TEAM: "Team",
  TEAM_ACTIVITY: "Team activity",
  TEAM_CASE_AVERAGE: "Team - average case closure time",
  TEAM_DEPARTMENT: "Team's department",
  TEAM_IS: "Team is",
  TEAM_LOAD: "Team - workload",
  TEAM_MEMBER: "Team member",
  TEAM_NAME: "Team's name",
  TEAM_SAVE_SUCCESS: "Team saved successfully",
  TEAM_TASK_AVERAGE: "Team - average task closure time",
  TEAM_WORKLOAD: "Team - workload",
  TEAMS: "Teams",
  TEAMS_CONTRIBUTORS: "Teams / Collaborators",
  TECHNICIANS: "Technicians",
  TELECOMMUNICATIONS: "Telecommunications",
  TEMPLATE: "Template",
  TEMPLATES: "Templates",
  TERMS_CONDITIONS: "Terms and conditions",
  TEST: "Test",
  TEST_EMAIL_SEND_SUCCESS: "Email successfully sent",
  TEST_EMAIL_SIGNATURE: "Test email signature",
  TEST_EMAIL_SIGNATURE_DESCRIPTION:
    "An email will be sent to your personal inbox.",
  TEXT: "Text",
  TEXT_FIELD_IS_REQUIRED: "Please write a message",
  TEXT_LINK: "Text link",
  TEXTILES: "Textiles",
  THE_CALL_IS_CREATED: "Call is created.",
  THE_CASE_STATUS: "The state of the case",
  THE_CLASSIFICATION_IS_CREATED: "Classification is created.",
  THE_CLIENT_IS_CREATED: "Account is created.",
  THE_CLIENT_STATUS: "The state of the account",
  THE_CONTACT_IS_CREATED: "Contact is created.",
  THE_CONTACT_STATUS: "The state of the contact",
  THE_CONTRACT_IS_CREATED: "Contract is created.",
  THE_DEAL_IS_CREATED: "Deal is created.",
  THE_PROJECT_IS_CREATED: "Project is created.",
  THE_PROJECT_STATUS: "The state of the project",
  THE_SUBSCRIPTION_IS_CREATED: "Subscription is created.",
  THE_TASK_IS_CREATED: "Task is created.",
  THE_TASK_STATUS: "The state of the task",
  THE_TEAM: "The team",
  THE_TICKET_IS_CREATED: "Ticket is created.",
  THE_TIME_IS_CREATED: "Time is created.",
  THE_TIME_RECORD_STATUS: "The state of the time record",
  THE_USER: "The user",
  THEME: "Theme",
  THINK_TANKS: "Think tanks",
  THIRD: "Third",
  THUMBNAIL: "Avatar",
  THURSDAY: "Thursday",
  TICKET_CLOSE: "Team - average case closing time",
  TICKET_CREATION: "Case creation",
  TICKET_ERROR_NO_CONTACT: "There is no email contact to send the notification",
  TICKET_FORM: "Case form",
  TICKET_HISTORY: "Cases history",
  TICKET_PERFORMANCE: "Case performance",
  TICKET_PROGRESS: "Time open cases in progress",
  TICKETS_CHANGED: "changed tickets",
  TICKETS_DELETE_DELAY_DESCRIPTION: "Preparing to delete cases",
  TICKETS_DELETE_DELAY_TITLE: "Elimination of cases",
  TICKETS_DELETE_DESCRIPTION: "Deleting cases",
  TICKETS_DELETE_TITLE: "Elimination of cases",
  TICKETS_DESCRIPTION:
    "Track your company's case count, response time and more.",
  TICKETS_FAILED_CHANGED: "failed when changing",
  TICKETS_MULTIPLE: "Case(s)",
  TICKETS_NOT_CHANGED: "unchanged tickets",
  TIME: "Time",
  TIME_CHARGES_MULTIPLE: "Time charge(s)",
  TIME_CONTROL: "Stopwatch",
  TIME_CONTROL_PERIODICITY: "Time control periodicity",
  TIME_EQUIVALENT: "This module is the same as time records",
  TIME_IS: "Time is",
  TIME_MONITORING: "Time monitoring",
  TIME_NR: "Time record Nº",
  TIME_RECORD: "Time record",
  TIME_RECORDS: "Time records",
  TIME_REGISTER: "Time records",
  TIME_REGISTRATION: "Time registrations",
  TIME_REPORT: "Time report",
  TIME_SUMMARY: "Summary of the last 9 days of the time log.",
  TIME_TYPE: "Type of time record",
  TIME_TYPE_EQUALS: 'Time type is equal to "',
  TIMEOUT: "Timeout",
  TIMEOUT_BETWEEN_FAIL: "Timeout between fails",
  TIMES: "Times",
  TIMESHEET: "Timesheet",
  TIMESHEET_DESCRIPTION: "Check the times posted by your employees.",
  TIMETABLES_PREDEFINED: "Timetables are predefined for the chosen day",
  TIMEZONE: "Timezone",
  TITLE: "Title",
  TO: "To",
  TO_BILL: "To be billed",
  TO_PLAN: "To plan",
  TO_PUBLISH: "To be published",
  TOBACCO: "Tobacco",
  TODAY: "Today",
  TODO_ITEMS: "To do's",
  TODO_NR_ITEMS: "Number of items {items}",
  TODOS_NUMBER: "Number of steps",
  TOMOROW: "Tomorow",
  TOP_ACCOUNTS: "Top accounts",
  TOTAL: "Total",
  TOTAL_HOURS: "Total hours",
  TOTAL_PAUSES: "Total pauses (minutes)",
  TOTAL_PAUSES_HOURS_ABRV: "Total pauses",
  TOTAL_RECORDS: "Total records",
  TOTAL_VALUE: "Total value",
  TOTALS: "Totals",
  TRANSACTION: "Transaction",
  TRANSFER: "Transfer",
  TRANSFER_OWNERSHIP: "Transfer ownership",
  TRANSFER_OWNERSHIP_DESCRIPTION:
    "Are you sure you want to transfer ownership of Workspace <b> {spaceName} </b> to {userName}?",
  TRANSFER_OWNERSHIP_SUBSCRIPTION_WARNING:
    "To transfer ownership, you need to cancel your subscription",
  TRANSFER_PROPERTY: "Transfer ownership",
  TRANSFER_PROPERTY_TITLE: "Transfer ownership of workspace",
  TRANSFER_PROPERTY_TITLE_DESCRIPTION:
    "Transferring this workspace to another user will cause you to lose owner privileges.",
  TRANSLATION_OR_LOCALIZATION: "Translation/Localization",
  TRANSPORTATION: "Transportation",
  TRAVEL: "Travel",
  TRAVEL_TIME: "Travel time (minutes)",
  TRAVEL_TIME_HOURS_ABRV: "Travel time",
  TRAVELS: "Travels",
  TREEVIEW_NO_CHILDREN: "No elements were created",
  TRIAL_ENDED: "Trial ended",
  TRIGGER_CONDITION: "Trigger condition",
  TRY_AGAIN: "Try again",
  TRY_AGAIN_IN_SOME_TIME: "Please try again in some time.",
  TUESDAY: "Tuesday",
  TWITTER_URL: "Twitter url",
  TYPE: "Type",
  TYPE_CONTRACT: "Type of contract",
  TYPE_CONTRACT_EQUALS: "Type of contract is equal to",
  TYPE_SUBSCRIPTION: "Type of subscription",
  TYPE_SUBSCRIPTION_EQUALS: "Type of subscription is equal to",
  TYPE_TO_SEARCH: "Type to search",
  TYPES: "Types",
  UNASSIGNED: "Unassigned",
  UNCLASSIFIED: "Unclassified",
  UNDERLINE: "Underline",
  UNDO: "Undo",
  UNFOLLOW: "Unfollow",
  UNIMPORTED_RECORDS: "Unimported records",
  UNIQUE_USAGE_PER_SPACE: "Single use per space",
  UNIT: "Unit",
  UNLIMITED: "Unlimited",
  UNMODIFIED_RECORDS: "Unmodified records",
  UNPIN: "Unpin",
  UNRESOLVED: "Unresolved",
  UNSPLASH_TOOLTIP: "Stockphoto Unsplash",
  UNSTICKY: "Unsticky",
  UPDATE: "Update",
  UPDATE_BILLING_DATA: "Update billing information",
  UPDATE_BILLING_INFO_SUCCESS: "Billing information updated successfully",
  UPDATE_CALL: "The user updated the call",
  UPDATE_CASE: "The user updated the case",
  UPDATE_DEAL: "The user updated the deal",
  UPDATE_DNS_DATA: "Update DNS data",
  UPDATE_NOTIFICATION_MSG: "Settings changed successfully",
  UPDATE_PASSWORD: "Update password",
  UPDATE_PAYMENT_METHOD_WARNING_PART_ONE: "Update your payment method,",
  UPDATE_PAYMENT_METHOD_WARNING_PART_TWO: "days for cancellation.",
  UPDATE_PAYMENT_SUCCESS: "Payment method updated successfully",
  UPDATE_TASK: "The user updated the Task",
  UPDATE_USER_PERMISSION_SUCCESS: "Permissions updated",
  UPDATED_RECORDS: "Updated records",
  UPDATING_USER_PERMISSION: "Permissions are being updated",
  UPGRADE_PLAN: "Upgrade",
  UPGRADE_TO_BASIC: "to Pro",
  UPGRADE_TO_COMPANY: "to Enterprise",
  UPGRADE_TO_PRO: "to Business",
  UPGRADE_TO_STARTER: "to Start",
  UPLOAD: "Upload",
  UPLOAD_IMAGE: "Upload picture",
  UPLOAD_LOGO: "Upload logo",
  UPLOAD_PHOTO: "Upload photo",
  URL: "Url",
  URL_BASE: "URL base",
  URL_LINK: "URL link",
  USE_DEFAULT: "Use default",
  USE_KEYBOARD: "Use the keyboard to navigate",
  USED: "consumed ",
  USED_COUNT: "No. of uses",
  USER: "User",
  USER_AVATAR: "Avatar",
  USER_COMPANY: "Company",
  USER_DEPARTMENTS: "Departments",
  USER_EMAIL: "Email",
  USER_EMPLOYEE_NUMBER: "Employee number",
  USER_EXNTENSION: "Phone extension",
  USER_FULL_NAME: "Full name",
  USER_NAME: "User name",
  USER_PERMISSION_ERROR_DESCRIPTION:
    "There was an error updating the permissions",
  USER_PERMISSION_ERROR_TITLE: "Permissions error",
  USER_PHONE: "Phone number",
  USER_ROLE: "Role",
  USER_SIGNATURE: "User signature",
  USER_TEAMS: "Teams",
  USERS: "Users",
  USERS_NUMBER: "Nº users",
  UTILITIES: "Utilities",
  VALIDATE_NIF: "Validate VAT",
  VALIDATED: "Validated",
  VALIDATION_COMPLETE: "Validation complete",
  VALIDITY: "Validity",
  VALUE: "Value",
  VALUE_HOUR: "Value per hour",
  VALUES: "Values",
  VARIABLE_SELCTION: "Variable selector",
  VENTURE_CAPITAL_OR_VC: "Venture capital/VC",
  VERSION: "Version",
  VERTICAL: "Vertical",
  VERY_BAD: "Very bad",
  VERY_GOOD: "Very good",
  VETERINARY: "Veterinary",
  VIDEOS: "Recordings",
  VIEW: "View",
  VIEW_ALL: "View all",
  VIEW_LOGS: "View logs",
  VIEWS: "Views",
  VISUALIZE: "Preview",
  WAITING_PAYMENT: "Awaiting payment",
  WAREHOUSING: "Warehousing",
  WARRANTY: "Warranty",
  WAS_CHANGED_TO: "has been changed to",
  WAS_UPDATED: "was updated",
  WE_APPRICIATE_YOUR_FEEDBACK:
    "Thanks for getting in touch with {CompanyName} recently! We’re always striving to improve and would love your feedback on the experience.",
  WE_APPRICIATE_YOUR_PATIANCE: "We appreciate your patience and support,",
  WEBHOOK: "Webhook",
  WEBSITE: "Website",
  WEBSITE_NAME_ERROR:
    "The website does not have a valid format (https://domain.com)",
  WEBSITE_SNIPPET: "Website snippet",
  WEDNESDAY: "Wednesday",
  WEED_LOVE_FEEDBACK: "We’d love your feedback",
  WEEK: "Week",
  WEEKDAY: "Week day",
  WEEKLY: "Weekly",
  WELCOME_ALL_READY: "All good! Go to dashboard",
  WELCOME_CLIENTS_CONTACTS_SUBTITLE: "Find out how to set up contacts",
  WELCOME_DEALS_CONTRACTS_SUBTITLE:
    "Find out how to set up the business module",
  WELCOME_FIRST_TITLE: "Store and use your business data",
  WELCOME_SECOND_TITLE: "Update and have more control over the business sector",
  WELCOME_SERVICES_SUBTITLE: "Find out how to set up services",
  WELCOME_THIRD_TITLE: "Start by using a whole range of services",
  WELCOME_USERNAME: "Welcome back",
  WELCOME_VIEW_LINK: "Welcome page",
  WHAT_DATES_TO_EXCLUDE: "What dates to esclude?",
  WHAT_TO_BE_NOTIFIED: "Notifications",
  WHERE: "Where",
  WHOLESALE: "Wholesale",
  WHOS_NEXT: "Who's next?!",
  WIDGET: "Widget",
  WIDGET_ALLOWED_DOMAINS_MSG:
    "The widget will only be allowed in these domains",
  WIDGET_BUTTON_CONFIGURATE: "Configure button",
  WIDGET_DOMAINS: "Widget will only be allowed in these domains",
  WIDGET_FEEDBACK_TICKET_CONFIGURATE: "Configure feedback - case assignment",
  WIDGET_FORM_CONFIG: "Configure subjects - case assignment",
  WIDGET_FORM_CONFIG_ERROR: "It is necessary atleast one case assignment",
  WIDGET_TAB_ERROR:
    "There are fields left to fill in the marked tabs. Check that you have filled in all the marked fields in all selected languages.",
  WIDGETS: "Widgets",
  WINE_OR_SPIRITS: "Wine/Spirits",
  WIRELESS: "Wireless",
  WITH_THE_SUBJECT: "with the subject",
  WON: "Won",
  WORK_HOURS: "Work schedule",
  WORKFLOW: "Workflow",
  WORKFLOW_CURRENTLY_RUNNING: "Automation is currently running",
  WORKING_IN: "Working in",
  WORKSPACE: "Select a workspace",
  WORKSPACE_NAME: "Workspace name",
  WORKSPACE_SUBDOMAIN: "Workspace domain",
  WORKSPACE_SUBDOMAIN_DESC:
    "You can customize the subdomain of your workspace. You can use your company's name for example. Once chosen it cannot be altered.",
  WORKSPACE_SUBSCRIPTION: "Workspace subscription",
  WORKSPACE_TEXT: "Workspace",
  WRITE_A_NEW_MESSAGE: "Write a new message",
  WRITE_MESSAGE_OR_DROP: "Write a new message and/or drop files here",
  WRITE_NOTE_OR_DROP: "Write a new note and/or drop files here",
  WRITE_YOUR_EMAIL: "Write a new email",
  WRITING_OR_EDITING: "Writing/Editing",
  YEAR: "Year",
  YEARLY: "Yearly",
  YES: "Yes",
  YESTERDAY: "Yesterday",
  YOUR_COMPANY_DATA: "Your company's data",
  YOUR_FEEDBACK: "Leave us a comment or testimonial",
  YOUTUBE_URL: "Youtube url",
  ZIP_CODE: "Zip codes",
  SUBSCRIPTION_UPDATED: "updated the subscription",
  DEAL_CREATED: "created the deal",
  SUBSCRIPTION_CREATED: "created the subscription",
  ACCOUNT_CREATED: "created the account",
  CONTRACT_CREATED: "created the contract",
  CASE_CREATED: "created the case",
  TASK_CREATED: "created the task",
  CALL_CREATED: "created the call",
  PROJECT_CREATED: "created the project",
  NOTIFICATION_TOPIC_CASES_DELETED_TITLE: "When a case is removed",
  NOTIFICATION_TOPIC_CASES_DELETED_DESCRIPTION:
    "Receive a notification each time a case is removed",
  TIMES_REPORTS: "Time report",
  RENOVATE: "Renew",
  ADD_TEMPLATE: "Save as template",
  USE_TEMPLATE: "Use template",
  NO_TEMPLATES: "No available templates",
  SAVE_FORM: "Save form as template",
  CHOOSE_A_TEMPLATE: "Choose a template",
  TEMPLATE_APPLIED: "Template applied",
  DATA: "Data",
  FINANCIAL_DATA: "Financial data",
  RENAME_VIEW: "Rename view",
  WARN_UNSAVED: "You have unsaved changes, are you sure you want to leave?",
  EXPORTS_REPORTS_DESCRIPTION:
    "See every export generated by each collaborator.",
  GIVEN_HOURS: "Time consumed ",
  SESSION_CLOSED_SUCCESS: "Session ended successfully",
  THE_DEAL_STATUS: "The state of the deal",
  THE_CONTRACT_STATUS: "The state of the contract",
  THE_CALL_STATUS: "The state of the call",
  THE_SUBSCRIPTION_STATUS: "The state of the subscription",
  TIMECHARGE_DELETED: "deleted the time record",
  TIMECHARGE_CREATED: "created the time record",
  TIMECHARGE_UPDATED: "updated the time record",
  BOOLEAN: "True or false",
  STRING: "Text",
  NUMBER: "Number",
  CONFIRM_UNSUB_TITLE:
    "Are you sure you want to disable notifications for this item?",
  CONFIRM_UNSUB_DESCRIPTION:
    "If you click on 'Confirm', you will lose access to the conversations and associated files, but you will still have access to the details.",
  UPDATE_USER_ERROR_COMPANY_MANAGER:
    "The user is managing atleast one removed company. Please change the company manager before removing from the company.",
  UPDATE_USER_ERROR_TEAM_MANAGER:
    "The user is managing atleast one removed team. Please change the team manager before removing from the team.",
  UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "The user is managing atleast one removed department. Please change the department manager before removing from the department.",
  TEST_JSON: "Test JSON",
  EMAIL_CONVERSATION_CREATED:
    "A new email conversation was created with the subject ",
  EMAIL_CONVERSATION_UPDATE: "updated the email conversation with the subject ",
  WARNING: "Warning",
  ITEM_DELETED_WARNING: "This item was deleted.",
  SIDEBAR_CONFIRM_EXIT:
    "The changes you made will be lost. Are you sure you want to exit?",
  VIEW_GRID: "Grid view",
  VIEW_CALENDAR: "Calendar view",
  VIEW_TIMELINE: "Timeline view",
  VIEW_BOARD: "Kanban view",
  VIEW_CARDS: "Card view",
  SHARE_WITH: "Share with",
  SEND_NOTIFICATION_TO: "Send notifications to",
  CLIENT_NOT_FOUND_OR_INVALID: "Account is invalid or not found",
  CLIENT_NOT_IN_SPACE: "Account not found",
  CLIENT_IS_DISABLED: "Account disabled",
  PROJECT_NOT_FOUND_OR_INVALID: "Project is invalid or not found",
  PROJECT_NOT_IN_SPACE: "Project not found",
  PROJECT_MUST_BE_OPEN: "Project must be open",
  TICKET_NOT_FOUND_OR_INVALID: "Case is invalid or not found",
  TICKET_NOT_IN_SPACE: "Case not found",
  TICKET_MUST_BE_OPEN: "Case must be open",
  CALL_NOT_FOUND_OR_INVALID: "Call is invalid or not found",
  CALL_NOT_IN_SPACE: "Call not found",
  CALL_MUST_BE_OPEN: "Call must be open",
  TASK_NOT_FOUND_OR_INVALID: "Task is invalid or not found",
  TASK_NOT_IN_SPACE: "Task not found",
  TASK_MUST_BE_OPEN: "Task must be open",
  CONTRACT_NOT_FOUND_OR_INVALID: "Contract is invalid or not found",
  CONTRACT_NOT_IN_SPACE: "Contract not found",
  CONTRACT_MUST_BE_OPEN: "Contract must be open",
  DEAL_NOT_FOUND_OR_INVALID: "Deal is invalid or not found",
  DEAL_NOT_IN_SPACE: "Deal not found",
  DEAL_MUST_BE_OPEN_WON: "Deal must be open",
  ORIGIN_IS_REQUIRED: "Origin is required",
  CLIENT_INVALID: "Account is invalid",
  DEAL_INVALID: "Deal is invalid",
  PROJECT_INVALID: "Project is invalid",
  TICKET_INVALID: "Case is invalid",
  CALL_INVALID: "Call is invalid",
  TASK_INVALID: "Task is invalid",
  CONTRACT_INVALID: "Contract is invalid",
  WORKSPACE_LABEL: "Workspace",
  ADD_ANOTHER_SCHEDULE: "Add another schedule",
  WORKSPACES: "Workspaces",
  VIEW_ALL_ITEMS: "View all",
  ERROR_GETTING_GLOBAL_PERMISSION: "Error getting base permissions",
  DISK_LIMIT_INVALID: "Invalid partition size",
  ERROR_DELETED_ACCOUNT_UNIQUE_PERMISSION: "Error deleting account permissions",
  ERROR_UPDATING_ACCOUNT_UNIQUE_PERMISSION:
    "Error updating account permissions",
  ERROR_GETTING_GLOBAL_CLIENTS: "Error fetching space accounts",
  ERROR_DELETED_ALL_CLIENTS_IN_SPACE: "Error deleting space accounts",
  ERROR_UPDATING_ALL_CLIENTS_IN_SPACE: "Error updating space accounts",
  ERROR_GETTING_GLOBAL_DEALS: "Error fetching deals from space",
  ERROR_DELETED_ALL_DEALS_IN_SPACE: "Error deleting deals from space",
  ERROR_UPDATING_ALL_DEALS_IN_SPACE: "Error updating space deals",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_HORIZONTAL:
    "Pssst, try here the new vertical menu and you won't regret it! 🤫",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_VERTICAL:
    "Did you notice something different? Great! We've improved the side menu 🥳",
  DOCUMENT_TYPES: "Document types",
  EMPTY_DOCUMENT_TYPES: "There are no associated document types.",
  DOCUMENT_TYPE: "Document type",
  ADD_DOCUMENT_TYPE: "Add document type",
  REVIEW: "In review",
  FAILURE: "Failed",
  PUBLIC_DOCUMENT_TITLE: "Public document",
  PUBLIC_DOCUMENT_DESCRIPTION:
    "Everyone, in this workspace, can view this document",
  SOCIAL_POST: "Publication",
  FONT_STYLE: "Font Style",
  PAGE_MARGIN: "Page Margins",
  SMALL: "Small",
  NORMAL: "Normal",
  LARGE: "Large",
  TOPICS: "Topics",
  DATA_TYPE_TITLE: "Data type",
  DATA_TYPE_DESCRIPTION: "Choose one of the following ",
  DATA_PARAMETER_TITLE: "Parameters",
  DATA_PARAMETER_DESCRIPTION: "Choose wich parameters you want to import",
  CHECK_ALL: "All",
  DATA_FILTER_TITLE: "Filters & sorting",
  DATA_FILTER_DESCRIPTION: "Choose the filters you want to apply",
  IMPORT_DATA: "Import data",
  STATUS_REASON: "Status reason",
  STATUS_REASON_DESCRIPTION: "Status reason description",
  ADD_FILTERS: "Add filters",
  EVERY: "Every",
  INDIVIDUAL: "Individual",
  LOST_CANCELLED_REASON: "Lost/Cancelation reason",
  LOST_CANCELLED_REASON_DESCRIPTION: "Lost/Cancelation description",
  CANCELLED_REASON: "Cancelation reason",
  CANCELLED_REASON_DESCRIPTION: "Cancelation description",
  SPENT_TIME: "Time consumed ",
  ADD_DOCUMENT: "Add document",
  TIME_SPENT_BEFORE_CONTRACT: "Time consumed before contract start",
  TIME_SPENT_AFTER_CONTRACT: "Time consumed after contract end",
  TIME_SPENT_OUTSIDE_CONTRACT: "Time consumed outside contract",
  ADD_FILTERS_AND_SORTING: "Add filters & sorting",
  VIEW_APPLIED_OPTIONS: "View applied options",
  ITEMS: "Items",
  RENOVATED: "Renewed",
  PAGE_BACKGROUND: "Background",
  EMAIL_CONVERSATION_NEW_EMAIL:
    "The email conversation with the subject {EmailSub} has received a new email.",
  CREATE_DOCUMENT: "Create Document",
  MISSING_RECORDS: "Missing Time Records",
  DOCUMENT: "Document",
  LAST_UPDATE_DATE: "Last update date",
  LAST_UPDATE_BY: "Last update by",
  CREATE_DOCUMENT_TEMPLATE: "Create document template",
  ADD_DOC_TEMPLATE: "Add document template",
  API_TYPE_INVALID: "The item type is invalid",
  API_NAME_REQUIRED: "Name is required",
  API_ASSOCIATE_TO_INVALID: "Association is invalid",
  API_ORGANIZATION_INVALID: "Organization is invalid",
  API_PIPELINE_INVALID: "Pipeline is invalid",
  API_PIPELINESTATUS_INVALID: "Pipeline status is invalid",
  API_VARIABLE_INVALID: "Variable is invalid",
  API_ASSIGNED_TO_INVALID: "Assigned to is invalid",
  API_INVALID_REQUEST_OR_REQUEST_LOCKED:
    "The request is invalid or another user is currently editing",
  API_NOT_FOUND_OR_NO_PERMISSIONS:
    "The item does not exist or you dont have permission",
  API_NOT_FOUND_OR_NO_PERMISSIONS_DELETE:
    "The item does not exist or you dont have permission to delete",
  API_FAILED_GETTING_TEMPLATE: "Can not find the given template",
  VARIABLES: "Variables",
  EMPTY: "Empty",
  VARIABLES_DESCRIPTION: "Variable Description",
  DOCUMENT_SAVE_SUCCESS: "Document saved successfully",
  TEMPLATE_SAVE_SUCCESS: "Document template saved successfully",
  EMAIL_NO_SUBJECT: "No Subject",
  API_EMAIL_SUBJECT_INVALID: "Email subject is required",
  CUSTOM_TAGS: "Custom tags",
  SUBSCRIPTION_IS: "Subscription is",
  LAST_CHANGE_DATE: "Last change date",
  DOCUMENT_TYPE_EQUALS: "Document type is equal",
  DOC_TEMPLATE: "Document template",
  DOC_TEMPLATE_NR: "Document template Nº",
  NO_DOC_TEMPLATES_LIST: "There are no associated document template records.",
  EMPTY_DOC_TEMPLATES_LIST: "No document templates",
  BI_NUMBER: "ID Number",
  BI_LOCALE: "Issuing Location",
  BI_DATE: "Issue Date",
  BI_EXPIRATION: "Expiration Date",
  NISS: "SSN",
  EMPTY_CATEGORIES_LIST: "No user categories",
  NO_CATEGORIES_LIST: "There are no associated user categories records.",
  EMPTY_INVITES_LIST: "No user invites",
  NO_INVITES_LIST: "There are no associated user invites records.",
  CATEGORIES: "Categories",
  DEFAULT_HOUR_COST: "Default cost per hour",
  ADD_CATEGORY: "Add Category",
  CODEERP: "ERP Code",
  API_NIF_INVALID: "Vat number Invalid",
  API_NISS_INVALID: "NISS Invalid",
  API_COUNTRY_INVALID: "Country Invalid",
  PRINT: "Print",
  API_CATEGORY_INVALID: "Category Invalid",
  API_UPDATE_USER_ERROR_COMPANY_MANAGER:
    "Remove the user from the company managers first",
  API_UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "Remove the user from the department managers first",
  API_UPDATE_USER_ERROR_TEAM_MANAGER:
    "Remove the user from the team managers first",
  API_ORGANIZATION_DEPARMENT_TEAM_INVALID: "Company/Department/Team Invalid",
  API_EXPIRATIONDATE_INVALID: "Document expiration data invalid",
  API_ISSUEDATE_INVALID: "Document issue data invalid",
  API_SOCIALSECURITY_TOLARGE: "NISS Invalid -  Too large",
  API_NIF_TOLARGE: "NIF Invalid -  Too large",
  API_BILOCALE_TOLARGE: "BI Issue Location - Too large",
  API_BINUMBER_TOLARGE: "BI card number - Too large",
  API_CODE_TOLARGE: "Code ERP - Too large",
  API_INVALID_REQUEST: "Invalid Request",
  API_EMAIL_REQUIRED: "Email is required",
  API_NAME_MAX_50: "User name too large",
  API_EMAIL_INVALID: "Email Invalid",
  API_USER_ALREADY_IN_SPACE: "User alredy exists in space",
  API_INVALID_INVITE_HAS_BLOCKED_USER: "The user is blocked",
  API_DEPARMENT_INVALID: "Department Invalid",
  API_TEAM_INVALID: "Team Invalid",
  API_SPACE_INVALID_OR_DELETED: "Space Invalid or Deleted",
  API_INVITATION_FULL: "Invites list is full",
  API_ERROR_SEND_EMAIL: "An error ocurred while sending the email",
  API_INVITATION_NOT_FOUND: "Invitaion not found or deleted",
  EMPLOYEE_PERSONAL_INFORMATION: "Employee Personal Information",
  SOCIAL_SECURITY: "Social Security Number",
  API_STANDARDCOST_TOLARGE: "Standart cost - Too large",
  API_ORGANIZATION_REQUIRED: "Company is required",
  PRIVATE_DATA: "Private Data",
  API_INVITATION_INVALID: "Invite invalid",
  JOINED_THE_SPACE: "Successfully joined the space ",
  CITIZEN_CARD: "Citizen Card",
  PASSPORT: "Passport",
  DOCUMENT_STYLE: "Styles",
  BIRTHDAY_FROM: "From",
  BIRTHDAY_TO: "to",
  EDITOR_IS_CLOSING_WARNING:
    "We did not detect changes to this document in the last 10 minutes. The document will close.",
  EDITOR_REFUSE_CLOSE: "Continue editing",
  DOCUMENT_VERSIONS: "Version history",
  CONFIRM_RESTORE_VERSION_QUESTION:
    "Your current document will be reverted to {Date}",
  RESTORE: "Restore",
  RESTORE_THIS_VERSION: "Restore this version",
  ORIGIN_DATA: "Origin data",
  API_POSTALWEBHOOK_NOT_FOUND: "Postal Webhook record not found",
  API_INVALID_POSTAL_MODEL: "Invalid Postal model",
  API_POSTALWEBHOOK_CAN_NOT_RETRY: "Can not be recover",
  IS_EDITING: "{Name} is editing",
  API_INVALID_REQUEST_YOUR_SESSION_EXPIRED: "Your edit session expired",
  COPY_URL: "Copy URL",
  API_NOT_FOUND_OR_NO_PERMISSION: "Not found or requires permission",
  DOCUMENT_IS_BEING_EDITED: "This document is alredy being edited",
  SELECT_DOC_TEMPLATE: "Select document template",
  NUMBER_TEMPLATES: "{num} templates",
  BLANK_DOCUMENT: "Blank Document",
  CREATED_SUCCEFULLY: "Created successfully",
  NO_DOCUMENTS_LIST: "Document list empty",
  EMPTY_DOCUMENTS_LIST: "There are no records of associated documents",
  KNOWLEDGE_BASE: "Knowledge base",
  BILLABLE: "Billable",
  ACTIVE_CONTRACTS: "Active Contracts",
  CONCLUDED_CONTRACTS: "Concluded Contracts",
  ACTIVE_CONTRACTS_PROGRESS: "Active Contracts Progress",
  TEMPLATE_CONFIRM_MESSAGE:
    "The changes you made will be replaced. Are you sure you want to apply this template?",
  THIS_MONTH: "This month",
  ALLOW_FUTURE: "Allow future dates",
  TASK_TYPE: "Task Type",
  TASK_TYPES: "Task Types",
  ADD_TASK_TYPE: "Add task type",
  EDIT_TASK_TYPE: "Edit task type",
  TASK_TYPES_LIST_EMPTY: "Empty task type listing.",
  TASK_TYPES_LIST_EMPTY_DESCRIPTION:
    "There are no associated task type records.",
  ELIMINATE_DOCUMENT_TYPE: "Do you want to delete this document type?",
  API_FAILED_LIST: "An error occurred while getting the requested list",
  API_REPEATED_RECORDS: "Error - Repeated Records",
  API_INVALID_TYPE: "Error - Invalid Type",
  API_FAILED_GETTING_DETAILS: "An error occurred while obtaining the details",
  API_INVALID_COMPANY: "Error - Invalid Company",
  INVALID_DEPARTMENT: "Error - Invalid Department",
  API_INVALID_TEAM: "Error - Invalid Team",
  API_DOCUMENT_TYPE_NOT_FOUND: "This document type was not found",
  API_STATUS_INVALID: "Error - Invalid status",
  API_COLLABORATOR_INVALID: "Error - Invalid Collaborator",
  API_FOLLOWERS_INVALID: "Error - Invalid follower",
  API_TEMPLATE_TYPE_INVALID: "Error - Invalid template type",
  API_NAME_TOLARGE: "Name too big",
  API_DESCRIPTION_TOLARGE: "Description too big",
  API_CLIENT_INVALID: "Error - Invalid client",
  API_DEAL_INVALID: "Error - Invalid deal",
  API_CONTRACT_INVALID: "Error - Invalid contract",
  API_SUBSCRIPTION_INVALID: "Error - Invalid Subscription",
  API_TICKET_INVALID: "Error - Invalid Case",
  API_PROJECT_INVALID: "Error - Invalid Project",
  API_TASK_INVALID: "Error - Invalid Task",
  API_TIMECHARGE_INVALID: "Error - Invalid Intervention",
  API_INVALID_ASSOCIATION: "Invalid Associated Service",
  API_FAIL_UPLOAD_FILES: "An error occurred while uploading one or more files",
  API_DOCUMENT_TYPE_REQUIRED: "Document Type Missing",
  TASK_TYPE_EQUALS: "Task Type is equal to",
  API_CAN_NOT_REMOVE_STATUS:
    "It is not possible to remove a pipeline state that is already in use",
  API_CAN_NOT_CHANGE_STATUS:
    "It is not possible to change the state of a pipeline state that is already in use",
  API_INVALID_PIPELINE_STATUS: "Error - Invalid Change of State",
  API_PIPELINE_STATUS_INVALID: "Error - Invalid Pipeline State",
  PIPELINE_STATUS_CHANGE_DATE: "Date the pipeline state changed",
  STATUS_CHANGE_DATE: "Date the state changed",
  LAST_PIPELINE_STATUS_ACCOUNT: "Who changed the last pipeline state ",
  LAST_PIPELINE_STATUS_DATE: "Date the pipeline state last changed",
  LAST_STATUS_ACCOUNT: "Who made the last change of state",
  LAST_STATUS_DATE: "Date the state last changed",
  FILTER_NOT_NEXT_X_DAYS_PREVIEW: "Not in the next {days} days of {field}",
  FILTER_NOT_LAST_X_DAYS_PREVIEW: "not in the last {days} days of {field}",
  FILTER_NOT_NEXT_DAYS: "not in the next days",
  FILTER_NOT_LAST_DAYS: "not in the last days",
  FILTER_LAST_DAYS: "in the last days",
  FILTER_NEXT_DAYS: "in the next days",
  API_TIMECHARGE_TYPE_INVALID: "Error - Invalid Type of time",
  DENIED_WARNING_NOTIFICATIONS:
    "We detected that your browser is blocking notifications.",
  WARNING_SEND_MAIL_MESSAGE:
    "This report will only be sent if the contract has an active contact with an associated email.",
  API_INVALID_EXPORT_TYPE: "You have to select an export type.",
  API_CONTRACT_NOT_FOUND: "Contract not found",
  API_CONTRACT_HISTORY_ERROR:
    "An error occurred while retrieving the information for the report",
  API_CONTRACT_CLIENT_ERROR:
    "Unable to find the customer related to this contract",
  API_ASSOCIATED_COMPANY: "Unable to find an associated company",
  API_ERROR_READ_PDF_CONTENT: "Error attaching PDF to email",
  API_ERROR_SENT_EMAIL: "An error occurred while sending the email.",
  API_CONTACT_ASSOCIATED_WITH_EMAIL:
    "There is no associated contact with email",
  API_CONTACT_ASSOCIATED_INVALID: "Invalid affiliate contact",
  API_PIPELINE_TYPE_INVALID: "Pipeline type is invalid",
  API_EXIST_INVALID_TICKETS: "One or more invalid cases exist",
  API_INVALID_REQUEST_MAX_25: "Cannot export more than 25 elements",
  API_INVALID_DATA_REPORT: "Error obtaining data for the report",
  API_CREATE_PDF_REPORT:
    "An error occurred when creating the attachment for the email",
  COST_CENTER: "Cost Center",
  EMPTY_COST_CENTER_LIST: "There are no cost center records.",
  NO_COST_CENTER_LIST: "No cost center available",
  ADD_COST_CENTER: "Add cost center",
  COST_CENTERS: "Cost centers",
  PRODUCTIVITY_REPORT: "Productivity Report",
  PRODUCTIVITY: "Productivity",
  PRODUCTIVITY_DESCRIPTION:
    "See the time totals classified by your employees grouped by cost centers.",
  PROD_AVERAGE: "Average",
  API_INVALID_ACCOUNT: "One or more invalid users exist",
  API_COST_CENTER_REQUIRED: "Cost Center Required",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EXPORT:
    "The desired content was not found or you do not have export permission",
  PRODUCTION_TOTAL_TIME: "Total Hours ",
  PRODUCTION_AVERAGE_TIMES: "Average Hours",
  PRODUCTION_TOTAL_TRAVEL: "Total Travels",
  PRODUCTION_AVERAGE_TRAVEL: "Average Travels",
  PRODUCTION_AVERAGE_FINAL: "Average Final",
  PRODUCTION_TOTAL_TIME_ABV: "Total H.",
  PRODUCTION_AVERAGE_TIMES_ABV: "Average H.",
  PRODUCTION_TOTAL_TRAVEL_ABV: "Total T.",
  PRODUCTION_AVERAGE_TRAVEL_ABV: "Average T.",
  PRODUCTION_AVERAGE_FINAL_ABV: "Average F.",
  TRAVEL_TIMES: "Travel records",
  TRAVEL_TIMES_DESCRIPTION: "See all the time entries that contain travels.",
  DESLOCATION_CONTRACT: "Includes travels",
  NEW_ITEMS_ADDDED_OR_UPDATED: "New items were added/edited",
  DESCRIPTION_TOO_BIG:
    "Content of the description field is too large. Please reduce the size of the text/image. If you have images, please attach them as attachments.",
  INTERNAL_DESCRIPTION_TOO_BIG:
    "Content of internal description field too large. Please reduce size of text/image. If you have images, please put it as an attachment.",
  TICKET_LIMIT: "Case Limit",
  TICKETS_ASSIGNED: "Associated cases",
  SUPPORT_EMAIL: "Support Email",
  CONTINUE_TO_WEBSITE: "Continue to browser page",
  ANY_SITUATION: "Any situation",
  INBOX_EMAIL_SENT_STATUS_DELAYED: "The email delivery has been delayed.",
  API_INVALID_TAGS: "There is one or multiple invalid tags.",
  API_REPEATED_TAG: "A marker with that name already exists",
  API_EMAIL_SUPPORT_EMAIL_INVALID: "Can't send email to support email",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_TO:
    "Support email is not allowed in to",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_CC:
    "Support email is not allowed in CC",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_BCC:
    "Support email is not allowed in BCC",
  CASE_TAGS: "Case Tags",
  API_FAILED_CONNECTION: "Failed to connect to the service",
  API_FAILED_CONNECTION_TO_EXPORT_PDF:
    "Unable to connect to the PDF export service. Try again later.",
  TAGS: "Tags",
  TAG: "Tag",
  CONVERSATION_REPLIED: "replied in the email conversation with the subject ",
  ADD_CASE_TAG: "Add case tag",
  EDIT_CASE_TAG: "Edit case tag",
  CASE_TAG: "Case Tag",
  EMAIL_CONVERSATION: "Email Conversation",
  CASE_CONVERSATIONS: "Case Conversations",
  CONVERSATION_NOTE_CREATED:
    "added a note in the email conversation with the subject",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_TITLE:
    "When a new internal note is added to the email conversation",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_DESCRIPTION:
    "Receive a notification each time a new internal message is associated with the conversation",
  THE_EMAIL_CONVERSATION_STATUS: "The state of the email conversation",
  CASE_TAGS_LIST_EMPTY: "Empty case tag listings.",
  CASE_TAGS_LIST_NO: "There are no associated case tag records.",
  API_FAILED_GENERATING_PDF:
    "An error occured while generation your PDF. Please try again later.",
  EMAIL_CONVERSATION_EXPORT: "Export email conversation pdf",
  GET_EXPORT_FILE_BY_ID: "Get export file by Id",
  COMPLEMENTED_TIME: "Complementary Time",
  BUDGET_TIME: "Time Budget",
  NOTE_CREATED_SUCCEFULLY: "Note created successfully",
  API_ERROR_DUPLICATE_INBOUND_EMAIL:
    "There is already an inbound email with this alias for this company",
  PERSONAL_PRODUCTIVITY_DESCRIPTION: "See your recorded classified times.",
  EMAIL_SIZE_TOO_BIG:
    "The size of your e-mail is too large. Please reduce the size of the text/images/annexes",
  EXTERNAL_USER: "External User",
  API_TAG_INVALID_DISABLED_INBOUND_EMAIL:
    "You can not disable a tag that is associated with a inbound email inbox",
  PLAN_PUBLIC_API: "Public API",
  API_DATE_INVALID_INTERVAL: "Dates are in the wrong range",
  API_INVALID_MODEL: "Invalid model",
  API_ERROR_NO_DATA: "Failed processing data",
  API_FAILED_GENERATING_EXCEL: "Failed to generate excel",
  HTTP_STATUS_REQUEST: "Http status request",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_TITLE:
    "When an email is sent or received in an email conversation that you have previously participated in.",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_DESCRIPTION:
    "You receive a notification each time an email or internal note is associated with an email conversation you have participated in.",
  EMAIL_CONVERSATION_EMAIL_PERSONAL_RESPONSE: "Receive Response Notification",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION:
    "The email conversation, in which you <b>participated</b>, with the subject <b>{subject}</b> has been updated.",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_TITLE:
    "When an email associated with an email conversation is received",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_DESCRIPTION:
    "Receive a notification each time an email associated with an email conversation is received.",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_TITLE:
    "When an email conversation is modified",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_DESCRIPTION:
    "Receive a notification each time an email conversation is changed.",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION_FROM_OUTSIDE:
    "The email conversation, in which you <b>participated</b>, with the subject <b>{subject}</b> has received a new email.",
  CONVERSATION_REPLIED_PARTICIPATION:
    "replied in the email conversation, in which you <b>participated</b>, with the subject <b>{subject}</b>",
  API_ERROR_EMAIL_REFUSED:
    "Email sent successfully, but the destination server refused it.",
  API_CLIENT_DELETED: "Client deleted",
  API_ATTACHMENTS_TOLARGE: "The attachment size exceeds the allowable limit",
  DEAL_TAGS_LIST_EMPTY: "Empty deal tag listings.",
  DEAL_TAGS_LIST_NO: "There are no associated deal tag records.",
  DEAL_TAG: "Deal Tag",
  ADD_DEAL_TAG: "Add deal tag",
  EDIT_DEAL_TAG: "Edit deal tag",
  ACCOUNT_TAGS_LIST_EMPTY: "Empty account tag listings.",
  ACCOUNT_TAGS_LIST_NO: "There are no associated account tag records.",
  ACCOUNT_TAG: "Account Tag",
  ADD_ACCOUNT_TAG: "Add account tag",
  EDIT_ACCOUNT_TAG: "Edit account tag",
  DEAL_TAGS: "Deal Tags",
  ACCOUNT_TAGS: "Account Tags",
  INVALID_TIME_VALUE_FOR_RENOVATION:
    "Invalid value for the time value of contract renovation",
  INVALID_TIME_VALUE_FOR_HOUR_CONTROL:
    "Invalid value for the time value of the hour interval renovation",
  API_CLIENT_REQUIRED: "Account required",
  CONTRACT_TAG: "Contract tag",
  ADD_CONTRACT_TAG: "Add Contract Tag",
  EDIT_CONTRACT_TAG: "Edit Contract Tag",
  CONTRACT_TAGS: "Contract Tags",
  CONTRACT_TAGS_LIST_EMPTY: "Empty contract tag listings.",
  CONTRACT_TAGS_LIST_NO: "There are no associated contract tag records.",
  SUBSCRIPTION_TAGS: "Subscription Tags",
  SUBSCRIPTION_TAG: "Subscription Tag",
  ADD_SUBSCRIPTION_TAGS: "Add Subscription Tag",
  EDIT_SUBSCRIPTION_TAGS: "Edit Subscription Tag",
  SUBSCRIPTION_TAGS_LIST_EMPTY: "Empty subscription tag listings.",
  SUBSCRIPTION_TAGS_LIST_NO:
    "There are no associated subscription tag records.",
  CALL_TAGS: "Call Tags",
  CALL_TAG: "Call Tag",
  ADD_CALL_TAG: "Add Call Tag",
  EDIT_CALL_TAG: "Edit Call Tag",
  CALL_TAGS_LIST_EMPTY: "Empty call tag listings.",
  CALL_TAGS_LIST_NO: "There are no associated call tag records.",
  PROJECT_TAGS: "Project Tags",
  PROJECT_TAG: "Project Tag",
  ADD_PROJECT_TAG: "Add Project Tag",
  EDIT_PROJECT_TAG: "Edit Project Tag",
  PROJECT_TAGS_LIST_EMPTY: "Empty project tag listings.",
  PROJECT_TAGS_LIST_NO: "There are no associated project tag records.",
  TASK_TAGS: "Task Tags",
  TASK_TAG: "Task Tag",
  ADD_TASK_TAG: "Add Task Tag",
  EDIT_TASK_TAG: "Edit Task Tag",
  TASK_TAGS_LIST_EMPTY: "Empty task tag listings.",
  TASK_TAGS_LIST_NO: "There are no associated task tag records.",
  API_TICKET_MANAGER_INVALID:
    "The user 'Assigned to' does not belong to the indicated 'Department/Team'.",
  CANCELLED_CASES: "Cancelled Tickets",
  CREATE_CONVERSATION: "Create Email Conversation",
  SEND_NOTIFICATION_WARNING:
    "Do you want to notify the Client about the status of this case?",
  SEND_DISABLED_EMAIL_MESSAGE: "Inactive airbot email",
  API_TICKET_EMAIL_REPORT_SENT_SUCCESS:
    "Status report sent via email successfully",
  API_TICKET_EMAIL_REPORT_SENT_ERROR:
    "An error occurred sending the status report via email",
  API_TICKET_EMAIL_REPORT_STARTED:
    "The request has been successfully initiated. You will be notified when it has finished.",
  API_COMPANY_NOT_FOUND: "Company not found.",
  API_DEPARTMENT_NOT_FOUND: "Department not found",
  HAS_SUPPORT_EMAIL: "Has support email",
  DOES_NOT_HAVE_SUPPORT_EMAIL: "No support email",
  API_STATUS_NAME_REQUIRED: "Name of the state is mandatory.",
  API_STATUS_REQUIRED: "Entering a state is mandatory.",
  API_PIPELINE_NAME_REQUIRED: "Entering the Pipeline name is mandatory.",
  API_FEEDBACK_STATUS_INVALID: "Invalid state to activate feedback.",
  API_EMAIL_TO_LARGE: "Email content exceeded the limit",
  API_STATUS_DETAIL_INVALID: "Cancellation reason invalid",
  API_STATUS_DETAIL_DESCRIPTION_REQUIRED:
    "Description of missing cancellation reason",
  CHANGE_TAGS: "Change Tags",
  REMOVE_TAGS: "Remove Tags",
  CLIENTS_NO_PERMISSION:
    "have not been changed due to not having the necessary permission",
  DEALS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  CONTRACTS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  SUBSCRIPTIONS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  CALL_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  TICKETS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  PROJECTS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  TASKS_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  TIMES_NO_PERMISSION:
    "have not been changed due to not having the required permission",
  API_PIPELINE_DISABLED: "Pipeline disabled",
  REPORT_EMAIL: "Report email",
  CONTRACT_SIGNED: "Contract Signed",
  SIGNED: "Signed",
  IS_LOCKED: "Is Locked",
  FAILED_PASSWORD_ATTEMPTS: "Failed password attempts",
  UNLOCK: "Unlock",
  NOTIFICATION_SUCCESS: "Notification sent successfully",
  ASK_NOTIFICATION_CASE: "Would you like to notify the contact of this case?",
  API_PERIOD_NO_CONTENT:
    "There is no information on markers for the selected period",
  SEND_CONTRACT_RENOVATION_WARNING:
    "Would you like to notify the Customer by email that this contract has been renewed?",
  SEND_CONTRACT_RENOVATION_BUTTON: "Send Renewal Notice",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_ERROR:
    "There was an error sending the renewal email for this contract",
  API_CONTRACT_NEED_EMAIL_CONTACT:
    "The contract must have a valid contact email address.",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_SUCCESS:
    "Contract renewal notice sent successfully",
  EMAIL_SENT: "Email sent",
  API_CONTRACT_NEED_OPV: "The contract must have an OPV"
};
