import React, { useContext, useMemo } from "react";
import { useFormState, createFormRequestHook } from "../../Components/Forms";

import {
  spaceInterventionsSchema,
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceClientSchema,
  spaceTeamSchema,
  spaceContractSchema,
  spaceOrganizationSchema,
  spaceDealsSchema
} from "../../config/schema";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import {
  SideForm,
  SideFormContent,
  SideFormFooter
} from "../../Components/Forms/SideMenuForm";
import FormInput from "../../Components/Forms/FormInput/FormInput";
import { ConnectedBreadcrumbs } from "../../Components/Breadcrumbs/Breadcrumbs";
import {
  InterventionTargetDropdown,
  InterventionTypeDropdown,
  OrganizationDropdown,
  ContactDropdown,
  IneficientTDropdown,
  UserDropdown
} from "../AdvancedMultiInputs";
import { injectIntl, FormattedMessage } from "react-intl";
// import SplitCol from "../../Components/SplitCol/SplitCol";
import ArDatePicker from "../../Components/DatePicker/ArDatePicker";
import MinuteInput from "../../Components/Input/MinuteInput";
import FileUploader from "../../Components/FileUploader/FileUploader";
import ClipboardProvider from "../../Components/ClipbordProvider/ClipboardProvider";
import { canUpdateEntity, ConclusionForm } from "../ChildForms/ChildForms";
import {
  useInterventionCommunicator,
  INTERVENTION_CREATED,
  convertInterventionEntityToForm,
  validateInterventionFormNoMinDescript,
  validateInterventionFormNoMinDescriptNoTravel,
  getTotalInterventionDuration,
  INTERVENTION_UPDATED
} from "./InterventionsHelper";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import { useIntervention, useInterventionType } from "../../Hooks/EntityHooks";
import {
  InterventionPlaceTypeDropdown,
  InterventionPlaceTypes
} from "../../Components/Interventions/Interventions";
import FormattedTimePreview from "../../Components/FormattedTimePreview/FormattedTimePreview";
import { useToast } from "../../Components/Toast/ToastProvider";
// import AdminCheckDatePicker from "../../Components/AdminCheckDatePicker/AdminCheckDatePicker";
import { originTypes, handleError } from "../../Helpers/MiscHelper";
import { useCurrentAccount } from "../../Contexts/UserContext";
import AddressSelector from "../../Components/AddressSelector/AddressSelector";
import HTMLInput from "../../Components/HTMLInput/HTMLInput";
import {
  useSidebarSpaceDelete,
  useSidebarSpacePost
} from "../../Components/Sidebar/SidebarV2Helper";
import CreatedBy from "../../Components/CreatedByComponent/CreatedBy";
import {
  isOrganzationRequestValid,
  OrganizationRequestSideError
} from "../../Components/Organizations/OrganizationHelper";
import {
  formatInterventionForm,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import FileAssociations from "../../Components/FileUploader/FileAssociations";
import { SidebarAutomationContext } from "../../Components/Sidebar/SidebarV2Automation";
import DocTyper from "../../Components/DocTyper/DocTyper";
import {
  FormTemplateProvider,
  useFormTemplateGetter
} from "../../Components/Forms/FormTemplatesHelper";
import { useSpace } from "../../Contexts/SpaceContext";

const getBeginDate = () => {
  const ReceivedDate = new Date();
  ReceivedDate.setSeconds(0);
  ReceivedDate.setMilliseconds(0);
  return ReceivedDate;
};

const getEndDate = () => {
  const ReceivedDate = new Date();
  ReceivedDate.setSeconds(0);
  ReceivedDate.setMilliseconds(0);
  ReceivedDate.setHours(ReceivedDate.getHours() + 1);
  return ReceivedDate;
};

const useInterventionRequest = createFormRequestHook({
  schema: spaceInterventionsSchema,
  dependencies: [
    {
      schema: spaceCallsSchema,
      getEntityId: ({ callId }) => callId,
      convertEntityToForm: (call) => {
        const { Duration, Id, Contact } = call;

        const InterventionTarget = {
          type: "Call",
          data: Id
        };

        return {
          Contact: Contact,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate(),
          Duration: Duration
        };
      }
    },
    {
      schema: spaceTicketsSchema,
      getEntityId: ({ ticketId }) => ticketId,
      convertEntityToForm: (ticket) => {
        const { Contact, Company, Files } = ticket;
        const InterventionTarget = {
          type: "Ticket",
          data: ticket.Id
        };
        return {
          Contact,
          Company,
          AssociatedFiles: Files,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceTasksSchema,
      getEntityId: ({ taskId }) => taskId,
      convertEntityToForm: (task) => {
        const InterventionTarget = {
          type: "Task",
          data: task.Id
        };

        return {
          AssociatedFiles: task.Files,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceProjectsSchema,
      getEntityId: ({ projectId }) => projectId,
      convertEntityToForm: (project) => {
        const InterventionTarget = {
          type: "Project",
          data: project.Id
        };

        return {
          AssociatedFiles: project.Files,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceClientSchema,
      getEntityId: ({ clientId }) => clientId,
      convertEntityToForm: (client) => {
        const InterventionTarget = {
          type: "Client",
          data: client.Id
        };

        return {
          AssociatedFiles: client.Files,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceContractSchema,
      getEntityId: ({ contractId }) => contractId,
      convertEntityToForm: (contract) => {
        const InterventionTarget = {
          type: "Contract",
          data: contract.Id
        };

        return {
          AssociatedFiles: contract.Files,
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceTeamSchema,
      getEntityId: ({ teamId }) => teamId,
      convertEntityToForm: (team) => {
        const InterventionTarget = {
          type: "Team",
          data: team.Id
        };

        return {
          InterventionTarget,
          BeginDate: getBeginDate(),
          EndDate: getEndDate()
        };
      }
    },
    {
      schema: spaceDealsSchema,
      getEntityId: ({ dealId }) => dealId,
      convertEntityToForm: (deal) => {
        const InterventionTarget = {
          type: originTypes.deals,
          data: deal.Id
        };

        return {
          InterventionTarget,
          AssociatedFiles: deal.Files,
          BeginDate: getBeginDate(),
          EndDate: getEndDate(),
          Company: deal.Company,
          Contact: deal.Contact
        };
      }
    }
  ],
  convertEntityToForm: convertInterventionEntityToForm
});

const InterventionForm = (props) => {
  const { key, loading, error, convertedForm, childProps } =
    useInterventionRequest(props);

  const user = useCurrentAccount();
  const orgState = useSpaceQuery(
    `query/organizations?$filter=((Account/Id eq '${user.Id}') or (Manager/Id eq '${user.Id}'))`,
    [spaceOrganizationSchema]
  );

  const { loading: loadingOrganizations, data: organizations } = orgState;

  const { id, defaultForm, onSuccess, className } = props;

  const intervention = useIntervention(id);

  const { data: formTemplates, refetch: formTemplateRefetch } =
    useFormTemplateGetter(spaceInterventionsSchema);

  const resolvedForm = useMemo(() => {
    const form = {
      ...defaultForm,
      ...convertedForm
    };

    if (childProps) {
      form.InterventionTarget =
        getInterventionTarget(childProps) || form.InterventionTarget;
    }

    if (
      !id &&
      organizations &&
      organizations.findIndex((e) => e === form.Company) === -1 &&
      !form.Company
    ) {
      form.Company = organizations[0];
    }

    return form;
  }, [childProps, convertedForm, defaultForm, id, organizations]);

  if (loading || loadingOrganizations || error)
    return <LoaderSpinner center size="sm" className="text-secondary" />;

  if (!isOrganzationRequestValid(orgState))
    return <OrganizationRequestSideError requestState={orgState} />;

  const canEdit = intervention ? intervention.CanEdit : true;

  return (
    <FormTemplateProvider forms={formTemplates} refetch={formTemplateRefetch}>
      <InterventionFormBase
        className={className}
        NoMinDescription={props.NoMinDescription}
        onSuccess={onSuccess}
        canDelete={intervention?.CanDelete}
        id={id}
        canEdit={canEdit}
        key={key}
        convertedForm={resolvedForm}
        organizations={organizations}
      />
    </FormTemplateProvider>
  );
};

// const CommentObject = {
// 	Note: fk(spaceNoteSchema)
// };

// const CommentInserter = ({ interventionID, onClose, addComment }) => {
// 	const [text, setText] = useState("");

// 	const [post, { loading }] = useSchemaPost(spaceNoteSchema, null, {
// 		normalizedSchema: CommentObject,
// 		onSuccess: ({ data }) => {
// 			addComment(data);
// 			onClose();
// 		}
// 	});

// 	return (
// 		<div>
// 			<HTMLInput
// 				disabled={loading}
// 				value={text}
// 				onChange={e => setText(e.target.value)}
// 			/>
// 			<LoadableButton
// 				size="sm"
// 				isLoading={loading}
// 				onClick={() =>
// 					post({
// 						Description: text,
// 						interventionid: interventionID,
// 						Type: 2,
// 					})
// 				}
// 			>
// 				Guardar
//       </LoadableButton>
// 			<Button className="ml-2" vType="link-danger" onClick={onClose}>
// 				Cancelar
//       </Button>
// 		</div>
// 	);
// };

// const CommentInsertion = ({ intervention: interventionId, addComment }) => {
// 	const [isInserting, setIsInserting] = useState(false);

// 	if (!isInserting) {
// 		return (
// 			<div
// 				className="text-primary d-inline-block cursor-pointer"
// 				onClick={() => {
// 					setIsInserting(true);
// 				}}
// 			>
// 				<FontAwesomeIcon style={{ fontSize: 14, width: 14 }} icon={faComment} />
// 				<span style={{ paddingLeft: 5, fontSize: 14 }}>Comentar</span>
// 			</div>
// 		);
// 	} else {
// 		return (
// 			<CommentInserter
// 				addComment={addComment}
// 				interventionID={interventionId}
// 				onClose={() => setIsInserting(false)}
// 			/>
// 		);
// 	}
// };

// const CommentComponent = ({ comment }) => {
// 	const { Account, Date } = comment;

// 	const e = useIntervention(comment.Intervention);

// 	return (
// 		<div
// 			style={{
// 				padding: "8px 8px 5px",
// 				backgroundColor: "#f7f7f7",
// 				marginBottom: 10,
// 				borderRadius: 5
// 			}}
// 		>
// 			<HistoryAccountProfile account={Account.Id} />{" "}
// 			{/* <div className="d-inline-block"> comentou o seguinte: </div> */}
// 			<CommentPreviewer comment={e} date={Date} />
// 		</div>
// 	);
// };

// const CommentsComponent = ({ comments }) => {
// 	return comments.map(e => {
// 		return <CommentComponent key={e.Project} comment={e} />;
// 	});
// };

// const CommentPreviewer = ({ comment, date }) => {
// 	const { NoTagDescription } = comment;
// 	const [isEditing, setIsEditing] = useState(false);

// 	if (!isEditing)
// 		return (
// 			<div style={{ wordBreak: "break-word", marginLeft: 40 }}>
// 				<span className="d-inline-block mr-2">
// 					{NoTagDescription}
// 					<div
// 						style={{ marginLeft: 10 }}
// 						className="text-primary d-inline-block cursor-pointer"
// 						onClick={() => setIsEditing(true)}
// 					>
// 						<FontAwesomeIcon size="sm" icon={faPen} />
// 					</div>
// 				</span>
// 				<br />

// 				<span style={{ fontSize: 11, color: "#002b63cf" }}>
// 					<RelativeTime date={date} />
// 				</span>
// 			</div>
// 		);

// 	return (
// 		<CommentUpdater comment={comment} onClose={() => setIsEditing(false)} />
// 	);
// };

// const CommentUpdater = ({ comment, onClose }) => {
// 	const { Description } = comment;

// 	const [text, setText] = useState(Description);

// 	const [post, { loading }] = useSchemaPost(spaceNoteSchema, comment.Id, {
// 		normalizedSchema: CommentObject,
// 		onSuccess: () => onClose()
// 	});

// 	return (
// 		<div>
// 			<HTMLInput
// 				disabled={loading}
// 				value={text}
// 				onChange={e => setText(e.target.value)}
// 			/>
// 			<LoadableButton
// 				size="sm"
// 				isLoading={loading}
// 				onClick={() =>
// 					post({
// 						Description: text
// 					})
// 				}
// 			>
// 				Guardar
//       </LoadableButton>
// 			<Button className="ml-2" vType="link-danger" onClick={onClose}>
// 				Cancelar
//       </Button>
// 		</div>
// 	);
// };

const InterventionFormBase = injectIntl(
  ({
    convertedForm,
    id,
    canDelete,
    intl,
    organizations,
    canEdit,
    onSuccess,
    isChild,
    NoMinDescription,
    className
  }) => {
    const currentUserId = useCurrentAccount().Id;
    const buildForm = () => {
      const EndDate = new Date();
      EndDate.setSeconds(0);
      EndDate.setMilliseconds(0);

      const BeginDate = new Date(EndDate);
      BeginDate.setHours(9);
      BeginDate.setMinutes(0);
      BeginDate.setSeconds(0);
      BeginDate.setMilliseconds(0);

      let AddressSelector;

      if (
        convertedForm &&
        convertedForm.Addresses &&
        convertedForm.Addresses.length > 0
      ) {
        AddressSelector = {
          Id: convertedForm.Addresses[0].Id || undefined,
          Country: convertedForm.Addresses[0].Country || undefined,
          Address1: convertedForm.Addresses[0].Address1 || "",
          Address2: convertedForm.Addresses[0].Address2 || "",
          District: convertedForm.Addresses[0].District || "",
          Locality: convertedForm.Addresses[0].Locality || "",
          PostalCode: convertedForm.Addresses[0].PostalCode || "",
          Latitude: convertedForm.Addresses[0].Latitude || "",
          Longitude: convertedForm.Addresses[0].Longitude || ""
        };
      } else {
        AddressSelector = {
          Id: undefined,
          Country: undefined,
          Address1: "",
          Address2: "",
          District: "",
          Locality: "",
          PostalCode: "",
          Latitude: "",
          Longitude: ""
        };
      }

      return {
        Organization: organizations ? organizations[0] : null,
        InterventionTarget: null,
        EstimatedCost: 3600000, //1 hora
        TotalPauses: 0,
        DeslocationTime: 0,
        InefficientDuration: 0,
        Place: "",
        AddressSelector,
        BeginDate,
        EndDate: EndDate,
        Type: null,
        PlaceType: InterventionPlaceTypes.remote,
        Description: "",
        InternalDescription: "",
        Files: null,
        Followers: id ? [] : [currentUserId],
        ...convertedForm
      };
    };
    const formState = useFormState(buildForm);
    const { form, handleChange, setForm } = formState;

    const {
      InterventionTarget,
      BeginDate,
      Account,
      // Description,
      EndDate,
      TotalPauses,
      // Company,
      Contact,
      CreationDate,
      // Comments,
      Organization,
      InterventionType,
      AssociatedFiles,
      AssignedAssociations,
      Signature,
      UserSignature,
      InefficientDuration,
      Client,
      IndirectClient
    } = form;

    // let InterventionItem = useIntervention(id ? id : undefined);
    // if (!InterventionItem) {
    // 	InterventionItem = {};
    // 	InterventionItem.Comments = [];
    // }
    // const { Comments } = InterventionItem;

    // const [c, setC] = useState(Comments);

    // const addComment = c => {
    // 	return
    // };

    let totalDuration =
      BeginDate && EndDate
        ? getTotalInterventionDuration(
            BeginDate,
            EndDate,
            TotalPauses + InefficientDuration
          )
        : 0;

    if (totalDuration < 0) totalDuration = 0;

    const dispatch = useInterventionCommunicator();
    // const handleTranslatedError = useHandleError(spaceInterventionsSchema);
    const createToast = useToast();

    const [post, { loading }] = useSidebarSpacePost(
      id ? `interventions/${id}` : "interventions",
      spaceInterventionsSchema,
      {
        onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
          onSuccess && onSuccess();
          if (!id) defineChildProps({ Intervention: newId });
          createToast({
            pos: "tm",
            type: "success",
            description: !id
              ? `${intl.formatMessage({ id: "CREATED_SUCCEFULLY" })}`
              : `${intl.formatMessage({ id: "SAVE_SUCCESS" })}`,
            title: `${intl.formatMessage({ id: "TIME" })} nº ${newId || id}`
          });

          if (!id) {
            dispatch({ type: INTERVENTION_CREATED });
          }
          if (id) {
            dispatch({ type: INTERVENTION_UPDATED });
          }

          const { data, type } = InterventionTarget;

          if (type === "Ticket" && canUpdateEntity(spaceTicketsSchema, data)) {
            return openSidebar(<ConclusionForm ticket={data} />);
          } else if (
            type === "Task" &&
            canUpdateEntity(spaceTasksSchema, data)
          ) {
            return openSidebar(<ConclusionForm task={data} />);
          } else if (
            type === "Call" &&
            canUpdateEntity(spaceCallsSchema, data)
          ) {
            return openSidebar(<ConclusionForm call={data} />);
          }
        },
        onError: (error) => {
          handleError(createToast, error);
        }
      }
    );

    const handleSubmit = (form) => {
      const formatedForm = formatInterventionForm(form);

      post(formatedForm);
    };

    const [remove, { loading: deleteLoading }] = useSidebarSpaceDelete(
      `interventions/${id}`,
      spaceInterventionsSchema,
      {
        onSuccess: ({ openSidebar, data: newId, defineChildProps }) => {
          onSuccess && onSuccess();
          createToast({
            pos: "tm",
            type: "success",
            title: `${intl.formatMessage({ id: "TIME" })} nº ${id}`,
            description: `${intl.formatMessage({ id: "SUCCESS_DELETED" })}`
          });
        },
        onError: (error) => {
          handleError(createToast, error);
        }
      }
    );

    const InterventionTypeData = useInterventionType(InterventionType);

    const FutureDatesStatus = useMemo(() => {
      if (InterventionTypeData) {
        const { AllowFutureTimes } = InterventionTypeData || {};

        return AllowFutureTimes || false;
      }
      return false;
    }, [InterventionTypeData]);

    // var parser = new DOMParser();
    // var dom = parser.parseFromString(
    //   "<!doctype html><body>" + Description,
    //   "text/html"
    // );
    // var ResolvedDescription = dom.body.textContent;

    const handleAssociationsChanges = (value) => {
      handleChange((oldF) => {
        const newF = { ...oldF };
        const newAssignedAssociations = Array.isArray(newF.AssignedAssociations)
          ? [...newF.AssignedAssociations]
          : [];
        const index = newAssignedAssociations.findIndex((e) => e === value);
        if (index !== -1) {
          newAssignedAssociations.splice(index, 1);
        } else newAssignedAssociations.push(value);
        newF.AssignedAssociations = newAssignedAssociations;
        return newF;
      });
    };
    const Automation = useContext(SidebarAutomationContext);
    const DateChange = (e) => {
      const { value } = e.target;

      if (!(value instanceof Date && isFinite(value))) return;
      let date = new Date(value);
      const currentDate = new Date();
      if (
        Date.parse(date) > Date.parse(currentDate) &&
        FutureDatesStatus === false
      )
        date = new Date();
      const beginDate = new Date(BeginDate);
      const endDate = new Date(EndDate);

      endDate.setFullYear(date.getFullYear());
      endDate.setMonth(date.getMonth(), date.getDate());
      beginDate.setFullYear(date.getFullYear());
      beginDate.setMonth(date.getMonth(), date.getDate());

      handleChange({
        BeginDate: beginDate,
        EndDate: endDate
      });
    };

    const HourChange = (e) => {
      const { name, value } = e.target;
      const tempDate = new Date(BeginDate);
      tempDate.setHours(value[0]);
      tempDate.setMinutes(value[1]);
      handleChange({
        [name]: tempDate
      });
    };

    const space = useSpace();

    // const possibleFollowers = useAvailableFollowers(Creator);
    // useFollowerAutoUpdater(Followers, possibleFollowers, handleChange);

    const handleTargetChange = (e) => {
      handleChange({
        InterventionTarget: e.target.value
        // Contact: null
      });
    };

    const contactValidationURL = useMemo(() => {
      if (!Contact) return null;

      let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

      if (InterventionTarget) {
        switch (InterventionTarget.type) {
          case originTypes.project:
            projectId = InterventionTarget.data;
            break;

          case originTypes.ticket:
            ticketId = InterventionTarget.data;
            break;

          case originTypes.task:
            taskId = InterventionTarget.data;
            break;

          case originTypes.contract:
            contractId = InterventionTarget.data;
            break;

          case originTypes.client:
            clientId = InterventionTarget.data;
            break;

          case originTypes.call:
            callId = InterventionTarget.data;
            break;

          case originTypes.deals:
            dealId = InterventionTarget.data;
            break;

          default:
            break;
        }
      }

      const project = getEntity(spaceProjectsSchema, projectId);
      const ticket = getEntity(spaceTicketsSchema, ticketId);
      const task = getEntity(spaceTasksSchema, taskId);
      const contract = getEntity(spaceContractSchema, contractId);
      const call = getEntity(spaceCallsSchema, callId);
      const deal = getEntity(spaceDealsSchema, dealId);

      const Client =
        clientId ||
        (project && (project.Client || project.IndirectClient)) ||
        (deal && (deal.Client || deal.IndirectClient)) ||
        (ticket && (ticket.Client || ticket.IndirectClient)) ||
        (task && (task.Client || task.IndirectClient)) ||
        (call && (call.Client || call.IndirectClient)) ||
        (contract && (contract.Client || contract.IndirectClient));

      return `Query/Contacts/${Client}/${Contact}`;
    }, [Contact, InterventionTarget]);

    // eslint-disable-next-line no-unused-vars
    const { loading: contactaValidation } = useSpaceQuery(
      contactValidationURL,
      null,
      {
        onSuccess: ({ data }) => {
          if (!Boolean(data)) {
            handleChange({
              Contact: null
            });
          }
        },
        onError: (err) => {
          handleChange({
            Contact: null
          });
        }
      }
    );

    //SO VALIDA DESLOCACAO NO ESPACO DA ALGARDATA
    const correctValidation = useMemo(() => {
      if (space.Id === 1) {
        return validateInterventionFormNoMinDescript;
      } else return validateInterventionFormNoMinDescriptNoTravel;
    }, [space.Id]);

    return (
      <SideForm
        canEdit={canEdit}
        formState={formState}
        onSubmit={handleSubmit}
        validate={correctValidation}
      >
        <SideFormContent className={className}>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id="ASSOCIATE_TIME_RECORD" />
          </div>

          {organizations && organizations.length > 1 && (
            <FormInput
              name="Organization"
              textId="COMPANY"
              inputType={OrganizationDropdown}
              items={organizations}
              value={Organization}
              className="mb-3"
            />
          )}

          {InterventionTarget && (
            <div className="mb-1 fs-14 text-black">
              <FormattedMessage id="ORIGIN" />
            </div>
          )}
          <ConnectedBreadcrumbs value={InterventionTarget} className="mb-3" />

          <FormInput
            name="InterventionTarget"
            textId="ASSOCIATE_TO"
            inputType={InterventionTargetDropdown}
            allClients={false}
            onChange={handleTargetChange}
            className="mb-3"
          />

          <FormInput
            className="mb-3"
            name="Contact"
            originTarget={InterventionTarget}
            textId="CONTACT"
            inputType={ContactDropdown}
            IndirectClient={IndirectClient || Client}
          />

          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id="LOCATION_ADDRESS" />
          </div>

          <FormInput
            className="mb-3"
            name="PlaceType"
            textId="WHERE"
            inputType={InterventionPlaceTypeDropdown}
          />

          <FormInput
            name="AddressSelector"
            className="mb-3"
            inputType={AddressSelector}
          />

          <hr className="mb-3"></hr>
          <div className="mb-3 text-black fs-16 fw-medium">
            <FormattedMessage id="DETAILS" />
          </div>

          <FormInput
            className="mb-3"
            name="InterventionType"
            OrganizationSpaceId={Organization}
            textId="TIME_TYPE"
            inputType={InterventionTypeDropdown}
          />

          <FormInput
            className="mb-3"
            name="BeginDate"
            textId="DATE"
            onChange={DateChange}
            inputType={ArDatePicker}
            componentClassName="w-100"
            enableDate
            showDateBar
            disableFutureDays={!FutureDatesStatus}
            enableHours={false}
          />

          <FormInput
            className="mb-3"
            onChange={HourChange}
            name="BeginDate"
            textId="START"
            inputType={ArDatePicker}
            componentClassName="w-100"
            enableDate={false}
          />

          <FormInput
            className="mb-3"
            name="EndDate"
            onChange={HourChange}
            textId="END"
            inputType={ArDatePicker} //AdminCheckDatePicker
            componentClassName="w-100"
            enableDate={false}
          />

          <FormInput
            className="mb-3"
            name="InefficientDuration"
            textId="INEFFICIENT_TIME"
            inputType={MinuteInput}
          />

          {InefficientDuration > 0 && (
            <>
              <FormInput
                className="mb-3"
                name="TimeDetail"
                OrganizationSpaceId={Organization}
                textId="INEFFICIENT_TIME_TYPE"
                inputType={IneficientTDropdown}
              />
              <FormInput
                className="mb-3"
                name="TimeDetailDescription"
                textId="INEFFICIENT_TIME_REASONS"
                inputType={HTMLInput}
              />
            </>
          )}

          <div className="d-flex align-items-center mb-3">
            <FormInput
              // className="mb-3"
              name="TotalPauses"
              textId="TOTAL_PAUSES"
              inputType={MinuteInput}
              componentClassName="w-100"
              className="flex-1"
            />
            <FormInput
              className="ml-3"
              value={totalDuration}
              componentClassName="ssi-control hidden text-black d-flex align-items-center fw-bold"
              asHourMinute
              textId="DURATION"
              inputType={FormattedTimePreview}
            />
          </div>

          <FormInput
            className="mb-3"
            name="DeslocationTime"
            textId="TRAVEL_TIME"
            inputType={MinuteInput}
          />

          <ClipboardProvider>
            <FormInput
              className="mb-3"
              name="Description"
              textId="REPORT_CLIENT"
              inputType={DocTyper}
            />
            {/* <div
              style={{ maxWidth: 320 }}
              className="mb-3 fs-12 mt-2  text-danger"
            >
              {ResolvedDescription.trim().length < 30 && !NoMinDescription && (
                <FormattedMessage
                  id="DESCRIPTION_LENGTH"
                  values={{ charLength: ResolvedDescription.trim().length }}
                />
              )}
            </div> */}

            <FormInput
              name="InternalDescription"
              textId="INTERNAL_NOTE"
              inputType={DocTyper}
              className="mb-3"
            />
            <FormInput
              name="Followers"
              textId="FOLLOWERS"
              inputType={UserDropdown}
              multiple
              // items={possibleFollowers}
              className="mb-3"
            />

            {!Automation && (
              <>
                {Array.isArray(AssociatedFiles) &&
                  AssociatedFiles.length > 0 && (
                    <>
                      <hr className="mb-3"></hr>
                      <div className="text-black fs-16 mb-3 fw-medium">
                        <FormattedMessage id="RELATED_ATTACHMENTS" />
                      </div>
                      <FormInput
                        disableAutomation
                        inputType={FileAssociations}
                        name="Files"
                        className="mb-3 form-Anexos-floater"
                        extendedMode
                        handleAssociationsChanges={handleAssociationsChanges}
                        AssignedAssociations={AssignedAssociations}
                        onChange={() => {}}
                        value={AssociatedFiles}
                      />
                    </>
                  )}
                <hr className="mb-3"></hr>
                <div className="text-black fs-16 fw-medium">
                  <FormattedMessage id={"ATTACHMENTS"} />
                </div>

                <FormInput
                  name="Files"
                  inputType={FileUploader}
                  enableSwapper
                  className="mb-3 form-Anexos-floater"
                />
              </>
            )}
          </ClipboardProvider>

          {(UserSignature || Signature) && (
            <>
              <hr className="mb-3"></hr>
              <div className="mb-3 text-black fs-16 fw-medium">
                <FormattedMessage id="EVIDENCE" />
              </div>
              {Signature && (
                <>
                  {" "}
                  <div className="mb-1 fs-14 text-black">
                    <FormattedMessage id="CLIENT_SIGNATURE" />
                  </div>
                  <div
                    style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
                    className="mb-3 w-100"
                  >
                    <img
                      className="p-2"
                      src={Signature}
                      alt="Signature"
                      height="75"
                    />
                  </div>
                </>
              )}
              {UserSignature && (
                <>
                  <div className="mb-1 fs-14 text-black">
                    <FormattedMessage id="USER_SIGNATURE" />
                  </div>
                  <div
                    style={{ border: "1px solid #ced4da", borderRadius: "5px" }}
                    className="mb-3 w-100"
                  >
                    <img
                      className="p-2"
                      src={UserSignature}
                      alt="Signature"
                      height="75"
                    />
                  </div>
                </>
              )}
            </>
          )}

          {/* {id && (
            <>
              <hr className="mb-3"></hr>
              <div className="mb-3">
                <span className="text-black fs-16 fw-medium">Comentários</span>
              </div>
              <div className="mb-3">
                <CommentModule
                  Type="TimeCharge"
                  Schema={spaceInterventionsSchema}
                  Id={id}
                  Comments={Comments || []}
                />
              </div>
            </>
          )} */}
          <CreatedBy Creator={Account} CreationDate={CreationDate} />

          {/* {id &&
						<>
							<hr style={{ margin: 0, marginTop: 1, width: "100%", borderColor: "#cdd5ea" }} />
							<div style={{ padding: "0.75rem 1rem" }}>
								<CommentInsertion addComment={addComment} intervention={id} />
							</div>
							{c && c.length > 0 ? (
								<DetailsAccordion title={`Comentários (${c.length})`}>
									<br />
									<CommentsComponent comments={c} />
								</DetailsAccordion>
							) : (
									""
								)}
						</>} */}
        </SideFormContent>
        <SideFormFooter
          canDelete={canDelete}
          deleteLoading={deleteLoading}
          form={form}
          handleChange={setForm}
          schema={spaceInterventionsSchema}
          handleDelete={remove}
          isLoading={loading}
        />
      </SideForm>
    );
  }
);

export default InterventionForm;
