import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { isEmail } from "../../Helpers/MiscHelper";
import { connect } from "react-redux";
import {
  login,
  removeLoginError,
  LoginStatus
} from "../../Redux/Actions/AuthActions";
import { Redirect } from "react-router-dom";
import LoadableButton from "../../Components/Button/LoadableButton";
import FormErrorAlert from "../../Components/Forms/FormErrorAlert/FormErrorAlert";

import AirdeskTextSvg from "../../Components/Svg/AirdeskTextSvg";
import AirdeskBackgroundSvg from "../../Components/Svg/AirdeskBackgroundSvg";
import { Link } from "react-router-dom";
import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import LabeledInput from "../../Components/Input/LabeledInput";
import { BasePageContainer } from "../BasicPageContainer/BasicPageContainer";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
        password: ""
      },
      validation: {
        isEmailValid: true,
        isPasswordValid: true
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state.form;
    const { os, browser } = getUserData();
    let isPasswordValid = password.length > 0;

    let isEmailValid = isEmail(email);

    if (!isPasswordValid || !isEmailValid)
      this.setState({
        validation: {
          isPasswordValid,
          isEmailValid
        }
      });
    else
      this.props.login({ ...this.state.form, Browser: browser, OpSystem: os });
  };

  handleChange = (e) => {
    const { removeLoginError, loginPage } = this.props;

    if (loginPage.error !== null) removeLoginError();

    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      validation: {
        isEmailValid: true,
        isPasswordValid: true
      }
    });
  };

  render() {
    const { intl, loginPage, isAuthenticated } = this.props;

    if (isAuthenticated) return <Redirect to="/s" />;

    const { email, password } = this.state.form;

    const { isEmailValid, isPasswordValid } = this.state.validation;

    const { error, isProcessing } = loginPage;

    let hasInvalidCredentials = false;
    let hasUnkownError = false;

    if (error !== null && !isProcessing) {
      switch (error) {
        case LoginStatus.InvalidPassword:
        case LoginStatus.InvalidPasswordFormat:
        case LoginStatus.InvalidUserName:
          hasInvalidCredentials = true;
          break;

        default:
          hasUnkownError = true;
          break;
      }
    }

    const hasErrors =
      !isEmailValid ||
      !isPasswordValid ||
      hasInvalidCredentials ||
      hasUnkownError;
    return (
      <form className="ssi-onboarding-page" onSubmit={this.handleSubmit}>
        <MaintenanceIcon absolute />

        <BasePageContainer className="content p-5">
          <AirdeskTextSvg className="mb-5 md mx-auto airdesk-logo" />
          {/* <div className="text-center mb-3 fs-32 text-black">Entrar</div> */}

          <div className="mb-2 fs-32 text-black mx-auto">
            <FormattedMessage id={"SIGN_IN"} />
          </div>

          {hasErrors && (
            <FormErrorAlert
              className="mb-3 rounded"
              errors={[
                {
                  isShowing: !isEmailValid,
                  text: intl.formatMessage({ id: "LOGIN_SET_VALID_EMAIL" })
                },
                {
                  isShowing: !isPasswordValid,
                  text: intl.formatMessage({ id: "LOGIN_SET_VALID_PASSWORD" })
                },
                {
                  isShowing: hasInvalidCredentials,
                  text: intl.formatMessage({ id: "LOGIN_INVALID_CREDENTIALS" })
                },
                {
                  isShowing: hasUnkownError,
                  text: intl.formatMessage({ id: "LOGIN_UNKNOWN_ERROR" })
                }
              ]}
            />
          )}

          <LabeledInput
            className="mb-3"
            name="email"
            type="email"
            value={email}
            onChange={this.handleChange}
            hasErrors={!isEmailValid}
            text={intl.formatMessage({ id: "EMAIL" })}
          />
          <LabeledInput
            name="password"
            type="password"
            value={password}
            onChange={this.handleChange}
            hasErrors={!isPasswordValid}
            text={intl.formatMessage({ id: "PASSWORD" })}
          />
          <div className="mt-3">
            <Link className="fs-12 link-primary float-right" to="recovery">
              <FormattedMessage id={"FORGET_PASSWORD"} />
            </Link>
          </div>
          <LoadableButton
            vType={"secondary"}
            isLoading={isProcessing}
            disabled={hasErrors}
            className="mt-4"
          >
            {intl.formatMessage({ id: "SIGN_IN" })}
          </LoadableButton>
          <div className="mt-4 fw-light text-black mx-auto">
            <FormattedMessage id={"LOGIN_NO_ACCOUNT"} />
            <Link className="link-secondary ml-1 fw-medium" to="register">
              <FormattedMessage id={"LOGIN_REGISTER"} />
            </Link>
          </div>
        </BasePageContainer>
        <div className="image">
          <AirdeskBackgroundSvg className="center-el" />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginPage: state.Pages.Login,
    isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (form) => dispatch(login(form)),
    removeLoginError: () => dispatch(removeLoginError())
  };
};

function getUserData() {
  var unknown = "-";
  // screen

  // browser
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browser = navigator.appName;
  var version = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;
  // Opera
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browser = "Microsoft Legacy Edge";
    version = nAgt.substring(verOffset + 5);
  }
  // Edge (Chromium)
  else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
    browser = "Microsoft Edge";
    version = nAgt.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browser = "Chrome";
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browser = "Safari";
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browser = "Firefox";
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf("Trident/") !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(nAgt.indexOf("rv:") + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(";")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(" ")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(")")) !== -1) version = version.substring(0, ix);
  majorVersion = parseInt("" + version, 10);
  if (isNaN(majorVersion)) {
    version = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  // mobile version
  // var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
  // cookie
  var cookieEnabled = navigator.cookieEnabled ? true : false;
  if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1 ? true : false;
  }
  // system
  var os = unknown;
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Chrome OS", r: /CrOS/ },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  var osVersion = unknown;
  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }

  switch (os) {
    case "Mac OS":
    case "Mac OS X":
    case "Android":
      osVersion =
        /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
          nAgt
        )[1];
      break;
    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
    default:
      osVersion = "";
  }

  return { os, browser, osVersion };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
