/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback
} from "react";
import ReactDOM from "react-dom";
import "./FilterList.css";
import { dateFormat, isDate } from "../../Assets/Utils";
import Radio from "../Radio/Radio";
import Button from "../Button/Button";
import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faChevronLeft,
  faSort,
  faFilter,
  faDrawCircle,
  faPlus,
  faTrashAlt,
  faMinusCircle,
  faRedoAlt,
  // eslint-disable-next-line no-unused-vars
  faObjectGroup,
  faEyeSlash,
  faLock,
  faPalette
} from "@fortawesome/pro-light-svg-icons";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { AdvancedFilterSidebar } from "./AdvancedFilter/AvancedFilter";
import { useServerAwareState, ServerAwareContext } from "../CGrid/ServerGrid";
import {
  useSchemaQuery,
  useSpacePostQuery,
  useSpaceQuery
} from "../../Helpers/IOClient";
import { spaceAdvancedFilterSchema } from "../../config/schema";
import {
  useAdvancedFilter,
  useAccount,
  usePipeline
} from "../../Hooks/EntityHooks";
import LoaderSpinner from "../Loader/LoaderSpinner/LoaderSpinner";
import querystring from "query-string";
import { many } from "../../Helpers/SchemaHelper";
import { AccountProfileImg } from "../Img/Img";
import Checkbox from "../Checkbox/Checkbox";
import { useCurrentAccount } from "../../Contexts/UserContext";
import FormInput from "../Forms/FormInput/FormInput";
import {
  usePipelineSettings,
  usePipelineQuery
} from "../Pipelines/PipelineProvider";
import KeyedDropdown from "../Dropdown/KeyedDropdown";
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd";
import GridSort from "../CGrid/GridSort";
import {
  GroupedByDictionaryForFilterBar,
  GroupedByOrderByDictionary,
  GroupedByDictionaryForIcons
} from "../../Helpers/GroupbyHelper";
import { faGripVertical, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { reverseMapping } from "../../Helpers/MiscHelper";
import { useSidebar } from "../Sidebar/SidebarV2";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { ServerAwareColumnsContext } from "../CGrid/ServerAwareContexts";
import {
  ColorFilterForm,
  ColorFilterViewTypeEnum
} from "./AdvancedFilter/AdvancedFilterColors/AdvanceFilterColorsForm";
import {
  useServerAwareColorFilterUpdater,
  useServerAwareSelectedFilterColumns
} from "../CGrid/ServerAwareColorFilters";
import {
  addSelectedFilterBySchema,
  getAllFiltersBySchema,
  getFilterBySchema,
  removeSelectedFilterBySchema,
  updateColorFitlersBySchema,
  updateFilterBySchema
} from "./AdvancedFilter/AdvancedFilterColors/AdvancedFilterColorsLs";
import { useSpace } from "../../Contexts/SpaceContext";
export const messages = {
  back: "FILTER_BACK",
  filter: "FILTER_FILTER",
  addFilter: "FILTER_ADDFILTER",
  group: "FILTER_GROUP",
  groupBy: "FILTER_GROUPBY",
  and: "FILTER_AND",
  filters: {
    default: {
      eq: "FILTER_EQ",
      ne: "FILTER_NEQ",
      neq: "FILTER_NEQ",
      le: "FILTER_LTE",
      lt: "FILTER_LT",
      lte: "FILTER_LTE",
      ge: "FILTER_GTE",
      gt: "FILTER_GT",
      gte: "FILTER_GTE",
      startswith: "FILTER_STARTSWITH",
      endswith: "FILTER_ENDSWITH",
      contains: "FILTER_CONTAINS",
      doesnotcontain: "FILTER_DOESNOTCONTAIN",
      isbetween: "FILTER_DATE_ISBETWEEN",
      isempty: "FILTER_ISEMPTY",
      isnotempty: "FILTER_ISNOEMPTY"
    },
    date: {
      lt: "FILTER_DATE_LT",
      gt: "FILTER_DATE_GT"
    }
  }
};

const viewTypes = {
  overview: 0,

  filterOptions: 2,
  filterSelector: 3,
  advancedFilterSelector: 4,
  orderbyOptions: 5,
  groupByOptions: 6,
  colorFilterSelector: 7
};

const PipelineProfileProfile = ({ item, className }) => {
  const pipeline = usePipeline(item);
  const intl = useIntl();
  let text;

  if (pipeline) text = pipeline.Name;
  else if (item === null) text = intl.formatMessage({ id: "ALL" });
  else return null;

  return <span className={className}>{text}</span>;
};

const PipelineSelector = ({ onParamsChange, settings, value }) => {
  const resolvedValue = useMemo(() => {
    if (!value) return null;

    const num = Number(value);

    if (isNaN(num)) return null;

    return num;
  }, [value]);

  const { type } = settings;

  const { loading, error, data } = usePipelineQuery();

  const handleChange = useCallback(
    (e) => {
      onParamsChange((v) => {
        //
        if (e.target.value && e.target.value.toString() === v.pipeline)
          return v;
        return {
          pipeline: e.target.value
        };
      });
    },
    [onParamsChange]
  );

  // const valueExists = useMemo(() => {
  //   if (!data) return false;

  //   const pipeline = getEntity(spacePipelineSchema, resolvedValue);

  //   return Boolean(pipeline);
  // }, [data, resolvedValue]);

  const location = useLocation();
  const { search } = location;
  const { view } = queryString.parse(search);
  const options = useMemo(() => {
    if (!data) return null;
    if (view === "board") {
      return [...data];
    }
    return [null, ...data];
  }, [data, view]);

  useEffect(() => {
    if (!data || !value || data.length === 0) return;

    if (!data.includes(Number(value))) {
      const timeout = setTimeout(() => {
        onParamsChange((v) => {
          return {
            pipeline: view === "board" ? data[0] : null
          };
        });
      });
      return () => clearTimeout(timeout);
    }
  }, [data, onParamsChange, value, view]);

  if (loading || error)
    return (
      <LoaderSpinner
        size="xs"
        className="mb-3 text-primary mx-auto d-flex justify-content-center"
      />
    );

  const result =
    data.length >= 2 ? (
      <FormInput
        //   text="Pipeline"
        Type={type}
        preview={data.length < 2}
        inputType={KeyedDropdown}
        value={resolvedValue}
        onChange={handleChange}
        componentClassName="bg-white fw-regular"
        className="mb-3"
        options={options}
        valueComponent={PipelineProfileProfile}
        closeOnSelect
      />
    ) : (
      <div className="disabled-pipeline-dropdown rounded d-flex justify-content-between px-3 align-items-center">
        <PipelineProfileProfile item={data[0]} />
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    );

  return (
    <>
      <div className="mb-2 fs-14  text-black fw-bold">
        <FontAwesomeIcon icon={faDrawCircle} className="mr-2" />

        <FormattedMessage id={"PIPELINE"} />
      </div>
      {result}
    </>
  );
};

const FilterOverview = ({
  filters = [],
  onRemoveAvancedFilters,
  onRemoveColorFilters,
  advancedFilters = [],
  onRemoveFilter,
  onAddFilter,
  onAddOrderBy,
  onRemoveAllOrderbys,
  value,
  onChange,
  filterDict,
  onRemoveAllFilters,
  onRemoveEveryKindOfFilter,
  onEditFilter,
  handleSingleFilterChange,
  groupableColumns,
  onReset,
  onUnreadAdd,
  onAddGroupBy,
  onAddAdvancedFilter,
  onRemoveAllGroups,
  enableDeactivation,
  availableFilters,
  filterType,
  iconDict,
  disableAdvancedFilters,
  ExtraFilterComponent,
  disableColorFilters,
  onAddColorFilter,
  disableOrdering
}) => {
  const [openSidebar, closeSidebar] = useSidebar();

  const { onParamsChange, consumerRef, params, schema, gridSchema } =
    useServerAwareState();

  // const updateColorFilter = useServerAwareColorFilterUpdater();

  const colorFilters = useServerAwareSelectedFilterColumns();

  // useEffect(() => {
  //   return () => closeSidebar();
  // }, [closeSidebar]);

  const handleSuccess = (advancedFilter) => {
    onParamsChange((p) => {
      const { advancedFilters } = p;
      const newAdvancedFilters = advancedFilters ? [...advancedFilters] : [];
      //;
      if (!newAdvancedFilters.includes(advancedFilter))
        newAdvancedFilters.push(advancedFilter);
      else {
        if (consumerRef.current) {
          consumerRef.current.refetch();
        }
      }

      return {
        advancedFilters: newAdvancedFilters
      };
    });
    closeSidebar();
  };

  const handleRemoval = (advancedFilter) => {
    onParamsChange((p) => {
      const { advancedFilters } = p;
      const newAdvancedFilters = advancedFilters ? [...advancedFilters] : [];
      //;
      const removeFilter = () => {
        const index = newAdvancedFilters
          ? newAdvancedFilters.indexOf(advancedFilter)
          : -1;

        if (index === -1) return;
        newAdvancedFilters.splice(index, 1);
        removeFilter();
      };

      removeFilter();

      return {
        advancedFilters: newAdvancedFilters
      };
    });

    if (consumerRef.current) {
      consumerRef.current.refetch();
    }
    closeSidebar();
  };
  const updateSelectedFilters = useServerAwareColorFilterUpdater();
  const space = useSpace();
  const handleColorFilterRemoval = (colorFilter) => {
    const selectedFilters = removeSelectedFilterBySchema(
      gridSchema || schema,
      colorFilter.Id,
      space.Id
    );
    updateSelectedFilters((fs) => {
      if (selectedFilters.length === 0) return [];
      else
        return selectedFilters.map((e) => {
          return getFilterBySchema(schema, e, space.Id);
        });

      // const index = fs.findIndex((v) => v.Id === fs.Id);
      // if (index === -1) {
      //   const newArr = [...fs];
      //   newArr.splice(index, 1);
      //   return newArr;
      // } else {
      //   return fs;
      // }
    });
  };

  const handleColorSuccess = (filter) => {
    updateSelectedFilters((fs) => {
      const newArr = [...fs];
      const index = fs.findIndex((f) => f.Id === filter.Id);
      if (index !== -1) {
        newArr[index] = filter;
      } else {
        newArr.push(filter);
      }
      return newArr;
    });
    closeSidebar();
    // onGoBack();
  };

  const handleAddOrUpdateColorFilter = (colorFilter) => {
    openSidebar(
      <ColorFilterForm
        schema={gridSchema || schema}
        onRemoval={handleColorFilterRemoval}
        onSuccess={handleColorSuccess}
        colorFilter={colorFilter}
        availableFilters={availableFilters}
      />
    );
  };

  const handleAdvancedFilterRemoval = (index) => {
    onParamsChange((p) => {
      const { advancedFilters } = p;
      const newAdvancedFilters = [...advancedFilters];

      newAdvancedFilters.splice(index, 1);

      return {
        advancedFilters: newAdvancedFilters
      };
    });
  };

  const handleAddOrUpdateAdvancedFilter = (advancedFilter) => {
    openSidebar(
      <AdvancedFilterSidebar
        id={advancedFilter}
        filterType={filterType}
        availableFilters={availableFilters}
        onSuccess={handleSuccess}
        onRemoval={handleRemoval}
      />
    );
  };

  const hasAdvancedFilters = advancedFilters.length > 0;
  const hasColorFilters = colorFilters.length > 0;
  const hasFilters = filters.length > 0;
  const hasOrderBys = value.orderby.length > 0;
  const hasGroupBys = value.groupBy.length > 0;

  const pipelineSettings = usePipelineSettings();

  const ArrayFilters = filters.map((item, index) => (
    <FilterItem
      key={index}
      index={index}
      enableDeactivation={enableDeactivation}
      item={item}
      handleSingleFilterChange={handleSingleFilterChange}
      onEdit={() => onEditFilter(index)}
      onRemove={(e) => {
        onRemoveFilter(item, index);
      }}
    />
  ));

  return (
    <div className="a-filter-home-view">
      <div>
        {pipelineSettings && (
          <PipelineSelector
            settings={pipelineSettings}
            onParamsChange={onParamsChange}
            value={params.pipeline || null}
          />
        )}
        <hr />
        <div className="a-filter-title fs-14 mb-2 fw-medium d-flex justify-content-between">
          <div className="cursor-pointer" onClick={onAddFilter}>
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            <FormattedMessage id={"FILTER"} />
          </div>
          <div className="d-flex">
            <div className="mr-1">
              <Button
                vType={"primary"}
                className={classnames("ar-minimal-button ")}
                onClick={onAddFilter}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            {hasFilters && (
              <Button
                vType={"danger-ghost"}
                className={classnames("ar-minimal-button ")}
                onClick={onRemoveAllFilters}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </div>

        {/* {hasFilters || hasAdvancedFilters ? (
          <Button
            vType="outline-danger"
            className="fs-14 fw-regular w-100 px-2 py-0 mb-3"
            onClick={onRemoveAllFilters}
          >
            Remover todos os filtros
          </Button>
        ) : (
          <div className="ssi-control fs-14 px-2 d-flex align-items-center rounded bg-grey border-0 mb-3 ">
            Todos
          </div>
        )} */}

        {hasFilters && (
          <div className="mb-3 justify-content-between">
            <OrderByChooserDragOrder
              filterDict={filterDict}
              selectedColumns={filters}
              iconDict={iconDict}
              enableDeactivation={enableDeactivation}
              itemsArray={ArrayFilters}
              onChange={(e) => {
                onChange({ filter: e });
              }}
              removeColumn={(e) => {
                onChange({ filter: e });
              }}
            />
          </div>
        )}
        {!disableAdvancedFilters && (
          <>
            <hr />
            <div
              className="a-filter-title fs-14 mb-2 fw-medium d-flex justify-content-between"
              // onClick={onAddAdvancedFilter}
            >
              <div onClick={onAddAdvancedFilter} className="cursor-pointer">
                <FontAwesomeIcon icon={faFilter} />
                <FontAwesomeIcon icon={faPlus} size="xs" />
                <span className="ml-2">
                  <FormattedMessage id={"ADVANCED_FILTER"} />
                </span>
              </div>
              <div className="d-flex">
                <div className="mr-1">
                  <Button
                    type={"button"}
                    vType={"primary"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onAddAdvancedFilter}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
                {hasAdvancedFilters && (
                  <Button
                    type={"button"}
                    vType={"danger-ghost"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onRemoveAvancedFilters}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )}
              </div>
            </div>

            {advancedFilters.map((item, i) => (
              <AdvancedFilterItem
                key={i}
                onRemove={() => handleAdvancedFilterRemoval(i)}
                onEdit={() => handleAddOrUpdateAdvancedFilter(item)}
                advancedFilter={item}
              />
            ))}
          </>
        )}
        {!disableColorFilters && (
          <>
            <hr />
            <div
              className="a-filter-title fs-14 mb-2 fw-medium d-flex justify-content-between "
              // onClick={onAddColorFilter}
            >
              <div className="cursor-pointer" onClick={onAddColorFilter}>
                <FontAwesomeIcon icon={faPalette} />
                {/* <FontAwesomeIcon icon={faPlus} size="xs" /> */}
                <span className="ml-2">
                  <FormattedMessage id={"COLOR_FILTER"} />
                </span>
              </div>
              <div className="d-flex">
                <div className="mr-1">
                  <Button
                    type={"button"}
                    vType={"primary"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onAddColorFilter}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
                {/* {hasColorFilters && (
                  <Button
                    vType={"danger-ghost"}
                    type={"button"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onRemoveColorFilters}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )} */}
              </div>
            </div>

            {colorFilters.map((item, i) => (
              <ColorFilterItem
                key={item.Id}
                onRemove={() => handleColorFilterRemoval(item)}
                onEdit={() => handleAddOrUpdateColorFilter(item)}
                colorFilter={item}
              />
            ))}
          </>
        )}
        {!disableOrdering && (
          <>
            <hr />
            <div className="a-filter-title fs-14 mb-2 fw-medium d-flex justify-content-between">
              <div className="cursor-pointer" onClick={onAddOrderBy}>
                <FontAwesomeIcon icon={faSort} className="mr-2" />
                <FormattedMessage id={"ORDER_BY"} />
              </div>
              <div className="d-flex">
                <div className="mr-1 bg-airdesk">
                  {hasOrderBys ? (
                    <Button
                      vType={"primary"}
                      className={classnames("ar-minimal-button ")}
                      onClick={onAddOrderBy}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Button>
                  ) : (
                    <Button
                      vType={"primary"}
                      className={classnames("ar-minimal-button ")}
                      onClick={onAddOrderBy}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                </div>
                {hasOrderBys && (
                  <Button
                    vType={"danger-ghost"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onRemoveAllOrderbys}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )}
              </div>
            </div>
            {hasOrderBys && (
              <div className="mb-3 justify-content-between">
                <OrderByChooserDragOrder
                  filterDict={filterDict}
                  groupdData={value.groupBy}
                  selectedColumns={value.orderby}
                  onChange={(e) => {
                    onChange({ $orderBy: e });
                  }}
                  iconDict={iconDict}
                  removeColumn={(e) => {
                    onChange({ $orderBy: e });
                  }}
                />
              </div>
            )}
          </>
        )}
        {groupableColumns && groupableColumns.length > 0 && (
          <>
            <hr />
            <div className="a-filter-title fs-14 mb-2 fw-medium d-flex justify-content-between">
              <div className="cursor-pointer" onClick={onAddGroupBy}>
                <FontAwesomeIcon icon={faObjectGroup} className="mr-2" />
                <FormattedMessage id={"GROUP_BY"} />
              </div>
              <div className="d-flex">
                <div className="mr-1">
                  <Button
                    vType={"primary"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onAddGroupBy}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
                {hasGroupBys && (
                  <Button
                    vType={"danger-ghost"}
                    className={classnames("ar-minimal-button ")}
                    onClick={onRemoveAllGroups}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                )}
              </div>
            </div>
            {hasGroupBys && (
              <div className="mb-3 justify-content-between">
                <GroupByChooserDragOrder
                  filterDict={filterDict}
                  iconDict={iconDict}
                  selectedColumns={value.groupBy}
                  onChange={(e) => {
                    onChange({ groupBy: e });
                  }}
                  removeColumn={(e) => {
                    onChange({ groupBy: e });
                  }}
                />
              </div>
            )}
          </>
        )}
        {ExtraFilterComponent && ExtraFilterComponent}
        <hr />
        <div className="d-flex flex-column align-items-baseline">
          <Button vType="link-primary" onClick={onReset}>
            <FontAwesomeIcon icon={faRedoAlt} />
            <span className="ml-2 text-decoration-underline">
              <FormattedMessage id={"RESTORE_ALL"} />
            </span>
          </Button>
          {onUnreadAdd && (
            <Button vType="link-primary" onClick={onUnreadAdd}>
              <FontAwesomeIcon icon={faEyeSlash} />
              <span className="ml-2 text-decoration-underline">
                <FormattedMessage id={"NOT_READ"} />
              </span>
            </Button>
            // <div
            //   className="text-primary mb-3 cursor-pointer disable-selection"
            //   onClick={onUnreadAdd}
            // >
            // </div>
          )}
          {(hasOrderBys || hasAdvancedFilters || hasFilters || hasGroupBys) && (
            <Button vType="link-danger" onClick={onRemoveEveryKindOfFilter}>
              <FontAwesomeIcon icon={faMinusCircle} />
              <span className="ml-2 text-decoration-underline">
                <FormattedMessage id={"REMOVE_ALL"} />
              </span>
            </Button>
            // <div
            //   className="text-danger-alt cursor-pointer"
            //   onClick={onRemoveEveryKindOfFilter}
            // >
            //   <FontAwesomeIcon icon={faMinusCircle} />
            //   <span className="ml-2 text-decoration-underline">
            //     <FormattedMessage id={"REMOVE_ALL"} />
            //   </span>
            // </div>
          )}
        </div>
        {/* <div
          className="text-primary mb-3 cursor-pointer disable-selection"
          onClick={onReset}
        >
          <FontAwesomeIcon icon={faRedoAlt} />
          <span className="ml-2 text-decoration-underline">
            <FormattedMessage id={"RESTORE_ALL"} />
          </span>
        </div> */}

        {/* {(hasOrderBys || hasAdvancedFilters || hasFilters || hasGroupBys) && (
          <Button vType="link-danger" onClick={onRemoveEveryKindOfFilter}>
            <FontAwesomeIcon icon={faMinusCircle} />
            <span className="ml-2 text-decoration-underline">
              <FormattedMessage id={"REMOVE_ALL"} />
            </span>
          </Button>
          // <div
          //   className="text-danger-alt cursor-pointer"
          //   onClick={onRemoveEveryKindOfFilter}
          // >
          //   <FontAwesomeIcon icon={faMinusCircle} />
          //   <span className="ml-2 text-decoration-underline">
          //     <FormattedMessage id={"REMOVE_ALL"} />
          //   </span>
          // </div>
        )} */}
      </div>
    </div>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const OrderByChooserDragOrder = ({
  selectedColumns,
  onChange,
  filterDict,
  groupdData,
  itemsArray,
  iconDict,
  enableDeactivation
}) => {
  //   const onDragEnd = useCallback(r => {
  //     const { destination, source } = r;
  //     if (!destination) return;
  //     const { droppableId: destId, index: destIndex } = destination;
  //     const { droppableId: sourceId, index: sourceIndex } = source;
  //   }, []);
  let groupedByExistesSoDoesOrderBy = null;
  let resolvedSelectedColumns = [...selectedColumns];
  let groupedByExsteIndex = -1;
  const itemToCompareWith =
    Array.isArray(groupdData) &&
    (GroupedByOrderByDictionary[groupdData[0]] || groupdData[0]);
  if (Array.isArray(groupdData) && groupdData.length > 0) {
    groupedByExsteIndex = selectedColumns.findIndex(
      (e) => e.column === itemToCompareWith
    );
    if (groupedByExsteIndex !== -1) {
      groupedByExistesSoDoesOrderBy = (
        <OrderByGroupedBYChild
          iconDict={iconDict}
          column={selectedColumns[groupedByExsteIndex]}
          filterDict={filterDict}
        />
      );
      resolvedSelectedColumns.splice(groupedByExsteIndex, 1);
    }
  }

  const removeColumn = (field) => {
    var index = selectedColumns.findIndex((e) => {
      return e.column === field;
    });
    if (index > -1) {
      const tempArray = selectedColumns;
      tempArray.splice(index, 1);
      onChange([...tempArray]);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list

    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }
    if (groupedByExsteIndex !== -1) {
      const newOrderedColumns = reorder(
        resolvedSelectedColumns,
        result.source.index,
        result.destination.index
      );

      if (onChange) {
        onChange([selectedColumns[groupedByExsteIndex], ...newOrderedColumns]);
      }
    } else {
      const newOrderedColumns = reorder(
        selectedColumns,
        result.source.index,
        result.destination.index
      );

      if (onChange) {
        onChange([...newOrderedColumns]);
      }
    }
  };

  const DragableContent = resolvedSelectedColumns.map((column, index) => {
    return (
      <Draggable
        draggableId={
          column.column ? column.column : column.field + column.value
        }
        index={index}
        key={
          column.column ? column.column : column.field + column.value + index
        }
      >
        {(draggableProvided, draggableSnapshot) => (
          <PortalAwareItem
            enableDeactivation={enableDeactivation}
            filterDict={filterDict}
            removeColumn={removeColumn}
            column={column}
            iconDict={iconDict}
            item={itemsArray && itemsArray[index]}
            provided={draggableProvided}
            snapshot={draggableSnapshot}
          />
        )}
      </Draggable>
    );
  });

  return (
    <div className="mt-1">
      <div style={{ height: "auto" }} className="FilterChooserOrderList">
        {groupedByExistesSoDoesOrderBy}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {DragableContent}

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const OrderByGroupedBYChild = ({ filterDict, column, iconDict }) => {
  const icon = useMemo(
    (item) => {
      if (iconDict && iconDict[column.column]) {
        return iconDict[column.column];
      }
      return undefined;
    },
    [column.column, iconDict]
  );
  return (
    <div style={{ opacity: 0.6 }} className={`py-1 text-black`}>
      <div
        className={`a-ordenable-filters d-flex pb-2 align-items-center justify-content-between fs-14`}
      >
        <span className="Filter-GripVerticalIcon ml-2"></span>

        <span className="flex-1 of-hidden">
          <GridSort
            className="a-filters-sortable-icon"
            asc={column.type === "asc"}
            desc={column.type === "desc"}
          />
          {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
          <span className="text-truncate">
            {filterDict ? filterDict[column.column] : column.column}
          </span>
        </span>

        <span style={{ color: "#ff5962", fontSize: 16 }} className="mr-2">
          <FontAwesomeIcon icon={faLock} />
        </span>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const GroupByChooserDragOrder = ({
  selectedColumns,
  onChange,
  filterDict,
  iconDict,
  itemsArray
}) => {
  const removeColumn = (field) => {
    var index = selectedColumns.indexOf(field);
    if (index > -1) {
      const tempArray = selectedColumns;
      tempArray.splice(index, 1);
      onChange([...tempArray]);
    }
  };

  const memoedIconDict = useMemo(() => {
    return {
      ...iconDict,
      ...GroupedByDictionaryForIcons
    };
  }, [iconDict]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedColumns = reorder(
      selectedColumns,
      result.source.index,
      result.destination.index
    );

    if (onChange) {
      onChange([...newOrderedColumns]);
    }
  };

  const DragableContent = selectedColumns.map((column, index) => {
    return (
      <Draggable draggableId={column} index={index} key={column}>
        {(draggableProvided, draggableSnapshot) => (
          <PortalAwareItem
            filterDict={filterDict}
            removeColumn={removeColumn}
            column={column}
            iconDict={memoedIconDict}
            addRemove
            item={GroupedByDictionaryForFilterBar[column]}
            iconItem={column}
            provided={draggableProvided}
            snapshot={draggableSnapshot}
          />
        )}
      </Draggable>
    );
  });

  return (
    <div className="mt-1">
      <div style={{ height: "auto" }} className="FilterChooserOrderList">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {DragableContent}

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const PortalAwareItem = ({
  provided,
  snapshot,
  filterDict,
  column,
  removeColumn,
  isDragDisabled,
  enableDeactivation,
  iconDict,
  required,
  iconItem,
  addRemove,
  item
}) => {
  const providedd = provided;
  const snapshott = snapshot;
  const droppedOutside =
    snapshott.isDropAnimating &&
    snapshott.draggingOver !== "droppable" &&
    (snapshot.dropAnimation.moveTo.y < -50 ||
      snapshot.dropAnimation.moveTo.y > 50);

  const usePortal = snapshott.isDragging;

  const style = {
    ...providedd.draggableProps.style,
    zIndex: 99999999,
    outline: "none",
    visibility: droppedOutside ? "hidden" : "initial"
  };

  const icon = useMemo(
    (item) => {
      if (iconDict && iconDict[column.column]) {
        return iconDict[column.column];
      }
      if (iconItem && iconDict[iconItem]) return iconDict[iconItem];
      return undefined;
    },
    [column.column, iconDict, iconItem]
  );
  const intl = useIntl();
  const child = (
    <div
      ref={providedd.innerRef}
      {...providedd.draggableProps}
      {...providedd.dragHandleProps}
      style={style}
      className={`py-1 text-black ${usePortal && "dragging"}`}
    >
      <div
        className={`a-ordenable-filters d-flex pb-2 ${
          usePortal && "dragging"
        } align-items-center justify-content-between fs-14`}
      >
        <span className="Filter-GripVerticalIcon ml-2">
          {!isDragDisabled && (
            <FontAwesomeIcon
              className="PointerEventsRemove"
              icon={faGripVertical}
            />
          )}
        </span>
        {!item && (
          <span className="flex-1 of-hidden">
            <div className="d-flex align-items-center">
              <GridSort
                className="a-filters-sortable-icon"
                asc={column.type === "asc"}
                desc={column.type === "desc"}
              />

              {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
              <span className="text-truncate">
                {filterDict
                  ? typeof filterDict[column.column] === "function"
                    ? filterDict[column.column]()
                    : filterDict[column.column]
                  : column.column}
              </span>
            </div>
          </span>
        )}
        {!required && !item && (
          <span
            onClick={() => removeColumn(column.column)}
            style={{ cursor: "pointer", color: "#ff5962", fontSize: 16 }}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </span>
        )}
        {item && (
          <div className="flex-1 overflow-hidden">
            {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
            {typeof item === "function"
              ? item()
              : typeof item !== "string"
              ? item
              : intl.formatMessage({ id: item })}
          </div>
        )}
        {item && addRemove && (
          <span
            onClick={() => removeColumn(column)}
            style={{ cursor: "pointer", color: "#ff5962", fontSize: 16 }}
            className="mr-2"
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </span>
        )}
      </div>
    </div>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, document.body);
};

const useOrderedFilterOptions = (filterColumns) => {
  const f = useIntl().formatMessage;

  return useMemo(() => {
    const newArr = [];

    for (const filterColumn of filterColumns) {
      const { title, titleId, icon } = filterColumn;
      newArr.push({
        title: title ? title : f({ id: titleId }),
        filterColumn,
        icon
      });
    }

    return newArr.sort((a, b) => {
      if (a.title < b.title) return -1;

      if (a.title > b.title) return 1;

      return 0;
    });
  }, [f, filterColumns]);
};

export const FilterOptions = ({ data, onGoBack, onAddFilter }) => {
  const orderedData = useOrderedFilterOptions(data);

  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const filteredOrderedData = useMemo(() => {
    if (!text) return orderedData;
    else
      return orderedData.filter((e) => {
        return e.title.toLowerCase().includes(text.toLowerCase());
      });
  }, [orderedData, text]);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <div className="a-filter-view-1 fs-14">
      <FilterBackButton onClick={onGoBack} />
      <div className="a-filter-filter-title">
        <FormattedMessage id={"ADD_FILTER"} />
      </div>
      <Input
        ref={inputRef}
        onChange={handleTextChange}
        value={text}
        className="my-2 bg-white "
      />
      {filteredOrderedData.map((item, i) => (
        <FilterOptionItem
          key={i}
          onClick={() => {
            onAddFilter(item.filterColumn);
          }}
        >
          <div className="d-flex">
            <div style={{ width: 18 }} className="mr-2">
              {item.icon}
            </div>
            <span>{item.title}</span>
          </div>
        </FilterOptionItem>
      ))}
      {filteredOrderedData.length === 0 && (
        <div>
          <FormattedMessage id="NO_FILTERED_FILTERS" />
        </div>
      )}
    </div>
  );
};

const GroupByOptions = ({
  data,
  onGoBack,
  onApply,
  filterDict,
  iconDict,
  groupableColumns
}) => {
  const filteredArray = groupableColumns.filter(function (e) {
    return this && this.indexOf(e) < 0;
  }, data);

  const reversedFilterDict = reverseMapping(GroupedByDictionaryForFilterBar);

  const sortedOrdenableColumns = filteredArray
    .map((item) => {
      return GroupedByDictionaryForFilterBar[item];
    })
    .sort();

  const getIcon = useCallback(
    (item) => {
      if (iconDict && Object.keys(iconDict)?.length > 0) {
        const reveserdItem =
          reversedFilterDict[
            typeof item === "object" ? item.props.id : item
          ].split(".");

        let result = undefined;
        for (const e of reveserdItem) {
          if (iconDict[e]) result = iconDict[e];
        }

        return result;
      }
      return undefined;
    },
    [iconDict, reversedFilterDict]
  );
  const intl = useIntl();
  return (
    <div className="a-filter-view-1">
      <FilterBackButton onClick={onGoBack} />
      <div className="a-filter-filter-title">
        <FormattedMessage id={"GROUP_BY"} />
      </div>
      {sortedOrdenableColumns.map((item, i) => {
        const icon = getIcon(item);
        return (
          <GroupByOptionItem
            field={reversedFilterDict[item]}
            key={i}
            onClick={() => {
              if (data.indexOf(item) === -1) {
                const reveserItem =
                  reversedFilterDict[
                    typeof item === "object" ? item.props.id : item
                  ].split(".");
                let result = undefined;
                for (const e of reveserItem) {
                  if (filteredArray.includes(e)) result = e;
                }
                onApply([result]);
              }
              onGoBack();
            }}
          >
            <div className="d-flex align-items-center">
              {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
              {typeof item === "function"
                ? item()
                : intl.formatMessage({ id: item })}
            </div>
          </GroupByOptionItem>
        );
      })}
    </div>
  );
};

const GroupByOptionItem = ({ state, field, onClick, children, ...rest }) => {
  return (
    <div
      onClick={onClick}
      className="a-filter-filter-item ssi-button-link py-1 px-1"
      {...rest}
    >
      <div className="d-flex align-items-center">{children}</div>
    </div>
  );
};

export const OrderByOptions = ({
  data,
  onGoBack,
  onApply,
  GroupdData,
  ApplyAuto,
  filterDict,
  iconDict,
  RemoveReturnButton,
  ordenableColums
}) => {
  const [orderByState, setOrderByState] = useState([...data]);

  const hasMaxOrderBys = useMemo(() => {
    if (orderByState.length >= 2) {
      return true;
    } else return false;
  }, [orderByState.length]);

  const handleOrderClick = (field) => {
    ////;
    const index = orderByState.findIndex((e) => {
      return e.column === field;
    });
    if (index === -1 && !hasMaxOrderBys) {
      setOrderByState([...orderByState, { column: field, type: "asc" }]);
    } else if (index !== -1) {
      const tempState = orderByState;
      if (tempState[index].type === "asc") {
        tempState[index].type = "desc";
        setOrderByState([...tempState]);
      } else if (GroupdData?.length > 0 && field === GroupdData[0]) {
        tempState[index].type = "asc";
        setOrderByState([...tempState]);
      } else {
        tempState.splice(index, 1);
        setOrderByState([...tempState]);
      }
    }
  };

  useEffect(() => {
    if (ApplyAuto) {
      onApply(orderByState);
    }
  }, [ApplyAuto, onApply, orderByState]);

  const reversedFilterDict = reverseMapping(filterDict);
  // const reolvedOrdenableColums =
  //   GroupdData.length > 0
  //     ? ordenableColums.filter(e => e !== GroupdData[0])
  //     : ordenableColums;
  const intl = useIntl();
  const sortedOrdenableColumns = ordenableColums
    .map((item) => {
      const resolvedItem =
        filterDict[typeof item === "object" ? item.props.id : item];

      if (typeof resolvedItem !== "string") {
        return intl.formatMessage({ id: resolvedItem.props.id });
      }

      return resolvedItem;
    })
    .sort();
  const getIcon = useCallback(
    (item) => {
      if (iconDict && Object.keys(iconDict)?.length > 0) {
        let result =
          iconDict[
            reversedFilterDict[typeof item === "object" ? item.props.id : item]
          ];
        return result;
      }
      return undefined;
    },
    [iconDict, reversedFilterDict]
  );

  return (
    <div className="a-filter-view-1">
      {!RemoveReturnButton && <FilterBackButton onClick={onGoBack} />}
      <div className="a-filter-filter-title d-flex">
        <FormattedMessage id={"ORDER_BY"} />
        <div className="text-danger-alt ml-2 fs-12 my-auto">(2 Max)</div>
      </div>
      {sortedOrdenableColumns.map((item, i) => {
        const icon = getIcon(item);
        return (
          <OrderByOptionItem
            state={orderByState}
            field={
              reversedFilterDict[
                typeof item === "object" ? item.props.id : item
              ]
            }
            key={i}
            onClick={() => {
              handleOrderClick(
                reversedFilterDict[
                  typeof item === "object" ? item.props.id : item
                ]
              );
            }}
          >
            <div className="d-flex align-items-center">
              {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
              {typeof item === "function" ? item() : item}
            </div>
          </OrderByOptionItem>
        );
      })}
      {!ApplyAuto && (
        <div className="mt-2 d-flex justify-content-center">
          <Button
            size="sm"
            className="mt-3 mx-auto d-block fs-14 fw-regular"
            vType="primary-ghost"
            onClick={() => {
              onApply(orderByState);
              onGoBack();
            }}
          >
            <FormattedMessage id="APPLY" />
          </Button>
        </div>
      )}
    </div>
  );
};

const OrderByOptionItem = ({
  state,
  field,
  icon,
  onClick,
  children,
  ...rest
}) => {
  const index = state.findIndex((e) => {
    return e.column === field;
  });
  const ascValue = index !== -1 && state[index].type === "asc";
  const descValue = index !== -1 && state[index].type === "desc";
  return (
    <div
      onClick={onClick}
      className="a-filter-filter-item disableLabelSelect ssi-button-link py-1 px-1"
      {...rest}
    >
      <div className="d-flex align-items-center">
        {index !== -1 && <span className="text-primary mr-1">{index + 1}</span>}
        <GridSort
          className="a-filters-sortable-icon"
          asc={ascValue}
          desc={descValue}
        />
        {typeof children === "function" ? children() : children}
      </div>
    </div>
  );
};

const FilterInput = ({ operator, filterType }) => {
  const {
    operator: currentOperator,
    value,
    setValue,
    handleChange,
    applyFilter
  } = useContext(FilterSelectorContext);

  const inputRef = useRef();

  const isSelected = operator === currentOperator;
  useEffect(() => {
    //   ////
    if (!isSelected) return;
    inputRef.current.focus();
  }, [isSelected]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      applyFilter();
    }
  };

  const handleValueChange = (e) => {
    const ALLOWED_CHARS_REGEXP = /[\{\}\(\)/]+/;
    if (ALLOWED_CHARS_REGEXP.test(e.target.value)) {
      return;
    } else {
      if (filterType === "number" && isNaN(e.target.value)) return;

      setValue(e.target.value);
    }
  };

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange("", operator)}
          checked={isSelected}
          name="filter"
          value={operator}
          bgWhite={true}
          textValue={
            <FormattedMessage id={messages.filters.default[operator]} />
          }
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <Input
            className="bg-white"
            onKeyUp={handleKeyUp}
            ref={inputRef}
            value={value}
            onChange={handleValueChange}
          />
        )}
      </div>
    </div>
  );
};

// const FilterRadio = ({ operator }) => {
//   const { operator: currentOperator, handleChange } = useContext(
//     FilterSelectorContext
//   );

//   const isSelected = operator === currentOperator;

//   return (
//     <div className="a-filter-radio-full-container">
//       <div className="text-black">
//         <Radio
//           change={() => handleChange(null, operator)}
//           checked={isSelected}
//           name="filter"
//           value={operator}
//           bgWhite={true}
//           textValue={
//             <FormattedMessage id={messages.filters.default[operator]} />
//           }
//         />
//       </div>
//     </div>
//   );
// };

const isDateType = (type) => {
  return type.includes("date");
};

const FilterDatepicker = React.memo(({ operator, ensureIsDate }) => {
  const {
    operator: currentOperator,
    value,
    setValue,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected =
    operator === currentOperator &&
    value !== "today" &&
    value !== "yesterday" &&
    value !== "tomorow";

  if (ensureIsDate && isSelected) {
    if (typeof value === "string") isSelected = false;
  }

  // useMemo(() => {
  //   if (isSelected) setValue(new Date());
  //   return true;
  // }, [setValue, isSelected]);

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => {
            if (typeof value === "string") handleChange(new Date(), operator);
            else handleChange(value || new Date(), operator);
          }}
          checked={isSelected}
          name="filter"
          value={operator}
          bgWhite={true}
          textValue={
            <FormattedMessage id={messages.filters.default[operator]} />
          }
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div>
    </div>
  );
});
const FilterDateToday = () => {
  const {
    operator: currentOperator,
    value,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected = currentOperator === "eq" && value === `today`;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange(`today`, "eq")}
          checked={isSelected}
          name="filter"
          value={"eq"}
          bgWhite={true}
          textValue={
            <div style={{ textTransform: "lowercase" }}>
              <FormattedMessage id="TODAY" />
            </div>
          }
        />
      </div>
      {/* <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div> */}
    </div>
  );
};
const FilterDateTomorow = () => {
  const {
    operator: currentOperator,
    value,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected = currentOperator === "eq" && value === `tomorow`;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange(`tomorow`, "eq")}
          checked={isSelected}
          name="filter"
          value={"eq"}
          bgWhite={true}
          textValue={
            <div style={{ textTransform: "lowercase" }}>
              <FormattedMessage id="TOMOROW" />
            </div>
          }
        />
      </div>
      {/* <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div> */}
    </div>
  );
};
const FilterDateYesterday = () => {
  const {
    operator: currentOperator,
    value,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected = currentOperator === "eq" && value === `yesterday`;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange(`yesterday`, "eq")}
          checked={isSelected}
          name="filter"
          value={"eq"}
          bgWhite={true}
          textValue={
            <div style={{ textTransform: "lowercase" }}>
              <FormattedMessage id="YESTERDAY" />
            </div>
          }
        />
      </div>
      {/* <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div> */}
    </div>
  );
};

const FilterDateLastDynamicDays = () => {
  const {
    operator: currentOperator,
    value,
    handleChange,
    applyFilter
  } = useContext(FilterSelectorContext);

  let isSelected =
    currentOperator === "gt" &&
    typeof value === "string" &&
    value.includes("lastX");

  const inputRef = useRef();
  useEffect(() => {
    //   ////
    if (!isSelected) return;
    inputRef.current.focus();
  }, [isSelected]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      applyFilter();
    }
  };

  const handleValueChanges = (e) => {
    const { value: thisVal } = e.target;

    handleChange(`lastX${thisVal}`, "gt");
  };

  const split = value && typeof value === "string" ? value.split("X") : [];
  const xValue = parseInt(split[1]) || 1;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => {
            handleValueChanges({ target: { value: 1 } });
          }}
          checked={isSelected}
          name="filter"
          value={"gt"}
          bgWhite={true}
          textValue={<FormattedMessage id="FILTER_LAST_DAYS" />}
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <Input
            className="bg-white"
            type="number"
            min="0"
            onKeyUp={handleKeyUp}
            ref={inputRef}
            value={xValue}
            onChange={handleValueChanges}
          />
        )}
      </div>
    </div>
  );
};

const FilterDateNotLastDynamicDays = () => {
  const {
    operator: currentOperator,
    value,
    handleChange,
    applyFilter
  } = useContext(FilterSelectorContext);

  let isSelected =
    currentOperator === "lt" &&
    typeof value === "string" &&
    value.includes("notLastX");

  const inputRef = useRef();
  useEffect(() => {
    //   ////
    if (!isSelected) return;
    inputRef.current.focus();
  }, [isSelected]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      applyFilter();
    }
  };

  const handleValueChanges = (e) => {
    const { value: thisVal } = e.target;

    handleChange(`notLastX${thisVal}`, "lt");
  };

  const split = value && typeof value === "string" ? value.split("X") : [];
  const xValue = parseInt(split[1]) || 1;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => {
            handleValueChanges({ target: { value: 1 } });
          }}
          checked={isSelected}
          name="filter"
          value={"lt"}
          bgWhite={true}
          textValue={<FormattedMessage id="FILTER_NOT_LAST_DAYS" />}
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <Input
            className="bg-white"
            type="number"
            min="0"
            onKeyUp={handleKeyUp}
            ref={inputRef}
            value={xValue}
            onChange={handleValueChanges}
          />
        )}
      </div>
    </div>
  );
};

const FilterDateNextDynamicDays = () => {
  const {
    operator: currentOperator,
    value,
    handleChange,
    applyFilter
  } = useContext(FilterSelectorContext);

  let isSelected =
    currentOperator === "lt" &&
    typeof value === "string" &&
    value.includes("nextX");

  const inputRef = useRef();
  useEffect(() => {
    //   ////
    if (!isSelected) return;
    inputRef.current.focus();
  }, [isSelected]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      applyFilter();
    }
  };

  const handleValueChanges = (e) => {
    const { value: thisVal } = e.target;

    handleChange(`nextX${thisVal}`, "lt");
  };

  const split = value && typeof value === "string" ? value.split("X") : [];
  const xValue = parseInt(split[1]) || 1;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => {
            handleValueChanges({ target: { value: 1 } });
          }}
          checked={isSelected}
          name="filter"
          value={"gt"}
          bgWhite={true}
          textValue={<FormattedMessage id="FILTER_NEXT_DAYS" />}
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <Input
            className="bg-white"
            type="number"
            min="0"
            onKeyUp={handleKeyUp}
            ref={inputRef}
            value={xValue}
            onChange={handleValueChanges}
          />
        )}
      </div>
    </div>
  );
};

const FilterDateNotNextDynamicDays = () => {
  const {
    operator: currentOperator,
    value,
    handleChange,
    applyFilter
  } = useContext(FilterSelectorContext);

  let isSelected =
    currentOperator === "gt" &&
    typeof value === "string" &&
    value.includes("notNextX");

  const inputRef = useRef();
  useEffect(() => {
    //   ////
    if (!isSelected) return;
    inputRef.current.focus();
  }, [isSelected]);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      applyFilter();
    }
  };

  const handleValueChanges = (e) => {
    const { value: thisVal } = e.target;

    handleChange(`notNextX${thisVal}`, "gt");
  };

  const split = value && typeof value === "string" ? value.split("X") : [];
  const xValue = parseInt(split[1]) || 1;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => {
            handleValueChanges({ target: { value: 1 } });
          }}
          checked={isSelected}
          name="filter"
          value={"gt"}
          bgWhite={true}
          textValue={<FormattedMessage id="FILTER_NOT_NEXT_DAYS" />}
        />
      </div>
      <div className="a-filter-radio-area">
        {isSelected && (
          <Input
            className="bg-white"
            type="number"
            min="0"
            onKeyUp={handleKeyUp}
            ref={inputRef}
            value={xValue}
            onChange={handleValueChanges}
          />
        )}
      </div>
    </div>
  );
};

const FilterDateLastDays = ({ days }) => {
  const {
    operator: currentOperator,
    value,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected = currentOperator === "gt" && value === `last${days}`;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange(`last${days}`, "gt")}
          checked={isSelected}
          name="filter"
          value={"gt"}
          bgWhite={true}
          textValue={
            <FormattedMessage id="FILTER_LAST_X_DAYS" values={{ days }} />
          }
        />
      </div>
      {/* <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div> */}
    </div>
  );
};

const FilterDateNextDays = ({ days }) => {
  const {
    operator: currentOperator,
    value,
    handleChange
  } = useContext(FilterSelectorContext);

  let isSelected = currentOperator === "lt" && value === `next${days}`;

  return (
    <div className="a-filter-radio-full-container">
      <div className="text-black">
        <Radio
          change={() => handleChange(`next${days}`, "lt")}
          checked={isSelected}
          name="filter"
          value={"lt"}
          bgWhite={true}
          textValue={
            <FormattedMessage id="FILTER_NEXT_X_DAYS" values={{ days }} />
          }
        />
      </div>
      {/* <div className="a-filter-radio-area">
        {isSelected && (
          <DatePicker
            placement="top-end"
            className="bg-white"
            onChange={(data) => setValue(data.Date)}
            value={value || new Date()}
            enableHours={false}
          />
        )}
      </div> */}
    </div>
  );
};

// const FilterDatepickerComparasion = ({ operator }) => {
//   const defaultValue = useMemo(() => {
//     return {
//       start: null,
//       end: null
//     };
//   }, []);

//   const {
//     operator: currentOperator,
//     value,
//     setValue,
//     handleChange
//   } = useContext(FilterSelectorContext);

//   const isSelected = operator === currentOperator;

//   const handleValueChange = useCallback(
//     (name, value) => {
//       setValue((v) => {
//         return {
//           ...v,
//           [name]: value
//         };
//       });
//     },
//     [setValue]
//   );
//   useMemo(() => {
//     if (isSelected) handleValueChange("start", new Date());
//     if (isSelected) handleValueChange("end", new Date());
//     return true;
//   }, [isSelected, handleValueChange]);
//   return (
//     <div className="a-filter-radio-full-container">
//       <div className="text-black">
//         <Radio
//           change={() =>
//             handleChange(defaultValue, operator, {
//               isValueValid: (v) => v.start && v.end
//             })
//           }
//           checked={isSelected}
//           name="filter"
//           bgWhite={true}
//           value={operator}
//           textValue={
//             <FormattedMessage id={messages.filters.default[operator]} />
//           }
//         />
//       </div>
//       {isSelected && (
//         <React.Fragment>
//           <div className="a-filter-radio-area">
//             <DatePicker
//               placement="top-end"
//               className="bg-white"
//               onChange={(data) => handleValueChange("start", data.Date)}
//               value={value.start || new Date()}
//               enableHours={false}
//             />
//           </div>
//           <div className="a-filter-radio-area a-filter-filter-title">e</div>
//           <div className="a-filter-radio-area">
//             <DatePicker
//               placement="top-end"
//               className="bg-white"
//               onChange={(data) => handleValueChange("end", data.Date)}
//               value={value.end || new Date()}
//               enableHours={false}
//             />
//           </div>
//         </React.Fragment>
//       )}
//     </div>
//   );
// };

const FilterSelectorContext = React.createContext();

export const FilterSelector = ({
  filterItem,
  onAddFilter,
  onGoBack,
  currentFilter
}) => {
  const [value, setValue] = useState(() => {
    if (currentFilter) return currentFilter.filter.value;
    else return undefined;
  });
  const [isValueValidFunc, setIsValueValidFunc] = useState(null);
  const [operator, setOperator] = useState(() => {
    if (currentFilter) return currentFilter.filter.operator;
    else return undefined;
  });

  const {
    type: filterType,
    title,
    titleId,
    field,
    getField,
    getResolvedValue,
    applyOnChange
  } = filterItem;
  const applyFilter = (optionalValue) => {
    let resolvedValue = optionalValue !== undefined ? optionalValue : value;
    if (isValueValidFunc) {
      if (!isValueValidFunc(resolvedValue)) return;
    } else if (resolvedValue === null || resolvedValue === undefined) return;

    onAddFilter({
      field: getField ? getField(resolvedValue) : field,
      value: resolvedValue,
      resolvedValue: getResolvedValue
        ? getResolvedValue(resolvedValue)
        : undefined,
      operator: operator || "eq",
      data: filterItem
    });
  };
  const autoSetValue = applyOnChange
    ? (v) => {
        if (v !== null && v !== undefined) applyFilter(v);
        else setValue(v);
      }
    : setValue;

  const contextValue = {
    value,
    setValue: autoSetValue,
    filterType,
    operator,
    handleChange: (defaultValue, operator, { isValueValid } = {}) => {
      setValue(defaultValue);
      setOperator(operator);
      setIsValueValidFunc(() => isValueValid);
    },
    applyFilter,
    onSelectFilter: () => {}
  };
  const applyFilterButton = (
    <Button
      size="sm"
      className="mt-3 mx-auto d-block fs-14 fw-regular"
      vType="primary-ghost"
      onClick={() => contextValue.applyFilter()}
    >
      <FormattedMessage id={"ADD_FILTER"} />
    </Button>
  );

  const { params } = useContext(ServerAwareContext);

  const intl = useIntl();
  const buildContent = () => {
    if (!filterType || filterType === "custom") {
      const { component: Component, requiresParams } = filterItem;

      return (
        <div className="a-filter-view-2">
          <FilterBackButton onClick={onGoBack} />
          <div className="a-filter-filter-title text-truncate">
            {title ? title : intl.formatMessage({ id: titleId })} ...
          </div>
          <Component
            value={value}
            onChange={autoSetValue}
            params={requiresParams ? params : null}
            className="bg-white"
          />
          {applyFilterButton}
        </div>
      );
    }

    return (
      <div className="a-filter-view-2">
        <FilterBackButton onClick={onGoBack} />

        <div className="a-filter-filter-title text-truncate">
          {title ? title : intl.formatMessage({ id: titleId })} ...
        </div>
        {(filterType === "string" || filterType === "number") && (
          <FilterInput filterType={filterType} operator="eq" />
        )}
        {(filterType === "string" || filterType === "number") && (
          <FilterInput filterType={filterType} operator="ne" />
        )}
        {filterType === "number" && (
          <FilterInput filterType={filterType} operator="lt" />
        )}
        {filterType === "number" && (
          <FilterInput filterType={filterType} operator="le" />
        )}
        {filterType === "number" && (
          <FilterInput filterType={filterType} operator="gt" />
        )}
        {filterType === "number" && (
          <FilterInput filterType={filterType} operator="ge" />
        )}
        {filterType === "string" && (
          <FilterInput filterType={filterType} operator="startswith" />
        )}
        {filterType === "string" && (
          <FilterInput filterType={filterType} operator="endswith" />
        )}
        {filterType === "string" && (
          <FilterInput filterType={filterType} operator="contains" />
        )}
        {filterType === "string" && (
          <FilterInput filterType={filterType} operator="doesnotcontain" />
        )}
        {isDateType(filterType) && <FilterDatepicker operator="eq" />}
        {isDateType(filterType) && <FilterDatepicker operator="ne" />}
        {isDateType(filterType) && (
          <FilterDatepicker ensureIsDate operator="lt" />
        )}
        {isDateType(filterType) && (
          <FilterDatepicker ensureIsDate operator="le" />
        )}
        {isDateType(filterType) && (
          <FilterDatepicker operator="gt" ensureIsDate />
        )}
        {isDateType(filterType) && (
          <FilterDatepicker operator="ge" ensureIsDate />
        )}
        {isDateType(filterType) && <FilterDateToday />}
        {isDateType(filterType) && <FilterDateTomorow />}
        {isDateType(filterType) && <FilterDateYesterday />}

        {isDateType(filterType) && <FilterDateLastDynamicDays />}
        {isDateType(filterType) && <FilterDateNotLastDynamicDays />}

        {isDateType(filterType) && <FilterDateNextDynamicDays />}
        {isDateType(filterType) && <FilterDateNotNextDynamicDays />}

        {/* {isDateType(filterType) && <FilterDateLastDays days={7} />}
        {isDateType(filterType) && <FilterDateLastDays days={15} />}
        {isDateType(filterType) && <FilterDateLastDays days={30} />}
        {isDateType(filterType) && <FilterDateLastDays days={45} />}
        {isDateType(filterType) && <FilterDateLastDays days={60} />}
        {isDateType(filterType) && <FilterDateLastDays days={180} />}
        {isDateType(filterType) && <FilterDateLastDays days={365} />}
        {isDateType(filterType) && <FilterDateNextDays days={7} />}
        {isDateType(filterType) && <FilterDateNextDays days={15} />}
        {isDateType(filterType) && <FilterDateNextDays days={30} />}
        {isDateType(filterType) && <FilterDateNextDays days={45} />}
        {isDateType(filterType) && <FilterDateNextDays days={60} />}
        {isDateType(filterType) && <FilterDateNextDays days={180} />}
        {isDateType(filterType) && <FilterDateNextDays days={365} />} */}
        {/* {isDateType(filterType) && (
          <FilterDatepickerComparasion
            className="bg-white"
            operator="isbetween"
          />
        )} */}

        {/* {(filterType === "string" ||
          filterType === "number" ||
          isDateType(filterType)) && <FilterRadio operator="isempty" />}
        {(filterType === "string" ||
          filterType === "number" ||
          isDateType(filterType)) && <FilterRadio operator="isnotempty" />} */}
        {applyFilterButton}
      </div>
    );
  };

  return (
    <FilterSelectorContext.Provider value={contextValue}>
      {buildContent()}
    </FilterSelectorContext.Provider>
  );
};

const advancedFilterResponseShema = {
  d: {
    results: many(spaceAdvancedFilterSchema)
  }
};

const ColorFilterOption = ({ colorFilter, addColorFilter }) => {
  const { Name, Color } = colorFilter;

  return (
    <FilterOptionItem
      className="d-flex align-items-center"
      onClick={() => addColorFilter(colorFilter)}
    >
      <div
        className="rounded-50 mr-2"
        style={{ background: Color, width: 24, height: 24 }}
      ></div>
      <span className="text-truncate flex-1">{Name}</span>
    </FilterOptionItem>
  );
};

const AdvancedFilterOption = ({ advancedFilter: id, addAdvancedFilter }) => {
  const advancedFilter = useAdvancedFilter(id);
  const { Name, Account: AccountId } = advancedFilter;
  const Account = useAccount(AccountId);
  return (
    <FilterOptionItem onClick={() => addAdvancedFilter(id)} title={Name}>
      <AccountProfileImg
        className="circular mr-2 flex-0-0-auto d-flex d-inline-block"
        size="xsm"
        account={Account}
      />
      <span className="text-truncate">{Name}</span>
    </FilterOptionItem>
  );
};

const AdvancedFilterSelector = ({ onGoBack, filterType, availableFilters }) => {
  const [query, setQuery] = useState("");
  const [openSidebar, closeSidebar] = useSidebar();
  const [resolvedQuery, setResolvedQuery] = useState("");
  const { onParamsChange, consumerRef } = useServerAwareState();
  const intl = useIntl();
  // useEffect(() => {
  //   return () => closeSidebar();
  // }, [closeSidebar]);

  const handleSuccess = (advancedFilter) => {
    onParamsChange((p) => {
      const { advancedFilters } = p;
      const newAdvancedFilters = advancedFilters ? [...advancedFilters] : [];
      //;
      if (!newAdvancedFilters.includes(advancedFilter))
        newAdvancedFilters.push(advancedFilter);
      else {
        if (consumerRef.current) {
          consumerRef.current.refetch();
        }
      }

      return {
        advancedFilters: newAdvancedFilters
      };
    });
    closeSidebar();
    onGoBack();
  };

  const handleRemoval = (advancedFilter) => {
    onParamsChange((p) => {
      const { advancedFilters } = p;
      const newAdvancedFilters = advancedFilters ? [...advancedFilters] : [];
      //;
      const removeFilter = () => {
        const index = newAdvancedFilters.indexOf(advancedFilter);

        if (index === -1) return;
        newAdvancedFilters.splice(index, 1);
        removeFilter();
      };

      removeFilter();

      return {
        advancedFilters: newAdvancedFilters
      };
    });

    if (consumerRef.current) {
      consumerRef.current.refetch();
    }
    closeSidebar();
  };
  const handleAddOrUpdateAdvancedFilter = (advancedFilter) => {
    openSidebar(
      <AdvancedFilterSidebar
        id={advancedFilter}
        filterType={filterType}
        availableFilters={availableFilters}
        onSuccess={handleSuccess}
        onRemoval={handleRemoval}
      />
    );
  };

  const [showAll, setShowAll] = useState(false);

  const handleQueryChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResolvedQuery(query);
    }, 300);

    return () => clearTimeout(timeout);
  }, [query]);

  const { Id: currentUserId } = useCurrentAccount();

  const search = useMemo(() => {
    const obj = {
      Type: filterType
    };

    if (!showAll) obj.$filter = `((Account/Id eq '${currentUserId}'))`;
    if (resolvedQuery) obj.Query = resolvedQuery;

    return querystring.stringify(obj);
  }, [currentUserId, filterType, resolvedQuery, showAll]);

  const { loading, data } = useSpaceQuery(
    `CustomFilters?${search}`,
    advancedFilterResponseShema,
    { cache: false }
  );

  const buildList = () => {
    if (loading || !data)
      return (
        <LoaderSpinner
          size="xs"
          className="text-primary py-2"
          centerHorizontal
        />
      );

    const {
      d: { results }
    } = data;

    if (results.length === 0)
      return (
        <div>
          {" "}
          <FormattedMessage id={"EMPTY_FILTERS"} />
        </div>
      );

    const onAdvancedFilterClick = (advancedFilter) => {
      onParamsChange((p) => {
        const { advancedFilters } = p;
        const newAdvancedFilters = advancedFilters ? [...advancedFilters] : [];

        newAdvancedFilters.push(advancedFilter);

        return {
          advancedFilters: newAdvancedFilters
        };
      });
      onGoBack();
    };

    return (
      <div className="d-flex flex-column">
        {results.map((r) => (
          <AdvancedFilterOption
            key={r}
            addAdvancedFilter={onAdvancedFilterClick}
            advancedFilter={r}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="of-y-auto h-100">
      <FilterBackButton onClick={onGoBack} />
      <Checkbox
        className="mt-4 d-flex align-items-center text-black fs-14 fw-medium"
        checked={showAll}
        onChange={(e) => setShowAll(e.target.checked)}
        textValue={<FormattedMessage id={"SHOW_ALL"} />}
      />
      {/* <span className="ml-2 text-black fs-14 fw-medium">Mostrar todos</span> */}
      {/* </Checkbox> */}
      <Input
        placeholder={intl.formatMessage({ id: "SEARCH" })}
        className="mb-2 mt-3 bg-white"
        value={query}
        onChange={handleQueryChange}
      />
      {buildList()}
      <Button
        className="mt-3 mb-3 h-32 mx-auto d-block fs-14 fw-regular w-100"
        vType="primary-ghost"
        onClick={() => handleAddOrUpdateAdvancedFilter()}
      >
        <FormattedMessage id={"CREATE_ADVANCED_FILTER"} />
      </Button>
    </div>
  );
};

const ColorFilterSelector = ({ onGoBack, filterType, availableFilters }) => {
  const [query, setQuery] = useState("");
  const [openSidebar, closeSidebar] = useSidebar();
  const [resolvedQuery, setResolvedQuery] = useState("");
  const { schema, gridSchema } = useServerAwareState();
  const rSchema = gridSchema || schema;
  // const intl = useIntl();
  // useEffect(() => {
  //   return () => closeSidebar();
  // }, [closeSidebar]);

  const update = useServerAwareColorFilterUpdater();

  const handleSuccess = (filter) => {
    update((fs) => {
      const exists = fs.some((f) => f.Id === filter.Id);
      if (exists) return fs;

      const newArr = [...fs];
      newArr.push(filter);

      return newArr;
    });
    onGoBack();
  };

  const handleAddOrUpdateColorFilter = (colorFilter) => {
    openSidebar(
      <ColorFilterForm
        onSuccess={handleSuccess}
        schema={rSchema}
        colorFilter={colorFilter}
        availableFilters={availableFilters}
      />
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResolvedQuery(query);
    }, 300);

    return () => clearTimeout(timeout);
  }, [query]);
  const intl = useIntl();
  const space = useSpace();
  // const { Id: currentUserId } = useCurrentAccount();

  // const search = useMemo(() => {
  //   const obj = {
  //     Type: filterType,
  //   };

  //   if (!showAll) obj.$filter = `((Account/Id eq '${currentUserId}'))`;
  //   if (resolvedQuery) obj.Query = resolvedQuery;

  //   return querystring.stringify(obj);
  // }, [currentUserId, filterType, resolvedQuery, showAll]);

  // const { loading, data } = useSpaceQuery(
  //   `CustomFilters?${search}`,
  //   advancedFilterResponseShema,
  //   { cache: false }
  // );
  const { Id: currentUserId } = useCurrentAccount();
  const [showAll, setShowAll] = useState(false);
  const search = useMemo(() => {
    const obj = {
      Type: filterType
    };

    if (!showAll) obj.$filter = `((Account/Id eq '${currentUserId}'))`;
    if (resolvedQuery) obj.Query = resolvedQuery;

    return querystring.stringify(obj);
  }, [currentUserId, filterType, resolvedQuery, showAll]);

  const url = useMemo(() => {
    return encodeURI(`ColorFilter?${search}`);
  }, [search]);

  const { data, loading, error } = useSpaceQuery(url, null, {
    cache: false,
    onSuccess: ({ data }) => {
      const {
        d: { results }
      } = data;

      updateColorFitlersBySchema(schema, results, space.Id);
    }
  });

  const buildList = () => {
    if (loading || !data)
      return (
        <LoaderSpinner
          size="xs"
          className="text-primary py-2"
          centerHorizontal
        />
      );

    // const filterColumns = getAllFiltersBySchema(rSchema, space.Id);
    const {
      d: { results }
    } = data;

    if (results.length === 0)
      return (
        <div>
          <FormattedMessage id={"EMPTY_FILTERS"} />
        </div>
      );

    const onColorFilterClick = (colorFilter) => {
      addSelectedFilterBySchema(rSchema, colorFilter.Id, space.Id);
      handleSuccess(colorFilter);
      // onGoBack();
    };

    return (
      <div className="d-flex flex-column">
        {results.map((r) => (
          <ColorFilterOption
            key={r.Id}
            addColorFilter={onColorFilterClick}
            colorFilter={r}
          />
        ))}
      </div>
    );
  };

  const handleQueryChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  return (
    <div className="of-y-auto h-100">
      <FilterBackButton className="mb-3" onClick={onGoBack} />
      <Checkbox
        className="mt-4 d-flex align-items-center text-black fs-14 fw-medium"
        checked={showAll}
        onChange={(e) => setShowAll(e.target.checked)}
        textValue={<FormattedMessage id={"SHOW_ALL"} />}
      />
      {/* <span className="ml-2 text-black fs-14 fw-medium">Mostrar todos</span> */}
      {/* </Checkbox> */}
      <Input
        placeholder={intl.formatMessage({ id: "SEARCH" })}
        className="mb-2 mt-3 bg-white"
        value={query}
        onChange={handleQueryChange}
      />

      {buildList()}
      <Button
        className="mt-3 mb-3 h-32 mx-auto d-block fs-14 fw-regular w-100"
        vType="primary-ghost"
        onClick={() => handleAddOrUpdateColorFilter()}
      >
        <FormattedMessage id={"CREATE_COLOR_FILTER"} />
      </Button>
    </div>
  );
};

const FilterList = React.memo(
  ({
    onRemoveEveryKindOfFilter,
    value,
    data,
    ordenableColums,
    className,
    onRemoveAvancedFilters,
    onRemoveColorFilters,
    onRemoveFilter,
    onAddFilter,
    onReset,
    onRemoveAllGroups,
    onEditFilter,
    onUnreadAdd,
    enableDeactivation,
    onRemoveAllOrderbys,
    ExtraFilterComponent,
    onRemoveAllFilters,
    filterType,
    onChange,
    groupableColumns,
    disableAdvancedFilters,
    disableColorFilters,
    disableOrdering
  }) => {
    const intl = useIntl();
    const gridColumns = useContext(ServerAwareColumnsContext);
    const filterDict = useMemo(() => {
      const dict = {};
      gridColumns.forEach((element) => {
        const resolvedItem =
          typeof element.title === "object"
            ? intl.formatMessage({ ...element.title.props })
            : element.title;
        dict[element.field] = resolvedItem;
      });
      return dict;
    }, [gridColumns, intl]);

    const iconDict = useMemo(() => {
      const dict = {};
      gridColumns.forEach((element) => {
        dict[element.field] = element.filterIcon || element.headerIcon;
      });
      return dict;
    }, [gridColumns]);

    const [view, setView] = useState(viewTypes.overview); //overview | filterOptions | filterSelector
    const [filterItem, setFilterItem] = useState(viewTypes.overview);
    const [currentFilter, setCurrentFilter] = useState();
    const { filters, advancedFilters } = value;

    const handleFilterOptionsClick = (filterItem) => {
      const { resolvedFilter } = filterItem;
      if (resolvedFilter) {
        setView(viewTypes.overview);
        onAddFilter(resolvedFilter);
      } else {
        setView(viewTypes.filterSelector);
        setFilterItem(filterItem);
      }
    };

    return (
      <div className={classnames("FilterList of-y-auto", className)}>
        {view === viewTypes.overview ? (
          <FilterOverview
            onRemoveColorFilters={onRemoveColorFilters}
            disableOrdering={disableOrdering}
            disableColorFilters={disableColorFilters}
            onRemoveAvancedFilters={onRemoveAvancedFilters}
            groupableColumns={groupableColumns}
            disableAdvancedFilters={disableAdvancedFilters}
            advancedFilters={advancedFilters}
            filterType={filterType}
            availableFilters={data}
            onReset={onReset}
            onUnreadAdd={onUnreadAdd}
            onRemoveEveryKindOfFilter={onRemoveEveryKindOfFilter}
            value={value}
            filters={filters}
            iconDict={iconDict}
            enableDeactivation={enableDeactivation}
            filterDict={filterDict}
            onAddGroupBy={() => {
              setView(viewTypes.groupByOptions);
            }}
            onRemoveAllGroups={onRemoveAllGroups}
            onChange={onChange}
            onAddOrderBy={() => setView(viewTypes.orderbyOptions)}
            onRemoveAllOrderbys={() => {
              onRemoveAllOrderbys();
            }}
            onRemoveFilter={onRemoveFilter}
            onRemoveAllFilters={onRemoveAllFilters}
            onAddColorFilter={() => setView(viewTypes.colorFilterSelector)}
            onAddAdvancedFilter={() =>
              setView(viewTypes.advancedFilterSelector)
            }
            handleSingleFilterChange={onEditFilter}
            onEditFilter={(filterI) => {
              const filter = filters[filterI];
              setFilterItem(filter.data);
              setCurrentFilter({ filter, index: filterI });
              setView(viewTypes.filterSelector);
            }}
            ExtraFilterComponent={ExtraFilterComponent}
            onAddFilter={() => setView(viewTypes.filterOptions)}
          />
        ) : view === viewTypes.filterOptions ? (
          <FilterOptions
            data={data}
            onAddFilter={handleFilterOptionsClick}
            onGoBack={() => setView(viewTypes.overview)}
          />
        ) : view === viewTypes.orderbyOptions ? (
          <OrderByOptions
            ordenableColums={ordenableColums}
            filterDict={filterDict}
            iconDict={iconDict}
            GroupdData={value.groupBy}
            data={value.orderby}
            onApply={(e) => {
              onChange({ $orderBy: e });
            }}
            onGoBack={() => setView(viewTypes.overview)}
          />
        ) : view === viewTypes.groupByOptions ? (
          <GroupByOptions
            groupableColumns={groupableColumns}
            iconDict={iconDict}
            filterDict={filterDict}
            data={value.groupBy}
            onApply={(e) => {
              const itemToCompareWith =
                GroupedByOrderByDictionary[e[0]] || e[0];
              const indexOfGroupByOrderBy =
                Array.isArray(value.orderby) && value.orderby.length > 0
                  ? value.orderby.findIndex(
                      (i) => i.column === itemToCompareWith
                    )
                  : -1;

              if (indexOfGroupByOrderBy === -1) {
                onChange({
                  groupBy: e,
                  $orderBy: [
                    {
                      column: itemToCompareWith,
                      type: "asc"
                    },
                    ...value.orderby
                  ]
                });
              } else {
                const tempArr = [...value.orderby];
                let splicedElement = tempArr.splice(indexOfGroupByOrderBy, 1);
                onChange({
                  groupBy: e,
                  $orderBy: [...splicedElement, ...tempArr]
                });
              }
            }}
            onGoBack={() => setView(viewTypes.overview)}
          />
        ) : view === viewTypes.filterSelector ? (
          <FilterSelector
            filterItem={filterItem}
            onAddFilter={(f) => {
              if (currentFilter) {
                onEditFilter(f, currentFilter.index);
              } else {
                onAddFilter(f);
              }
              setCurrentFilter(null);
              setView(viewTypes.overview);
            }}
            currentFilter={currentFilter}
            onGoBack={() => {
              if (currentFilter) {
                setCurrentFilter(null);
                setView(viewTypes.overview);
              } else {
                setView(viewTypes.filterOptions);
              }
            }}
          />
        ) : view === viewTypes.advancedFilterSelector ? (
          <AdvancedFilterSelector
            filterType={filterType}
            availableFilters={data}
            onGoBack={() => setView(viewTypes.overview)}
          />
        ) : (
          view === viewTypes.colorFilterSelector && (
            <ColorFilterSelector
              filterType={filterType}
              availableFilters={data}
              onGoBack={() => setView(viewTypes.overview)}
            />
          )
        )}
      </div>
    );
  }
);

export const FilterText = injectIntl(({ filter, intl }) => {
  const { operator, value, data, type } = filter;
  const { title, titleId } = data;
  // const title = data.title;
  // console.log(data);
  if (type === "dateTime" && typeof value === "string") {
    const split = value.split("X");
    const days = split[1] || value.substring(4, value.length);
    // const days = value.substring(4, value.length);
    if (operator === "eq") {
      return `${
        titleId ? intl.formatMessage({ id: titleId }) : title
      } ${intl.formatMessage({ id: "FILTER_EQ" })} ${intl
        .formatMessage({
          id: value.toUpperCase()
        })
        .toLocaleLowerCase()}`;
    } else if (operator === "lt" && value.includes("notLastX"))
      return intl.formatMessage(
        { id: "FILTER_NOT_LAST_X_DAYS_PREVIEW" },
        {
          days,
          field: titleId
            ? intl.formatMessage({ id: titleId }).toLocaleLowerCase()
            : title.toLocaleLowerCase()
        }
      );
    else if (operator === "gt" && value.includes("notNextX"))
      return intl.formatMessage(
        { id: "FILTER_NOT_NEXT_X_DAYS_PREVIEW" },
        {
          days,
          field: titleId
            ? intl.formatMessage({ id: titleId }).toLocaleLowerCase()
            : title.toLocaleLowerCase()
        }
      );
    else if (operator === "gt")
      return intl.formatMessage(
        { id: "FILTER_LAST_X_DAYS_PREVIEW" },
        {
          days,
          field: titleId
            ? intl.formatMessage({ id: titleId }).toLocaleLowerCase()
            : title.toLocaleLowerCase()
        }
      );
    else if (operator === "lt")
      return intl.formatMessage(
        { id: "FILTER_NEXT_X_DAYS_PREVIEW" },
        {
          days,
          field: titleId
            ? intl.formatMessage({ id: titleId }).toLocaleLowerCase()
            : title.toLocaleLowerCase()
        }
      );
  }

  if (operator === "isbetween") {
    return (
      (typeof title === "object"
        ? intl.formatMessage({ id: title.props.id })
        : titleId
        ? intl.formatMessage({ id: titleId })
        : title) +
      " " +
      intl.formatMessage({ id: messages.filters.default.isbetween }) +
      ' "' +
      (isDate(value.start)
        ? dateFormat(value.start, "dd/MM/yyyy")
        : value.start) +
      '" e "' +
      (isDate(value.end) ? dateFormat(value.end, "dd/MM/yyyy") : value.end) +
      '"'
    );
  }
  return (
    (typeof title === "object"
      ? intl.formatMessage({ id: title.props.id })
      : titleId
      ? intl.formatMessage({ id: titleId })
      : title) +
    " " +
    intl.formatMessage({ id: messages.filters.default[operator] }) +
    (value !== ""
      ? ' "' + (isDate(value) ? dateFormat(value, "dd/MM/yyyy") : value) + '"'
      : "")
  );
});

export default FilterList;

const ColorFilterItem = ({ colorFilter, onEdit, onRemove }) => {
  if (!colorFilter) return null;
  const { Name, Color } = colorFilter;

  return (
    <div className="a-filter-item a-ordenable-filters d-flex p-2 border-bottom mb-2">
      <div className="flex-1 breakWord overflow-hidden">
        <div className="d-flex align-items-center">
          <div
            className="rounded-50 mr-1"
            style={{ background: Color, width: 16, height: 16 }}
          ></div>
          <span className="flex-1 text-truncate">{Name}</span>
        </div>

        {/* <div onClick={onEdit} className="mt-1 a-filter-linkbutton">
          <FormattedMessage id={"EDIT_FILTER"} />
        </div> */}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <FontAwesomeIcon
          onClick={onEdit}
          icon={faPencil}
          className="cursor-pointer text-primary ml-2"
        />
        <FontAwesomeIcon
          onClick={onRemove}
          icon={faMinusCircle}
          className="removal-cross cursor-pointer ml-2"
        />
      </div>
    </div>
  );
};

//TODO Perguntar se mete-se msg de erro
const AdvancedFilterItem = ({ advancedFilter: id, onEdit, onRemove }) => {
  useSchemaQuery(spaceAdvancedFilterSchema, id, {
    onError: ({ error }) => {
      if (error.status === 404 || error.status === 403) {
        onRemove();
      }
    }
  });

  const advancedFilter = useAdvancedFilter(id);

  const { Name, Account: accountId, CanEdit } = advancedFilter || {};

  const Account = useAccount(accountId);

  return (
    <div className="a-filter-item a-ordenable-filters d-flex p-2 border-bottom mb-2">
      <div className="flex-1 breakWord overflow-hidden w-75">
        {advancedFilter ? (
          <>
            <AccountProfileImg
              className="circular mr-1 d-inline-flex align-items-center"
              size="xsm"
              account={Account}
            />
            <span>{Name}</span>

            {CanEdit && (
              <div onClick={onEdit} className="mt-1 a-filter-linkbutton">
                <FormattedMessage id={"EDIT_FILTER"} />
              </div>
            )}
          </>
        ) : (
          <div className="skeleton h-100 rounded"></div>
        )}
      </div>
      <div className="ml-2">
        <div className="d-flex align-items-center justify-content-center">
          {/* <FontAwesomeIcon
            // size="lg"
            onClick={onEdit}
            icon={faPencil}
            className="link-color cursor-pointer"
          /> */}
          <FontAwesomeIcon
            size="lg"
            onClick={onRemove}
            icon={faMinusCircle}
            className="removal-cross cursor-pointer ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export const FilterItem = ({
  item,
  onRemove,
  onEdit,
  enableDeactivation,
  index,
  handleSingleFilterChange
}) => {
  const { resolvedFilter, active } = item;
  const { DescriptionComponent } = item.data;
  const desc = DescriptionComponent ? (
    <DescriptionComponent item={item.Data} allData={item} value={item.value} />
  ) : (
    <FilterText filter={item} />
  );

  const handleActiveChanges = (e) => {
    const { isChecked } = e;
    handleSingleFilterChange(
      {
        ...item,
        active: !isChecked
      },
      index
    );
  };

  return (
    <div className="a-filter-item d-flex pr-1">
      <div className="flex-1 breakWord overflow-hidden w-75">{desc}</div>
      {enableDeactivation && (
        <Checkbox
          change={handleActiveChanges}
          checked={active}
          className="mr-4 sm"
        />
      )}
      <div className="ml-2 mr-1 h-auto">
        <div className="d-flex h-100 align-items-center justify-content-center">
          {!Boolean(resolvedFilter) && (
            <FontAwesomeIcon
              // size="lg"
              onClick={onEdit}
              icon={faPencil}
              className="text-primary mr-2 cursor-pointer"
            />
          )}
          {!enableDeactivation && (
            <FontAwesomeIcon
              size="lg"
              onClick={onRemove}
              icon={faMinusCircle}
              className="removal-cross cursor-pointer"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const FilterOptionItem = ({ children, className, ...rest }) => {
  return (
    <div
      className={classnames(
        "a-filter-filter-item ssi-button-link d-flex align-items-center py-1 px-1",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

const FilterBackButton = ({ onClick, className }) => {
  return (
    <div
      className={classnames("a-filter-linkbutton", className)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
      <span>
        {" "}
        <FormattedMessage id={"BACK"} />
      </span>
    </div>
  );
};
