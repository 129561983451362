import React from "react";
import classnames from "classnames";
import "./EmptyListContainer.css";
import { useGridRequest } from "../CGrid/ServerGrid";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-light-svg-icons";
const EmptyListContainer = ({ type, forcedRefetch, children }) => {
  const { refetch } = useGridRequest() || {};
  return (
    <div className="ar-empty-list-container">
      <div
        className={classnames(
          "ar-empty-list d-flex flex-column disable-selection align-items-center",
          type
        )}
      >
        {children}

        {(refetch || forcedRefetch) && (
          <Button
            onClick={() => {
              if (forcedRefetch) forcedRefetch();
              else refetch();
            }}
            size="sm"
            className="mt-3"
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyListContainer;
