import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import ReactDOM from "react-dom";

import { Toast, ToastGroup } from "../Toast/Toast";

export const ToastContext = React.createContext();

export const ToastProvider = (props) => {
  const [brToasts, setBrToast] = useState([]);
  const [tmToasts, setTmToast] = useState([]);
  const [blToasts, setBlToast] = useState([]);
  const [trToasts, setTrToast] = useState([]);
  const clearState = useCallback((pos, index) => {
    switch (pos) {
      case "br":
        setBrToast((brToasts) => {
          const temp = [...brToasts];
          delete temp[index];
          return temp;
        });
        break;
      case "bl":
        setBlToast((blToasts) => {
          const temp = [...blToasts];
          delete temp[index];
          return temp;
        });
        break;
      case "tm":
        setTmToast((tmToasts) => {
          const temp = [...tmToasts];
          delete temp[index];
          return temp;
        });
        break;
      case "tr":
        setTrToast((trToasts) => {
          const temp = [...trToasts];
          delete temp[index];
          return temp;
        });
        break;
      default:
        break;
    }
  }, []);

  const countRef = useRef(0);

  const createToast = useCallback(
    (toastDef) => {
      countRef.current++;
      const key = countRef.current;
      let toast = (
        <Toast
          clear={clearState}
          key={key}
          entry={toastDef.entry ? toastDef.entry : ""}
          exit={toastDef.exit ? toastDef.exit : ""}
          timeout={toastDef.timeout ? toastDef.timeout : ""}
          noTimeout={toastDef.noTimeout ? toastDef.noTimeout : ""}
          withIcon={toastDef.withIcon ? toastDef.withIcon : ""}
          insert={toastDef.insert ? toastDef.insert : ""}
          type={toastDef.type ? toastDef.type : ""}
          title={toastDef.title ? toastDef.title : ""}
          {...toastDef}
        >
          {toastDef.description}
        </Toast>
      );

      let setter;

      switch (toastDef.pos) {
        case "br":
          setter = setBrToast;
          break;
        case "bl":
          setter = setBlToast;
          break;
        case "tm":
          setter = setTmToast;
          break;
        case "tr":
          setter = setTrToast;
          break;
        default:
          break;
      }

      setter((v) => [...v, toast]);

      const updateToast = (newProps) => {
        setter((vls) => {
          // countRef.current++;
          const newVl = (
            <Toast
              clear={clearState}
              key={key}
              entry={newProps.entry ? newProps.entry : ""}
              exit={newProps.exit ? newProps.exit : ""}
              timeout={newProps.timeout ? newProps.timeout : ""}
              noTimeout={newProps.noTimeout ? newProps.noTimeout : ""}
              withIcon={newProps.withIcon ? newProps.withIcon : ""}
              insert={newProps.insert ? newProps.insert : ""}
              type={newProps.type ? newProps.type : ""}
              title={newProps.title ? newProps.title : ""}
              {...newProps}
            >
              {newProps.description}
            </Toast>
          );
          const index = vls.indexOf(toast);
          let newVls = [...vls];
          if (index !== -1) {
            newVls.splice(index, 1, newVl);
            newVls = newVls.filter((v) => v);
          } else {
            newVls.push(newVl);
          }
          toast = newVl;
          return newVls;
        });

        return updateToast;
      };

      return updateToast;
    },
    [clearState]
  );

  useEffect(() => {
    const brToastNullChecker = brToasts.some((el) => {
      return Boolean(el);
    });
    const blToastNullChecker = blToasts.some((el) => {
      return Boolean(el);
    });
    const tmToastNullChecker = tmToasts.some((el) => {
      return Boolean(el);
    });
    const trToastNullChecker = trToasts.some((el) => {
      return Boolean(el);
    });
    if (!brToastNullChecker && brToasts.length > 0) {
      setBrToast([]);
    } else if (!blToastNullChecker && blToasts.length > 0) {
      setBlToast([]);
    } else if (!tmToastNullChecker && tmToasts.length > 0) {
      setTmToast([]);
    } else if (!trToastNullChecker && trToasts.length > 0) {
      setTrToast([]);
    }
  }, [brToasts, blToasts, tmToasts, trToasts]);

  return (
    <ToastContext.Provider value={createToast}>
      {brToasts.length > 0 ? (
        ReactDOM.createPortal(
          <ToastGroup clear={clearState} notifications={brToasts} pos="br" />,
          props.portalElem
        )
      ) : (
        <></>
      )}
      {blToasts.length > 0 ? (
        ReactDOM.createPortal(
          <ToastGroup clear={clearState} notifications={blToasts} pos="bl" />,
          props.portalElem
        )
      ) : (
        <></>
      )}
      {tmToasts.length > 0 ? (
        ReactDOM.createPortal(
          <ToastGroup clear={clearState} notifications={tmToasts} pos="tm" />,
          props.portalElem
        )
      ) : (
        <></>
      )}
      {trToasts.length > 0 ? (
        ReactDOM.createPortal(
          <ToastGroup clear={clearState} notifications={trToasts} pos="tr" />,
          props.portalElem
        )
      ) : (
        <></>
      )}
      {props.children}
    </ToastContext.Provider>
  );
};

export const injectToast = (WrappedComponent) => {
  return (props) => (
    <ToastContext.Consumer>
      {(value) => <WrappedComponent {...props} toastInstance={value} />}
    </ToastContext.Consumer>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
