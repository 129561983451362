import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
// import MultiSelectInput from "../../Components/MultiSelectInput/MultiSelectInput";
import { FormStateContext } from "../../Components/Forms";
import Dropdown, {
  DropdownList,
  DropdownListRemovalItem
} from "../../Components/Dropdown/Dropdown";

import { FormattedMessage } from "react-intl";

import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import { ConnectedClientListProfile } from "../../Components/Clients/ClientProfile";
import { ConnectedAccountDropdownInput } from "../../Components/Accounts/AccountDropdownInput";
import {
  ConnectedContactListProfile,
  ContactStatusProfile
} from "../../Components/Contacts/ContactProfile";
import { ConnectedContactDropdownInput } from "../../Components/Contacts/ContactDropdownInput";
import { ConnectedDepartmentProfile } from "../../Components/Departments/DepartmentProfile";
import { ConnectedTeamProfile } from "../../Components/Teams/TeamProfile";
import { ConnectedCountryProfile } from "../../Components/Country/CountryProfile";
import { ConnectedCountryDropdownInput } from "../../Components/Country/CountryDropdownInput";
import { ConnectedIndustryProfile } from "../../Components/Industry/IndustryProfile";
import { ConnectedIndustryDropdownInput } from "../../Components/Industry/IndustryDropdownInput";
import { ConnectedProjectListItem } from "../../Components/Projects/ProjectListItem";
import { ConnectedTicketListItem } from "../../Components/Tickets/TicketListItem";
import { ConnectedCallListItem } from "../../Components/Calls/CallListItem";
import { ConnectedTaskListItem } from "../../Components/Tasks/TaskListItem";
import { ConnectedDepartmentDropdownInput } from "../../Components/Departments/DepartmentDropdownInput";
import { ConnectedCancellationReasonProfile } from "../../Components/CancellationReason/CancellationReason";
import { ConnectedCancellationReasonDropdownInput } from "../../Components/CancellationReason/CancellationReasonDropdownInput";
import { ConnectedTeamDropdownInput } from "../../Components/Teams/TeamDropdownInput";
import { ConnectedOrganizationProfile } from "../../Components/Organizations/OrganizationProfile";
import { ConnectedOrganizationDropdownInput } from "../../Components/Organizations/OrganizationDropdownInput";
// import { ConnectedInterventionTypeProfile } from "../../Components/InterventionTypes/InterventionTypeProfile";
// import { ConnectedInterventionTypeDropdownInput } from "../../Components/InterventionTypes/InterventionTypeDropdownInput";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
// import { ConnectedContractTypeProfile } from "../../Components/ContractType/ContractTypeProfile";
// import { ConnectedContractTypeDropdownInput } from "../../Components/ContractType/ContractTypeDropdownInput";
import { ConnectedContractListItem } from "../../Components/Contract/ContractListItem";
import {
  ProjectStatusProfile,
  ConnectedProjectCategoryItem
} from "../../Components/Projects/ProjectProfile";
import {
  cacheType,
  client,
  getEntity,
  useSpaceQuery
} from "../../Helpers/IOClient";
import querystring from "query-string";
import {
  spaceClientSchema,
  spaceProjectsSchema,
  spaceCallsSchema,
  spaceTicketsSchema,
  spaceTasksSchema,
  spaceContractSchema,
  spaceInterventionTypeSchema,
  spaceContactSchema,
  spaceDepartmentSchema,
  spaceTeamSchema,
  accountSchema,
  countrySchema,
  industrySchema,
  spaceContractTypeSchema,
  spaceOrganizationSchema,
  spaceCancelationReasonSchema,
  spaceBillingSchema,
  currencySchema,
  spaceDealsSchema,
  spaceIneficientTypesSchema,
  spaceSubscriptionTypeSchema,
  spaceSocialAccountSchema,
  spaceProjectCategorySchema,
  spaceWidgetLanguagesSchema,
  spaceSchema,
  spaceEmailDomainsSchema,
  spaceInterventionsSchema,
  spaceApiTokensSchema,
  spaceClientTypeSchema,
  spaceSignatureAggregationSchema,
  spaceAutomationSchema,
  spaceSubscriptionSchema,
  spaceClassificationSchema,
  spaceDocumentTypesSchema,
  spaceUserCategoriesSchema,
  UserDocumentType,
  spaceTaskTypeSchema,
  spaceCostCentersSchema,
  spaceTicketTagsSchema,
  spaceEmailReceptionSchema,
  spaceDealsTagsSchema,
  spaceClientTagsSchema,
  spaceContractTagsSchema,
  spaceSubscriptionTagsSchema,
  spaceCallsTagsSchema,
  spaceProjectTagsSchema,
  spaceTaskTagsSchema
} from "../../config/schema";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import classnames from "classnames";
import {
  useProject,
  useTicket,
  useTask,
  useContract,
  useBilling,
  useCall,
  useContractType,
  useInterventionType,
  useDeal,
  useClient,
  useIneficientTypes,
  useSubscriptionType,
  useSocialAccount,
  useWidgetLanguage,
  useEmailDomain,
  useClientType,
  useDocumentType,
  useUserCategory,
  useTaskType,
  useCostCenter,
  useTicketType,
  useEmailReception,
  useDealTag,
  useClientTag,
  useContractTag,
  useSubscriptionTag,
  useCallTag,
  useProjectTag,
  useTaskTag
} from "../../Hooks/EntityHooks";
import { ContractStatusProfile } from "../../Components/Contract/ContractProfile";
import DropdownInput, {
  DropdownInputContainer
} from "../../Components/Dropdown/DropdownInput";
import { getEntityFromState } from "../../Helpers/ReduxHelper";
import store from "../../store";
import {
  CallFormButton,
  TicketFormButton,
  ProjectFormButton,
  ClientFormButton,
  ContactFormButton,
  ContractFormButton,
  TaskFormButton,
  DealFormButton,
  ContractTypeFormButton,
  SubscriptionTypeFormButton,
  ProjectCategoryFormButton,
  InterventionTypeFormButton,
  InterventionFormButton,
  ClientTypeFormButton,
  SubscriptionFormButton,
  DocumentTypeFormButton,
  UserCategoryFormButton,
  CostCenterFormButton,
  TicketTagsFormButton,
  EmailReceptionFormButton,
  DealsTagsFormButton,
  ClientTagsFormButton,
  ContractTagsFormButton,
  SubscriptionTagsFormButton,
  CallsTagsFormButton,
  ProjectTagsFormButton,
  TaskTagsFormButton
} from "../Form/FormButtons";
import { originTypes } from "../../Helpers/MiscHelper";
import {
  ConnectedCurrencyProfile,
  ConnectedCurrencyDropdownInput
} from "../../Components/Currency/Currency";
import { DealListItem } from "../../Components/Deals/DealProfile";
import {
  SocialAccountComponentForSocialPostGrid,
  SocialAccountComponent
} from "../SocialPosts/SocialPostsHelper";
import {
  TypeToSearch,
  Searching,
  NoResults,
  createDropdown,
  PipelineTagsMultiListItems
} from "./AdvancedMultiInputHelper";
import { useSpace, useSpaceEntity } from "../../Contexts/SpaceContext";
import { ConnectedSpaceProfile } from "../../Components/Spaces/SpacesProfile";
import { ConnectedSpacesDropdownInput } from "../../Components/Spaces/SpacesDropdownInput";
import {
  faBuilding,
  faCalendarExclamation,
  faClipboardCheck,
  faFileContract,
  faHandshake,
  faSquareParking,
  faPhone,
  faRoad,
  faListCheck,
  faSuitcase,
  faTicketSimple,
  faUser,
  faUserClock,
  faUserGroup,
  faUsers,
  faImagePortrait,
  faPassport,
  faUsersClass,
  faCoin
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ConnectedCompanyEmailSignature,
  ConnectedDepartmentEmailSignature,
  ConnectedTeamEmailSignature,
  ConnectedUserEmailSignature
} from "../EmailSignature/EmailSignatureFormHelper";
import { ConnectedInterventionListItem } from "../../Components/Interventions/InterventionListItem";
import {
  ApiKeyDropdownInput,
  ApiKeyDropdownValue
} from "../Settings/ApiKeys/ApiKeysHelper";
import { canUserEditModule, moduleTypes } from "../../Helpers/ModulesHelper";
import {
  useCurrentAccount,
  useCurrentAccountSpace
} from "../../Contexts/UserContext";
import { ConnectedAutomationListItem } from "../../Components/Automations/AutomationListItem";
import { ConnectedSubscriptionListItem } from "../../Components/Subscription/SubscriptionListItem";
import { ConnectedClassificationListItem } from "../../Components/Classifications/ClassificationListItem";
import { faBriefcase } from "@fortawesome/pro-solid-svg-icons";

import { ConnectedUserDocumentTypeProfile } from "../../Components/Users/UserDocumentType";

const getkey = (v) => v;

const createNoEntityComponent = ({ Button, shouldHide, getNewForm }) => {
  const NoEntityComp = (props) => {
    const {
      name,
      type,
      component: Component,
      className,
      dontShowAddButton
    } = props;
    const form = useContext(FormStateContext);

    if ((shouldHide && shouldHide(props)) || dontShowAddButton) return null;

    const getParentForm = name
      ? (v) => {
          let resolvedValue;
          if (type) {
            resolvedValue = {
              type,
              data: v
            };
          } else resolvedValue = v;
          return {
            [name]: resolvedValue
          };
        }
      : undefined;
    // if(!name) return null;

    return (
      <Button
        Component={Component}
        className={className}
        oldForm={form}
        newForm={getNewForm ? getNewForm(props) : {}}
        push={Boolean(form)}
        getParentForm={getParentForm}
        link
        isChild
      />
    );
  };
  return NoEntityComp;
};

const MultiListSingleItem = React.memo(
  ({
    query,
    data,
    loading,
    onClick,
    filter,
    className,
    name,
    dontShowAddButton,
    disableEntityCreation,
    ...rest
  }) => {
    const currentAccountSpace = useCurrentAccountSpace();

    const {
      ListItemComponent,
      key,
      icon,
      name: queryName,
      NoEntityComponent,
      getKey: optionalGetKey,
      moduleType
    } = query;

    const handleClick = (item) => {
      onClick({
        type: key,
        data: item
      });
    };

    const hasEditPermission = useMemo(() => {
      if (!NoEntityComponent) return false;
      return canUserEditModule(currentAccountSpace, moduleType);
    }, [NoEntityComponent, currentAccountSpace, moduleType]);

    const AddMoreButton = useMemo(() => {
      if (hasEditPermission && NoEntityComponent && !disableEntityCreation) {
        return (
          <NoEntityComponent
            name={name}
            dontShowAddButton={dontShowAddButton}
            type={key}
            text={filter}
            className={className}
            // component={({ Button }) => {
            //   return (
            //     <div className={classnames("ar-dropdown-list-button")}>
            //       {Button}
            //     </div>
            //   );
            // }}
          />
        );
      } else return null;
    }, [
      NoEntityComponent,
      className,
      disableEntityCreation,
      dontShowAddButton,
      filter,
      hasEditPermission,
      key,
      name
    ]);

    return (
      <div
        className={classnames("ar-dropdow-container", {
          loading: loading
        })}
      >
        <div className="ar-dropdown-list-column text-gray-darker">
          <span>
            {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
            <FormattedMessage id={queryName} />
          </span>
        </div>
        {data.length > 0 && (
          <DropdownList
            {...rest}
            items={data}
            onClick={handleClick}
            getkey={optionalGetKey || getkey}
            component={ListItemComponent}
          />
        )}
        {AddMoreButton}
        {/* {hasEditPermission && NoEntityComponent && !disableEntityCreation && (
          <NoEntityComponent
            name={name}
            type={key}
            text={filter}
            {...rest}
            component={({ Button }) => {
              return (
                // <div className={classnames("ar-dropdow-container")}>
                  Button
                // </div>
              );
            }}
          />
        )} */}
      </div>
    );
  }
);

export const MultiListComponent = React.memo((props) => {
  const { items, onClick, value, removable, ...rest } = props;
  const { filter, hasResults, hasNoResults, values, searchOnType, name } =
    items;

  if (!filter && !searchOnType) {
    return <TypeToSearch />;
  }

  if (!hasResults && !hasNoResults) {
    return <Searching />;
  }

  return (
    <React.Fragment>
      {removable && value && <DropdownListRemovalItem onClick={onClick} />}
      {!hasNoResults &&
        values.map(({ fetchState, query }, i) => {
          const { data, loading, error } = fetchState;
          if (!data || error) return null;
          return (
            <MultiListSingleItem
              key={i}
              query={query}
              onClick={onClick}
              filter={filter}
              name={name}
              data={data}
              loading={loading}
              {...rest}
            />
          );
        })}

      {hasNoResults && <NoResults />}
    </React.Fragment>
  );
});

// const useMemoSpaceQuery = (...args) => {
//   const dataRef = useRef();

//   const state = useSpaceQuery(...args);

//   if (state.data) dataRef.current = state.data;

//   return { ...state, data: dataRef.current };
// };

const validateEntityValue = (value, queries, space) => {
  if (!value) return;
  const resolvedQuery = queries.find((e) => e.key === value.type);
  if (!resolvedQuery) return;
  const connectedValue = getEntity(resolvedQuery.schema[0], value.data);
  if (connectedValue) return;
  const url = resolvedQuery.schema[0].getEndpoint(space) + `/${value.data}`;
  client.get(url, {
    schema: resolvedQuery.schema[0]
  });
};

const useMultipleEntityFetch = (value, queries) => {
  const space = useSpace();
  const runned = useRef();
  useEffect(() => {
    if (runned.current) return;
    runned.current = true;
    if (Array.isArray(value)) {
      for (const v of value) {
        validateEntityValue(v, queries, space.Id);
      }
    } else {
      validateEntityValue(value, queries, space.Id);
    }

    // return () => {};
  }, [queries, space.Id, value]);
};

const createColumnDropdown = ({
  queries,
  enableSearch,
  searchOnType = true,
  ...rest
}) => {
  const queryOptions = {
    cache: cacheType.component
  };

  const buildEndpoint = (filter, query, props) => {
    if (!filter && !searchOnType) return undefined;

    let search;
    const { getUpdateParams, endpoint } = query;
    if (!filter) {
      search = querystring.stringify(
        getUpdateParams ? getUpdateParams(props) : undefined
      );
    } else {
      search = querystring.stringify({
        query: filter,
        ...(getUpdateParams ? getUpdateParams(props) : undefined)
      });
    }

    return search ? `${endpoint}?${search}` : endpoint;
  };

  const useAllQueries = (filter, props, name) => {
    const values = [];
    let loading = false;
    let hasResults = false;
    let noResultsCount = 0;

    for (const query of queries) {
      const { schema, successCallbackName } = query;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const fetchState = useSpaceQuery(
        buildEndpoint(filter, query, props),
        schema,
        {
          ...queryOptions,
          onSuccess: (r) => {
            const callback = props[successCallbackName];

            if (callback) callback(r);
          }
        }
      );
      const { loading: isLoading, error, data } = fetchState;
      if (isLoading) {
        loading = true;
      } else {
        if (error || (data && data.length === 0)) noResultsCount++;
      }

      if (data && data.length > 0) hasResults = true;

      values.push({
        fetchState,
        query
      });
    }
    return {
      filter,
      values,
      loading,
      hasResults,
      hasNoResults: noResultsCount === queries.length,
      searchOnType,
      name
    };
  };

  const valueComponent = (props) => {
    const { item, disabled, textChange, text, onfocus, placeholder } = props;

    if (!item)
      return (
        <input
          ref={rest.inpuRef}
          type="text"
          autoComplete="off"
          disabled={disabled}
          autoCorrect="off"
          spellCheck="false"
          name="ar-dropdown"
          onChange={textChange}
          className="ar-dropdown-button"
          value={text}
          onFocus={onfocus}
          placeholder={placeholder}
        />
      );

    const query = queries.find((q) => q.key === item.type);

    if (!query) return null;

    const { Component } = query;

    return <Component {...props} item={item.data} />;
  };

  const AdvancedMultiSelect = React.memo((props) => {
    const { ...rest } = props;

    const [filter, setFilter] = useState();

    const state = useAllQueries(filter, props, rest.name);

    const timeoutRef = useRef();

    const handleOnTextChange = (text) => {
      clearTimeout(timeoutRef.current);

      if (!text) return setFilter(undefined);
      timeoutRef.current = setTimeout(() => {
        setFilter(text);
      }, 500);
    };

    const handlePopupClosure = () => setFilter(undefined);

    useEffect(() => {
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, []);

    // useMultipleEntityFetch(props.value, queries);

    return (
      <Dropdown
        inpuref={rest.inpuRef}
        className="w-100 mb-3"
        serverFiltering
        isLoading={state.loading}
        valueComponent={valueComponent}
        listComponent={MultiListComponent}
        loadingComponent={BarLoader}
        onTextChange={handleOnTextChange}
        items={state}
        closeOnSelect={!rest.multiple}
        enableSearch={enableSearch}
        removable
        onClose={handlePopupClosure}
        {...rest}
      />
    );
  });
  return AdvancedMultiSelect;
};

const createBasicDropdownComponent = (BaseProfile, InputProfile, propName) => {
  return ({
    enableInput,
    isMultiple,
    item,
    textChange,
    focus,
    text,
    isTyping,
    placeholder
  }) => {
    let props = { [propName]: item };
    if (isMultiple) {
      return <BaseProfile ListItemMultiple {...props} />;
    }

    props = {
      ...props,
      shownumber: propName === "contact",
      enableInput,
      textChange,
      focus,
      text,
      isTyping,
      placeholder
    };

    return <InputProfile ListItem {...props} />;
  };
};

const clientQuery = {
  endpoint: "query/clients",
  key: originTypes.client,
  icon: faSuitcase,
  name: "ACCOUNTS",
  schema: [spaceClientSchema],
  ListItemComponent: ({ item }) => <ConnectedClientListProfile client={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedClientListProfile hidestatus hideIndex client={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { allClients } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (allClients) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.clients,
  NoEntityComponent: createNoEntityComponent({
    Button: ClientFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const costCenterQuery = {
  endpoint: "Query/User/CostCenter",
  key: originTypes.costCenter,
  icon: faCoin,
  name: "COST_CENTER",
  schema: [spaceCostCentersSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedCostCenterItem costCenter={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedCostCenterItem costCenter={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { all } = nextProps;

    const obj = {
      $top: 15,
      $skip: 0,
      all
    };
    return obj;
  },
  moduleType: moduleTypes.spaceCostCenters
  // NoEntityComponent: createNoEntityComponent({
  //   Button: CostCenterFormButton,
  //   getNewForm: ({ text }) => {
  //     return {
  //       Name: text || ""
  //     };
  //   }
  // })
};

const ticketTagQuery = {
  endpoint: "Query/Ticket/Tags",
  key: "TicketTag",
  icon: faTicketSimple,
  name: "CASE_TAGS",
  schema: [spaceTicketTagsSchema],
  getKey: (e) => {
    const resolvedTag = getEntity(spaceTicketTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedTicketTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedTicketTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsTickets,
  NoEntityComponent: createNoEntityComponent({
    Button: TicketTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const callTagQuery = {
  endpoint: "Query/Call/Tags",
  key: "CallTag",
  icon: faTicketSimple,
  name: "CALL_TAGS",
  schema: [spaceCallsTagsSchema],
  getKey: (e) => {
    const resolvedTag = getEntity(spaceCallsTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedCallTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedCallTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsCalls,
  NoEntityComponent: createNoEntityComponent({
    Button: CallsTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const dealTagQuery = {
  endpoint: "Query/Deal/Tags",
  key: "DealTag",
  icon: faHandshake,
  name: "DEAL_TAGS",
  schema: [spaceDealsTagsSchema],
  ListItemComponent: ({ item }) => <ConnectedDealTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedDealTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsDeals,
  NoEntityComponent: createNoEntityComponent({
    Button: DealsTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const projectTagQuery = {
  endpoint: "Query/Project/Tags",
  key: "ProjectTag",
  icon: faHandshake,
  name: "PROJECT_TAGS",
  schema: [spaceProjectTagsSchema],
  ListItemComponent: ({ item }) => <ConnectedProjectTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedProjectTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsProjects,
  NoEntityComponent: createNoEntityComponent({
    Button: ProjectTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const taskTagQuery = {
  endpoint: "Query/Task/Tags",
  key: "TaskTag",
  icon: faHandshake,
  name: "TASK_TAGS",
  schema: [spaceTaskTagsSchema],
  ListItemComponent: ({ item }) => <ConnectedTaskTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedTaskTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsTasks,
  NoEntityComponent: createNoEntityComponent({
    Button: TaskTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const contractTagQuery = {
  endpoint: "Query/Contract/Tags",
  key: "ContractTag",
  icon: faFileContract,
  name: "CONTRACT_TAGS",
  schema: [spaceContractTagsSchema],
  ListItemComponent: ({ item }) => <ConnectedContractTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedContractTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsContracts,
  NoEntityComponent: createNoEntityComponent({
    Button: ContractTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const subscriptionTagQuery = {
  endpoint: "Query/Subscription/Tags",
  key: "SubscriptionTag",
  icon: faCalendarExclamation,
  name: "SUBSCRIPTION_TAGS",
  schema: [spaceSubscriptionTagsSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedSubscriptionTagItem category={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedSubscriptionTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.settingsSubscriptions,
  NoEntityComponent: createNoEntityComponent({
    Button: SubscriptionTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const clientTagQuery = {
  endpoint: "Query/Client/Tags",
  key: "ClientTag",
  icon: faSuitcase,
  name: "ACCOUNT_TAGS",
  schema: [spaceClientTagsSchema],
  ListItemComponent: ({ item }) => <ConnectedClientTagItem category={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedClientTagItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { params, allTags } = nextProps;

    const obj = {
      pipelineId: params?.pipeline
        ? params.pipeline
        : nextProps?.pipelineId
        ? nextProps.pipelineId
        : undefined,
      $top: 15,
      $skip: 0
    };

    if (allTags) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }
    return obj;
  },
  moduleType: moduleTypes.settingsClient,
  NoEntityComponent: createNoEntityComponent({
    Button: ClientTagsFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const userCategoryQuery = {
  endpoint: "Query/User/Category",
  key: originTypes.userCategory,
  icon: faImagePortrait,
  name: "CATEGORIES",
  schema: [spaceUserCategoriesSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedUserCategoryItem category={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedUserCategoryItem category={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const obj = {
      $top: 15,
      $skip: 0
    };
    return obj;
  },
  moduleType: moduleTypes.spaceUserCategories,
  NoEntityComponent: createNoEntityComponent({
    Button: UserCategoryFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

const dealsQuery = {
  endpoint: "query/deals",
  key: originTypes.deals,
  icon: faHandshake,
  name: "DEALS",
  schema: [spaceDealsSchema],
  ListItemComponent: ({ item }) => <DealListItem deal={item} />,
  Component: ({ item, ...rest }) => <DealListItem deal={item} {...rest} />,
  getUpdateParams: (nextProps) => {
    const { allDeals, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (allDeals) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }

    return obj;
  },
  moduleType: moduleTypes.deals,
  NoEntityComponent: createNoEntityComponent({
    Button: DealFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};
const subscriptionQuery = {
  endpoint: "query/subscriptions",
  key: originTypes.subscription,
  icon: faCalendarExclamation,
  name: "SUBSCRIPTIONS",
  schema: [spaceSubscriptionSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedSubscriptionListItem Subscription={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedSubscriptionListItem Subscription={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { allSubscriptions } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (allSubscriptions) {
      obj.filterBlock = true;
    } else {
      obj.filterBlock = false;
    }

    return obj;
  },
  moduleType: moduleTypes.subscriptions,
  NoEntityComponent: createNoEntityComponent({
    Button: SubscriptionFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text || ""
      };
    }
  })
};

// const emailConversationsQuery = {
//   endpoint: "query/emailConversations",
//   key: originTypes.emailConversation,
//   icon: faEnvelope,
//   name: "EMAIL_CONVERSATIONS",
//   schema: [spaceEmailConversationSchema],
//   ListItemComponent: ({ item }) => (
//     <ConnectedInterventionListItem call={item} />
//   ),
//   Component: ({ item, ...rest }) => (
//     <ConnectedInterventionListItem call={item} {...rest} />
//   ),
//   getUpdateParams: (nextProps) => {
//     const { allTimes } = nextProps;
//     const obj = {
//       $top: 15,
//       $skip: 0,
//     };
//     // if (Task) {
//     //   obj.taskId = Task;
//     // }
//     // if (Ticket) {
//     //   obj.ticketId = Ticket;
//     // }
//     if (allTimes) obj.filterBlock = true;
//     else obj.filterBlock = false;
//     return obj;
//   },
//   NoEntityComponent: createNoEntityComponent({
//     Button: InterventionFormButton,
//     getNewForm: ({ text }) => {
//       return {
//         Name: text,
//       };
//     },
//   }),
// };

const interventionsQuery = {
  endpoint: "query/timeCharges",
  key: originTypes.timecharge,
  icon: faUserClock,
  name: "TIMES",
  moduleType: moduleTypes.interventions,
  schema: [spaceInterventionsSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedInterventionListItem intervention={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedInterventionListItem intervention={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { allTimes } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    // if (Task) {
    //   obj.taskId = Task;
    // }
    // if (Ticket) {
    //   obj.ticketId = Ticket;
    // }
    if (allTimes) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  },
  NoEntityComponent: createNoEntityComponent({
    Button: InterventionFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const classificationQuery = {
  endpoint: "query/classification",
  key: originTypes.classification,
  icon: faClipboardCheck,
  name: "CLASSIFICATION",
  moduleType: moduleTypes.classification,
  schema: [spaceClassificationSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedClassificationListItem classification={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedClassificationListItem classification={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { allTimes } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    // if (Task) {
    //   obj.taskId = Task;
    // }
    // if (Ticket) {
    //   obj.ticketId = Ticket;
    // }
    if (allTimes) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  }
};

const callsQuery = {
  endpoint: "query/calls",
  key: originTypes.call,
  icon: faPhone,
  name: "CALLS",
  moduleType: moduleTypes.calls,
  schema: [spaceCallsSchema],
  ListItemComponent: ({ item }) => <ConnectedCallListItem call={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedCallListItem call={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { Task, Ticket, allCalls, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (Task) {
      obj.taskId = Task;
    }
    if (Ticket) {
      obj.ticketId = Ticket;
    }
    if (allCalls) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }

    return obj;
  },
  NoEntityComponent: createNoEntityComponent({
    Button: CallFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const companySignatureQuery = {
  endpoint: "Query/EmailSignatures",
  key: originTypes.organization,
  icon: faBuilding,
  name: "COMPANY",
  schema: [spaceSignatureAggregationSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedCompanyEmailSignature item={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedCompanyEmailSignature item={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { type, id } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0,
      organizationType: 1,
      type,
      id
    };

    return obj;
  }
};
const departmentSignatureQuery = {
  endpoint: "Query/EmailSignatures",
  key: originTypes.department,
  icon: faUsers,
  name: "DEPARTMENT",
  schema: [spaceSignatureAggregationSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedDepartmentEmailSignature item={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedDepartmentEmailSignature item={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { type, id } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0,
      organizationType: 2,
      type,
      id
    };

    return obj;
  }
};

const teamSignatureQuery = {
  endpoint: "Query/EmailSignatures",
  key: originTypes.team,
  icon: faUsersClass,
  name: "TEAM",
  schema: [spaceSignatureAggregationSchema],
  ListItemComponent: ({ item }) => <ConnectedTeamEmailSignature item={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedTeamEmailSignature item={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { type, id } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0,
      organizationType: 3,
      type,
      id
    };

    return obj;
  }
};
const personalSignatureQuery = {
  endpoint: "Query/EmailSignatures",
  key: originTypes.users,
  icon: faUser,
  name: "PERSONAL",
  schema: [spaceSignatureAggregationSchema],
  ListItemComponent: ({ item }) => <ConnectedUserEmailSignature item={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedUserEmailSignature item={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { type, id } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0,
      type,
      id
    };

    return obj;
  }
};

const projectsQuery = {
  endpoint: "query/projects",
  key: originTypes.project,
  icon: faSquareParking,
  name: "PROJECTS",
  schema: [spaceProjectsSchema],
  ListItemComponent: ({ item }) => <ConnectedProjectListItem project={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedProjectListItem project={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { allProjects, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    if (allProjects) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  },
  moduleType: moduleTypes.projects,
  NoEntityComponent: createNoEntityComponent({
    Button: ProjectFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const tasksQuery = {
  endpoint: "query/tasks",
  key: originTypes.task,
  icon: faListCheck,
  name: "TASKS",
  schema: [spaceTasksSchema],
  ListItemComponent: ({ item }) => <ConnectedTaskListItem task={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedTaskListItem task={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { Task, Call, allTasks, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (Task) {
      obj.taskId = Task;
    }
    if (Call) {
      obj.callId = Call;
    }
    if (allTasks) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }

    return obj;
  },
  moduleType: moduleTypes.tasks,
  NoEntityComponent: createNoEntityComponent({
    Button: TaskFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const ticketsQuery = {
  endpoint: "query/tickets",
  key: originTypes.ticket,
  icon: faTicketSimple,
  name: "CASES",
  schema: [spaceTicketsSchema],
  // ListItemComponent: ({ item }) => <ConnectedTicketListItem ticket={item} />,
  ListItemComponent: ({ item }) => <ConnectedTicketListItem ticket={item} />,
  Component: ({ item, ...rest }) => (
    <ConnectedTicketListItem ticket={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { Call, allTickets, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (Call) {
      obj.callId = Call;
    }
    if (allTickets) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }

    return obj;
  },
  moduleType: moduleTypes.tickets,
  NoEntityComponent: createNoEntityComponent({
    Button: TicketFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const contractsQuery = {
  endpoint: "query/contracts",
  key: originTypes.contract,
  icon: faFileContract,
  name: "CONTRACTS",
  schema: [spaceContractSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedContractListItem contract={item} />
  ),
  Component: ({ item, ...rest }) => {
    return <ConnectedContractListItem contract={item} {...rest} />;
  },
  getUpdateParams: (nextProps) => {
    const { allContracts, OrganizationSpaceId } = nextProps;
    const obj = {
      $top: 15,
      $skip: 0
    };
    if (allContracts) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }

    return obj;
  },
  moduleType: moduleTypes.contracts,
  NoEntityComponent: createNoEntityComponent({
    Button: ContractFormButton,
    getNewForm: ({ text }) => {
      return {
        Name: text
      };
    }
  })
};

const inChargeOfAccountQuery = {
  endpoint: "query/accountsbydepartmentiammanager",
  key: originTypes.account,
  name: "COLABORATORS",
  schema: [accountSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedAccountProfile ListItem account={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedAccountProfile,
    ConnectedAccountDropdownInput,
    "account"
  ),
  getUpdateParams: (nextProps) => {
    const { DepartmentTeam, excludeMe, allAccounts, userId } = nextProps;
    const obj = {};
    obj.userid = userId;
    if (DepartmentTeam) {
      if (DepartmentTeam.type === "Department") {
        obj.departmentId = DepartmentTeam.data;
      } else {
        obj.teamId = DepartmentTeam.data;
      }
    }

    if (allAccounts) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (excludeMe) {
      obj.excludeMe = true;
    }
    return obj;
  }
};

const inChargeOfDepartmentQuery = {
  endpoint: "query/departmentsiammanager",
  key: originTypes.department,
  name: "DEPARTMENTS",
  successCallbackName: "onDepartmentsLoad",
  schema: [spaceDepartmentSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedDepartmentProfile ListItem department={item} />
  ),
  getUpdateParams: (nextProps) => {
    const { allDepartments, userId } = nextProps;
    const obj = {};
    obj.userid = userId;
    if (allDepartments) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  },
  Component: createBasicDropdownComponent(
    ConnectedDepartmentProfile,
    ConnectedDepartmentDropdownInput,
    "department"
  )
};

const departmentQuery = {
  endpoint: "query/departments",
  key: originTypes.department,
  icon: faUsers,
  name: "DEPARTMENTS",
  successCallbackName: "onDepartmentsLoad",
  schema: [spaceDepartmentSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedDepartmentProfile ListItem department={item} />
  ),
  getUpdateParams: (nextProps) => {
    const { allDepartments, companyId } = nextProps;
    const obj = {};
    if (allDepartments) obj.filterBlock = true;
    else obj.filterBlock = false;
    if (companyId) obj.CompanyId = companyId;
    return obj;
  },
  Component: createBasicDropdownComponent(
    ConnectedDepartmentProfile,
    ConnectedDepartmentDropdownInput,
    "department"
  )
};

const UserDocumentTypeQuery = {
  endpoint: "Query/User/DocumentType",
  key: originTypes.userDocumentType,
  icon: faPassport,
  name: "DOCUMENT_TYPE",
  // successCallbackName: "onDepartmentsLoad",
  schema: [UserDocumentType],
  ListItemComponent: ({ item }) => (
    <ConnectedUserDocumentTypeProfile doc={item} />
  ),
  getUpdateParams: (nextProps) => {
    const obj = {};
    return obj;
  },
  Component: ({ item }) => <ConnectedUserDocumentTypeProfile doc={item} />
};

const teamQuery = {
  endpoint: "query/teams",
  key: originTypes.team,
  name: "TEAMS",
  icon: faUserGroup,
  successCallbackName: "onTeamsLoad",
  schema: [spaceTeamSchema],
  getUpdateParams: (nextProps) => {
    const { allTeams, departmentId } = nextProps;
    const obj = {};
    if (allTeams) obj.filterBlock = true;
    else obj.filterBlock = false;
    if (departmentId) obj.DepartmentId = departmentId;
    return obj;
  },
  ListItemComponent: ({ item }) => (
    <ConnectedTeamProfile ListItem team={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedTeamProfile,
    ConnectedTeamDropdownInput,
    "team"
  )
};

export const ClientDropdown = createDropdown({
  query: clientQuery,
  enableSearch: true,
  searchOnType: true
});

export const ProjectDropdown = createDropdown({
  query: projectsQuery,
  enableSearch: true,
  searchOnType: true
});

export const InterventionDropdown = createDropdown({
  query: interventionsQuery,
  enableSearch: true,
  searchOnType: true
});

export const ClassificationDropdown = createDropdown({
  query: classificationQuery,
  enableSearch: true,
  searchOnType: true
});

export const CallDropdown = createDropdown({
  query: callsQuery,
  enableSearch: true,
  searchOnType: true
});

export const TaskDropdown = createDropdown({
  query: tasksQuery,
  enableSearch: true,
  searchOnType: true
});

export const TicketDropdown = createDropdown({
  query: ticketsQuery,
  enableSearch: true,
  searchOnType: true
});

export const ContractDropdown = createDropdown({
  query: contractsQuery,
  enableSearch: true,
  searchOnType: true
});

export const DepartmentDropdown = createDropdown({
  query: departmentQuery,
  searchOnType: true,
  enableSearch: true
});

export const UserDocumentTypeDropdown = createDropdown({
  query: UserDocumentTypeQuery,
  searchOnType: true,
  enableSearch: true
});

export const DealDropdown = createDropdown({
  query: dealsQuery,
  enableSearch: true,
  searchOnType: true
});

export const SubscriptionDropdown = createDropdown({
  query: subscriptionQuery,
  enableSearch: true,
  searchOnType: true
});

const DocumentTypeQuery = {
  endpoint: "Query/DocumentTypes",
  key: "DocumentTypes",
  name: "DocumentTypes",
  schema: [spaceDocumentTypesSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedDocumentTypeItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedDocumentTypeItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceIds } = nextProps;
    const obj = {};
    if (OrganizationSpaceIds) {
      obj.OrganizationSpaceIds = OrganizationSpaceIds;
    }
    return obj;
  },
  moduleType: moduleTypes.SettingsDocumentType,
  NoEntityComponent: createNoEntityComponent({
    Button: DocumentTypeFormButton,
    getNewForm: ({ text, OrganizationSpaceIds }) => {
      return {
        Organizations: OrganizationSpaceIds
          ? OrganizationSpaceIds.split(",")
          : [],
        Name: text
      };
    }
  })
};

const InterventionTypeQuery = {
  endpoint: "Query/InterventionTypes",
  key: "InterventionTypes",
  name: "InterventionTypes",
  schema: [spaceInterventionTypeSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedInterventionCategoryItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedInterventionCategoryItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId, All } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    if (All) {
      obj.All = true;
    }
    return obj;
  },
  moduleType: moduleTypes.interventionTypes,
  NoEntityComponent: createNoEntityComponent({
    Button: InterventionTypeFormButton,
    getNewForm: ({ text, OrganizationSpaceId }) => {
      return {
        Organizations: OrganizationSpaceId ? [OrganizationSpaceId] : [],
        Name: text
      };
    }
  })
};
const TaskTypeQuery = {
  endpoint: "Query/TaskTypes",
  key: "TaskTypes",
  name: "TaskTypes",
  schema: [spaceTaskTypeSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedTaskTypeItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedTaskTypeItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  },
  moduleType: moduleTypes.settingsTaskTypes
  // NoEntityComponent: createNoEntityComponent({
  //   Button: TaskTypeFormButton,
  //   getNewForm: ({ text, OrganizationSpaceId }) => {
  //     return {
  //       Organizations: OrganizationSpaceId ? [OrganizationSpaceId] : [],
  //       Name: text
  //     };
  //   }
  // })
};

export const DocumentTypeDropdown = createDropdown({
  query: DocumentTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const DocumentTDTypeDropdown = ({ ...props }) => {
  return <DocumentTypeDropdown {...props} />;
};

export const InterventionTDropdown = createDropdown({
  query: InterventionTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const InterventionTypeDropdown = ({ ...props }) => {
  return <InterventionTDropdown {...props} />;
};

export const TaskTDropdown = createDropdown({
  query: TaskTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const TaskTypeDropdown = ({ ...props }) => {
  return <TaskTDropdown {...props} />;
};

const IneficientTypeQuery = {
  endpoint: "Query/InefficientTypes",
  key: "InefficientTypes",
  name: "InefficientTypes",
  schema: [spaceIneficientTypesSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedIneficientTypeItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedIneficientTypeItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  }
};

export const IneficientTypeDropdown = createDropdown({
  query: IneficientTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const IneficientTDropdown = ({ ...props }) => {
  return <IneficientTypeDropdown {...props} />;
};

export const ConnectedIneficientTypeItem = ({ type, ...rest }) => {
  const c = useIneficientTypes(type);
  return <IneficientTypeComponent type={c} {...rest} />;
};

export const ConnectedInterventionCategoryItem = ({ type, ...rest }) => {
  const c = useInterventionType(type);
  return <InterventionCategoryItem type={c} {...rest} />;
};

export const ConnectedTaskTypeItem = ({ type, ...rest }) => {
  const c = useTaskType(type);
  return <InterventionCategoryItem type={c} {...rest} />;
};

export const ConnectedTicketTagItem = ({ category, ...rest }) => {
  const c = useTicketType(category);
  return <TagItem type={c} {...rest} />;
};

export const ConnectedCallTagItem = ({ category, ...rest }) => {
  const c = useCallTag(category);
  return <TagItem type={c} {...rest} />;
};

export const ConnectedDealTagItem = ({ category, ...rest }) => {
  const c = useDealTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedProjectTagItem = ({ category, ...rest }) => {
  const c = useProjectTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedTaskTagItem = ({ category, ...rest }) => {
  const c = useTaskTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedContractTagItem = ({ category, ...rest }) => {
  const c = useContractTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedSubscriptionTagItem = ({ category, ...rest }) => {
  const c = useSubscriptionTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedClientTagItem = ({ category, ...rest }) => {
  const c = useClientTag(category);
  return <TagItem type={c} {...rest} />;
};
export const ConnectedUserCategoryItem = ({ category, ...rest }) => {
  const c = useUserCategory(category);
  return <InterventionCategoryItem type={c} {...rest} />;
};
export const ConnectedCostCenterItem = ({ costCenter, ...rest }) => {
  const c = useCostCenter(costCenter);
  return <InterventionCategoryItem type={c} {...rest} />;
};

export const ConnectedDocumentTypeItem = ({ type, ...rest }) => {
  const c = useDocumentType(type);
  return <InterventionCategoryItem type={c} {...rest} />;
};

export const ConnectedSocialAccountItem = ({ type, ...rest }) => {
  const c = useSocialAccount(type);
  return <SocialAccountItem type={c} {...rest} />;
};

const SocialAccountQuery = {
  endpoint: "Query/connections",
  key: "SocialAccounts",
  name: "SocialAccounts",
  schema: [spaceSocialAccountSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedSocialAccountItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedSocialAccountItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { IsTwitterAllowed, canPublishOnly } = nextProps;
    const obj = { $top: 50 };
    if (IsTwitterAllowed === true) {
      obj.istwitterallowed = IsTwitterAllowed;
    } else if (IsTwitterAllowed === false) {
      obj.istwitterallowed = IsTwitterAllowed;
    } else {
      obj.istwitterallowed = true;
    }
    if (canPublishOnly) {
      obj.canpublishonly = true;
    }

    return obj;
  }
};

export const SocialAccountDropdown = createDropdown({
  query: SocialAccountQuery,
  enableSearch: true,
  searchOnType: true,
  multiple: true,
  clearTextOnChanges: true,
  excludeOnSelect: true,
  removable: true
});
export const SocialAccountItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={`dropdown-item-maintext w-100 d-flex ${
              grid ? "fs-12" : ""
            }`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate w-100">
                <SocialAccountComponentForSocialPostGrid
                  style={{ width: 16 }}
                  data={type}
                />
              </span>
            ) : (
              <SocialAccountComponent
                style={{ width: 16 }}
                data={type}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const IneficientTypeComponent = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={`dropdown-item-maintext w-100 d-flex ${
              grid ? "fs-12" : ""
            }`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate w-100">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TagItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={`dropdown-item-maintext w-100 d-flex ${
              grid ? "fs-12" : ""
            }`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate w-100">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const InterventionCategoryItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column w-100 text-truncate">
          <div
            className={`dropdown-item-maintext w-100 d-flex ${
              grid ? "fs-12" : ""
            }`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate w-100">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const organizationQuery = {
  endpoint: "query/organizations",
  key: "organization",
  name: "COMPANIES",
  icon: faBriefcase,
  schema: [spaceOrganizationSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedOrganizationProfile ListItem organization={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedOrganizationProfile,
    ConnectedOrganizationDropdownInput,
    "organization"
  ),
  getUpdateParams: (nextProps) => {
    const { allOrganizations } = nextProps;
    const obj = { $top: 15 };
    if (allOrganizations) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  }
};

const manualWorkflowQuery = {
  endpoint: "Query/Automations",
  key: "automations",
  name: "AUTOMATIONS",
  icon: faRoad,
  schema: [spaceAutomationSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedAutomationListItem automation={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedAutomationListItem automation={item} {...rest} />
  )
  // getUpdateParams: (nextProps) => {
  //   const { allOrganizations } = nextProps;
  //   const obj = { $top: 15 };
  //   if (allOrganizations) obj.filterBlock = true;
  //   else obj.filterBlock = false;
  //   return obj;
  // }
};

const spacesQuery = {
  endpoint: "query/spaces",
  key: "space",
  name: "SPACES",

  schema: [spaceSchema],
  ListItemComponent: ({ item }) => {
    const space = useSpaceEntity(item);
    return space.Name;
  },
  Component: createBasicDropdownComponent(
    ConnectedSpaceProfile,
    ConnectedSpacesDropdownInput,
    "space"
  ),
  getUpdateParams: (nextProps) => {
    const obj = { $top: 100 };
    return obj;
  }
};

export const OrganizationDropdown = createDropdown({
  query: organizationQuery,
  enableSearch: true,
  searchOnType: true
});

export const ManualWorkflowDropdown = createDropdown({
  query: manualWorkflowQuery,
  enableSearch: true,
  searchOnType: true
});

export const SpacesDropdown = createDropdown({
  query: spacesQuery,
  enableSearch: true,
  searchOnType: true,
  grid: true,
  requiresSpace: false
});

export const TeamDropdown = createDropdown({
  query: teamQuery,
  enableSearch: true,
  searchOnType: true
});

const industryQuery = {
  endpoint: "query/Industries",
  key: "industry",
  name: "Industrias",
  schema: [industrySchema],
  ListItemComponent: ({ item }) => <ConnectedIndustryProfile industry={item} />,
  Component: createBasicDropdownComponent(
    ConnectedIndustryProfile,
    ConnectedIndustryDropdownInput,
    "industry"
  ),
  getUpdateParams: (nextProps) => {
    const { allIndustries } = nextProps;
    const obj = { $top: 15 };
    if (allIndustries) obj.filterBlock = true;
    else obj.filterBlock = false;
    return obj;
  }
};

export const IndustryDropdown = createDropdown({
  query: industryQuery,
  requiresSpace: false,
  enableSearch: true,
  searchOnType: true
});

const contractTypeQuery = {
  endpoint: "Query/ContractTypes",
  key: "ContractTypes",
  name: "ContractTypes",
  schema: [spaceContractTypeSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedContractCategoryItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedContractCategoryItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  },
  NoEntityComponent: createNoEntityComponent({
    Button: ContractTypeFormButton,
    getNewForm: ({ text = "", OrganizationSpaceId }) => {
      return {
        Organizations: OrganizationSpaceId ? [OrganizationSpaceId] : [],
        Name: text
      };
    }
  })
};

const ResolvedInboundEmailQueryComponent = ({ item }) => {
  const connectedEmailReception = useEmailReception(item);
  if (!item) return <div></div>;
  return <div>{connectedEmailReception.Email}</div>;
};
const ResolvedInboundEmailQueryInputComponent = ({ item, ...props }) => {
  const connectedEmailReception = useEmailReception(item);
  return (
    <DropdownInputContainer>
      <DropdownInput
        {...props}
        fallbackValue={connectedEmailReception && connectedEmailReception.Email}
      />
    </DropdownInputContainer>
  );
};

const InboundEmailQuery = {
  endpoint: "Query/InboundEmails",
  key: "InboundEmails",
  name: "InboundEmails",
  schema: [spaceEmailReceptionSchema],
  ListItemComponent: ({ item, ...rest }) => {
    const connectedEmailReception = useEmailReception(item);
    if (!item) return <div></div>;
    return <div>{connectedEmailReception.Email}</div>;
  },
  Component: createBasicDropdownComponent(
    ResolvedInboundEmailQueryComponent,
    ResolvedInboundEmailQueryInputComponent,
    "item"
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  },
  NoEntityComponent: createNoEntityComponent({
    Button: EmailReceptionFormButton,
    getNewForm: ({ text = "" }) => {
      return {};
    }
  })
};

export const InboundEmailDropdown = createDropdown({
  query: InboundEmailQuery,
  enableSearch: true,
  searchOnType: true
});

const subscriptionTypeQuery = {
  endpoint: "Query/SubscriptionTypes",
  key: "SubscriptionTypes",
  name: "SubscriptionTypes",
  schema: [spaceSubscriptionTypeSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedSubscriptionCategoryItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedSubscriptionCategoryItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  },
  moduleType: moduleTypes.subscriptions,
  NoEntityComponent: createNoEntityComponent({
    Button: SubscriptionTypeFormButton,
    getNewForm: ({ text, OrganizationSpaceId }) => {
      return {
        Organizations: OrganizationSpaceId ? [OrganizationSpaceId] : [],
        Name: text
      };
    }
  })
};

export const ContractTDropdown = createDropdown({
  query: contractTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const SubscriptionTDropdown = createDropdown({
  query: subscriptionTypeQuery,
  enableSearch: true,
  searchOnType: true
});

const widgetLanguageQuery = {
  endpoint: "query/languages",
  key: "Languages",
  name: "Languages",
  schema: [spaceWidgetLanguagesSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedWidgetLanguageItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedWidgetLanguageItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const obj = { $top: 15 };

    return obj;
  }
};

export const WidgetLanguageDropdown = createDropdown({
  query: widgetLanguageQuery,
  enableSearch: true,
  requiresSpace: false,
  searchOnType: true
});

export const ContractTypeDropdown = ({ ...props }) => {
  return <ContractTDropdown fixedWidth={450} {...props} />;
};

export const SubscriptionTypeDropdown = ({ ...props }) => {
  return <SubscriptionTDropdown {...props} />;
};

export const ConnectedWidgetLanguageItem = ({ type, ...rest }) => {
  const c = useWidgetLanguage(type);
  return <WidgetLanguageItem type={c} {...rest} />;
};

export const ConnectedSubscriptionCategoryItem = ({ type, ...rest }) => {
  const c = useSubscriptionType(type);
  return <SubscriptionCategoryItem type={c} {...rest} />;
};

export const ConnectedContractCategoryItem = ({ type, ...rest }) => {
  const c = useContractType(type);
  return <ContractCategoryItem type={c} {...rest} />;
};

const WidgetLanguageItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate w-100">
          <div
            className={`dropdown-item-maintext d-flex ${grid ? "fs-12" : ""}`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionCategoryItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate w-100">
          <div
            className={`dropdown-item-maintext d-flex ${grid ? "fs-12" : ""}`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate">
                {type.Type} - {type.Name}
              </span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ContractCategoryItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate w-100">
          <div
            className={`dropdown-item-maintext d-flex ${grid ? "fs-12" : ""}`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate">
                {type.Type} - {type.Name}
              </span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const cancellationReasonsQueryBase = {
  key: "cancellationReason",
  name: "Motivos de cancelamento",
  schema: [spaceCancelationReasonSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedCancellationReasonProfile cancellationReason={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedCancellationReasonProfile,
    ConnectedCancellationReasonDropdownInput,
    "cancellationReason"
  )
};

const callCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Calls/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const callCancellationReasonsDropdown = createDropdown({
  query: callCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const tasksCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Tasks/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const tasksCancellationReasonsDropdown = createDropdown({
  query: tasksCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const contractCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Contracts/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

const subscriptionsCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Subscriptions/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const contractsCancellationReasonsDropdown = createDropdown({
  query: contractCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});
export const SubscriptionsCancellationReasonsDropdown = createDropdown({
  query: subscriptionsCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const ticketsCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Tickets/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const ticketsCancellationReasonsDropdown = createDropdown({
  query: ticketsCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const dealsCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Deals/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const dealsCancellationReasonsDropdown = createDropdown({
  query: dealsCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const dealsLostReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Deals/LostReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const dealsLostReasonsDropdown = createDropdown({
  query: dealsLostReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const projectsCancellationReasonQuery = {
  ...cancellationReasonsQueryBase,
  endpoint: "query/Projects/CancellationReasons",
  getUpdateParams: (nextProps) => {
    const { company } = nextProps;
    const obj = {};
    obj.OrganizationSpaceId = company;
    return obj;
  }
};

export const projectsCancellationReasonsDropdown = createDropdown({
  query: projectsCancellationReasonQuery,
  enableSearch: true,
  searchOnType: true
});

const GenderTypes = {
  male: 1,
  female: 2
};

const GenderOptions = [GenderTypes.male, GenderTypes.female];

const GenderProfile = ({ item, className }) => {
  let text;

  switch (item) {
    case GenderTypes.male:
      text = <FormattedMessage id="MALE" />;
      break;

    case GenderTypes.female:
      text = <FormattedMessage id="FEMALE" />;
      break;

    default:
      break;
  }

  return <span className={className}>{text}</span>;
};

export const GenderDropdown = (props) => {
  return (
    <KeyedDropdown
      options={GenderOptions}
      valueComponent={GenderProfile}
      closeOnSelect
      {...props}
    />
  );
};

const ProjectStatusTypes = {
  open: 1,
  canceled: 3,
  finished: 4,
  draft: 5
};

export const ProjectStatusDropdown = (props) => {
  const { hideCancellation } = props;

  const options = useMemo(() => {
    if (hideCancellation)
      return [
        ProjectStatusTypes.draft,
        ProjectStatusTypes.open,
        ProjectStatusTypes.finished
      ];
    else
      return [
        ProjectStatusTypes.draft,
        ProjectStatusTypes.open,
        ProjectStatusTypes.canceled,
        ProjectStatusTypes.finished
      ];
  }, [hideCancellation]);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={ProjectStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

const FeedbackProfile = ({ status, item, value }) => {
  const resovledStatus = status || item || value;
  if (resovledStatus === "Scheduled") return <div>Agendado</div>;
  else return <div>Falhado</div>;
};

export const FeedbackQueueStatusDropdown = (props) => {
  const options = useMemo(() => {
    return ["Scheduled", "Failed"];
  }, []);

  return (
    <KeyedDropdown
      options={options}
      valueComponent={FeedbackProfile}
      closeOnSelect
      {...props}
    />
  );
};

const ContractStatusTypes = {
  open: 1,
  closed: 2,
  canceled: 3,
  draft: 5,
  staging: 6,
  renovated: 7
};

const ContractStatusOptions = [
  ContractStatusTypes.staging,
  ContractStatusTypes.open,
  ContractStatusTypes.draft,
  ContractStatusTypes.renovated,
  ContractStatusTypes.closed,
  ContractStatusTypes.canceled
];

export const ContractStatusDropdown = (props) => {
  return (
    <KeyedDropdown
      options={ContractStatusOptions}
      valueComponent={ContractStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

// const ContactStatusOptions = [
//   { text: <ContactStatusProfile status={1} />, key: 1 },
//   { text: <ContactStatusProfile status={2} />, key: 2 }
// ];

export const ContactStatusTypes = {
  active: 1,
  inactive: 2
};

export const ContactStatusOptions = [
  ContactStatusTypes.active,
  ContactStatusTypes.inactive
];

export const ContactStatusDropdown = (props) => {
  return (
    <KeyedDropdown
      dropdownClassname="text-truncate"
      options={ContactStatusOptions}
      valueComponent={ContactStatusProfile}
      closeOnSelect
      {...props}
    />
  );
};

const billingQuery = {
  endpoint: "query/billing",
  key: originTypes.billing,
  name: "Faturação",
  schema: [spaceBillingSchema],
  ListItemComponent: ({ item }) => {
    const billing = useBilling(typeof item === "number" ? item : null);

    return (billing && billing.Identifier) || item;
  },
  Component: ({ item, ...rest }) => {
    const billing = useBilling(typeof item === "number" ? item : null);

    const fallBack = (billing && billing.Identifier) || item;

    return (
      <DropdownInput
        className="flat-input h-100"
        fallbackValue={fallBack}
        // text={text}
        // enableInput={enableInput}
        // isTyping={isTyping}
        {...rest}
      />
    );
  },
  getUpdateParams: (nextProps) => {
    const { Organization } = nextProps;
    const obj = {};
    if (Organization) {
      obj.organizationId = Organization;
    }
    return obj;
  }
};

const doesQueryContainIdentifier = (billings, Identifier) => {
  for (const billing of billings) {
    const { Identifier: billingIdentifier } = getEntityFromState(
      store.getState(),
      spaceBillingSchema,
      billing
    );
    if (Identifier === billingIdentifier) return true;
  }
  return false;
};

export const BillingDropdown = createDropdown({
  query: billingQuery,
  enableSearch: true,
  searchOnType: true,
  canAdd: true,
  ListComponent: React.memo((props) => {
    const { items, isLoading, value, onClick, ...rest } = props;

    const { query: text, values } = items;

    if (isLoading && !values) return <Searching />;

    if (!values) return <TypeToSearch />;

    if (values.length === 0) {
      return (
        <React.Fragment>
          {value && (
            <DropdownListRemovalItem
              onClick={onClick}
              // field="Name"
            />
          )}
          {text && value !== text && (
            <div
              className="ar-dropdown-list-button"
              onClick={() => onClick(text)}
            >
              Adicionar
            </div>
          )}
          <NoResults />
        </React.Fragment>
      );
    }

    const canAdd =
      text && value !== text && !doesQueryContainIdentifier(values, text);
    return (
      <React.Fragment>
        {canAdd && (
          <div
            className="ar-dropdown-list-button mb-1"
            onClick={() => onClick(text)}
          >
            Adicionar
          </div>
        )}
        <DropdownList
          {...rest}
          value={value}
          onClick={onClick}
          items={values}
          getkey={getkey}
        />
      </React.Fragment>
    );
  })
});

const currencyQuery = {
  endpoint: "query/currency",
  key: "currency",
  name: "Moedas",
  schema: [currencySchema],
  ListItemComponent: ({ item }) => <ConnectedCurrencyProfile currency={item} />,
  // Component: createBasicDropdownComponent(
  //   ConnectedCountryProfile,
  //   ConnectedCountryDropdownInput,
  //   "country"
  // ),
  Component: createBasicDropdownComponent(
    ConnectedCurrencyProfile,
    ConnectedCurrencyDropdownInput,
    "currency"
  ),
  getUpdateParams: (nextProps) => {
    return {
      $top: 15
    };
  }
};

export const CurrencyDropdown = createDropdown({
  query: currencyQuery,
  requiresSpace: false,
  enableSearch: true,
  searchOnType: true
});

const countryQuery = {
  endpoint: "query/countries",
  key: "country",
  name: "Paises",
  schema: [countrySchema],
  ListItemComponent: ({ item }) => <ConnectedCountryProfile country={item} />,
  Component: createBasicDropdownComponent(
    ConnectedCountryProfile,
    ConnectedCountryDropdownInput,
    "country"
  ),
  getUpdateParams: (nextProps) => {
    return {
      $top: 100
    };
  }
};

export const CountryDropdown = createDropdown({
  query: countryQuery,
  requiresSpace: false,
  enableSearch: true,
  searchOnType: true
});

const projectTypeQuery = {
  endpoint: "Query/ProjectTypes",
  key: "ProjectTypes",
  name: "PROJECT_TYPES",
  schema: [spaceProjectCategorySchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedProjectCategoryItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedProjectCategoryItem type={item} {...rest} />
  ),
  getUpdateParams: (nextProps) => {
    const { OrganizationSpaceId } = nextProps;
    const obj = {};
    if (OrganizationSpaceId) {
      obj.OrganizationSpaceId = OrganizationSpaceId;
    }
    return obj;
  },
  moduleType: moduleTypes.projectTypes,
  NoEntityComponent: createNoEntityComponent({
    Button: ProjectCategoryFormButton,
    getNewForm: ({ text, OrganizationSpaceId }) => {
      return {
        Organizations: OrganizationSpaceId ? [OrganizationSpaceId] : [],
        Name: text
      };
    }
  })
};

export const ProjectTDropdown = createDropdown({
  query: projectTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const ProjectTypeDropdown = ({ ...props }) => {
  return <ProjectTDropdown {...props} />;
};

const RemoveDefaultActiveButtonContext = React.createContext();

export const RemoveDefaultActiveButtonProvider = ({ children }) => {
  return (
    <RemoveDefaultActiveButtonContext.Provider value={1}>
      {children}
    </RemoveDefaultActiveButtonContext.Provider>
  );
};

export const accountQuery = {
  endpoint: "query/accounts",
  icon: faUser,
  key: originTypes.account,
  name: "COLABORATORS",
  schema: [accountSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedAccountProfile showRole ListItem account={item} />
  ),
  DefaultActiveButton: (props) => {
    const item = useCurrentAccount();
    const removeDefaultProvValue = useContext(RemoveDefaultActiveButtonContext);
    if (removeDefaultProvValue) return null;
    return item.Id;
  },
  Component: createBasicDropdownComponent(
    ConnectedAccountProfile,
    ConnectedAccountDropdownInput,
    "account"
  ),
  getUpdateParams: (nextProps) => {
    const {
      DepartmentTeam,
      Company,
      Department,
      ExcludedUsers,
      Team,
      excludeMe,
      OnlyTheseUsers,
      allAccounts
    } = nextProps;
    const obj = {};

    if (Company) {
      obj.companyId = Company;
    }

    if (Department) {
      obj.departmentId = Department;
    }

    if (Team) {
      obj.teamId = Team;
    }

    if (OnlyTheseUsers) {
      obj.$filter = `((Account/Id in('${OnlyTheseUsers.join("','")}')))`;

      // obj.$filter = `(`;
      // for (const user of OnlyTheseUsers) {
      //   if (obj.$filter.length > 1) obj.$filter += " or ";
      //   obj.$filter += `(Account/Id eq '${user}')`;
      // }
      // obj.$filter += ")";
    } else if (ExcludedUsers && ExcludedUsers?.length > 0) {
      obj.$filter = `((Account/Id notin('${ExcludedUsers.join("','")}')))`;
      // obj.$filter = `(`;
      // for (const user of ExcludedUsers) {
      //   if (obj.$filter.length > 1) obj.$filter += " and ";
      //   obj.$filter += `(Account/Id ne '${user}')`;
      // }
      // obj.$filter += ")";
    }

    if (DepartmentTeam) {
      if (DepartmentTeam.type === "Department") {
        obj.departmentId = DepartmentTeam.data;
      } else {
        obj.teamId = DepartmentTeam.data;
      }
    }

    if (allAccounts) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (excludeMe) {
      obj.excludeMe = true;
    }

    return obj;
  }
};

const ReadOptionsDropdownValueComponent = ({ item }) => {
  if (Number(item) === 1) return <FormattedMessage id="YES" />;
  else return <FormattedMessage id="NO" />;
};

export const ReadOptionsDropdown = ({ ...rest }) => {
  const options = [0, 1];

  return (
    <KeyedDropdown
      options={options}
      valueComponent={ReadOptionsDropdownValueComponent}
      ListComponent={ReadOptionsDropdownValueComponent}
      {...rest}
    />
  );
};

export const UserDropdown = createDropdown({
  query: accountQuery,
  enableSearch: true,
  searchOnType: true
});

export const ShareWithDropdown = createColumnDropdown({
  queries: [organizationQuery, departmentQuery, teamQuery, accountQuery],
  enableSearch: true,
  searchOnType: true
});

export const CompanyDeptTeamDropdown = createColumnDropdown({
  queries: [organizationQuery, departmentQuery, teamQuery],
  enableSearch: true,
  searchOnType: true
});

const DomainDropdownInput = (props) => {
  const { domain } = props;

  const { BaseDomain } = useEmailDomain(domain) || {};

  return (
    <DropdownInputContainer className="w-100">
      <DropdownInput {...props} className="w-100" fallbackValue={BaseDomain} />
    </DropdownInputContainer>
  );
};

const DomainDropdownValue = ({ domain }) => {
  const { BaseDomain } = useEmailDomain(domain);
  return <span>{BaseDomain}</span>;
};

const domainQuery = {
  endpoint: "query/domains",
  key: originTypes.account,
  name: "DOMAINS",
  schema: [spaceEmailDomainsSchema],
  ListItemComponent: ({ item }) => <DomainDropdownValue domain={item} />,
  Component: createBasicDropdownComponent(
    DomainDropdownValue,
    DomainDropdownInput,
    "domain"
  ),
  getUpdateParams: (nextProps) => {
    const { Company } = nextProps;
    const obj = {};

    if (Company) {
      obj.OrganizationSpaceId = Company;
    }

    return obj;
  }
};

const TimezoneValue = ({ timezone }) => {
  const { Name } = timezone;
  return <span>{Name}</span>;
};

const TimezoneInput = (props) => {
  const { timezone } = props;

  return (
    <DropdownInputContainer className="w-100">
      <DropdownInput
        {...props}
        className="w-100"
        fallbackValue={timezone?.Name}
      />
    </DropdownInputContainer>
  );
};

const timezoneQuery = {
  endpoint: "query/timezones",
  key: originTypes.account,
  name: "TIMEZONES",
  schema: null,
  ListItemComponent: ({ item }) => <TimezoneValue timezone={item} />,
  Component: createBasicDropdownComponent(
    TimezoneValue,
    TimezoneInput,
    "timezone"
  ),
  getUpdateParams: (nextProps) => {
    const { Country, Language } = nextProps;
    const obj = {};
    const connectedCountry = getEntity(countrySchema, Country);

    if (Country) {
      obj.country = connectedCountry.Code;
      obj.langId = Language?.Id;
    }

    return obj;
  }
};

export const TimezoneDropdown = createDropdown({
  query: timezoneQuery,
  requiresSpace: false,
  enableSearch: true,
  searchOnType: true
});

export const EmailDomainDropdown = createDropdown({
  query: domainQuery,
  grid: true,
  enableSearch: true,
  searchOnType: true
});

export const TeamCollabDropdown = createColumnDropdown({
  queries: [accountQuery, teamQuery],
  enableSearch: true,
  searchOnType: true
});

export const DeptTeamCollabDropdown = createColumnDropdown({
  queries: [accountQuery, teamQuery, departmentQuery],
  enableSearch: true,
  searchOnType: true
});

export const convertDepartmentTeam = (DepartmentTeam) => {
  let department, team;
  if (DepartmentTeam) {
    switch (DepartmentTeam.type) {
      case "Department":
        department = DepartmentTeam.data;
        break;

      case "Team":
        team = DepartmentTeam.data;
        break;

      default:
        break;
    }
  }

  return { department, team };
};

export const convertDepartmentTeams = (DepartmentTeams) => {
  let departments = [],
    teams = [];

  if (!Array.isArray(DepartmentTeams)) return { departments, teams };

  for (const DepartmentTeam of DepartmentTeams) {
    const { department, team } = convertDepartmentTeam(DepartmentTeam);
    if (department) departments.push(department);
    else if (team) teams.push(team);
  }

  return { departments, teams };
};

export const convertToDepartmentTeams = (entity) => {
  const { Departments, Teams } = entity;
  const DepartmentTeams = [];

  if (Departments)
    for (const Department of Departments) {
      DepartmentTeams.push({
        type: "Department",
        data: Department
      });
    }

  if (Teams)
    for (const Team of Teams) {
      DepartmentTeams.push({
        type: "Team",
        data: Team
      });
    }
  return DepartmentTeams;
};

export const DepartmentTeamDropdown = createColumnDropdown({
  enableSearch: true,
  searchOnType: true,
  queries: [departmentQuery, teamQuery]
});
export const DepartmentTeamUserDropdown = createColumnDropdown({
  enableSearch: true,
  searchOnType: true,
  queries: [departmentQuery, teamQuery, accountQuery]
});

const contactQuery = {
  endpoint: "query/contacts",
  key: originTypes.contact,
  name: "CONTACS",
  schema: [spaceContactSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedContactListProfile contact={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedContactListProfile,
    ConnectedContactDropdownInput,
    "contact"
  ),
  moduleType: moduleTypes.contacts,
  getUpdateParams: (nextProps) => {
    const { Client, Project, allContacts, hasEmail, EmailConversationId } =
      nextProps;
    const obj = {};

    if (hasEmail) obj.HasEmail = true;

    if (Client) obj.clientId = Client;

    if (Project) obj.projectId = Client;

    if (allContacts) obj.filterBlock = true;
    else obj.filterBlock = false;

    if (EmailConversationId && hasEmail) {
      obj.EmailConversationId = EmailConversationId;
    }

    return obj;
  },
  // NoEntityComponent: ({ name, Client, text }) => {
  //   const form = useContext(FormStateContext);

  //   if (!Client) return null;

  //   const getParentForm = name
  //     ? v => {
  //         return {
  //           [name]: v
  //         };
  //       }
  //     : undefined;
  //   // if(!name) return null;

  //   return (
  //     <ContactFormButton
  //       oldForm={form}
  //       newForm={{
  //         Clients: [Client],
  //         Name: text
  //       }}
  //       push={Boolean(form)}
  //       getParentForm={getParentForm}
  //       link
  //       isChild
  //     />
  //   );
  // },
  NoEntityComponent: createNoEntityComponent({
    Button: ContactFormButton,
    // shouldHide: ({ Client }) => !Client,
    getNewForm: ({ Client, text }) => {
      return {
        Clients: Client ? [Client] : [],
        Name: text || ""
      };
    }
  })
};

const ContactDropdownBase = createDropdown({
  query: contactQuery,
  enableSearch: true,
  searchOnType: true
});

export const ContactDropdown = ({ originTarget, IndirectClient, ...rest }) => {
  let projectId, ticketId, taskId, contractId, clientId, callId, dealId;

  if (originTarget) {
    switch (originTarget.type) {
      case originTypes.project:
        projectId = originTarget.data;
        break;

      case originTypes.ticket:
        ticketId = originTarget.data;
        break;

      case originTypes.task:
        taskId = originTarget.data;
        break;

      case originTypes.contract:
        contractId = originTarget.data;
        break;

      case originTypes.client:
        clientId = originTarget.data;
        break;

      case originTypes.call:
        callId = originTarget.data;
        break;

      case originTypes.deals:
        dealId = originTarget.data;
        break;

      default:
        break;
    }
  }

  const project = useProject(projectId);
  const ticket = useTicket(ticketId);
  const task = useTask(taskId);
  const contract = useContract(contractId);
  const call = useCall(callId);
  const deal = useDeal(dealId);
  const indirectClient = useClient(IndirectClient);

  const Client =
    clientId ||
    (project && (project.Client || project.IndirectClient)) ||
    (deal && (deal.Client || deal.IndirectClient)) ||
    (ticket && (ticket.Client || ticket.IndirectClient)) ||
    (task && (task.Client || task.IndirectClient)) ||
    (call && (call.Client || call.IndirectClient)) ||
    (contract && (contract.Client || contract.IndirectClient)) ||
    (indirectClient && indirectClient.Id);

  return <ContactDropdownBase Client={Client} {...rest} />;
};

export const BillingTargetDropdown = createColumnDropdown({
  queries: [
    clientQuery,
    projectsQuery,
    contractsQuery,
    ticketsQuery,
    tasksQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const CallTargetDropdown = createColumnDropdown({
  queries: [
    clientQuery,
    projectsQuery,
    contractsQuery,
    ticketsQuery,
    tasksQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const UserCategoryDropdown = createColumnDropdown({
  queries: [userCategoryQuery],
  enableSearch: true
});

export const TicketTagTDropdown = createDropdown({
  query: ticketTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceTicketTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceTicketTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedTicketTagItem category={item} />
});
export const SubscriptionTagTDropdown = createDropdown({
  query: subscriptionTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceSubscriptionTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceSubscriptionTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => (
    <ConnectedSubscriptionTagItem category={item} />
  )
});
export const DealTagTDropdown = createDropdown({
  query: dealTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceDealsTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceDealsTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedDealTagItem category={item} />
});
export const ProjectTagTDropdown = createDropdown({
  query: projectTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceProjectTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceProjectTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedProjectTagItem category={item} />
});
export const TaskTagTDropdown = createDropdown({
  query: taskTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceTaskTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceTaskTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedTaskTagItem category={item} />
});

export const CallTagTDropdown = createDropdown({
  query: callTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceCallsTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceDealsTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedCallTagItem category={item} />
});

export const ContractTagTDropdown = createDropdown({
  query: contractTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,

  schema: spaceContractTagsSchema,
  ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceContractTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedContractTagItem category={item} />
});

export const ClientTagTDropdown = createDropdown({
  query: clientTagQuery,
  enableSearch: true,
  searchOnType: true,
  unremovable: true,
  dontShowAddButton: true,
  schema: spaceClientTagsSchema,
  // ListComponent: PipelineTagsMultiListItems,
  getKey: (e) => {
    const resolvedTag = getEntity(spaceDealsTagsSchema, e);
    return `${e}-${resolvedTag?.Pipeline?.Id || "?"}`;
  },
  ListItemComponent: ({ item }) => <ConnectedClientTagItem category={item} />
});

export const TicketTagDropdown = createColumnDropdown({
  queries: [ticketTagQuery],
  enableSearch: true
});

export const CallTagDropdown = createColumnDropdown({
  queries: [callTagQuery],
  enableSearch: true
});

export const DealTagDropdown = createColumnDropdown({
  queries: [dealTagQuery],
  enableSearch: true
});

export const ProjectTagDropdown = createColumnDropdown({
  queries: [projectTagQuery],
  enableSearch: true
});
export const TaskTagDropdown = createColumnDropdown({
  queries: [taskTagQuery],
  enableSearch: true
});

export const ContractTagDropdown = createColumnDropdown({
  queries: [contractTagQuery],
  enableSearch: true
});

export const SubscriptionTagDropdown = createColumnDropdown({
  queries: [subscriptionTagQuery],
  enableSearch: true
});

export const ClientTagDropdown = createColumnDropdown({
  queries: [clientTagQuery],
  enableSearch: true
});

export const CostCenterDropdown = createColumnDropdown({
  queries: [costCenterQuery],
  enableSearch: true,
  searchOnType: true
});

export const CostCenterBaseDropdown = createDropdown({
  query: costCenterQuery,
  enableSearch: true,
  searchOnType: true
});

export const TaskTargetDropdown = createColumnDropdown({
  queries: [
    projectsQuery,
    ticketsQuery,
    callsQuery,
    tasksQuery,
    contractsQuery,
    clientQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const DealTargetDropdown = createColumnDropdown({
  queries: [
    projectsQuery,
    ticketsQuery,
    callsQuery,
    tasksQuery,
    contractsQuery,
    clientQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const TSSTargetDropdown = createColumnDropdown({
  queries: [
    // projectsQuery,
    // ticketsQuery,
    // callsQuery,
    // tasksQuery,
    contractsQuery
    // clientQuery,
    // dealsQuery
  ],
  enableSearch: true,
  dontShowAddButton: true
});

export const ReceptionTargetDropdown = createColumnDropdown({
  queries: [ticketsQuery, tasksQuery, dealsQuery],
  enableSearch: true
});

export const EmailSignaturesTypesDropdown = createColumnDropdown({
  queries: [
    companySignatureQuery,
    departmentSignatureQuery,
    teamSignatureQuery,
    personalSignatureQuery
  ],
  enableSearch: true
});

export const InterventionTargetDropdown = createColumnDropdown({
  queries: [
    projectsQuery,
    ticketsQuery,
    callsQuery,
    tasksQuery,
    contractsQuery,
    clientQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const DocumentTargetDropdown = createColumnDropdown({
  queries: [
    clientQuery,
    dealsQuery,
    contractsQuery,
    subscriptionQuery,
    callsQuery,
    projectsQuery,
    tasksQuery,
    ticketsQuery,
    interventionsQuery
  ],
  enableSearch: true
});

export const FavoritesTargetDropdown = createColumnDropdown({
  queries: [
    clientQuery,
    dealsQuery,
    contractsQuery,
    callsQuery,
    projectsQuery,
    ticketsQuery,
    tasksQuery,
    interventionsQuery,
    accountQuery
  ],
  enableSearch: true
});

export const ClassificationReportsTargetDropdown = createColumnDropdown({
  queries: [departmentQuery, accountQuery],
  enableSearch: true,
  searchOnType: true
});

export const ProductivityReportsTargetDropdown = createColumnDropdown({
  queries: [costCenterQuery, accountQuery],
  enableSearch: true,
  searchOnType: true
});

export const TimesReportsTargetDropdown = createColumnDropdown({
  queries: [inChargeOfDepartmentQuery, inChargeOfAccountQuery],
  enableSearch: true,
  searchOnType: true
});

export const ReportsTargetDropdown = createColumnDropdown({
  queries: [organizationQuery, departmentQuery, teamQuery, accountQuery],
  //   queries: [departmentQuery, teamQuery, accountQuery],
  enableSearch: true,
  searchOnType: true
});

export const ReportsDealsTargetDropdown = createColumnDropdown({
  queries: [organizationQuery, accountQuery],
  //   queries: [departmentQuery, teamQuery, accountQuery],
  enableSearch: true,
  searchOnType: true
});

export const ClassificationTargetDropdown = createColumnDropdown({
  queries: [projectsQuery, contractsQuery, clientQuery, dealsQuery],
  enableSearch: true
});

export const TicketTargetDropdown = createColumnDropdown({
  queries: [
    ticketsQuery,
    projectsQuery,
    clientQuery,
    callsQuery,
    contractsQuery,
    dealsQuery
  ],
  enableSearch: true
});

export const ProjectTargetDropdown = createColumnDropdown({
  queries: [clientQuery, dealsQuery],
  enableSearch: true
});

export const ConversationTargetDropdown = createColumnDropdown({
  queries: [ticketsQuery, dealsQuery, tasksQuery],
  enableSearch: true
});

export const ContractTargetDropdown = createColumnDropdown({
  queries: [clientQuery, dealsQuery],
  enableSearch: true
});

export const SubscriptionTargetDropdown = createColumnDropdown({
  queries: [clientQuery],
  enableSearch: true
});

const PrimaryDomainDropdownInput = (props) => {
  const { domain } = props;
  const { BaseDomain } = useEmailDomain(domain) || {};
  return (
    <DropdownInputContainer className="w-100">
      <DropdownInput {...props} className="w-100" fallbackValue={BaseDomain} />
    </DropdownInputContainer>
  );
};

const PrimaryDomainValue = ({ item }) => {
  const domain = useEmailDomain(item);
  return domain.BaseDomain || null;
};

const primaryDomainQuery = {
  endpoint: "query/PrimaryDomains",
  key: originTypes.team,
  name: "Domains",
  // successCallbackName: "onTeamsLoad",
  schema: [spaceEmailDomainsSchema],
  getUpdateParams: (nextProps) => {
    const { Company } = nextProps;
    const obj = {};
    if (Company) obj.OrganizationSpaceId = Company;

    return obj;
  },
  ListItemComponent: PrimaryDomainValue,
  Component: createBasicDropdownComponent(
    PrimaryDomainValue,
    PrimaryDomainDropdownInput,
    "domain"
  )
};

export const PrimaryDomainDropdown = createDropdown({
  query: primaryDomainQuery,
  enableSearch: true,
  searchOnType: true
});

const duplicateContactQuery = {
  endpoint: "query/duplicateContacts",
  key: "contact",
  name: "CONTACS",
  schema: [spaceContactSchema],
  ListItemComponent: ({ item }) => (
    <ConnectedContactListProfile contact={item} />
  ),
  Component: createBasicDropdownComponent(
    ConnectedContactListProfile,
    ConnectedContactDropdownInput,
    "contact"
  ),
  moduleType: moduleTypes.contacts,
  getUpdateParams: (nextProps) => {
    const { email, phoneNumber, contact } = nextProps;
    const obj = {};

    if (email) {
      obj.Email = email;
    }
    if (phoneNumber) {
      obj.PhoneNumber = phoneNumber;
    }
    if (contact) {
      obj.ContactId = contact;
    }

    return obj;
  }
  // NoEntityComponent: createNoEntityComponent({
  //   Button: ContactFormButton,
  //   // shouldHide: ({ Client }) => !Client,
  //   getNewForm: ({ Client, text }) => {
  //     return {
  //       Clients: Client ? [Client] : [],
  //       Name: text || ""
  //     };
  //   }
  // })
};

export const DuplicateContactDropdown = createDropdown({
  query: duplicateContactQuery,
  grid: true,
  enableSearch: true,
  searchOnType: true
});

const apiKeyQuery = {
  endpoint: "query/apiKeys",
  key: "apiKeys",
  name: "API_KEYS",
  schema: [spaceApiTokensSchema],
  ListItemComponent: ({ item }) => <ApiKeyDropdownValue id={item} />,
  Component: createBasicDropdownComponent(
    ApiKeyDropdownValue,
    ApiKeyDropdownInput,
    "id"
  )
};

export const ApiKeyDropdown = createDropdown({
  query: apiKeyQuery,
  grid: true,
  enableSearch: true,
  searchOnType: true
});

export const ConnectedClientTypeCategoryItem = ({ type, ...rest }) => {
  const c = useClientType(type);
  return <ClientTypeItem type={c} {...rest} />;
};

const ClientTypeItem = ({
  type,
  enableInput,
  isTyping,
  dispatch,
  grid,
  text,
  ...inputProps
}) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center w-100">
        <div className="d-flex flex-column text-truncate flex-1">
          <div
            className={`dropdown-item-maintext d-flex ${grid ? "fs-12" : ""}`}
          >
            {/* {!isTyping && pipeline && `${pipeline.Id} - `} */}
            {!enableInput && type ? (
              <span className="text-truncate">{type.Name}</span>
            ) : (
              <DropdownInput
                className="flat-input"
                fallbackValue={type && type.Name}
                text={text}
                enableInput={enableInput}
                isTyping={isTyping}
                {...inputProps}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const clientTypeQuery = {
  endpoint: "Query/clienttypes",
  key: "ClientTypes",
  name: "ClientTypes",
  schema: [spaceClientTypeSchema],
  ListItemComponent: ({ item, ...rest }) => (
    <ConnectedClientTypeCategoryItem type={item} {...rest} />
    // <ConnectedContractTypeProfile contracttype={item} />
  ),
  Component: ({ item, ...rest }) => (
    <ConnectedClientTypeCategoryItem type={item} {...rest} />
  ),
  moduleType: moduleTypes.settingsClient,
  NoEntityComponent: createNoEntityComponent({
    Button: ClientTypeFormButton,
    getNewForm: (props) => {
      return {
        Name: props.text || ""
      };
    }
  })
};

const ClientTDropdown = createDropdown({
  query: clientTypeQuery,
  enableSearch: true,
  searchOnType: true
});

export const ClientTypeDropdown = ({ ...props }) => {
  return <ClientTDropdown {...props} />;
};
