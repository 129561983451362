import {
  faBell as faBellLight,
  faCheck,
  faClock,
  faDownload,
  faFilePdf,
  faInboxOut,
  faTriangleExclamation,
  faEnvelopeOpenText
} from "@fortawesome/pro-light-svg-icons";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { faBell, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import classnames from "classnames";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { StaticAccountsInRoomStack } from "../../Components/AccountsInRoom/AccountsInRoomStack";
import KeyedDropdown from "../../Components/Dropdown/KeyedDropdown";
import {
  ConnectionContext,
  EmailConnectedMessenger,
  MessagesConnection
} from "../../Components/EntitySummaries/EntitySummaryMessages/MessagesConnection";
import { ContainerProvider } from "../../Components/EntitySummaries/EntitySummaryMessages/MessagesHelper";
import EmailPreview, {
  checkForImageType,
  validadeFilePreview
} from "../../Components/FileUploader/EmailPreview";
import { ComponentElementByExtension } from "../../Components/FileUploader/FileUploader";
import ImagePreview from "../../Components/FileUploader/ImagePreview";
import OfficePreview from "../../Components/FileUploader/OfficePreview";
import PdfPreview from "../../Components/FileUploader/PdfPreview";
import VideoPreview from "../../Components/FileUploader/VideoPreview";
import { BarLoader } from "../../Components/GlobalLoader/GlobalLoader";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import Modal from "../../Components/Modal/Modal";
import {
  EmailClosedStatusDot,
  EmailOpenStatusDot
} from "../../Components/StatusHelper/StatusHelper";
import TextEllipsis from "../../Components/TextEllipsis/TextEllipsis";
import { useToast } from "../../Components/Toast/ToastProvider";
import {
  spaceEmailConversationSchema,
  spaceEmailSchema,
  spaceNoteSchema
} from "../../config/schema";
import { useSpace } from "../../Contexts/SpaceContext";
import { useSpacePost, useSpaceQuery } from "../../Helpers/IOClient";
import { handleError } from "../../Helpers/MiscHelper";
import { fk } from "../../Helpers/SchemaHelper";
import { useEmailConversation } from "../../Hooks/EntityHooks";
import {
  MessageCountEmailConversation,
  NoteCountEmailConversation
} from "../../Redux/Reducer/Entities";
import { ConnectedAccountPopup } from "../Origin/AcountPopup";
import ContactOrigin, { ConnectedContactOrigin } from "../Origin/ContactOrigin";
import { ConnectedContactPopup } from "../Origin/ContactPopup";
import { useConnectionId } from "../RealTime/RealTime";
import { RealTimeRoomTypes } from "../RealTime/RealTimeHelper";
import { useSpaceEntitiesHub } from "../RealTime/RealTimeSpace";
import OriginalEmailButton from "./OriginalEmailButton";
import { ReceptionSchema, SummaryAttachmentsPreview } from "./ReceptionComp";
import { MessageItemProfileStatus } from "./ReceptionCompMsgList";
import { ConversationDataContext } from "./ReceptionEmails/ReceptionEmails";
import { useReceptionResizeCallback } from "./ReceptionResizer";
import { PostalEmailStatusModelEventEnum } from "./ReceptionSettings";
import DumbFilesPreview from "../../Components/FileUploader/DumbFilesPreview";
import Button from "../../Components/Button/Button";
import { ContactDropdown } from "../AdvancedMultiInputs";
import LoadableButton from "../../Components/Button/LoadableButton";

export const RefreshEmailChatConversation = React.createContext();

// const EntityType = (Type, Data) => {
//   let EntityKey;
//   switch (Type) {
//     case ConversationTypeEnum.Email:
//       EntityKey = Data.EmailConversation;
//       break;
//     case ConversationTypeEnum.Chat:
//       EntityKey = 1;
//       break;
//     case ConversationTypeEnum.EntityNotes:
//       EntityKey = 2;
//       break;
//     default:
//       break;
//   }
//   return `${Type}-${EntityKey}`;
// };

export const EmailSendingStatus = ({ Status, className }) => {
  const intl = useIntl();

  if (Status === PostalEmailStatusModelEventEnum.MessageSent) {
    return (
      <div className={classnames("text-success", className)}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
    );
  } else if (Status === PostalEmailStatusModelEventEnum.MessageDeliveryFailed) {
    return (
      <InformationComponent
        className={className}
        text={intl.formatMessage({ id: "API_ERROR_EMAIL_REFUSED" })}
      >
        <div className={classnames("text-danger-alt", className)}>
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </div>
      </InformationComponent>
    );
  } else if (
    Status === PostalEmailStatusModelEventEnum.MessageHeld ||
    Status === PostalEmailStatusModelEventEnum.MessageBounced
  ) {
    return (
      <InformationComponent
        className={className}
        text={intl.formatMessage({ id: "API_ERROR_SENT_EMAIL" })}
      >
        <div className={classnames("text-danger-alt", className)}>
          <FontAwesomeIcon icon={faInboxOut} />
        </div>
      </InformationComponent>
    );
  } else if (Status === PostalEmailStatusModelEventEnum.MessageDelayed) {
    return (
      <InformationComponent
        text={intl.formatMessage({ id: "INBOX_EMAIL_SENT_STATUS_DELAYED" })}
      >
        <div className={classnames(className)}>
          <FontAwesomeIcon icon={faClock} />
        </div>
      </InformationComponent>
    );
  } else {
    return (
      <div className={className}>
        <LoaderSpinner className="text-primary" size="xxxs" />
      </div>
    );
  }
};

const ImageModal = ({ src, isOpen, setIsOpen, extraCloseButton }) => {
  return (
    <Modal
      className="of-v"
      extraCloseButton={
        <>
          <div
            className="ar-modal-preview-file-content-close-button"
            onClick={() => setIsOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div
            style={{ top: 55 }}
            className="ar-modal-preview-file-content-close-button text-color-link"
            onClick={() => {
              var a = document.createElement("a");
              a.href = src;
              a.download = "Image.png";
              a.click();
              a.remove();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </>
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ImagePreview src={src} />
    </Modal>
  );
};

export const EmailChatMessagesItem = ({ Data }) => {
  const {
    FromContact,
    ToContacts,
    CcContacts,
    BccContacts,
    Text,
    Subject,
    Date,
    Account,
    Files,
    Id,
    Status
    // IsPinned,
  } = Data || {};
  // const intl = useIntl();
  const iframeRef = useRef();
  const d = moment(Date).format("DD/MM/YYYY HH:mm");

  const emailConversationId = useContext(ConversationDataContext);

  const [src, setSrc] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const resizeIframe = useCallback(() => {
    if (
      !iframeRef.current ||
      !iframeRef.current.contentWindow.document.documentElement
    )
      return;
    iframeRef.current.style.height =
      iframeRef.current.contentWindow.document.documentElement.offsetHeight +
      "px";
  }, []);

  const UpdateScrollOfContainer = useContext(ContainerProvider);

  const handleLoad = useCallback(() => {
    resizeIframe();
    const imgs =
      iframeRef.current.contentWindow.document.documentElement.getElementsByTagName(
        "img"
      );
    const body = iframeRef.current.contentWindow.document.body;

    if (body) {
      body.style.wordBreak = "break-word";
    }

    for (const img of imgs) {
      const link = img.closest("a");

      if (link) continue;

      img.style.cursor = "pointer";

      img.addEventListener("click", (e) => {
        setIsOpen(true);
        setSrc(e.target.src);
        // var w = window.open("");
        // w.document.write(e.target.outerHTML);
        // window.open(e.target.src, "_blank");
      });
    }

    if (UpdateScrollOfContainer) {
      UpdateScrollOfContainer();
    }
  }, [UpdateScrollOfContainer, resizeIframe]);

  useReceptionResizeCallback(resizeIframe);

  useEffect(() => {
    const timeout = setTimeout(() => {
      resizeIframe();
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [resizeIframe]);

  const resovledSrcDoc = useMemo(
    () => `<html><style>
	body{
		overflow-y: auto;
		overflow-x: hidden;
		box-sizing: border-box;
		font-family: "Roboto", sans-serif;
		padding-top: 0.75rem;
    padding-left: 1rem;
    margin: 0;
    padding-right: 1rem;
}
img {
	max-width: 100% !important;
	object-fit: contain;
	border: 0px;
}
::-webkit-scrollbar {
		padding: 4px 2px;
		width: 12px;
		height: 12px;
		border-radius: 12px;
}
::-webkit-scrollbar-thumb {
		background: #c1c9d2;
		border-radius: 12px;
		border: 2px solid transparent;
		background-clip: content-box;
}
blockquote {
		margin: 0 !important;
}
/*table.x_x_MsoNormalTable, table.x_MsoNormalTable, */

table {
		overflow: hidden;
		max-width: 100%;
		width: 100% !important;
		box-sizing: border-box;
}
.x_bodyContainer > table {
		max-width: 100%;
		overflow: hidden;
		box-sizing: border-box;
		border-radius: 10px;
		table-layout: fixed;
}
.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type {
		overflow: hidden;
}
.x_templateContainer {
		max-width: 100% !important;
		overflow: hidden;
		table-layout: fixed;
}
.x_bodyContainer > table .x_mcnTextBlockInner:first-of-type > table{
		max-width: 100%;
		min-width: 100%;
		overflow: hidden;
		table-layout: fixed;
}
#x_templateBodyAirdesk > table{
		width: 100%;
		max-width: 100% !important;
		overflow: hidden;
		box-sizing: border-box;
		table-layout: fixed;
}
#x_bodyCellAirdesk > table {
		table-layout: fixed !important;
}
</style><link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet"><head>
<base target="_blank"></head><body>${Text}</body></html>`,
    [Text]
  );

  if (Account) {
    return (
      <>
        <ImageModal isOpen={isOpen} setIsOpen={setIsOpen} src={src} />
        <div className="d-flex w-100 justify-content-end">
          <div className="air-reception-email-item-container border ml-2 mb-4">
            <div style={{ background: "white" }} className="w-100 p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center flex-1">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="EMAIL_FROM" />:
                  </div>
                  <div className="text-black d-flex align-items-center flex-wrap">
                    {FromContact ? (
                      <ConnectedContactPopup contact={FromContact} />
                    ) : (
                      <ConnectedAccountPopup account={Account} />
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <OriginalEmailButton
                    className="mr-2"
                    emailConversationId={emailConversationId}
                    emailId={Id}
                  />
                  <span className="mr-3 fs-14">{d}</span>
                  {/* <MessagesExtraAction isLoading={pinPostLoading} onPin={handlePinSubmit} /> */}
                </div>
              </div>
              <div className="d-flex align-items-center flex-1">
                <div className="d-flex align-items-center flex-1">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="EMAIL_TO" />:
                  </div>
                  <div className="text-black d-flex align-items-center flex-wrap">
                    {ToContacts.map((c, i) => {
                      // let isLast = i === ToContacts.length - 1;
                      return (
                        <ConnectedContactPopup
                          key={c}
                          className="my-1 mr-2"
                          contact={c}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {CcContacts && CcContacts.length > 0 && (
                  <div className="d-flex align-items-center mr-3">
                    <div className="fs-14 mr-3">CC:</div>
                    <div className="text-black d-flex align-items-center flex-wrap">
                      {CcContacts.map((c, i) => {
                        // let isLast = i === ToContacts.length - 1;
                        return (
                          <ConnectedContactPopup
                            key={c}
                            className="my-1 mr-2"
                            contact={c}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {BccContacts && BccContacts.length > 0 && (
                  <div className="d-flex align-items-center">
                    <div className="fs-14 mr-3">BCC:</div>
                    <div className="text-black d-flex align-items-center flex-wrap">
                      {BccContacts.map((c, i) => {
                        // let isLast = i === ToContacts.length - 1;
                        return (
                          <ConnectedContactPopup
                            key={c}
                            className="my-1 mr-2"
                            contact={c}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center border-left pl-2"></div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex  align-items-center">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="SUBJECT" />:
                  </div>
                  <div className="fs-14 text-black">{Subject}</div>
                </div>
                <div className="d-flex  align-items-center">
                  <EmailSendingStatus Status={Status} />
                </div>
              </div>
              {Files && Files.length > 0 && (
                <div className="d-flex mt-2">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="ATTACHMENTS" />:
                  </div>
                  <div className="fs-14 text-black">
                    <EmailChatAttachmentsComponent Files={Files} />
                  </div>
                </div>
              )}
              <div className="border-bottom mt-3 border-top w-100"></div>
            </div>
            <div className="bg-white">
              <iframe
                ref={iframeRef}
                scrolling="auto"
                onLoad={handleLoad}
                className="w-100 border-0"
                style={{ boxShadow: "none" }}
                title="email"
                srcDoc={resovledSrcDoc}
              />
            </div>

            {/* 
          <div className="d-flex align-items-center justify-content-between">
            <div className={"d-flex align-items-center"}>
              <div className="ar-history-item-header-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="fs-16 text-black fw-medium">{ContactName}</div>
            </div>
            <div className="fs-12">{d}</div>
          </div>
          <div className="ar-history-item-content">
            <div className="d-flex mt-2 fs-14">
              <span className="text-black mr-2">
                <FormattedMessage id={"SUBJECT"} />:{" "}
              </span>
              <span>{Subject}</span>
            </div>

            <iframe
              ref={iframeRef}
              scrolling="no"
              onLoad={resizeIframe}
              className="w-100 border-0"
              style={{ boxShadow: "none" }}
              title="email"
              srcDoc={`<html><style>body{font-family: "Roboto", sans-serif;}</style><link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet"><head><base target="_blank"></head><body>${Text}</body></html>`}
            />
</div>*/}
          </div>
          <div className="align-items-lg-start ml-2 mt-2">
            <ConnectedAccountPopup justImage size="md" account={Account} />
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <ImageModal isOpen={isOpen} setIsOpen={setIsOpen} src={src} />
        <div className="d-flex w-100">
          {FromContact ? (
            <ContactOrigin className="fs-14" contact={FromContact} />
          ) : (
            ToContacts.map((c) => {
              return <ConnectedContactOrigin key={c} contact={c} />;
            })
          )}
          <div className="air-reception-email-item-container border ml-2 mb-4">
            <div className="w-100 p-3">
              <div className="d-flex  align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="EMAIL_FROM" />:
                  </div>
                  <div className="text-black d-flex align-items-center">
                    {FromContact && (
                      <ConnectedContactPopup contact={FromContact} />
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <OriginalEmailButton
                    className="mr-2"
                    emailConversationId={emailConversationId}
                    emailId={Id}
                  />
                  <span className="mr-3 fs-14">{d}</span>
                  {/* <MessagesExtraAction isLoading={pinPostLoading} onPin={handlePinSubmit} /> */}
                </div>
              </div>
              {Array.isArray(ToContacts) && ToContacts.length > 0 && (
                <div className="d-flex align-items-center">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="EMAIL_TO" />:
                  </div>
                  <div className="text-black d-flex align-items-center flex-wrap">
                    {ToContacts.map((k, i) => {
                      // let isLast = i === ToContacts.length - 1;
                      return (
                        <ConnectedContactPopup
                          key={i}
                          className="my-1 mr-2"
                          contact={k}
                        />
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="d-flex align-items-center justify-content-between">
                {CcContacts && CcContacts.length > 0 && (
                  <div className="d-flex align-items-center mr-3">
                    <div className="fs-14 mr-3">CC:</div>
                    <div className="text-black d-flex align-items-center flex-wrap">
                      {CcContacts.map((c, i) => {
                        // let isLast = i === ToContacts.length - 1;
                        return (
                          <ConnectedContactPopup
                            key={c}
                            className="my-1 mr-2"
                            contact={c}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {BccContacts && BccContacts.length > 0 && (
                  <div className="d-flex align-items-center">
                    <div className="fs-14 mr-3">BCC:</div>
                    <div className="text-black d-flex align-items-center flex-wrap">
                      {BccContacts.map((c, i) => {
                        // let isLast = i === ToContacts.length - 1;
                        return (
                          <ConnectedContactPopup
                            key={c}
                            className="my-1 mr-2"
                            contact={c}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center border-left pl-2"></div>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <div className="fs-14 mr-3">
                  <FormattedMessage id="SUBJECT" />:
                </div>
                <div className="fs-14 text-black">{Subject}</div>
              </div>
              {Files && Files.length > 0 && (
                <div className="d-flex mt-2">
                  <div className="fs-14 mr-3">
                    <FormattedMessage id="ATTACHMENTS" />:
                  </div>
                  <div className="fs-14 text-black">
                    <EmailChatAttachmentsComponent Files={Files} />
                  </div>
                </div>
              )}
              <div className="border-bottom mt-3 w-100"></div>
            </div>
            <div className="bg-white">
              <iframe
                ref={iframeRef}
                scrolling="auto"
                onLoad={handleLoad}
                className="w-100 border-0"
                style={{ boxShadow: "none" }}
                title="email"
                srcDoc={resovledSrcDoc}
              />
            </div>
          </div>
        </div>
      </>
    );
};

export const emailChatResponseSchema = {
  Email: fk(spaceEmailSchema),
  Note: fk(spaceNoteSchema)
};

const Settings = {
  getEndpoint: (entityId) => {
    return `EmailConversation/${entityId}/Emails`;
  },

  Schema: emailChatResponseSchema,
  SettingsTop: 5,
  convertNote: (note) => {
    return {
      Note: note
    };
  }
};

const EmailChatComponent = ({ OpenedId }) => {
  const entitiesHub = useSpaceEntitiesHub();

  const isConnected = Boolean(entitiesHub);

  return (
    <MessagesConnection
      key={isConnected ? `${RealTimeRoomTypes.Email}-${OpenedId}` : ""}
      roomType={RealTimeRoomTypes.Email}
      entityId={OpenedId}
      schema={spaceEmailConversationSchema}
      Settings={Settings}
    >
      <OuterInnerEmailChatComponent OpenedId={OpenedId} />
    </MessagesConnection>
  );
};

export const EmailStatusDictionary = {
  0: "OPEN",
  1: "CLOSED_RECEPTION"
};

const StatusIcon = ({ item }) => {
  if (item === 0) return <EmailOpenStatusDot />;
  else return <EmailClosedStatusDot />;
};

export const EmailStatus = [0, 1];

const PageSelectComponent = ({ item }) => {
  const f = useIntl().formatMessage;

  return (
    <div className="px-2 d-flex fs-14 align-items-center">
      <StatusIcon item={item} />
      <div
        className={classnames("ml-2", {
          "text-black": item === 1,
          "text-green": item === 0
        })}
      >{`${f({ id: EmailStatusDictionary[item] })}`}</div>
    </div>
  );
};

const useConvMessageSync = () => {
  const entitiesHub = useSpaceEntitiesHub();
  const { addMessage } = useContext(ConnectionContext);
  useEffect(() => {
    if (!entitiesHub) return;
    // const { convertNote } = Settings || {};

    const listner = (type, conversationId, messageId, data) => {
      const parseData = JSON.parse(data);

      const id = parseData.EmailConversation.LastEmail.Id;
      // console.log("received message");

      addMessage({ Email: id });
      // if (following === false) return;
      // if (canReadEntitiesRef.current) post(url);
      // else {
      //   markEntityAsNonRead(schema, entityId);
      //   setUnreadCount((c) => c + 1);
      //   unreadRef.current++;
      // }
    };

    entitiesHub.on("NewConversationMessage", listner);

    return () => entitiesHub.off("NewConversationMessage", listner);
  }, [addMessage, entitiesHub]);
};

const useConvMessageSyncV2 = (updateNoteCounter, updateMessageCounter) => {
  const entitiesHub = useSpaceEntitiesHub();
  const conversationId = useContext(ConversationDataContext);
  const { addMessage } = useContext(ConnectionContext);

  const [settings, setSettings] = useState({
    url: null,
    schema: null,
    isNote: null
  });

  useSpaceQuery(settings.url, settings.schema, {
    onSuccess: ({ data }) => {
      if (settings.isNote) {
        addMessage({ Note: data });
      } else addMessage({ Email: data });
      setSettings({
        url: null,
        schema: null,
        isNote: null
      });
    },
    onError: () => {
      setSettings({
        url: null,
        schema: null,
        isNote: null
      });
    }
  });

  useEffect(() => {
    if (!entitiesHub) return;
    // const { convertNote } = Settings || {};

    const listner = (dataId, isNote) => {
      setSettings({
        url: !isNote
          ? `EmailConversation/${conversationId}/Emails/${dataId}`
          : `EmailConversation/${conversationId}/Notes/${dataId}`,
        schema: isNote ? spaceNoteSchema : spaceEmailSchema,
        isNote: isNote
      });

      if (isNote) {
        updateNoteCounter(conversationId);
      } else {
        updateMessageCounter(conversationId);
      }

      // const parseData = JSON.parse(data);

      // const id = parseData.EmailConversation.LastEmail.Id;
      // // console.log("received message");
      // addMessage({ Email: id });
      // if (following === false) return;
      // if (canReadEntitiesRef.current) post(url);
      // else {
      //   markEntityAsNonRead(schema, entityId);
      //   setUnreadCount((c) => c + 1);
      //   unreadRef.current++;
      // }
    };

    entitiesHub.on("NewConversationMessageV2", listner);

    return () => entitiesHub.off("NewConversationMessageV2", listner);
  }, [conversationId, entitiesHub, updateMessageCounter, updateNoteCounter]);
};

const OuterInnerEmailChatComponent = ({ OpenedId }) => {
  const Conversation = useEmailConversation(OpenedId);
  if (Conversation) return <InnerEmailChatComponent OpenedId={OpenedId} />;
  else
    return (
      <div className="h-100 w-100 d-flex flex-column">
        <div
          className={classnames(
            "EmailChatHeader d-flex justify-content-between position-relative p-0"
          )}
        >
          <BarLoader isLoading={true} />
        </div>
        <div className="mt-0 mb-0 border-bottom" />
        {/* <EmailConnectedMessenger
        entityId={OpenedId}
        schema={spaceEmailSchema}
        roomType={RealTimeRoomTypes.Email}
      /> */}
      </div>
    );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNoteCounter: (emailCovnersationId) => {
      dispatch(NoteCountEmailConversation(emailCovnersationId));
    },
    updateMessageCounter: (emailCovnersationId) => {
      dispatch(MessageCountEmailConversation(emailCovnersationId));
    }
  };
};

const InnerEmailChatComponent = connect(
  null,
  mapDispatchToProps
)(({ OpenedId, updateNoteCounter, updateMessageCounter }) => {
  const Conversation = useEmailConversation(OpenedId);
  // console.log(Conversation);
  const space = useSpace();
  // useConvMessageSync();
  useConvMessageSyncV2(updateNoteCounter, updateMessageCounter);
  const {
    Unreads = 0,
    AccountsInRoom,
    Status,
    IsFollowing,
    IsMessageRead,
    IsMessagesRead,
    Organization,
    Tickets,
    Subject,
    Client
  } = Conversation || {};
  const intl = useIntl();
  const inputRef = useRef();
  const readRef = useRef();
  const UnreadComponent = useMemo(() => {
    if (Unreads > 0) {
      return (
        <div
          title={intl.formatMessage({ id: "NOT_READ" })}
          className="ar-badge bg-primary"
        >
          {Unreads}
        </div>
      );
    } else return null;
  }, [Unreads, intl]);

  const followUrl = useMemo(
    () => `EmailConversation/${OpenedId}/Follow`,
    [OpenedId]
  );

  const [followPost] = useSpacePost(followUrl, spaceEmailConversationSchema, {
    onSuccess: ({ Data }) => {
      // console.log(Data);
    }
  });

  const unfollowUrl = useMemo(
    () => `EmailConversation/${OpenedId}/Unfollow`,
    [OpenedId]
  );

  const [unfollowPost] = useSpacePost(
    unfollowUrl,
    spaceEmailConversationSchema,
    {
      onSuccess: ({ Data }) => {
        // console.log(Data);
      }
    }
  );

  const readRefErrorOccurred = useRef(false);

  const readUrl = useMemo(
    () =>
      (!IsMessagesRead || !IsMessageRead) && !readRefErrorOccurred.current
        ? `EmailConversation/${OpenedId}/Read`
        : null,
    [IsMessageRead, IsMessagesRead, OpenedId]
  );

  const [readPosts] = useSpacePost(readUrl, spaceEmailConversationSchema, {
    onSuccess: ({ Data }) => {
      readRef.current = true;
    },
    onError: ({ error }) => {
      readRefErrorOccurred.current = error;
    }
  });

  const isBuiltRef = useRef(false);

  useEffect(() => {
    if (
      IsFollowing &&
      (!IsMessagesRead || !IsMessageRead) &&
      !isBuiltRef.current
    ) {
      readPosts();
    }
    isBuiltRef.current = true;
  }, [IsFollowing, IsMessageRead, IsMessagesRead, readPosts]);

  // useEffect(() => {
  //   if (
  //     !readRef.current &&
  //     IsFollowing &&
  //     IsMessageRead === false &&
  //     !readRefErrorOccurred.current
  //   ) {
  //     readRef.current = true;
  //     readPosts();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const shouldFocusRef = useRef(false);

  useLayoutEffect(() => {
    if (shouldFocusRef.current) {
      shouldFocusRef.current = false;
      inputRef.current.focus();
    }
  }, []);

  const createToast = useToast();

  const openedUrl = useMemo(
    () => `EmailConversation/${OpenedId}/Status`,
    [OpenedId]
  );

  const [statusChangePost, { loading: statusChangeLoading }] = useSpacePost(
    openedUrl,
    ReceptionSchema,
    {
      onSuccess: ({ data }) => {
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "STATUS_CHANGED_SUCCESS" })
        });
      },
      onError: ({ error }) => {
        // console.log(error);
      }
    }
  );

  const handleStatusChange = useCallback(
    (status) => {
      statusChangePost(`${status}`);
    },
    [statusChangePost]
  );

  const handleBellClick = () => {
    if (IsFollowing) {
      unfollowPost();
    } else {
      followPost();
    }
  };

  const [sendNotificationModalStatus, SetsendNotificationModalStatus] =
    useState(false);

  const closeNoticationModalStatus = () => {
    SetsendNotificationModalStatus(false);
  };

  const [notificationContact, setNotificationContact] = useState(null);

  const SendNotificaitonOnChangeContact = (val) => {
    setNotificationContact(val);
  };

  const handleSendNotificationToSomeone = () => {
    SetsendNotificationModalStatus(true);
    setNotificationContact(null);
  };

  const [sendNotificationPost, { loading: sendNotificationLoading }] =
    useSpacePost(`EmailConversation/${OpenedId}/NotifyContact`, null, {
      onSuccess: () => {
        closeNoticationModalStatus();
        setNotificationContact(null);
        createToast({
          pos: "tm",
          type: "success",
          description: intl.formatMessage({ id: "NOTIFICATION_SUCCESS" })
        });
      },
      onError: () => {
        handleError(
          createToast,
          intl.formatMessage({ id: "ERROR_OCCURRED_SORRY" })
        );
      }
    });

  const handleRefetch = useContext(RefreshEmailChatConversation);

  const [downloadStatus, setDownloadStatus] = useState(false);

  const downloadUrl = useMemo(
    () =>
      `Spaces/${space.Id}/EmailConversation/Email/View?EmailConversationId=${OpenedId}&IsPreview=false&PerPage=true&Inline=true`,
    [OpenedId, space.Id]
  );

  const connectionId = useConnectionId();
  const handleCreatePdf = useCallback(() => {
    setDownloadStatus(true);
    Axios.get(downloadUrl, {
      headers: {
        ConnectionId: connectionId
      }
    }).then(
      (response) => {
        // let link = document.createElement("a");
        // link.href = window.URL.createObjectURL(response);
        // link.download = "SingleEmailExport";
        // link.click();
        // link.remove();
        createToast({
          pos: "tm",
          type: "info",
          description: intl.formatMessage({
            id: "API_EXPORT_STARTED"
          })
        });
        setDownloadStatus(false);
      },
      (error) => {
        handleError(createToast, "Ocorreu um erro a exportar");
        setDownloadStatus(false);
      }
    );
  }, [connectionId, createToast, downloadUrl, intl]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <div
        className={classnames(
          "EmailChatHeader d-flex justify-content-between position-relative",
          {
            "align-items-center px-4": Status !== undefined,
            "p-0": Status === undefined
          }
        )}
      >
        {Status === undefined ? (
          <BarLoader isLoading={true} />
        ) : (
          <>
            <div className="d-flex align-items-center text-black flex-1 of-hidden">
              <MessageItemProfileStatus Status={Status} />
              <div className="ml-2 fs-18 text-truncate flex-1">
                <span className="text-truncate">{Subject}</span>
              </div>
              {Unreads > 0 && UnreadComponent}
            </div>
            <div className="d-flex align-items-center">
              <div>
                <div className="px-3">
                  {!AccountsInRoom || AccountsInRoom.length === 0 ? (
                    <div className="float-right">―</div>
                  ) : (
                    // <ConnectedAccountProfileStack
                    //   className="float-right"
                    //   accounts={AccountsInRoom}
                    // />
                    <StaticAccountsInRoomStack
                      accountsInRoom={AccountsInRoom}
                    />
                  )}
                </div>
              </div>
              <div
                className={classnames("receptionChatStatus", {
                  open: Status === 0,
                  closed: Status === 1
                })}
              >
                <KeyedDropdown
                  fixedWidth
                  onChange={handleStatusChange}
                  disabled={statusChangeLoading}
                  ListComponent={PageSelectComponent}
                  closeOnSelect
                  valueComponent={PageSelectComponent}
                  options={EmailStatus}
                  value={Status}
                />
              </div>
              <Modal
                isOpen={sendNotificationModalStatus}
                onClose={closeNoticationModalStatus}
                enableCloseButton
              >
                <div className="bg-white p-4 rounded w90-header">
                  {Array.isArray(Tickets) && Tickets.length > 0 ? (
                    <>
                      <div className="d-flex fs-18 fw-bold justify-content-center mb-3 text-black">
                        <FormattedMessage id="ASK_NOTIFICATION_CASE"></FormattedMessage>
                      </div>
                      <div className="mb-4"></div>
                      <div className="w-100 d-flex justify-content-end">
                        <LoadableButton
                          isLoading={sendNotificationLoading}
                          onClick={() => {
                            sendNotificationPost({
                              OrganizationSpaceId: Organization,
                              TicketId: Tickets[0]
                            });
                          }}
                          vType="primary"
                        >
                          <FormattedMessage id="SEND" />
                        </LoadableButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex fs-18 fw-bold justify-content-center mb-3 text-black">
                        <FormattedMessage id="SEND_NOTIFICATION"></FormattedMessage>
                      </div>
                      <div className="mb-4">
                        <ContactDropdown
                          grid
                          labelClassName="h-100"
                          containerClassName="h-100"
                          className="h-100"
                          value={notificationContact}
                          onChange={SendNotificaitonOnChangeContact}
                          hasEmail
                          IndirectClient={Client}
                          EmailConversationId={OpenedId}
                        />
                      </div>

                      <div className="w-100 d-flex justify-content-end">
                        <LoadableButton
                          isLoading={sendNotificationLoading}
                          disabled={notificationContact === null}
                          onClick={() => {
                            sendNotificationPost({
                              ContactId: notificationContact,
                              OrganizationSpaceId: Organization,
                              TicketId: Array.isArray(Tickets)
                                ? Tickets[0]
                                : undefined
                            });
                          }}
                          vType="primary"
                        >
                          <FormattedMessage id="SEND" />
                        </LoadableButton>
                      </div>
                    </>
                  )}
                </div>
              </Modal>
              <InformationComponent
                text={intl.formatMessage({ id: "SEND_NOTIFICATION" })}
              >
                <div
                  onClick={handleSendNotificationToSomeone}
                  className={classnames("cursor-pointer ml-3 text-primary")}
                >
                  <FontAwesomeIcon icon={faEnvelopeOpenText} />
                </div>
              </InformationComponent>
              <InformationComponent text={intl.formatMessage({ id: "FOLLOW" })}>
                <div
                  onClick={handleBellClick}
                  className={classnames("cursor-pointer mx-3", {
                    "text-primary": IsFollowing
                  })}
                >
                  {IsFollowing && <FontAwesomeIcon icon={faBell} />}
                  {!IsFollowing && <FontAwesomeIcon icon={faBellLight} />}
                </div>
              </InformationComponent>
              <InformationComponent
                text={intl.formatMessage({ id: "EXPORT_PDF" })}
              >
                <div
                  className="cursor-pointer text-primary"
                  onClick={() => {
                    if (!downloadStatus) {
                      handleCreatePdf();
                    }
                  }}
                >
                  {downloadStatus ? (
                    <LoaderSpinner size="xxs" center />
                  ) : (
                    <FontAwesomeIcon icon={faFilePdf} />
                  )}
                </div>
              </InformationComponent>
              <InformationComponent text={intl.formatMessage({ id: "UPDATE" })}>
                <div
                  className="cursor-pointer ml-3 text-primary"
                  onClick={() => {
                    handleRefetch();
                  }}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </div>
              </InformationComponent>
              {/* <ConversationPdfButton fromSettings id={OpenedId} /> */}
            </div>
          </>
        )}
      </div>
      <div className="mt-0 mb-0 border-bottom" />
      <EmailConnectedMessenger
        entityId={OpenedId}
        schema={spaceEmailSchema}
        roomType={RealTimeRoomTypes.Email}
      />
    </div>
  );
});

export const downloadFile = (Url, Name, statusFunc) => {
  if (typeof statusFunc === "function") {
    statusFunc(true);
  }
  Axios.get(Url, {
    responseType: "blob"
  }).then(
    (response) => {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = Name;
      link.click();
      link.remove();
      if (typeof statusFunc === "function") {
        statusFunc(false);
      }
    },
    (error) => {
      if (typeof statusFunc === "function") {
        statusFunc(false);
      }
      // console.log(error);
    }
  );
};

const FileMaker = ({
  Attachments,
  EmailType = "",
  test,
  ParentFile,
  className,
  style,
  SetFilePreview,
  EnablePreview,
  token
}) => {
  const ResolvedAttachments = Attachments.map((e, i, arr) => {
    return (
      <div
        key={i}
        className="border  rounded ar-fileUploader_Preview_Email_Attachments_Container d-inline-flex mr-2 bg-white"
      >
        <div
          onClick={() => {
            if (validadeFilePreview(e)) {
              SetFilePreview(e);
              EnablePreview();
            } else {
              downloadFile(e.Url, e?.Name);
            }
          }}
          className="d-flex cursor-pointer justify-content-center py-2 align-items-center border-right px-2"
        >
          <ComponentElementByExtension extension={e.Extension} />
        </div>
        <div
          onClick={() => {
            if (validadeFilePreview(e)) {
              SetFilePreview(e);
              EnablePreview();
            } else {
              downloadFile(e.Url, e?.Name);
            }
          }}
          className="flex-1 d-flex ssi-button-standard-link cursor-pointer justify-content-start align-content-center align-self-center px-2 text-black"
        >
          <TextEllipsis
            text={e?.Name.split(".")[0] || ""}
            length={15}
            beforeEnd={5}
            truncate
            span
            afterText={e.Extension}
          />
        </div>
        <div
          onClick={() => {
            downloadFile(e.Url, e?.Name);
          }}
          className="d-flex cursor-pointer justify-content-center px-2 align-items-center text-color-link "
        >
          <FontAwesomeIcon icon={faDownload} />
        </div>
      </div>
    );
  });

  return (
    <div style={style} className={classnames("breakWord", className)}>
      {ResolvedAttachments}
    </div>
  );
};

const ModalFill = ({ Space, Attachement }) => {
  switch (`${Attachement.Extension.toLowerCase()}`) {
    case ".csv":
      return <DumbFilesPreview src={Attachement.Url} />;
    case ".doc":
    case ".docx":
    case ".xls":
    case ".xlr":
    case ".dotx":
    case ".xlam":
    case ".xlsx":
    case ".xlt":
    case ".pptx":
    case ".pptm":
    case ".txt":
    case ".ppt":
    case ".xps":
    case ".pps":
    case ".ppam":
    case ".tif":
      return <OfficePreview src={Attachement.Url + "&inline=false"} />;
    case ".pdf":
    case ".pdfa":
      return <PdfPreview src={Attachement.Url + "&inline=true"} />;

    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".jfif":
    case ".svg":
    case ".webp":
      return <ImagePreview src={Attachement.Url + "&inline=true"} />;
    case ".msg":
    case ".eml":
      return <EmailPreview File={Attachement} />;
    case ".webm":
    case ".mp4":
      return <VideoPreview src={Attachement.Url + "&inline=true"} />;
    default:
      return null;
  }
};

const EmailChatAttachmentsComponent = ({ Files }) => {
  const [previewFile, setPreviewFile] = useState(null);

  const [modalState, setModalState] = useState(false);

  const OpenModal = useCallback(() => {
    setModalState(true);
  }, []);

  const CloseModal = useCallback(() => {
    setModalState(false);
  }, []);

  const isImage = checkForImageType(previewFile || undefined);
  const Space = useSpace();

  const SummaryAttachmentsPreviewRef = useContext(SummaryAttachmentsPreview);
  useEffect(() => {
    if (!SummaryAttachmentsPreviewRef.current) {
      SummaryAttachmentsPreviewRef.current = (e) => {
        setPreviewFile(e);
        OpenModal();
      };
    }
    return () => {
      SummaryAttachmentsPreviewRef.current = null;
    };
  }, [OpenModal, SummaryAttachmentsPreviewRef]);
  return (
    <div>
      <Modal
        className={classnames(
          "ar-modal-preview-file-content d-flex flex-column p-1",
          {
            "ar-modal-preview-image-content": isImage,
            "ar-modal-preview-files-content-files": !isImage
          }
        )}
        extraCloseButton={
          <>
            <div
              className="ar-modal-preview-file-content-close-button"
              onClick={() => CloseModal()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div
              style={{ top: 55 }}
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => {
                downloadFile(previewFile.Url, previewFile?.Name);
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </div>
          </>
        }
        isOpen={modalState}
        onClose={CloseModal}
      >
        <ModalFill Space={Space.Id} Attachement={previewFile} />
        <div className="flex-1 text-black px-1 d-flex justify-content-between align-items-center">
          {previewFile && <span className="mr-1">{previewFile?.Name}</span>}
        </div>
      </Modal>
      <FileMaker
        style={{ maxHeight: 64 }}
        className="of-y-auto"
        SetFilePreview={setPreviewFile}
        EnablePreview={OpenModal}
        Attachments={Files}
        EmailType={".msg"}
        ParentFile={File}
      />
    </div>
  );
};

export default EmailChatComponent;
