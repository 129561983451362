import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useReducer,
  useLayoutEffect,
  useContext,
  useMemo
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import "./GlobalSearch.css";
import {
  clientQuery,
  projectQuery,
  ticketQuery,
  callQuery,
  taskQuery,
  contractQuery,
  userQuery,
  contactQuery,
  dealQuery
} from "./GlobalSearchQueries";
import { client } from "../../Helpers/IOClient";
import Axios from "axios";
import { useSpace } from "../../Contexts/SpaceContext";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { BarLoader } from "../GlobalLoader/GlobalLoader";
import {
  hasModulePermission,
  isAdmin,
  modulePermissions
} from "../../Helpers/ModulesHelper";
import { subscribeUniqueEvent } from "../../Helpers/ComponentsHelper";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { FormattedMessage, useIntl } from "react-intl";
import { ObfuscateProvider } from "../../Containers/Space/Space2";
import AirdeskTextSvg from "../Svg/AirdeskTextSvg";
import {
  faLevelUpAlt,
  faLongArrowAltDown,
  faLongArrowAltUp
} from "@fortawesome/pro-solid-svg-icons";
import InformationComponent from "../InformationComponent/InformationComponent";

const GlobalSearchResultsItem = React.memo(
  ({ query, id, isHint, hintElemRef, toggleHintResult, index }) => {
    const {
      Component,
      // getLink,
      GetFormButton
    } = query;
    // const space = useSpace();
    const close = useGlobalSearchCloser();

    const options = {
      onMouseEnter: () => toggleHintResult(index),

      className: classnames(
        "ar-global-search-result-item h-100 justify-content-start px-3 py-2",
        {
          hint: isHint
        }
      )
    };
    if (query.type !== "Users") {
      const FormButton = GetFormButton();
      return (
        <FormButton
          innerRef={hintElemRef}
          onMouseEnter={() => {
            toggleHintResult(index);
          }}
          onSuccess={close}
          forceOnlyChildren
          id={id}
          vType="link-primary"
          {...options}
        >
          <Component query={query} value={id} />
        </FormButton>

        // <Link
        //   innerRef={hintElemRef}
        //   onClick={close}
        //   to={`/s/${space.Id}/${getLink({ id })}`}
        //   {...options}
        // >
        //   <Component query={query} value={id} />
        // </Link>
      );
    } else
      return (
        <div
          onMouseEnter={() => {
            toggleHintResult(index);
          }}
          ref={hintElemRef}
          {...options}
        >
          <Component query={query} value={id} />
        </div>
      );
  }
);

const GlobalSearchResultsItems = React.memo(
  ({ query, results, hint, hintElemRef, toggleHint, index }) => {
    const toggleHintResult = useCallback(
      (resultIndex) => {
        toggleHint(index, resultIndex);
      },
      [index, toggleHint]
    );

    return (
      <div className="ar-global-search-result-items">
        {results.map((v, index) => {
          const isHint = hint && hint.id === v;
          return (
            <GlobalSearchResultsItem
              index={index}
              toggleHintResult={toggleHintResult}
              hintElemRef={isHint ? hintElemRef : undefined}
              isHint={isHint}
              isHintDown={isHint && hint.isDown}
              query={query}
              key={v}
              id={v}
            />
          );
        })}
      </div>
    );
  }
);

const GlobalSearchResults = React.memo(({ value, hint, toggleHint }) => {
  const hintElemRef = useRef();
  const prevHintElemRef = useRef();
  const prevValueRef = useRef(value);

  const resultsRef = useRef();
  useLayoutEffect(() => {
    if (!value || value.length === 0) return;
    const resultsElem = resultsRef.current;
    const hintElem = hintElemRef.current;
    if (value !== prevValueRef.current) {
      prevValueRef.current = value;
      prevHintElemRef.current = hintElem;
      resultsElem.scrollTop = 0;
      return;
    }

    if (hintElem === prevHintElemRef.current) return;

    if (!hintElem) return;
    if (hint.isManual) return;
    const { offsetHeight, scrollTop } = resultsElem;
    const totalHeight = offsetHeight + scrollTop;

    if (
      prevHintElemRef.current &&
      prevHintElemRef.current.offsetTop < hintElem.offsetTop
    ) {
      if (hintElem.offsetTop + hintElem.offsetHeight >= totalHeight)
        hintElem.scrollIntoView(false);
    } else {
      if (hintElem.offsetTop <= scrollTop) hintElem.scrollIntoView(true);
    }
    prevHintElemRef.current = hintElem;
  });

  return (
    <div className="w-50 ar-global-search-results" ref={resultsRef}>
      {value.map((v, index) => {
        const { query, results } = v;
        const { name, type } = query;
        return (
          <div className="ar-global-search-result" key={type}>
            <div className="ar-global-search-result-title px-3 py-1 fs-14 text-black">
              {name}
            </div>
            <GlobalSearchResultsItems
              toggleHint={toggleHint}
              index={index}
              hintElemRef={hintElemRef}
              hint={query === hint.query ? hint : undefined}
              query={query}
              results={results}
            />
          </div>
        );
      })}
    </div>
  );
});

const GlobalSearchPreview = ({ hint }) => {
  ////;
  if (!hint) return null;

  const { query, id } = hint;

  const { PreviewComponent } = query;
  return (
    <div className="w-50 d-flex of-hidden justify-content-around border-left">
      <PreviewComponent query={query} value={id} />
    </div>
  );
};

const GlobalSearchKey = ({ children, icon, transform, className }) => {
  return (
    <div
      className={classnames(
        "ar-global-search-key d-flex justify-content-center align-items-center rounded",
        className
      )}
    >
      {!icon ? (
        <span className="fs-10 fw-medium">{children}</span>
      ) : (
        <div style={transform ? { transform } : undefined}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
    </div>
  );
};

const GlobalSearchKeyText = ({
  children,
  icons,
  iconText,
  transform,
  className
}) => {
  return (
    <div className={classnames("d-flex align-items-center", className)}>
      {iconText ? (
        <GlobalSearchKey
          children={iconText}
          transform={transform}
          className="mr-1"
        />
      ) : (
        icons.map((icon, i) => {
          return (
            <GlobalSearchKey
              key={i}
              icon={icon}
              transform={transform}
              className="mr-1"
            />
          );
        })
      )}

      <div className="fs-12">{children}</div>
    </div>
  );
};

const GlobalSearchBody = ({ value, toggleHint, loading, hint, text }) => {
  if (!text) return null;

  if (!value) return <BarLoader isLoading={loading} />;

  if (value.length === 0) {
    return (
      <>
        <BarLoader isLoading={loading} />
        <div className="d-flex flex-column py-5 align-items-center justify-content-center text-black ar-global-search-body">
          <div className="ar-global-search-no-results-icon mb-2" />
          <div>
            <FormattedMessage id={"NO_RECORDS"} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <BarLoader isLoading={loading} />
      <div className="d-flex flex-column overflow-hidden">
        <div className="d-flex flex-1 of-y-auto ar-global-search-body">
          <GlobalSearchResults
            toggleHint={toggleHint}
            hint={hint}
            value={value}
          />
          <GlobalSearchPreview hint={hint} />
        </div>
        <div className="ar-global-search-footer d-flex justify-content-between align-items-center px-4">
          <div className="d-flex">
            <GlobalSearchKeyText
              className="mr-3"
              icons={[faLevelUpAlt]}
              transform="scaleY(-1) rotate(270deg)"
            >
              <FormattedMessage id="GLOBAL_SEARCH_DEF_TO_SEARCH" />
            </GlobalSearchKeyText>

            <GlobalSearchKeyText
              className="mr-3"
              icons={[faLongArrowAltUp, faLongArrowAltDown]}
            >
              <FormattedMessage id="GLOBAL_SEARCH_DEF_TO_NAVIGATE" />
            </GlobalSearchKeyText>

            <GlobalSearchKeyText className="mr-3" iconText="Esc">
              <FormattedMessage id="GLOBAL_SEARCH_DEF_TO_CLOSE" />
            </GlobalSearchKeyText>

            {/* <GlobalSearchKeyText
              className="mr-2"
              icon={faLevelUpAlt}
              transform="scaleY(-1) rotate(270deg)"
            >
              para selecionar
            </GlobalSearchKeyText> */}
          </div>
          <AirdeskTextSvg className="ar-global-search-footer-air-icon text-secondary" />
        </div>
      </div>
    </>
  );
};

const GlobalSearchInput = ({ loading, onClose, ...rest }) => {
  const inputRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    let frame;

    frame = requestAnimationFrame(() => {
      frame = requestAnimationFrame(() => {
        frame = requestAnimationFrame(() => {
          inputRef.current.focus();
        });
      });
    });
    return () => cancelAnimationFrame(frame);
  }, []);

  return (
    <div className="border-bottom d-flex">
      <div className="ml-3 d-flex align-items-center">
        <FontAwesomeIcon size="lg" icon={faSearch} />
      </div>
      <input
        {...rest}
        autoFocus
        ref={inputRef}
        onBlur={(e) => e.nativeEvent.stopImmediatePropagation()}
        placeholder={intl.formatMessage({ id: "SEARCH_CASES_CALLS" })}
        className="ar-global-search-input flex-1 py-3 px-3"
      />
      {/* <div
        className="px-3 cursor-pointer text-black d-flex align-items-center"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </div> */}
    </div>
  );
};

const buildQueriesState = (queries, dict) => {
  const arr = [];
  for (const q of queries) {
    const { type } = q;
    const queryState = dict[type];
    if (queryState && queryState.data.length > 0)
      arr.push({ query: q, results: queryState.data });
  }
  return arr;
};

const useMultipleSearchQueries = (queries, text, { onSuccess }) => {
  const queriesRef = useRef({});
  const sourceRef = useRef({});

  const space = useSpace();

  useEffect(() => {
    if (!text) return;

    let successRef = { current: 0 };

    for (const query of queries) {
      const { type, endpoint, schema } = query;
      const url = `spaces/${space.Id}/${endpoint}?query=${text}&filterBlock=false&$top=10`;
      const source = Axios.CancelToken.source();
      client.get(url, {
        schema: [schema],
        cancelToken: source.token,
        onSuccess: (s) => {
          successRef.current++;
          queriesRef.current[type] = s;
          if (successRef.current === queries.length) {
            // setQueriesState({ loading: false });

            onSuccess(buildQueriesState(queries, queriesRef.current));
          }
        },
        onError: () => {
          successRef.current++;
          if (successRef.current === queries.length) {
            // setQueriesState({ loading: false });

            onSuccess(buildQueriesState(queries, queriesRef.current));
          }
        }
      });
      sourceRef.current[type] = source;
    }

    const cleanup = () => {
      successRef.current = 0;
      if (successRef.current !== queries.count) {
        for (const sourceI in sourceRef.current) {
          if (sourceRef.current.hasOwnProperty(sourceI)) {
            const source = sourceRef.current[sourceI];
            source.cancel();
          }
        }
      }
      sourceRef.current = {};
    };
    return cleanup;
  }, [onSuccess, queries, space.Id, text]);
};

const baseQueries = [
  projectQuery,
  ticketQuery,
  callQuery,
  taskQuery,
  contractQuery,
  // subscriptionQuery,
  dealQuery,
  clientQuery,
  contactQuery,
  userQuery
];

const buildHint = (value, valueIndex, resultIndex, isDown, isManual) => {
  const QueryValues = value[valueIndex];
  const { query, results } = QueryValues;

  return {
    query,
    id: results[resultIndex],
    isDown,
    isManual
  };
};

const getNextResultIndex = (currentIndex, results, isDown) => {
  if (isDown) {
    const nextIndex = currentIndex + 1;
    if (nextIndex > results.length - 1) return null;
    return nextIndex;
  } else {
    if (currentIndex === 0) return null;
    else return currentIndex - 1;
  }
};

const getNextValueIndex = (currentIndex, value, isDown) => {
  if (isDown) {
    if (currentIndex === value.length - 1) return 0;
    else return currentIndex + 1;
  } else {
    if (currentIndex === 0) return value.length - 1;
    else return currentIndex - 1;
  }
};

window.getNextValueIndex = getNextValueIndex;

const useHintFromSearchQueries = (value, { onClose, history }) => {
  const prevValueRef = useRef(value);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const currentHintRef = useRef();

  if (value && value !== prevValueRef.current && value.length > 0) {
    if (value.length === 0) currentHintRef.current = null;
    else {
      currentHintRef.current = {
        hint: buildHint(value, 0, 0, false),
        valueIndex: 0,
        resultIndex: 0
      };
    }
  }

  const changeHint = useCallback(
    (isDown) => {
      if (!currentHintRef.current) return;

      const { valueIndex, resultIndex } = currentHintRef.current;

      const { results } = value[valueIndex];

      const newResultIndex = getNextResultIndex(resultIndex, results, isDown);

      if (newResultIndex !== null) {
        currentHintRef.current = {
          hint: buildHint(value, valueIndex, newResultIndex, isDown),
          valueIndex: valueIndex,
          resultIndex: newResultIndex
        };
        forceUpdate();
        return;
      } else {
        const newValueIndex = getNextValueIndex(valueIndex, value, isDown);
        const newResultIndex = isDown
          ? 0
          : value[newValueIndex].results.length - 1;
        currentHintRef.current = {
          hint: buildHint(value, newValueIndex, newResultIndex, isDown),
          valueIndex: newValueIndex,
          resultIndex: newResultIndex
        };
        forceUpdate();
        return;
      }
    },
    [value]
  );

  const space = useSpace();

  const handleKeyDown = useCallback(
    (e) => {
      const isDown = e.keyCode === 40;
      //arrow up or down
      if (isDown || e.keyCode === 38) {
        e.preventDefault();
        changeHint(isDown);
      }
      //enter
      else if (e.keyCode === 13) {
        const resolvedHint =
          currentHintRef.current && currentHintRef.current.hint;
        if (!resolvedHint) return;

        const { query, id } = resolvedHint;

        const { getLink } = query;

        if (getLink) {
          history.push(`/s/${space.Id}/${getLink({ id })}`);
          onClose();
        }
      }
    },
    [changeHint, history, onClose, space.Id]
  );

  const toggleHint = useCallback(
    (valueIndex, resultIndex) => {
      currentHintRef.current = {
        hint: buildHint(value, valueIndex, resultIndex, false, true),
        valueIndex,
        resultIndex
      };
      forceUpdate();
    },
    [value]
  );

  prevValueRef.current = value;

  const resolvedHint = currentHintRef.current && currentHintRef.current.hint;

  return {
    hint: resolvedHint,
    handleKeyDown,
    toggleHint
  };
};

const GlobalSearchClosureContext = React.createContext();

export const useGlobalSearchCloser = () =>
  useContext(GlobalSearchClosureContext);

const Content = withRouter(({ onClose, history }) => {
  const [text, setText] = useState("");
  const [resolvedText, setResolvedText] = useState("");

  const [queriesState, setQueriesState] = useState({ loading: false });

  const handleTextChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  useEffect(() => {
    if (text === resolvedText) return;
    const timeout = setTimeout(() => {
      setResolvedText(text);
      if (!text) {
        setQueriesState({ loading: false });
      } else {
        setQueriesState((s) => {
          return {
            ...s,
            loading: true
          };
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [resolvedText, text]);

  const handleSuccess = useCallback((r) => {
    setQueriesState({
      loading: false,
      value: r
    });
  }, []);

  const accountSpace = useCurrentAccountSpace();

  const resolvedQueries = useMemo(() => {
    if (isAdmin(accountSpace)) return baseQueries;
    const arr = [];
    for (const query of baseQueries) {
      const { moduleType } = query;
      if (moduleType) {
        const Module = accountSpace.Modules[moduleType];

        if (
          !hasModulePermission(Module, modulePermissions.DepartementReadOnly) &&
          !hasModulePermission(
            Module,
            modulePermissions.OrganizationReadOnly
          ) &&
          !hasModulePermission(Module, modulePermissions.PrivateReadOnly) &&
          !hasModulePermission(Module, modulePermissions.ReadAll) &&
          !hasModulePermission(Module, modulePermissions.TeamReadOnly)
        )
          continue;
      }
      arr.push(query);
    }

    return arr;
  }, [accountSpace]);

  const [filteredQueries, setFilteredQueries] = useState(() => {
    const result = {};

    for (const q of resolvedQueries) {
      result[q.type] = false;
    }
    return result;
  });

  const handleFilterChange = useCallback((val) => {
    setFilteredQueries(val);
  }, []);

  const filteredResolvedQueries = useMemo(() => {
    const result = [];

    for (const q of resolvedQueries) {
      if (filteredQueries[q.type]) result.push(q);
    }

    if (result.length === 0) return resolvedQueries;
    else return result;
  }, [filteredQueries, resolvedQueries]);

  useMultipleSearchQueries(filteredResolvedQueries, resolvedText, {
    onSuccess: handleSuccess
  });

  const { loading, value } = queriesState;

  const { hint, handleKeyDown, toggleHint } = useHintFromSearchQueries(value, {
    onClose,
    history
  });

  return (
    <GlobalSearchClosureContext.Provider value={onClose}>
      <div className="ar-global-search-container d-flex flex-column">
        <GlobalSearchInput
          onKeyDown={handleKeyDown}
          value={text}
          onChange={handleTextChange}
          loading={loading}
          onClose={onClose}
        />
        <GlobalSearchFilters
          handleChange={handleFilterChange}
          value={filteredQueries}
          queries={resolvedQueries}
        />
        <GlobalSearchBody
          toggleHint={toggleHint}
          hint={hint}
          loading={loading}
          text={text}
          value={value}
        />
      </div>
    </GlobalSearchClosureContext.Provider>
  );
});

const GlobalSearchFilters = ({ handleChange, value, queries }) => {
  const handleToggle = useCallback(
    (key) => {
      const newVal = { ...value };
      newVal[key] = !newVal[key];
      handleChange(newVal);
    },
    [handleChange, value]
  );

  const items = useMemo(() => {
    const result = [];

    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        const val = value[key];
        const q = queries.find((k) => k.type === key);
        result.push(
          <div
            key={key}
            onClick={() => {
              handleToggle(key);
            }}
            className={classnames(
              { "bg-airdesk text-black": !val, "bg-blue text-white": val },
              "p-2 cursor-pointer mr-2 fs-12"
            )}
            style={{ borderRadius: 7 }}
          >
            {q.name}
          </div>
        );
      }
    }

    return result;
  }, [handleToggle, queries, value]);

  return (
    <div className="border-bottom py-2 ml-3 disable-selection d-flex align-items-center">
      {items}
    </div>
  );
};

export const GlobalSearch = (props) => {
  const { className, placement, size, labelText, buttonClass } = props;
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const listener = (e) => {
      //F1
      if (e.keyCode === 112) {
        e.preventDefault();
        setIsOpen(true);
      }
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);

  useEffect(() => {
    return subscribeUniqueEvent((e) => {
      if (e.keyCode === 27) {
        e.preventDefault();
        setIsOpen(false);
      }
    });
  }, [isOpen]);

  const ObfuscateState = useContext(ObfuscateProvider);
  const handleClick = (e) => {
    if (ObfuscateState === true) e.preventDefault();
    else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <Modal
        containerClassName="ar-global-search-modal"
        isOpen={isOpen}
        enableCloseButton
        onClose={closeModal}
      >
        <Content onClose={closeModal} />
      </Modal>

      <div
        style={{
          opacity: ObfuscateState === true && 0.3,
          pointerEvents: ObfuscateState === true && "none"
        }}
        className={className || "ml-1"}
      >
        <Button
          onClick={handleClick}
          className={classnames("ar-header-rounded-icon", buttonClass)}
          vType={null}
        >
          <FontAwesomeIcon icon={faSearch} size={size ? size : "lg"} />
          {labelText && (
            <span style={{ fontWeight: 500 }} className="ml-12">
              {labelText}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

export default GlobalSearch;
