import {
  faEdit,
  faEnvelope,
  faPaperclip,
  faPaperPlane as faPaperPlaneLight
} from "@fortawesome/pro-light-svg-icons";
import {
  faAt,
  faChevronDown,
  faChevronUp
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { spaceNoteSchema } from "../../../../config/schema";
import {
  useConnectionId,
  useReconnectionStatus
} from "../../../../Containers/RealTime/RealTime";
import { useUserEntitiesHub } from "../../../../Containers/RealTime/RealTimeUser";
import PreviewEmailButton from "../../../../Containers/Reception/PreviewEmailButton";
import EmailNoteInputInput from "../../../../Containers/Reception/ReceptionEmails/ReceptionEmailMessageInputs";
import { useSchemaPost } from "../../../../Helpers/IOClient";
import { createFormData } from "../../../../Helpers/MiscHelper";
import { useNote } from "../../../../Hooks/EntityHooks";
import LoadableButton from "../../../Button/LoadableButton";
import ClipboardProvider from "../../../ClipbordProvider/ClipboardProvider";
import DocTyper from "../../../DocTyper/DocTyper";
import { getFilesAsync } from "../../../Files/Files";
import { UpploadReact } from "../../../FileUploader/Upploader";
import HTMLInput from "../../../HTMLInput/HTMLInput";
import TabStrip from "../../../TabStrip/TabStrip";
import { ReplyMessage } from "../Message/Message";
import EmailMessageInput from "./EmailMessageInput";
import MessageSenderEditFiles from "./MessageSenderEditFiles";
import MessageSenderFiles from "./MessageSenderFiles";
import { SetAddedNewMessageManualyContext } from "../MessagesHelper";

export const MessageInput = ({
  inputRef,
  onSubmit,
  EmailNote,
  disabled,
  onPasteEvent,
  placeholder,
  value,
  size,
  onChange,
  onDrop
}) => {
  // const dragging = useDragDropEffect();

  const [dragging, setDragging] = useState(false);

  const handleDrop = (e) => {
    setDragging(false);
    onDrop && onDrop(e);
  };

  const handleKeyDown = (e) => {
    //enter
    if (e.keyCode === 13 && !e.shiftKey) {
      //Stops enter from creating a new line
      e.preventDefault();
      onSubmit();
    }
  };
  return (
    <ClipboardProvider>
      <HTMLInput
        style={{ minHeight: 40 }}
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        onDrop={handleDrop}
        onPasteEvent={onPasteEvent}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        className={classnames("ar-entity-summary-message-textarea flex-1", {
          dragging
        })}
        // disabled={loading}
        value={value}
        onChange={onChange}
      />
    </ClipboardProvider>
  );
};

// const EmailMessageInputFunction = React.forwardRef((props, ref) => (
//   <EmailMessageInput {...props} ref={ref} />
// ));

export const EmailInput = ({
  inputRef,
  onSubmit,
  disabled,
  onPasteEvent,
  placeholder,
  value,
  onChange,
  onDrop,
  ...rest
}) => {
  // const dragging = useDragDropEffect();

  const [dragging, setDragging] = useState(false);

  const handleDrop = (e) => {
    setDragging(false);
    onDrop && onDrop(e);
  };

  const handleKeyDown = (e) => {
    //enter
    if (e.keyCode === 13 && !e.shiftKey) {
      //Stops enter from creating a new line
      e.preventDefault();
      if (onSubmit) onSubmit();
    }
  };
  return (
    <ClipboardProvider>
      <DocTyper
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        onDrop={handleDrop}
        onPasteEvent={onPasteEvent}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        className={classnames("ar-entity-summary-message-textarea", {
          dragging
        })}
        // disabled={loading}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {/* <HTMLEditor
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        onDrop={handleDrop}
        onPasteEvent={onPasteEvent}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        className={classnames("ar-entity-summary-message-textarea", {
          dragging
        })}
        // disabled={loading}
        value={value}
        onChange={onChange}
        {...rest}
      /> */}
    </ClipboardProvider>
  );
};

export const useMessagePost = (id, options) => {
  const conId = useConnectionId();
  return useSchemaPost(spaceNoteSchema, id, {
    ...options,
    headers: {
      ConnectionID: conId
    }
  });
};

// const useDragDropEffect = () => {
//   const [dragging, setDragging] = useState(false);
//   const entersRef = useRef(0);
//   useEffect(() => {
//     let prevState = false;
//     const updateState = (increment, droped) => {
//       if (droped) entersRef.current = 0;
//       else if (increment) entersRef.current++;
//       else entersRef.current--;
//       // console.log(entersRef.current);
//       if (entersRef.current) {
//         if (!prevState) {
//           prevState = true;
//           setDragging(true);
//         }
//       } else {
//         if (prevState) {
//           prevState = false;
//           setDragging(false);
//         }
//       }
//     };

//     const handleDragStart = (e) => {
//       updateState(true);
//     };
//     const handleDragEnd = () => updateState();

//     const handleDrop = (e) => {
//       e.preventDefault();
//       ;
//       updateState(true, true);
//     };

//     window.addEventListener("drop", handleDrop);
//     window.addEventListener("dragenter", handleDragStart);
//     window.addEventListener("dragleave", handleDragEnd);
//     return () => {
//       window.removeEventListener("drop", handleDrop);
//       window.removeEventListener("dragenter", handleDragStart);
//       window.removeEventListener("dragleave", handleDragEnd);
//     };
//   }, []);
//   // useEffect(() => {
//   //   console.log(`isDragging: ${dragging}`);
//   // }, [dragging]);
//   return dragging;
// };

export const EmailPreviewFormContext = React.createContext();

const EmailCreationMessageSender = ({
  type,
  schema,
  roomType,
  entityId,
  replyMessageId,
  messages,
  onChangeReplyMessageId,
  addMessage
}) => {
  // const { Id } = useCurrentAccount();

  const inputRef = useRef();

  const shouldFocusRef = useRef(false);

  // const setMessages = useContext(MessagesSetterContext);
  const isReconnecting = useReconnectionStatus();

  useLayoutEffect(() => {
    if (shouldFocusRef.current) {
      shouldFocusRef.current = false;
      if (inputRef.current && inputRef.current.focus) inputRef.current.focus();
    }
  });

  useLayoutEffect(() => {
    if (replyMessageId && inputRef.current.focus) inputRef.current.focus();
  }, [replyMessageId]);

  const [tabIndex, setTabIndex] = useState(0);
  // useDragDropEffect();

  const disabled = isReconnecting;

  const intl = useIntl();
  const [isHidden, setIsHidden] = useState(false);
  const handleTabSwitching = (i) => {
    setTabIndex(i);
    if (isHidden) toggleHidden();
  };

  const [formMinimized, setFormMinimized] = useState(true);

  const tabItems = useMemo(() => {
    return [
      {
        default: true,
        exact: true,
        name: (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            <FormattedMessage id="SEND_EMAIL" />
          </div>
        ),
        component: (
          <EmailMessageInput
            roomType={roomType}
            messages={messages}
            formMinimized={formMinimized}
            setFormMinimized={setFormMinimized}
            onFocus={formMinimized ? () => setFormMinimized(false) : undefined}
            onChangeReplyMessageId={onChangeReplyMessageId}
            addMessage={addMessage}
            entityId={entityId}
            schema={schema}
            disabled={disabled}
            placeholder={intl.formatMessage({ id: "WRITE_MESSAGE_OR_DROP" })}
          />
        )
      },
      {
        default: true,
        exact: true,
        name: (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            <FormattedMessage id="CREATE_INTERNAL_NOTE" />
          </div>
        ),
        component: (
          <EmailNoteInputInput
            roomType={roomType}
            inputRef={inputRef}
            disabled={disabled}
            onChangeReplyMessageId={onChangeReplyMessageId}
            addMessage={addMessage}
            entityId={entityId}
            placeholder={intl.formatMessage({ id: "WRITE_MESSAGE_OR_DROP" })}
          />
        )
      }
    ];
  }, [
    addMessage,
    disabled,
    entityId,
    formMinimized,
    intl,
    onChangeReplyMessageId,
    roomType,
    schema
  ]);

  const toggleMinimized = () => {
    setFormMinimized((m) => !m);
    if (isHidden) setIsHidden(false);
  };

  const toggleHidden = () => {
    setIsHidden((m) => !m);
  };

  const FormRef = useRef({});

  const [size, setSize] = useState(240);

  const handler = useCallback(() => {
    function onMouseMove(e) {
      setSize((currentSize) =>
        currentSize - e.movementY > 340 ? currentSize - e.movementY : 340
      );
    }
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <EmailPreviewFormContext.Provider value={FormRef}>
      <form
        // style={{ height: size > 600 ? 600 : size }}
        style={{ height: isHidden && 70 }}
        className={classnames(
          "border-top px-4 ar-message-sender air-email-chat-footer d-flex flex-column",
          { "overflow-auto": !isHidden }
        )}
      >
        {/* <div onMouseDown={handler} className="pt-3 messages-drag-resize"></div> */}
        {replyMessageId && (
          <ReplyMessage
            className="mb-3"
            replyMessageId={replyMessageId}
            onChangeReplyMessageId={onChangeReplyMessageId}
          />
        )}
        <TabStrip
          noUnmout
          extraHeaderContent={
            <div className="d-flex align-items-center justify-content-end">
              {tabIndex === 0 && (
                <>
                  <PreviewEmailButton emailConversationId={entityId} />
                  <div
                    className={classnames("cursor-pointer ml-3", {
                      "text-primary": !formMinimized
                    })}
                    onClick={toggleMinimized}
                  >
                    <FontAwesomeIcon icon={faAt} />
                  </div>
                </>
              )}
              <div className="cursor-pointer ml-3" onClick={toggleHidden}>
                {isHidden ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </div>
          }
          headerClassName="d-flex justify-content-between align-items-center"
          contentClassName="pt-1 of-v"
          index={tabIndex}
          switchTab={handleTabSwitching}
          onTabChange
          alignLeft
          route={false}
          content
          items={tabItems}
          className="ar-details-tabstrip flex-1"
        />
        {/* {files && files.length > 0 && (
				<div className="mb-1 mt-2 flex-1 of-y-auto of-x-hidden">
				<MessageSenderFiles files={files} setFiles={setFiles} />
        </div>
				)}
				<div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
				<label className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button">
            <FontAwesomeIcon icon={faPaperclip} />

            <input
						className="ar-fileUploader__inputFile"
						// accept={accept && accept.join(",")}
						type="file"
						multiple
						onChange={handleDrop}
            />
						</label>
          <div className="ml-3">
					<UpploadReact onFileDone={HandleExtraUploads} Chat={true} />
          </div>
					</div>
					<button
          disabled={
						(tabIndex === 0 && text === "") ||
            (tabIndex === 1 && textNoHtml === "") ||
            disabled
          }
          className="float-right ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
          onClick={handleSubmit}
					>
          <FontAwesomeIcon icon={faPaperPlane} />
					</button>
				</div> */}
      </form>
    </EmailPreviewFormContext.Provider>
  );
};

const CreationMessageSender = ({
  type,
  schema,
  roomType,
  entityId,
  replyMessageId,
  messageType,
  noteCreator,
  onChangeReplyMessageId,
  addMessage
}) => {
  // const { Id } = useCurrentAccount();

  const [text, setText] = useState("");
  useMessageTextSync(text, roomType, entityId, noteCreator);
  const inputRef = useRef();

  const shouldFocusRef = useRef(false);
  const setAddedNewMessage = useContext(SetAddedNewMessageManualyContext);
  // const setMessages = useContext(MessagesSetterContext);
  const isReconnecting = useReconnectionStatus();

  const [post, { loading }] = useMessagePost(null, {
    onSuccess: ({ data }) => {
      // addComment(data);
      // onClose();
      shouldFocusRef.current = true;
      onChangeReplyMessageId();
      addMessage(data);
      setAddedNewMessage && setAddedNewMessage(true);
      // ;
      // if (ContainerItems)
      //   ContainerItems[0].scrollTop = ContainerItems[0].scrollHeight;

      // updateCommentSchemas(schema, entityId, data);
      setText("");
      setFiles([]);
    }
  });

  useLayoutEffect(() => {
    if (shouldFocusRef.current) {
      shouldFocusRef.current = false;
      inputRef.current.focus();
    }
  });

  useLayoutEffect(() => {
    if (replyMessageId) inputRef.current.focus();
  }, [replyMessageId]);

  const [files, setFiles] = useState([]);
  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (text === "" && files.length === 0) return;
    const data = createFormData(
      {
        noteId: replyMessageId,
        Description: text,
        [`${type}Id`]: entityId,
        type: messageType || 2
      },
      files
    );
    post(data);
  };
  // useDragDropEffect();

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer
      ? await getFilesAsync(e.dataTransfer)
      : e.currentTarget.files;
    setFiles((f) => [...f, ...files]);
  };

  const handlePaste = (e) => {
    setFiles((f) => [...f, ...e]);
  };

  const disabled = loading || isReconnecting;

  const intl = useIntl();

  const HandleExtraUploads = (f) => {
    setFiles((e) => [...e, f]);
  };

  const handleDragBar = useRef();

  const [size, setSize] = useState(160);

  const handler = useCallback(() => {
    function onMouseMove(e) {
      setSize((currentSize) =>
        currentSize - e.movementY > 115 ? currentSize - e.movementY : 115
      );
    }
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, []);

  const resolvedSize = useMemo(() => {
    if (files.length >= 3 && size < 220) {
      return 220;
    } else if (files.length >= 1 && size < 180) {
      return 180;
    } else if (size > 350) return 350;
    else return size;
  }, [files.length, size]);

  return (
    <form
      onSubmit={handleSubmit}
      style={{ height: resolvedSize }}
      className="border-top pb-3 mx-4 ar-message-sender d-flex flex-column"
    >
      {replyMessageId && (
        <ReplyMessage
          className=""
          replyMessageId={replyMessageId}
          onChangeReplyMessageId={onChangeReplyMessageId}
        />
      )}
      <div
        ref={handleDragBar}
        onMouseDown={handler}
        className="pt-3 messages-drag-resize"
      ></div>
      <MessageInput
        onDrop={handleDrop}
        onPasteEvent={handlePaste}
        inputRef={inputRef}
        onSubmit={handleSubmit}
        disabled={disabled}
        placeholder={intl.formatMessage({
          id: noteCreator ? "WRITE_NOTE_OR_DROP" : "WRITE_MESSAGE_OR_DROP"
        })}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      {files && files.length > 0 && (
        <div
          style={{ maxHeight: 96 }}
          className="mb-1 mt-2 flex-0-0-auto of-y-auto of-x-hidden"
        >
          <MessageSenderFiles files={files} setFiles={setFiles} />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between mt-2">
        <div className="d-flex align-items-center">
          <label className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button">
            <FontAwesomeIcon icon={faPaperclip} />

            <input
              className="ar-fileUploader__inputFile"
              // accept={accept && accept.join(",")}
              type="file"
              multiple
              onChange={handleDrop}
            />
          </label>
          <div className="">
            <UpploadReact onFileDone={HandleExtraUploads} Chat={true} />
          </div>
        </div>
        <LoadableButton
          isLoading={loading}
          disabled={text === ""}
          className="float-right"
          onClick={handleSubmit}
        >
          <FormattedMessage id="SEND" />
          <FontAwesomeIcon className="ml-2" icon={faPaperPlaneLight} />
        </LoadableButton>
        {/* <button
          disabled={(text === "" && files.length === 0) || disabled}
          className="float-right ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button> */}
      </div>
    </form>
  );
};

const EditMessageSender = ({
  editMessageId,
  noteCreator,
  onChangeEditMessageId,
  roomType,
  entityId
}) => {
  const { Description, Files: ActiveFiles } = useNote(editMessageId);

  const [allFiles, setAllFiles] = useState(() => {
    return [...(ActiveFiles || [])];
  });

  const [text, setText] = useState(Description);

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer
      ? await getFilesAsync(e.dataTransfer)
      : e.currentTarget.files;
    setAllFiles((f) => [...f, ...files]);
  };

  useMessageTextSync(text, roomType, entityId);

  const inputRef = useRef();

  // useLayoutEffect(()=>{
  // 	if(allFiles.length >= 3){
  // 		setSize((oldS)=>oldS+96)
  // 	}else if()
  // }.[allFiles.length])

  useLayoutEffect(() => {
    inputRef.current.focus();
  }, []);

  const [post, { loading }] = useMessagePost(editMessageId, {
    onSuccess: () => onChangeEditMessageId()
  });

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (text === "") return;

    const data = createFormData(
      {
        Description: text,
        type: noteCreator ? 3 : 2
        // Files: JSON.stringify(oldFiles),
      },
      [...allFiles]
    );

    post(data);
  };
  const intl = useIntl();

  const handleDragBar = useRef();

  const [size, setSize] = useState(240);

  const handler = useCallback(() => {
    function onMouseMove(e) {
      setSize((currentSize) =>
        currentSize - e.movementY > 200 ? currentSize - e.movementY : 200
      );
    }
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ height: size > 450 ? 450 : size }}
        className="border-top pb-3 mx-4 ar-message-sender d-flex flex-column"
      >
        <div
          ref={handleDragBar}
          onMouseDown={handler}
          className="pt-3 messages-drag-resize"
        ></div>
        <ReplyMessage
          className="mb-3"
          replyMessageId={editMessageId}
          onChangeReplyMessageId={onChangeEditMessageId}
        />
        <MessageInput
          onDrop={handleDrop}
          inputRef={inputRef}
          onSubmit={handleSubmit}
          disabled={loading}
          placeholder={intl.formatMessage({ id: "WRITE_MESSAGE_OR_DROP" })}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <MessageSenderEditFiles files={allFiles} setFiles={setAllFiles} />
        {/* {oldFiles && oldFiles.length > 0 && <MessageFiles files={oldFiles} />}
      {files && files.length > 0 && (
        <div className="mb-1">
          <MessageSenderFiles files={files} setFiles={setFiles} />
        </div>
      )} */}
        <div className="d-flex align-items-center flex-0-0-auto justify-content-between">
          <label className="ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button">
            <FontAwesomeIcon icon={faPaperclip} />

            <input
              className="ar-fileUploader__inputFile"
              // accept={accept && accept.join(",")}
              type="file"
              multiple
              onChange={handleDrop}
            />
          </label>
          <LoadableButton
            isLoading={loading}
            disabled={text === ""}
            className="float-right"
            onClick={handleSubmit}
          >
            <FormattedMessage id="SEND" />
            <FontAwesomeIcon className="ml-2" icon={faPaperPlaneLight} />
          </LoadableButton>
          {/* <button
            disabled={text === "" || loading}
            className="float-right ar-entity-summary-message-rounded-button ar-entity-summary-message-send-button"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </button> */}
        </div>
      </form>
    </>
  );
};

export const useMessageTextSync = (text, roomType, entityId, noteCreator) => {
  const isTypingRef = useRef(false);
  const entitiesHub = useUserEntitiesHub();
  const hasText = text.length > 0;
  const stringValue = noteCreator ? "EntityNoteTyping" : "EntityMessageTyping";

  const isUnmountingRef = useRef(false);

  let entityIdString = null;

  try {
    entityIdString = parseInt(entityId);
  } catch (error) {
    entityIdString = entityId;
  }

  const resolvedentityId =
    typeof entityIdString === "number" ? entityIdString : null;
  const entityIdGuid =
    typeof entityIdString === "string" ? entityIdString : null;

  useEffect(() => {
    return () => {
      isUnmountingRef.current = true;
    };
  });

  useEffect(() => {
    if (!entitiesHub) return;
    const isTyping = isTypingRef.current;

    if (isTyping) {
      if (!hasText) {
        isTypingRef.current = false;
        try {
          entitiesHub.invoke(
            stringValue,
            roomType,
            resolvedentityId,
            entityIdGuid,
            false
          );
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      if (hasText) {
        isTypingRef.current = true;
        try {
          entitiesHub.invoke(
            stringValue,
            roomType,
            resolvedentityId,
            entityIdGuid,
            true
          );
        } catch (error) {
          console.log(error);
        }
      }
    }

    return () => {
      if (isUnmountingRef.current && isTypingRef.current) {
        try {
          entitiesHub.invoke(
            stringValue,
            roomType,
            resolvedentityId,
            entityIdGuid,
            false
          );
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [
    entitiesHub,
    hasText,
    entityId,
    roomType,
    resolvedentityId,
    entityIdGuid,
    stringValue
  ]);
};

export const MessageSender = ({
  type,
  schema,
  roomType,
  entityId,
  replyMessageId,
  onChangeReplyMessageId,
  editMessageId,
  messageType,
  onChangeEditMessageId,
  noteCreator,
  addMessage
}) => {
  if (editMessageId) {
    return (
      <EditMessageSender
        noteCreator={noteCreator}
        editMessageId={editMessageId}
        messageType={messageType}
        onChangeEditMessageId={onChangeEditMessageId}
        entityId={entityId}
        roomType={roomType}
      />
    );
  }

  return (
    <CreationMessageSender
      noteCreator={noteCreator}
      roomType={roomType}
      addMessage={addMessage}
      type={type}
      schema={schema}
      messageType={messageType}
      entityId={entityId}
      replyMessageId={replyMessageId}
      onChangeReplyMessageId={onChangeReplyMessageId}
    />
  );
};

export const EmailMessageSender = ({
  type,
  schema,
  roomType,
  entityId,
  messages,
  replyMessageId,
  onChangeReplyMessageId,
  editMessageId,
  onChangeEditMessageId,
  addMessage
}) => {
  return (
    <EmailCreationMessageSender
      roomType={roomType}
      addMessage={addMessage}
      messages={messages}
      type={type}
      schema={schema}
      entityId={entityId}
      replyMessageId={replyMessageId}
      onChangeReplyMessageId={onChangeReplyMessageId}
    />
  );
};
